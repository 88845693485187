<template lang="pug">
.campaign-promotions
  .promotions-title {{ $t('featurePromotions.title') }}
  .promotions
    om-select-card.w-100(
      v-if="experiencesPromoVisible"
      arrow
      arrowColor="#ED5A29"
      @click="experiencesPromoClick"
    )
      template(#title)
        .brand-select-card-title {{ $t('featurePromotions.experiences.title') }}
      template(#details)
        .brand-select-card-details {{ $t('featurePromotions.experiences.description') }}
      template(#iconContent)
        img(:src="require(`@/assets/admin/svg/icons/experiences.svg`)")
    om-select-card.w-100(
      v-if="followUpPromoVisible"
      arrow
      arrowColor="#ED5A29"
      @click="followUpPromoClick"
    )
      template(#title)
        .brand-select-card-title {{ $t('featurePromotions.couponFollowUp.title') }}
      template(#details)
        .brand-select-card-details {{ $t('featurePromotions.couponFollowUp.description') }}
      template(#iconContent)
        img(:src="require(`@/assets/admin/svg/icons/discount.svg`)")
    popper(
      v-if="smartPopupPromoVisible && smartPopupsPopoverVisible"
      key="smartPopupsPopper"
      :force-show="true"
      trigger="click"
      :options="{ placement: 'top' }"
      transition="fade"
      enter-active-class="fade-enter-active"
      leave-active-class="fade-leave-active"
    )
      .popper.mt-3.brand-tooltip.brand-tooltip-light.ml-n1(style="margin-bottom: -4px")
        feature-info.w-100(@close="hideSmartPopupsPopover" :withImage="true")
          template(slot="image")
            img(
              :src="require('@/assets/admin/img/new-campaign-flow/smartPopup/smart-popup.jpg')"
              width="155"
              height="136"
            )
          template(slot="title")
            span(v-html="$t('smartPopupsPopover.title')")
          template(slot="text")
            span(v-html="$t('smartPopupsPopover.description')")
      div(slot="reference")
        om-select-card.w-100(
          v-if="smartPopupPromoVisible"
          arrow
          arrowColor="#ED5A29"
          @click="smartPopupPromoClick"
        )
          template(#title)
            .brand-select-card-title
              div {{ $t('featurePromotions.aipersonalization.title') }}
              img.ml-2(
                v-if="smartPopupPromoNeedsBadge"
                :src="require('@/assets/admin/svg/icons/new.svg')"
              )
          template(#details)
            .brand-select-card-details {{ $t('featurePromotions.aipersonalization.description') }}
          template(#iconContent)
            img(:src="require(`@/assets/admin/svg/icons/personalize.svg`)")
    om-select-card.w-100(
      v-else-if="smartPopupPromoVisible"
      arrow
      arrowColor="#ED5A29"
      @click="smartPopupPromoClick"
    )
      template(#title)
        .brand-select-card-title
          div {{ $t('featurePromotions.aipersonalization.title') }}
          img.ml-2(
            v-if="smartPopupPromoNeedsBadge"
            :src="require('@/assets/admin/svg/icons/new.svg')"
          )
      template(#details)
        .brand-select-card-details {{ $t('featurePromotions.aipersonalization.description') }}
      template(#iconContent)
        img(:src="require(`@/assets/admin/svg/icons/personalize.svg`)")
  MoreDetails(
    :aiTypes="aiTypes"
    :about="about"
    @track="onTrack($event)"
    :type="getOptimizationType(about)"
  )
  Book(
    :about="about"
    @track="onTrack($event)"
    :type="getOptimizationType(about)"
    @close="openDetailsModal"
  )
</template>

<script>
  import FeatureInfo from '@/components/Popovers/FeatureInfo.vue';
  import MoreDetails from '@/components/Modals/NewCampaignFlow/MoreDetails.vue';
  import cookie from '@/mixins/cookie';
  import { track } from '@/services/xray';
  import commonFlowsMixin from '@/mixins/commonFlows';

  const COOKIE_KEY_SMART_POPUPS = 'x-om-seen-smart-popups';

  export default {
    components: { FeatureInfo, MoreDetails },
    mixins: [cookie, commonFlowsMixin],
    props: {
      smartPopupPromoVisible: {
        type: Boolean,
        default: false,
      },
      experiencesPromoVisible: {
        type: Boolean,
        default: false,
      },
      followUpPromoVisible: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      about: 'smartPopup',
      smartPopupsPopoverVisible: false,
    }),
    computed: {
      smartPopupPromoNeedsBadge() {
        const SHOW_BEFORE_DATE = '2024-04-20';
        return new Date() < new Date(SHOW_BEFORE_DATE);
      },
    },
    watch: {
      smartPopupPromoVisible(v) {
        if (v) {
          this.showSmartPopupsPopover();
        }
      },
    },
    mounted() {
      if (this.smartPopupPromoVisible) {
        track('smartPopupBannerShow');
        this.showSmartPopupsPopover();
      }
    },
    methods: {
      experiencesPromoClick() {
        this.hideSmartPopupsPopover();
        this.$emit('startPersonalizedExperiences');
      },
      followUpPromoClick() {
        this.$emit('followUpPromoClick');
      },
      smartPopupPromoClick() {
        track('smartPopupBannerClick');
        this.hideSmartPopupsPopover();
        this.openDetailsModal();
        this.$emit('smartPopupPromoClick');
      },
      showSmartPopupsPopover() {
        if (!this.getCookie(COOKIE_KEY_SMART_POPUPS)) {
          this.setCookie(COOKIE_KEY_SMART_POPUPS, 1);
          setTimeout(() => {
            this.smartPopupsPopoverVisible = true;
          }, 500);
        }
      },
      hideSmartPopupsPopover() {
        this.smartPopupsPopoverVisible = false;
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import "@/sass/variables/_colors.sass"

  .promotions-title
    color: #272727
    font-size: 24px
    font-style: normal
    font-weight: 500
    line-height: 32px
    margin-bottom: 20px
  .promotions
    display: flex
    gap: 1rem
    span
      width: 100%
    ::v-deep .brand-select-card
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.10)
      height: 6.25rem
      border: 1px solid $om-gray-300
      border-radius: 0.25rem
      .brand-select-card-title
        display: flex
        margin-bottom: 0.25rem
</style>
