<template lang="pug">
om-modal(name="domain-management" headerBorder width="500px" @beforeOpen="beforeOpen")
  template(slot="modal-header")
    .row
      .col
        om-heading(h5) {{ $t('domainManagementTitle') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('domain-management')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    domain-input-v2(
      @selectedDomain="selectedDomain = $event"
      :selected="selectedDomain"
      :savedExternally="saveBtnClickCounter"
      @saveEvent="saveDomain"
      @refetch="refetch"
      :domainDeletable="domainDeletable"
    )
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button(secondary @click="$modal.hide('domain-management')") {{ $t('cancel') }}
      om-button.ml-3(primary @click="save") {{ $t('save') }}
</template>

<script>
  import DomainInputV2 from '@/components/DomainInputV2.vue';

  export default {
    components: {
      DomainInputV2,
    },

    props: {
      domain: { type: String },
      domainId: { type: String },
      domainDeletable: {
        type: Boolean,
        default: true,
      },
    },

    data() {
      return {
        selectedDomain: null,
        saveBtnClickCounter: 0,
      };
    },

    validations: {
      selectedDomain: {
        isCool(v) {
          return (v && v._id) || false;
        },
      },
    },

    methods: {
      beforeOpen() {
        this.selectedDomain = { _id: this.domainId, domain: this.domain };
        this.saveBtnClickCounter = 0;
      },

      refetch() {
        this.updateDomainInfos();
        this.$emit('refetch');
      },

      removeTrailingSlash(str) {
        return str.replace(/\/+$/, '');
      },

      updateDomainInfos() {
        let domain = this.selectedDomain ? this.selectedDomain.domain : '';
        domain = this.removeTrailingSlash(domain);
        const domainId = this.selectedDomain ? this.selectedDomain._id : null;

        this.$emit('update:domain', domain);
        this.$emit('update:domainId', domainId);
      },

      save() {
        this.saveBtnClickCounter++;
      },

      saveDomain() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('newCampaignFlow.domain.empty'),
          });
          this.$v.$reset();
        } else {
          this.updateDomainInfos();
          this.$modal.hide('domain-management');
        }
      },
    },
  };
</script>
