<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='30', height='30', viewBox='0 0 30 30')
  path(:fill='color', fill-rule='evenodd', d='M23.98 14a23.757 23.757 0 0 0-.492-4h3.516c.52 1.246.852 2.592.958 4H23.98zm3.024 6h-3.516c.268-1.264.438-2.606.492-4h3.98a12.829 12.829 0 0 1-.956 4zm-6.394 6.73c.966-1.286 1.77-2.894 2.356-4.73h2.992a13.066 13.066 0 0 1-5.348 4.73zM16 27.872V22h4.9c-1.074 3.114-2.846 5.322-4.9 5.872zM16 16h5.98a22.175 22.175 0 0 1-.52 4H16v-4zm0-6h5.46c.284 1.246.458 2.592.52 4H16v-4zm0-7.87c2.054.548 3.826 2.756 4.9 5.87H16V2.13zM25.958 8h-2.992c-.586-1.836-1.386-3.446-2.356-4.73A13.081 13.081 0 0 1 25.958 8zM14 8H9.1c1.074-3.114 2.844-5.322 4.9-5.87V8zm0 6H8.02c.058-1.408.236-2.754.518-4H14v4zm0 6H8.538a22.353 22.353 0 0 1-.518-4H14v4zm0 7.872c-2.056-.55-3.826-2.758-4.9-5.872H14v5.872zM4.042 22h2.994c.584 1.836 1.386 3.446 2.354 4.73A13.055 13.055 0 0 1 4.042 22zm-2.004-6H6.02c.054 1.394.224 2.736.492 4H2.996a12.885 12.885 0 0 1-.958-4zm.958-6h3.516a23.751 23.751 0 0 0-.492 4H2.038c.106-1.408.438-2.754.958-4zM9.39 3.27C8.424 4.554 7.622 6.164 7.038 8H4.042A13.07 13.07 0 0 1 9.39 3.27zM29.964 14C29.472 6.512 23.488.526 16 .034V0h-2v.034C6.512.526.526 6.512.034 14H0v2h.034C.526 23.488 6.512 29.472 14 29.964V30h2v-.036c7.488-.492 13.472-6.476 13.964-13.964H30v-2h-.036z')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
