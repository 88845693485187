export default {
  methods: {
    camelCaseToDash(str) {
      const r = str.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);
      if (r[0] === '-') {
        return r.substr(1);
      }
      return r;
    },
  },
};
