<template lang="pug">
om-modal(
  name="experience-rename"
  width="420px"
  @beforeOpen="beforeOpen"
  @opened="opened"
  headerBorder
)
  template(#modal-header)
    .row
      .col
        om-heading(h5) {{ $t('experiences.renameExperience.title') }}
    .brand-modal-action-icon
      span.cursor-pointer(
        @click="$modal.hide('experience-rename')"
        title="close"
        aria-hidden="true"
      )
        close-icon(:width="12" :height="12")
  template(#modal-body)
    input#experienceNameInput.form-control.form-control-lg(
      @keyup.enter="updateExperience"
      :class="{ 'is-invalid': $v.name.$error }"
      type="text"
      :placeholder="$t('experiences.renameExperience.placeholder')"
      v-model.trim="name"
      :aria-label="$t('experiences.renameExperience.placeholder')"
    )
  template(#modal-footer)
    .d-flex.justify-content-end
      om-button(primary @click="updateExperience") {{ $t('experiences.renameExperience.btn') }}
</template>
<script>
  import { minLength, required } from 'vuelidate/lib/validators';
  import RENAME_EXPERIENCE from '@/graphql/RenameExperience.gql';

  export default {
    data() {
      return {
        experienceId: null,
        name: '',
        index: null,
      };
    },
    validations: {
      name: {
        required,
        minLength: minLength(2),
      },
    },
    methods: {
      beforeOpen(event) {
        this.experienceId = event.params.experienceId;
        this.index = event.params.index;
        this.name = event.params.name;
      },
      opened() {
        document.getElementById('experienceNameInput').focus();
      },
      updateExperience() {
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }

        const { experienceId, name, index } = this;

        this.$apollo
          .mutate({
            mutation: RENAME_EXPERIENCE,
            variables: { experienceId, name },
          })
          .then(() => {
            this.$emit('changeExperienceName', { name, experienceId, index });
            this.$modal.hide('experience-rename');
          });
      },
    },
  };
</script>
