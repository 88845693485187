<template lang="pug">
notifications(v-bind="$attrs" group="om-toast" animation-name="v-fade-left" position="bottom right")
  template(slot="body" slot-scope="props")
    om-toast.mb-5(v-bind="props.item.data.toast")
      slot(v-bind="props")
</template>
<script>
  export default {
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
    },
  };
</script>
