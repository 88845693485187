<template lang="pug">
.brand-notification
  .d-flex.align-items-center
    slot(name="body")
    .ml-auto.cursor-pointer(@click="$emit('close')")
      slot(name="close")
        close-icon(:width="10" :height="10" :color="'white'")
  .brand-separator(v-if="brandSeparator")
</template>

<script>
  export default {
    name: 'AlertBar',
    props: {
      brandSeparator: { type: Boolean, default: true },
    },
  };
</script>
