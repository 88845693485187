<template lang="pug">
.activate-campaign
  .activate-campaign-header
    span(@click="close")
      uil-times.cursor-pointer(:size="'20px'")
  lottie(filename="activate_campaign")
  om-body-text.mb-2(bt500md) {{ $t('activateFirstCampaignPopover.title') }}
  om-body-text.mb-3(bt400sm) {{ $t('activateFirstCampaignPopover.description') }}
</template>
<script>
  import { UilTimes } from '@iconscout/vue-unicons';

  export default {
    components: { UilTimes },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
<style lang="sass">
  .activate-campaign
    position: relative
    &-header
      position: absolute
      top: 0
      right: 0
</style>
