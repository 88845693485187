<template lang="pug">
.text-color-mild-gray
  template(v-if="rule.type === 'exitIntent'")
    span {{ $t('eventLead.exitIntent', { p: prefix }) }}
  template(v-if="rule.type === 'click'")
    span {{ prefix }}
    template(v-if="$i18n.locale === 'en'")
      span clicks
      template(v-for="(expression, index) in rule.options.expression")
        div(
          v-html="$t('eventLead.click', { selector: expression.selector, device: $t(`onDevice.${expression.device}`) })"
        )
    template(v-else v-for="(expression, index) in rule.options.expression")
      div(
        v-html="$t('eventLead.click', { selector: expression.selector, device: $t(`onDevice.${expression.device}`) })"
      )
  template(v-if="rule.type === 'javascriptEvent'")
    template(v-if="$i18n.locale === 'en'")
      span {{ $t('eventLead.javascriptEvent.prefix') }}
      template(v-for="(expression, index) in rule.options.expression")
        div(
          v-html="$t('eventLead.javascriptEvent.listing', { eventName: expression.eventName, device: $t(`onDevice.${expression.device}`) })"
        )
    template(v-else v-for="(expression, index) in rule.options.expression")
      div(
        v-html="$t('eventLead.javascriptEvent', { eventName: expression.eventName, device: $t(`onDevice.${expression.device}`) })"
      )
  template(v-if="rule.type === 'scrollDown'")
    span(v-html="$t('eventLead.scrollDown', { p: prefix, percent: rule.options.percentage })")
  template(v-if="rule.type === 'inactivity'")
    span(v-html="$t('eventLead.inactivity', { p: prefix, delay: rule.options.delay })")
  template(v-if="rule.type === 'timed'")
    span(v-html="$t('eventLead.timed', { p: prefix, delay: rule.options.delay })")
</template>

<script>
  export default {
    props: {
      rule: {
        type: Object,
      },
      withPrefix: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      prefix() {
        return this.withPrefix ? this.$t('eventLead.prefix') : '';
      },
    },
  };
</script>
