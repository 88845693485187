<template lang="pug">
div
  device-chooser.mb-4(:currentRule.sync="currentRule")
  .row
    .col.d-flex.align-items-center
      span {{ $t('scrollDown1') }}
      om-input#scrollDownTrigger.w-6.mx-3(
        type="number"
        :min="0"
        :max="100"
        :error="$v.currentRule.options.percentage.$error"
        v-model="currentRule.options.percentage"
      )
      span {{ $t('scrollDown2') }}
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import { required, minValue, maxValue } from 'vuelidate/lib/validators';
  import DeviceChooser from './DeviceChooser.vue';

  export default {
    components: {
      DeviceChooser,
    },
    mixins: [settingsValidation],

    validations: {
      currentRule: {
        options: {
          percentage: {
            required,
            minValue: minValue(0),
            maxValue: maxValue(100),
          },
        },
      },
    },
  };
</script>
