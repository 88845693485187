export default {
  methods: {
    resetExpression(fieldName) {
      this.currentRule.options[fieldName] = [];
    },
    addExpression(fieldName, hash) {
      this.currentRule.options[fieldName].push(hash);
    },
    removeExpression(fieldName, expressionIndex) {
      this.currentRule.options[fieldName].splice(expressionIndex, 1);
    },
    resetExpressionGroup(fieldName, groupIndex) {
      this.currentRule.options[fieldName][groupIndex] = [];
    },
    addExpressionToGroup(fieldName, groupIndex, hash) {
      this.currentRule.options[fieldName][groupIndex].push(hash);
    },
    addExpressionGroup(fieldName, hash) {
      this.currentRule.options[fieldName].push([hash]);
    },
    positionExpressionToGroup(fieldName, groupIndex, expressionIndex, items) {
      this.currentRule.options[fieldName][groupIndex].splice(expressionIndex, 0, ...items);
    },
    replaceExpressionInGroup(fieldName, groupIndex, expressionIndex, items) {
      this.currentRule.options[fieldName][groupIndex].splice(expressionIndex, 1, ...items);
    },
    replaceExpression(fieldName, index, ...items) {
      this.currentRule.options[fieldName].splice(index, 1, ...items);
    },
    removeExpressionFromGroup(fieldName, groupIndex, expressionIndex) {
      this.currentRule.options[fieldName][groupIndex].splice(expressionIndex, 1);
      if (this.currentRule.options[fieldName][groupIndex].length === 0) {
        this.currentRule.options[fieldName].splice(groupIndex, 1);
      }
    },
    removeExpressionGroup(fieldName, groupIndex) {
      this.currentRule.options[fieldName].splice(groupIndex, 1);
    },
  },
};
