<template lang="pug">
.analytics-skeleton
  .skeleton-row.title-row
    om-heading(h3) {{ $t('analyticsPage.campaignPerformance') }}
    .one-sixth-column.align-items-start(v-for="i in 4" :key="i")
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
  .skeleton-row.header-row
    .quarter-column-flex
    .one-sixth-column(v-for="i in 6" :key="i")
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
  .skeleton-row.campaigns(v-for="index in 2")
    .quarter-column-flex
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
    .one-sixth-column(v-for="i in 6" :key="i")
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
  .skeleton-row.variants(v-for="index in 2")
    .quarter-column-flex
      img.mr-1.ml-1(:src="require(`@/assets/admin/svg/icons/u_angle-down.svg`)")
      vue-skeleton-loader.variant-loader(
        type="rect"
        width="calc(90% - 2rem)"
        height="24px"
        :rounded="rounded"
      )
    .one-sixth-column(v-for="i in 6" :key="i")
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
  .skeleton-row.campaigns(v-for="index in 2")
    .quarter-column-flex
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
    .one-sixth-column(v-for="i in 6" :key="i")
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
</template>

<script>
  export default {
    props: {
      rows: { type: Number },
      rounded: { type: Boolean },
      bottomLine: { type: Boolean },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../../sass/variables/_colors'
  .analytics-skeleton
    border: 1px solid $om-gray-400
    border-radius: 4px

  .domain-name
    max-width: 100px

  hr
    margin: 0 0.5rem 0 0
  .skeleton-row
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0.625rem 1rem 0.625rem 0.5rem


    &.campaigns
      padding: 1rem 2rem
      border-top: 1px solid $om-gray-300

    &.variants
      padding: 1rem 2rem
      border-top: 1px solid $om-gray-300

      .quarter-column-flex
        justify-content: flex-start

    &.title-row
      padding: 2rem
      justify-content: flex-start

      ::v-deep .heading-3
        color: $om-dark-grey-3
        margin-right: 6rem

    &.header-row
      padding: 1rem 2rem
      justify-content: flex-start
      background-color: #F7F7F8
      border-top: 1px solid $om-gray-300

    &.sum-row
      padding: 1.375rem 1rem 1.375rem 0.5rem

  .quarter-column-flex
      width: 25%
      display: flex
      justify-content: space-between
  .one-sixth-column
    width: calc(100% / 8)
    display: flex
    justify-content: flex-start
  .skeleton-inner-row
    display: flex
    flex-direction: column
    justify-content: space-evenly
    width: calc( 100% - 45px )

  .content-right
    display: flex
    justify-content: flex-end
</style>
