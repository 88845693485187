import axios from 'axios';
import runtimeConfig from '@/config/runtime';
import { stringify } from 'query-string';

const { VUE_APP_JF_API_URL, VUE_APP_JF_APP_ID } = runtimeConfig;

const client = axios.create({ baseURL: VUE_APP_JF_API_URL });

const sendV2Request = async ({ context, queries = [], events = [] } = {}) => {
  return client.post(
    '/v2',
    stringify({
      context: JSON.stringify(context),
      events: JSON.stringify(events),
      queries: JSON.stringify(queries),
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      withCredentials: false,
    },
  );
};

const getContext = ({ accountId = null, provider = null, shopId = null } = {}) => {
  const ctx = { addresses: [], appId: VUE_APP_JF_APP_ID };

  if (accountId) {
    ctx.addresses.push({
      provider: 'optimonk',
      providerServiceId: `${accountId}`,
      customerAddress: null,
    });
  }

  if (provider && shopId) {
    ctx.addresses.push({
      provider,
      providerServiceId: shopId,
      customerAddress: null,
    });
  }

  return ctx;
};

const collectionsQuery = (provider) => {
  if (provider === 'shoprenter') {
    return `
query {
  store: shoprenter {
    collections: categories {
      collectionId: categoryId
      title(locale: "hu")
      parent {
        collectionId: categoryId
      }
    }
  }
}
    `;
  }
  if (provider === 'shopify') {
    return `
query {
  store: shopify {
    collections {
      collectionId
      title
    }
  }
}
    `;
  }
};

export const getCollections = async (accountId, provider, shopId) => {
  try {
    const resp = await sendV2Request({
      context: getContext({ accountId, provider, shopId }),
      queries: {
        0: {
          type: 'qgql',
          values: {},
          gql: collectionsQuery(provider),
        },
      },
    });

    if (resp.status === 200 && resp.data) {
      if (resp.data.errors && Object.keys(resp.data.errors).length > 0) {
        console.log('JF api error response:', resp.data);
        const respErr = resp.data.errors[0];
        if (respErr) {
          const err = new Error(respErr.message);
          err.code = respErr.code;

          throw err;
        }

        throw new Error('Unknown error occurred when fetching collections from JF backend.');
      }
      if (resp.data.results && resp.data.results[0]) {
        return resp.data.results[0].store.collections;
      }
    }

    console.log('JF Get collections response:', resp);
    throw new Error('Failed to fetch collections');
  } catch (err) {
    console.error(err);
  }
};
