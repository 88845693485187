<template lang="pug">
om-modal(
  name="old-editor-termination"
  :modalClasses="`old-editor-termination-modal old-editor-termination-modal-page-${page}`"
  :width="800"
  color="light"
  @beforeOpen="beforeOpen"
)
  template(slot="modal-header")
    .row
      .col
        .font-weight-bold.font-size-1--25 {{ $t('oldEditorTermination.title') }}
        .my-3 {{ $t(`oldEditorTermination.lead1`) }}
        .font-weight-bold {{ $t('oldEditorTermination.callout') }}
    .old-editor-termination-modal-close(@click="$modal.hide('old-editor-termination')")
      close-icon(:width="15" :height="15")
  template(slot="modal-body")
    .row
      .col-7.d-flex.align-items-center
        .new-editor-video-holder
          video(width="435" autoplay loop)
            source(:src="require('@/assets/new-editor.mp4')" type="video/mp4")
      .col-5.pl-5.d-flex.justify-content-center.flex-column
        p(v-for="i in 5")
          i.fas.fa-check-circle.mr-2
          span {{ $t(`oldEditorTermination.features.${i}`) }}
  template(slot="modal-footer")
    .d-flex.justify-content-center
      button.brand-btn.brand-btn-lg.brand-btn-secondary.mr-4.flex-grow-1(@click="preview") {{ $t(`preview`) }}
      router-link.brand-btn.brand-btn-lg.brand-btn-primary(
        :to="{ name: 'new_campaign' }"
        tag="button"
      ) {{ $t(`oldEditorTermination.createNew`) }}
</template>

<script>
  export default {
    data() {
      return {
        page: 1,
        campaignId: null,
        variantId: null,
      };
    },

    methods: {
      beforeOpen(event) {
        this.campaignId = event.params.campaignId;
        this.variantId = event.params.variantId;

        this.page = 1;
      },

      preview() {
        this.$modal.show('old-campaign-preview', { variantId: this.variantId });
      },
    },
  };
</script>

<style lang="sass">
  .old-editor-termination-modal
    position: relative
    &-close
      cursor: pointer
      position: absolute
      top: 0.625rem
      right: 0.625rem
    &-page-1
      left: 50% !important
      transform: translateX(-50%) !important
      width: 800px !important

      .brand-modal-header
        padding: 2.125rem 1.875rem !important
        font-size: 1rem !important
      .brand-modal-body
        padding: 2.125rem 1.875rem !important
        background-color: #F4F7FA
        font-size: 1.125rem

        .new-editor-video-holder
          box-shadow: 0 .3125rem .9375rem rgba(0,0,0,.05)

        .fa-check-circle
          color: #2CC898

        p
          &:last-child
            margin-bottom: 0
      .brand-modal-footer
        padding: 1.875rem !important

        .brand-btn-secondary
          color: #9198A9

        .brand-btn
          min-width: 22.1875rem
    &-page-2
      left: 50% !important
      transform: translateX(-50%) !important
      width: 470px !important

      .brand-modal-header
        position: relative
        padding: 0 !important
        padding-bottom: 4.6875rem !important
        font-size: 1rem !important

        .viki-container
          position: absolute
          top: -25px
          left: 50%
          transform: translateX(-50%)
          font-size: 0.75rem
          color: #AAB1C1
          font-weight: 500

          img
            margin: 0 auto
            margin-bottom: 0.3125rem
      .brand-modal-body
        padding-bottom: 2.5rem !important
      .brand-modal-footer
        padding: 0 !important
</style>
