<template lang="pug">
div
  a.text-primary.brand-link(v-if="trigger" @click="$modal.show('embed-code')") {{ $t('embedCode.button') }}
  br
  a.text-primary.brand-link(@click="openWebSelector") Point & Click
  om-modal.brand-embedded-modal(
    name="embed-code"
    width="750"
    color="light"
    @beforeOpen="beforeOpen"
  )
    template(slot="modal-header")
      .w-100.text-left.font-weight-bold.font-size-1--25 {{ $t('embedCode.title') }}
    template(slot="modal-body")
      .mt-1.mb-2.text-left {{ $t('embedCode.desc') }}
      highlight.embed-code-highlight(
        :codeStr="$t('embedCode.code', { campaign: this.campaign || this.evtCampaign })"
      )
    template(slot="modal-footer")
      .d-flex.justify-content-end
        om-button(ghost @click="$modal.hide('embed-code')") {{ $t('cancel') }}
        om-button.ml-3(primary @click="done") {{ $t('okItsDone') }}
</template>
<script>
  import { get as _get } from 'lodash-es';
  import Highlight from '@/components/Highlight.vue';
  import { createEmbeddedUrl } from '@/utils/pncURLBuilder';

  export default {
    components: { Highlight },
    props: {
      trigger: { type: Boolean, default: true },
      campaign: { type: [Number, String], default: 0 },
      domain: { type: String },
      positions: { type: Array },
    },
    data: () => ({ evtCampaign: 0 }),
    methods: {
      beforeOpen(e) {
        this.evtCampaign = _get(e, 'params.campaign', 0);
      },
      done() {
        this.$modal.hide('embed-code');
      },
      openWebSelector() {
        const url = createEmbeddedUrl({
          domain: this.domain,
          campaignId: this.campaign,
          positions: this.positions,
        });
        window.open(url);
      },
    },
  };
</script>
<style lang="sass">
  .embed-code-highlight
    .brand-embedded-modal .brand-copy-code
      top: 0
      right: 0
      bottom: 0
      border-top-left-radius: 0
      border-bottom-left-radius: 0
</style>
