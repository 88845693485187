<template lang="pug">
div
  template(v-for="(expressionGroup, expressionGroupIndex) in currentRule.options.expressions")
    template(v-if="expressionGroupIndex === 0")
      .settings-label.settings-label-larger.mb-3 {{ $t('cartTargeting') }}
      template(v-for="(target, targetIndex) in expressionGroup")
        .row.align-items-center.mt-3
          .col-3
            label.settings-label(:for="'visitorCart' + target.attributeName") {{ $t(target.attributeName) }}:
          .col-3
            select.form-control.mr-2(
              :id="'visitorCart' + target.attributeName"
              v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].operator"
            )
              template(v-for="type in onlyNumberOperators")
                option(:value="type") {{ $t(type) }}
          .col-4
            template(
              v-if="currentRule.options.expressions[expressionGroupIndex][targetIndex].operator === 'interval'"
            )
              .d-flex.align-items-center
                input.form-control(
                  type="text"
                  :class="{ 'is-invalid': $v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error }"
                  :value="getIntervalValue(currentRule.options.expressions[expressionGroupIndex][targetIndex].value, 0)"
                  @change="setIntervalValue(expressionGroupIndex, targetIndex, $event.target.value, 0)"
                )
                span.mx-4 -
                input.form-control(
                  type="text"
                  :class="{ 'is-invalid': $v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error }"
                  :value="getIntervalValue(currentRule.options.expressions[expressionGroupIndex][targetIndex].value, 1)"
                  @change="setIntervalValue(expressionGroupIndex, targetIndex, $event.target.value, 1)"
                )
            template(v-else)
              input.form-control(
                type="text"
                :class="{ 'is-invalid': $v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error }"
                v-model.lazy="currentRule.options.expressions[expressionGroupIndex][targetIndex].value"
              )
      .settings-label.settings-label-larger.mt-4.mb-3 {{ $t('cartCustomTargeting') }}
    template(v-else)
      template(v-for="(target, targetIndex) in expressionGroup")
        .d-flex.align-items-center.mb-3
          .settings-label.mr-2(v-if="$i18n.locale !== 'hu'") For
          select.form-control.settings-label.w-8.mr-2(
            v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].for"
          )
            template(v-for="type in ['allItems', 'atLeastOneItem', 'noneOfTheItems']")
              option(:value="type") {{ $t(type) }}
          .settings-label {{ $t('cartCustomOne') }}
          input.form-control.w-7.mx-2(
            type="text"
            :class="{ 'is-invalid': $v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].attributeName.$error }"
            v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeName"
          )
          .settings-label {{ $t('cartCustomTwo') }}
          select.form-control.settings-label.w-7.mx-2(
            v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeType"
            @change="onTypeChange(expressionGroupIndex, targetIndex)"
          )
            option(value="string") {{ $t('text') }}
            option(value="number") {{ $t('number') }}
          .settings-label {{ $t('and') }}
          select.form-control.settings-label.w-8.mx-2(
            v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].operator"
            @change="onOperatorChange(expressionGroupIndex, targetIndex)"
          )
            option(
              v-for="type in typeBasedOperators(expressionGroupIndex, targetIndex)"
              :value="type"
            ) {{ $t(type) }}
          template(
            v-if="currentRule.options.expressions[expressionGroupIndex][targetIndex].operator === 'interval'"
          )
            .d-flex.align-items-center
              input.form-control(
                type="text"
                :class="{ 'is-invalid': $v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error }"
                :value="getIntervalValue(currentRule.options.expressions[expressionGroupIndex][targetIndex].value, 0)"
                @change="setIntervalValue(expressionGroupIndex, targetIndex, $event.target.value, 0)"
              )
              span.mx-2 -
              input.form-control(
                type="text"
                :class="{ 'is-invalid': $v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error }"
                :value="getIntervalValue(currentRule.options.expressions[expressionGroupIndex][targetIndex].value, 1)"
                @change="setIntervalValue(expressionGroupIndex, targetIndex, $event.target.value, 1)"
              )
          template(v-else)
            input.form-control.w-7(
              v-if="needsInput(currentOperator(expressionGroupIndex, targetIndex))"
              type="text"
              :class="{ 'is-invalid': $v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error }"
              v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].value"
            )
          .d-flex.flex-grow-1.justify-content-end
            .font-size-0--75.font-weight-bold.mx-2.flex-grow-1 {{ $t('andOperation') }}
            .brand-link.settings-label.mr-2.flex-grow-0.text-center(
              v-if="targetIndex === expressionGroup.length - 1"
              @click="addExpressionToGroup('expressions', expressionGroupIndex, { for: 'allItems', attributeName: '', attributeType: 'string', operator: 'equals', value: '' })"
            )
              fa-icon(variant="fa-plus-circle" size="1.5")
            .brand-link.settings-label.flex-grow-0.text-right(
              @click="removeExpressionFromGroup('expressions', expressionGroupIndex, targetIndex)"
            )
              fa-icon(variant="fa-trash" size="1.5")
      .d-flex.align-items-center.mb-3(
        v-if="expressionGroupIndex !== currentRule.options.expressions.length - 1"
      )
        .font-size-0--75.font-weight-bold.mr-2 {{ $t('orOperation') }}
        .brand-separator
  span.brand-link.settings-label(
    @click="addExpressionGroup('expressions', { for: 'allItems', attributeName: '', attributeType: 'string', operator: 'equals', value: '' })"
  )
    fa-icon(variant="fa-plus" :html="`&nbsp;${$t('addNew')}`")
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import {
    stringOperators,
    numberOperators,
    onlyNumberOperators,
    existsOperators,
  } from './statics';

  export default {
    mixins: [settingsValidation, expressionOperations],
    data: () => ({
      stringOperators,
      numberOperators,
      onlyNumberOperators,
    }),
    validations: {
      currentRule: {
        options: {
          expressions: {
            $each: {
              $each: {
                attributeName: {
                  required,
                },
                value: {
                  isReq(val, expression) {
                    if (
                      expression.for === 'cart' &&
                      this.currentRule.options.expressions.length > 1
                    ) {
                      return true;
                    }
                    return !this.needsInput(expression.operator) || val !== '';
                  },
                  isCool(val, expression) {
                    if (!this.needsInput(expression.operator)) return true;
                    if (expression.operator === 'interval') {
                      const parts = `${expression.value}`.replace(';', '-').split('-');

                      if (parts[0] === '' || parts[1] === '') return false;

                      return /^-?[0-9]*$/.test(parts[0]) && /^-?[0-9]*$/.test(parts[1]);
                    }
                    return expression.attributeType === 'number' ? /^-?[0-9]*$/.test(val) : true;
                  },
                },
              },
            },
          },
        },
      },
    },
    methods: {
      getIntervalValue(value, index) {
        const values = `${value}`.replace(';', '-').split('-');
        return values.length === 2 ? values[index] : '';
      },
      setIntervalValue(expressionGroupIndex, targetIndex, value, index) {
        const currentValue = this.currentRule.options.expressions[expressionGroupIndex][targetIndex]
          .value.length
          ? this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value
          : '-';
        const parts = currentValue.replace(';', '-').split('-');
        parts[index] = value;
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value =
          parts.join('-');
      },
      currentOperator(groupIndex, index) {
        return this.currentRule.options.expressions[groupIndex][index].operator;
      },
      typeBasedOperators(groupIndex, index) {
        return this.currentRule.options.expressions[groupIndex][index].attributeType === 'string'
          ? stringOperators
          : numberOperators;
      },
      needsInput(operator) {
        return !existsOperators.includes(operator);
      },
      onTypeChange(groupIndex, index) {
        this.currentRule.options.expressions[groupIndex][index].operator =
          this.currentRule.options.expressions[groupIndex][index].attributeType === 'string'
            ? this.stringOperators[0]
            : this.numberOperators[0];
      },
      onOperatorChange(groupIndex, index) {
        if (!this.needsInput(this.currentOperator(groupIndex, index))) {
          this.currentRule.options.expressions[groupIndex][index].value = '';
        }
      },
    },
  };
</script>
