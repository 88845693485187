<template>
  <svg
    v-if="direction === 'right'"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M14.8336 11.29L10.5936 7.04999C10.5006 6.95627 10.39 6.88187 10.2682 6.8311C10.1463 6.78033 10.0156 6.7542 9.88361 6.7542C9.75159 6.7542 9.62089 6.78033 9.49903 6.8311C9.37717 6.88187 9.26657 6.95627 9.1736 7.04999C8.98735 7.23736 8.88281 7.49081 8.88281 7.75499C8.88281 8.01918 8.98735 8.27263 9.1736 8.45999L12.7136 12L9.1736 15.54C8.98735 15.7274 8.88281 15.9808 8.88281 16.245C8.88281 16.5092 8.98735 16.7626 9.1736 16.95C9.26705 17.0427 9.37786 17.116 9.4997 17.1658C9.62154 17.2155 9.752 17.2408 9.88361 17.24C10.0152 17.2408 10.1457 17.2155 10.2675 17.1658C10.3893 17.116 10.5002 17.0427 10.5936 16.95L14.8336 12.71C14.9273 12.617 15.0017 12.5064 15.0525 12.3846C15.1033 12.2627 15.1294 12.132 15.1294 12C15.1294 11.868 15.1033 11.7373 15.0525 11.6154C15.0017 11.4936 14.9273 11.383 14.8336 11.29Z"
      fill="#505763"
    />
  </svg>
  <svg
    v-else-if="direction === 'down'"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17.0036 9.17C16.8162 8.98375 16.5628 8.87921 16.2986 8.87921C16.0344 8.87921 15.781 8.98375 15.5936 9.17L12.0036 12.71L8.46361 9.17C8.27625 8.98375 8.0228 8.87921 7.75861 8.87921C7.49442 8.87921 7.24097 8.98375 7.05361 9.17C6.95988 9.26297 6.88549 9.37357 6.83472 9.49543C6.78395 9.61729 6.75781 9.74799 6.75781 9.88C6.75781 10.012 6.78395 10.1427 6.83472 10.2646C6.88549 10.3864 6.95988 10.497 7.05361 10.59L11.2936 14.83C11.3866 14.9237 11.4972 14.9981 11.619 15.0489C11.7409 15.0997 11.8716 15.1258 12.0036 15.1258C12.1356 15.1258 12.2663 15.0997 12.3882 15.0489C12.51 14.9981 12.6206 14.9237 12.7136 14.83L17.0036 10.59C17.0973 10.497 17.1717 10.3864 17.2225 10.2646C17.2733 10.1427 17.2994 10.012 17.2994 9.88C17.2994 9.74799 17.2733 9.61729 17.2225 9.49543C17.1717 9.37357 17.0973 9.26297 17.0036 9.17Z"
      fill="#505763"
    />
  </svg>
</template>

<script>
  export default {
    props: ['direction'],
  };
</script>
