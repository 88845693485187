<template lang="pug">
.priority
  .priority-container
    .priority-num.mr-1 {{ priorityIndex }}
    .priority-arrow-container
      span.priority-increase(@click="changePriority(1)")
        UilAngleUp.cursor-pointer(size="1.5rem")
      span.priority-decrease(@click="changePriority(-1)")
        UilAngleDown.cursor-pointer(size="1.5rem")
</template>
<script>
  import { UilAngleUp, UilAngleDown } from '@iconscout/vue-unicons';

  export default {
    components: { UilAngleUp, UilAngleDown },
    props: {
      index: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      priorityIndex() {
        return this.index + 1;
      },
    },
    methods: {
      changePriority(direction) {
        this.$emit('changePriority', direction);
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .priority
    display: flex
    align-items: flex-start
    justify-content: center
  .priority-container
    display: flex
    align-items: center
    justify-content: center
  .priority-num
    background: $om-orange-500
    width: 24px
    height: 24px
    border-radius: 100%
    font-size: .85rem
    font-weight: 700
    display: flex
    color: #fff
    align-items: center
    justify-content: center
  .priority-increase,
  .priority-decrease
    display: flex
    line-height: 0
  .priority-arrow-container
    display: flex
    flex-direction: column
</style>
