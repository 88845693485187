<template lang="pug">
om-modal.delete-experience-modal(
  :clickToClose="true"
  name="delete-experience-modal"
  :width="420"
  color="light"
  @beforeOpen="beforeOpen"
  :headerBorder="true"
)
  template(slot="modal-header")
    .brand-modal-action-icon
      close-icon.cursor-pointer(
        @click.native="$modal.hide('delete-experience-modal')"
        :width="14"
        :height="14"
        color="#C2C2C2"
      )
    om-heading(h5) {{ $t('experiences.deleteExperienceModal.title', { experienceName }) }}
  template(slot="modal-body")
    om-body-text.mb-5(
      bt400md
      v-html="$t('experiences.deleteExperienceModal.description', { experienceName })"
    )
    .dont-show-again.form-check
      input#dont-show-again.form-check-input(type="checkbox" v-model="dontShowModal")
      label.form-check-label(for="dont-show-again") {{ $t('experiences.deleteExperienceModal.dontShowAgain') }}
  template(slot="modal-footer")
    .d-flex.justify-content-end.align-center
      om-button#cancelDeleteExperienceModalBtn.mr-4(
        ghost
        @click.native="$modal.hide('delete-experience-modal')"
      ) {{ $t('experiences.deleteExperienceModal.cancel') }}
      om-button#deleteExperienceModalBtn(primary @click="deleteExperience") {{ $t('experiences.deleteExperienceModal.continue') }}
</template>
<script>
  import cookie from '@/mixins/cookie';

  export default {
    name: 'DeleteExperienceModal',
    mixins: [cookie],
    data() {
      return {
        experienceId: null,
        experienceName: null,
        dontShowModal: false,
      };
    },
    methods: {
      async beforeOpen(event) {
        const { experienceId, experienceName } = event.params;
        this.experienceName = experienceName;
        this.experienceId = experienceId;
      },
      deleteExperience() {
        if (this.dontShowModal) {
          this.setCookie('x-om-not-show-delete-experience-modal', true, 360); // expire after 360 days
        }
        const { experienceId } = this;
        this.$emit('markAsDeleted', experienceId);
        this.$modal.hide('delete-experience-modal');
      },
    },
  };
</script>
<style lang="sass">
  .dont-show-again
    display: flex
    justify-content: flex-start
    align-items: center
</style>
