<template lang="pug">
div
  campaign-skeleton(
    v-if="type === 'campaign'"
    :rounded="rounded"
    :rows="rows"
    :bottomLine="bottomLine"
  )
  subscriber-skeleton(
    v-else-if="type === 'subscriber'"
    :rounded="rounded"
    :rows="rows"
    :bottomLine="bottomLine"
  )
  variant-skeleton(
    v-else-if="type === 'variant'"
    :rounded="rounded"
    :rows="rows"
    :bottomLine="bottomLine"
  )
  report-skeleton(v-else-if="type === 'report'" :rounded="rounded" :bottomLine="bottomLine")
  analytics-skeleton(v-else-if="type === 'analytics'" :rounded="rounded" :bottomLine="bottomLine")
  dashboard-stats-skeleton(
    v-else-if="type === 'dashboard-stat'"
    :rounded="rounded"
    :bottomLine="bottomLine"
  )
  experiment-skeleton(
    v-else-if="type === 'experiment'"
    :rounded="rounded"
    :bottomLine="bottomLine"
    :rows="rows"
  )
  goal-skeleton(
    v-else-if="type === 'goal'"
    :rounded="rounded"
    :bottomLine="bottomLine"
    :rows="rows"
  )
  sab-reporting-skeleton(
    v-else-if="type === 'SAB-reporting'"
    :rounded="rounded"
    :bottomLine="bottomLine"
    :rows="rows"
  )
  base-skeleton(v-else :rounded="rounded" :rows="rows" :bottomLine="bottomLine")
</template>

<script>
  import CampaignSkeleton from './CampaignSkeleton/CampaignSkeleton.vue';
  import SubscriberSkeleton from './SubscriberSkeleton/SubscriberSkeleton.vue';
  import VariantSkeleton from './VariantSkeleton/VariantSkeleton.vue';
  import ReportSkeleton from './ReportSkeleton/ReportSkeleton.vue';
  import AnalyticsSkeleton from './AnalyticsSkeleton/AnalyticsSkeleton.vue';
  import DashboardStatsSkeleton from './DashboardStatsSkeleton/DashboardStatsSkeleton.vue';
  import ExperimentSkeleton from './ExperimentSkeleton/ExperimentSkeleton.vue';
  import GoalSkeleton from './GoalSkeleton/GoalSkeleton.vue';
  import BaseSkeleton from './BaseSkeleton/BaseSkeleton.vue';
  import SabReportingSkeleton from './SabReportingSkeleton/SabReportingSkeleton.vue';

  export default {
    components: {
      CampaignSkeleton,
      SubscriberSkeleton,
      VariantSkeleton,
      BaseSkeleton,
      ReportSkeleton,
      AnalyticsSkeleton,
      DashboardStatsSkeleton,
      ExperimentSkeleton,
      GoalSkeleton,
      SabReportingSkeleton,
    },
    props: {
      rounded: { type: Boolean, default: true },
      type: { type: String },
      rows: { type: Number, default: 5 },
      bottomLine: { type: Boolean, default: true },
    },
  };
</script>

<style></style>
