<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.995 8.00409H7.40501L8.70501 6.71409C8.89332 6.52579 8.9991 6.27039 8.9991 6.00409C8.9991 5.73779 8.89332 5.4824 8.70501 5.29409C8.51671 5.10579 8.26132 5 7.99501 5C7.72871 5 7.47332 5.10579 7.28501 5.29409L4.28501 8.29409C4.19397 8.3892 4.12261 8.50134 4.07501 8.62409C3.975 8.86755 3.975 9.14063 4.07501 9.38409C4.12261 9.50684 4.19397 9.61899 4.28501 9.71409L7.28501 12.7141C7.37798 12.8078 7.48858 12.8822 7.61044 12.933C7.7323 12.9838 7.863 13.0099 7.99501 13.0099C8.12703 13.0099 8.25773 12.9838 8.37959 12.933C8.50145 12.8822 8.61205 12.8078 8.70501 12.7141C8.79874 12.6211 8.87314 12.5105 8.9239 12.3887C8.97467 12.2668 9.00081 12.1361 9.00081 12.0041C9.00081 11.8721 8.97467 11.7414 8.9239 11.6195C8.87314 11.4977 8.79874 11.3871 8.70501 11.2941L7.40501 10.0041H16.995C17.2602 10.0041 17.5146 10.1094 17.7021 10.297C17.8897 10.4845 17.995 10.7389 17.995 11.0041V15.0041C17.995 15.2693 18.1004 15.5237 18.2879 15.7112C18.4754 15.8987 18.7298 16.0041 18.995 16.0041C19.2602 16.0041 19.5146 15.8987 19.7021 15.7112C19.8897 15.5237 19.995 15.2693 19.995 15.0041V11.0041C19.995 10.2084 19.6789 9.44538 19.1163 8.88277C18.5537 8.32016 17.7907 8.00409 16.995 8.00409Z"
      :fill="disabled ? '#B9BEC6' : '#505763'"
    />
    <path
      d="M18 15.995L18 11L20 11L20 15.995C20 16.7907 19.6839 17.5537 19.1213 18.1163C18.5587 18.6789 17.7956 18.995 17 18.995L7 18.995C6.73478 18.995 6.48043 18.8897 6.29289 18.7021C6.10536 18.5146 6 18.2602 6 17.995C6 17.7298 6.10536 17.4754 6.29289 17.2879C6.48043 17.1004 6.73478 16.995 7 16.995L17 16.995C17.2652 16.995 17.5196 16.8897 17.7071 16.7021C17.8946 16.5146 18 16.2602 18 15.995Z"
      :fill="disabled ? '#B9BEC6' : '#505763'"
    />
  </svg>
</template>

<script>
  export default {
    props: ['disabled'],
  };
</script>
