<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', xmlns:xlink='http://www.w3.org/1999/xlink', width='30', height='20', viewBox='0 0 30 20')
  defs
    path#a(d='M.056.045H9.62v12.084H.056z')
  g(fill='none', fill-rule='evenodd')
    g(transform='translate(9.2 7.621)')
      mask#b(:fill='color')
        use(xlink:href='#a')
      path(:fill='color', d='M6.592 11.428a4.468 4.468 0 0 1-3.273.613A4.03 4.03 0 0 1 .19 7.047l.927-3.628.03-.105c.306.544.7 1.029 1.16 1.428l-.666 2.6a2.64 2.64 0 0 0 .313 2.077 2.641 2.641 0 0 0 1.733 1.187 2.92 2.92 0 0 0 2.14-.4 2.92 2.92 0 0 0 1.291-1.75l.928-3.629a2.641 2.641 0 0 0-.313-2.077A2.64 2.64 0 0 0 6 1.564 1.174 1.174 0 0 1 5.087.108l.01-.04c.424-.045.85-.025 1.27.06C7.912.443 9.1 1.562 9.49 3.065l-.006.007.028.128a4.08 4.08 0 0 1-.016 1.923L8.569 8.75a4.467 4.467 0 0 1-1.977 2.678', mask='url(#b)')
    path(:fill='color', d='M15.715 12.187c-.43.04-.856.017-1.268-.067-1.547-.314-2.733-1.433-3.124-2.936l.006-.007-.029-.129a4.08 4.08 0 0 1 .017-1.922l.925-3.618A4.468 4.468 0 0 1 14.22.829a4.468 4.468 0 0 1 3.272-.612 4.04 4.04 0 0 1 2.651 1.816 4.04 4.04 0 0 1 .48 3.178l-.926 3.618c-.009.035-.018.07-.029.106a5.416 5.416 0 0 0-1.16-1.428l.663-2.591a2.64 2.64 0 0 0-.313-2.077 2.64 2.64 0 0 0-1.733-1.187 2.92 2.92 0 0 0-2.14.4 2.92 2.92 0 0 0-1.291 1.75l-.926 3.62a2.64 2.64 0 0 0 .313 2.076 2.64 2.64 0 0 0 1.733 1.187c.674.137 1.083.79.913 1.455l-.012.047zM2.636 5.524a1.318 1.318 0 1 1-2.636 0 1.318 1.318 0 0 1 2.636 0M7.044 5.524a1.318 1.318 0 1 1-2.636 0 1.318 1.318 0 0 1 2.636 0M2.636 10.15a1.318 1.318 0 1 1-2.636 0 1.318 1.318 0 0 1 2.636 0M7.044 10.15a1.318 1.318 0 1 1-2.636 0 1.318 1.318 0 0 1 2.636 0M2.636 15.03a1.318 1.318 0 1 1-2.636 0 1.318 1.318 0 0 1 2.636 0M7.044 15.03a1.318 1.318 0 1 1-2.636 0 1.318 1.318 0 0 1 2.636 0M25.586 5.51a1.32 1.32 0 1 1-2.639 0 1.32 1.32 0 0 1 2.64 0M30 5.51a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0M25.586 10.141a1.32 1.32 0 1 1-2.639 0 1.32 1.32 0 0 1 2.64 0M30 10.141a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0M25.586 15.028a1.32 1.32 0 1 1-2.639 0 1.32 1.32 0 0 1 2.64 0M30 15.028a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
