<template lang="pug">
om-modal.add-variant-to-campaign-modal(
  name="add-variant-to-campaign"
  :width="640"
  color="light"
  scrollable
  :clickToClose="true"
  @beforeOpen="beforeOpen"
  @opened="onOpened"
  :headerBorder="true"
)
  template(slot="modal-header")
    .brand-modal-action-icon
      close-icon.cursor-pointer(
        @click.native="$modal.hide('add-variant-to-campaign')"
        :width="14"
        :height="14"
        color="#C2C2C2"
      )
    om-heading(h5) {{ $t('abTestModal.title') }}
  template(slot="modal-body")
    .variant-to-campaign-container.col-12.mx-0.px-0.d-flex(v-if="isDynamicContent")
      .d-flex.mb-4.flex-column.w-100
        om-heading(h6) {{ $t('abTestModal.addNewVariant') }}
        .d-flex.align-items-center
          img.variant-placeholder.mt-4.cursor-pointer(
            :src="require('@/assets/admin/svg/dynamic-content-placeholder.svg')"
            @click="newVariant"
          )
          .mt-4.ml-3
            om-body-text.variant-name.cursor-pointer(bt400md @click.native="newVariant") {{ $t('abTestModal.newVariant') }}
    .variant-to-campaign-container.col-12.mx-0.px-0.d-flex(v-if="!campaignHasControlVariant")
      .d-flex.mb-4.flex-column.w-100
        om-heading(h6) {{ $t('abTestModal.addControlVariant') }}
        .d-flex.align-items-center
          img.variant-placeholder.mt-4.cursor-pointer(
            :src="require('@/assets/admin/svg/control-variant-placeholder.svg')"
            @click="createControlVariant"
          )
          .mt-4.ml-3
            om-body-text.variant-name.cursor-pointer(bt400md @click.native="createControlVariant") {{ $t('abTestModal.controlVariant') }}
            om-body-text.variant-name.variant-name-description.cursor-pointer(
              v-if="!isDynamicContent"
              bt400xs
              @click.native="createControlVariant"
            ) {{ $t('abTestModal.controlVariantDesc') }}
            om-body-text.variant-name.variant-name-description.cursor-pointer(
              v-else
              bt400xs
              @click.native="createControlVariant"
            ) {{ $t('abTestModal.dynamicContentDesc') }}
    .variant-to-campaign-container.col-12.mx-0.px-0.d-flex
      .d-flex.mb-4.flex-column.w-100
        om-heading(h6) {{ $t('abTestModal.copyPreviousVariant') }}
        .experience-variant.d-flex.align-items-center(
          v-for="(variant, variantIndex) in variantsWithoutControl"
          :key="variantIndex"
        )
          variant-preview.mt-4(
            ref="variants"
            :key="variant._id"
            @observable="onObservable($event, '.add-variant-to-campaign-modal')"
            :allowSsr="!!campaign.templateType"
            @inited="updateDimensions"
            :dimensions="boxDimensions"
            :updatedAt="campaign.updatedAt"
            :variant="variant"
            :templateName="campaign.templateName"
            :transparentOverlay="false"
            @click="copyVariant(variant._id)"
            :templateType="campaign.templateType"
            removeHidden
            :ready="!!campaign.updatedAt"
          )
          .mt-4.ml-3
            om-body-text.variant-name.cursor-pointer(
              bt400md
              @click.native="copyVariant(variant._id)"
            ) {{ variant.name }}
          .mt-4.ml-auto.px-1
            om-chip(small :color="variant.status === 'active' ? 'green' : 'secondary'") {{ variant.status === 'active' ? $t('active') : $t('inactive') }}

  template(slot="modal-footer")
</template>
<script>
  import VariantPreview from '@/components/Template/VariantPreview.vue';
  import addVariantModalMixin from '@/mixins/addVariantModal';

  export default {
    components: {
      VariantPreview,
    },
    mixins: [addVariantModalMixin],
    computed: {
      isDynamicContent() {
        return this.campaign.templateType === 'dynamic_content';
      },
      variantsWithoutControl() {
        return this.variants.filter((variant) => {
          return !variant?.isControlVariant;
        });
      },
    },
    methods: {
      async beforeOpen(event) {
        const { campaign, variants = [] } = event.params;
        this.campaign = campaign;
        this.variants = variants;
      },

      newVariant() {
        this.$emit('newVariant');
        this.$modal.hide('add-variant-to-campaign');
      },

      createControlVariant() {
        this.$emit('createControlVariant');
        this.$modal.hide('add-variant-to-campaign');
      },

      copyVariant(variantId) {
        this.$emit('copyVariant', { variantId });
        this.$modal.hide('add-variant-to-campaign');
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .variant-name
    max-width: 300px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  .variant-name-description
    color: $om-gray-600
  .variant-placeholder
    width: 7.1875rem !important
</style>

<style lang="sass">
  @media screen and (max-width: 768px)
    .add-variant-to-campaign-modal .v--modal
      width: 90% !important
      margin: 0 5%
</style>
