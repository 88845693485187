<template lang="pug">
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 1.38462C4.76697 1.38462 1.38462 4.76697 1.38462 9C1.38462 13.233 4.76697 16.6154 9 16.6154C13.233 16.6154 16.6154 13.233 16.6154 9C16.6154 4.76697 13.233 1.38462 9 1.38462ZM0 9C0 4.00226 4.00226 0 9 0C13.9977 0 18 4.00226 18 9C18 13.9977 13.9977 18 9 18C4.00226 18 0 13.9977 0 9Z" :fill="color"/>
    <path d="M9.00005 10.1998C9.66279 10.1998 10.2 9.66255 10.2 8.9998C10.2 8.33706 9.66279 7.7998 9.00005 7.7998C8.33731 7.7998 7.80005 8.33706 7.80005 8.9998C7.80005 9.66255 8.33731 10.1998 9.00005 10.1998Z" :fill="color"/>
    <path d="M12.6922 10.1998C13.3549 10.1998 13.8922 9.66255 13.8922 8.9998C13.8922 8.33706 13.3549 7.7998 12.6922 7.7998C12.0294 7.7998 11.4922 8.33706 11.4922 8.9998C11.4922 9.66255 12.0294 10.1998 12.6922 10.1998Z" :fill="color"/>
    <path d="M5.30748 10.1998C5.97022 10.1998 6.50748 9.66255 6.50748 8.9998C6.50748 8.33706 5.97022 7.7998 5.30748 7.7998C4.64474 7.7998 4.10748 8.33706 4.10748 8.9998C4.10748 9.66255 4.64474 10.1998 5.30748 10.1998Z" :fill="color"/>
  </svg>
</template>

<script>
  export default {
    props: {
      color: { type: String, default: '#828282' },
    },
  };
</script>
