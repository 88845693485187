<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.70994 6.79C9.61698 6.69627 9.50637 6.62188 9.38452 6.57111C9.26266 6.52034 9.13195 6.4942 8.99994 6.4942C8.86793 6.4942 8.73722 6.52034 8.61536 6.57111C8.4935 6.62188 8.3829 6.69627 8.28994 6.79L3.28994 11.79C3.19621 11.883 3.12182 11.9936 3.07105 12.1154C3.02028 12.2373 2.99414 12.368 2.99414 12.5C2.99414 12.632 3.02028 12.7627 3.07105 12.8846C3.12182 13.0064 3.19621 13.117 3.28994 13.21L8.28994 18.21C8.3829 18.3037 8.4935 18.3781 8.61536 18.4289C8.73722 18.4797 8.86793 18.5058 8.99994 18.5058C9.13195 18.5058 9.26266 18.4797 9.38452 18.4289C9.50637 18.3781 9.61698 18.3037 9.70994 18.21C9.80367 18.117 9.87806 18.0064 9.92883 17.8846C9.9796 17.7627 10.0057 17.632 10.0057 17.5C10.0057 17.368 9.9796 17.2373 9.92883 17.1154C9.87806 16.9936 9.80367 16.883 9.70994 16.79L5.40994 12.5L9.70994 8.21C9.80367 8.11704 9.87806 8.00644 9.92883 7.88458C9.9796 7.76272 10.0057 7.63201 10.0057 7.5C10.0057 7.36799 9.9796 7.23728 9.92883 7.11542C9.87806 6.99356 9.80367 6.88296 9.70994 6.79ZM20.7099 11.79L15.7099 6.79C15.6167 6.69676 15.506 6.6228 15.3842 6.57234C15.2624 6.52188 15.1318 6.49591 14.9999 6.49591C14.7336 6.49591 14.4782 6.6017 14.2899 6.79C14.1967 6.88324 14.1227 6.99393 14.0723 7.11575C14.0218 7.23757 13.9958 7.36814 13.9958 7.5C13.9958 7.7663 14.1016 8.0217 14.2899 8.21L18.5899 12.5L14.2899 16.79C14.1962 16.883 14.1218 16.9936 14.071 17.1154C14.0203 17.2373 13.9941 17.368 13.9941 17.5C13.9941 17.632 14.0203 17.7627 14.071 17.8846C14.1218 18.0064 14.1962 18.117 14.2899 18.21C14.3829 18.3037 14.4935 18.3781 14.6154 18.4289C14.7372 18.4797 14.8679 18.5058 14.9999 18.5058C15.132 18.5058 15.2627 18.4797 15.3845 18.4289C15.5064 18.3781 15.617 18.3037 15.7099 18.21L20.7099 13.21C20.8037 13.117 20.8781 13.0064 20.9288 12.8846C20.9796 12.7627 21.0057 12.632 21.0057 12.5C21.0057 12.368 20.9796 12.2373 20.9288 12.1154C20.8781 11.9936 20.8037 11.883 20.7099 11.79Z"
      :fill="color"
    />
  </svg>
</template>

<script>
  export default {
    props: ['color'],
  };
</script>
