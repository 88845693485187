<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='30', height='30', viewBox='0 0 30 30')
  path(:fill='color', fill-rule='evenodd', d='M4.286 23.571h21.071v-9.285H4.286v9.285zm2.143-2.142h16.785v-5H6.43v5zM2.143 11.07h26.071V9.286H2.143v1.785zm0-2.857h26.071V6.43H2.143v1.785zm8.214-4.643a1.071 1.071 0 1 0 0 2.143 1.071 1.071 0 0 0 0-2.143zm-2.857 0a1.071 1.071 0 1 0 0 2.143 1.071 1.071 0 0 0 0-2.143zm-2.857 0a1.071 1.071 0 1 0 0 2.143 1.071 1.071 0 0 0 0-2.143zM26.12 0H3.88A3.88 3.88 0 0 0 0 3.88v22.24A3.88 3.88 0 0 0 3.88 30h22.24A3.88 3.88 0 0 0 30 26.12V3.88A3.88 3.88 0 0 0 26.12 0zm.253 2.143c1.015 0 1.841.826 1.841 1.841v22.389a1.844 1.844 0 0 1-1.841 1.841H3.984a1.844 1.844 0 0 1-1.841-1.841V3.984c0-1.015.826-1.841 1.841-1.841h22.389z')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
