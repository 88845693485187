import GET_SHOPIFY_PRODUCTS from '@/graphql/GetShopifyProducts.gql';
import GET_SHOPRENTER_PRODUCTS from '@/graphql/GetShoprenterProducts.gql';

export default {
  methods: {
    addExpression(fieldName, hash) {
      this.currentRule.options[fieldName].push(hash);
    },
    async getShopifyProducts(productIds) {
      const { data } = await this.$apollo.query({
        query: GET_SHOPIFY_PRODUCTS,
        variables: {
          input: {
            criteria: {
              domain: this.campaign.domain,
              productIds,
            },
          },
        },
      });
      return data && data.response
        ? data.response
        : {
            products: [],
            moneyFormat: '',
          };
    },
    async getShoprenterProducts(productIds) {
      const { data } = await this.$apollo.query({
        query: GET_SHOPRENTER_PRODUCTS,
        variables: {
          input: {
            criteria: {
              domain: this.campaign.domain,
              productIds,
            },
          },
        },
      });

      return data && data.response
        ? data.response
        : {
            products: [],
            moneyFormat: '',
          };
    },
    getProductIds() {
      const visitorCartRevampRulesIndex = this.campaign.frontendRules.findIndex(
        (rule) => rule.type === 'visitorCartRevamp',
      );
      if (visitorCartRevampRulesIndex > -1) {
        const visitorCartRevampRules = this.campaign.frontendRules[visitorCartRevampRulesIndex];
        let shopifyProductIds = [];
        visitorCartRevampRules.options.expressions.forEach((group) => {
          group.forEach((expression) => {
            if (Array.isArray(expression.value)) {
              shopifyProductIds = shopifyProductIds.concat(expression.value);
            }
          });
        });

        return shopifyProductIds;
      }
    },
    async getShopifyProductDetails(ids) {
      let productIds = ids || this.getProductIds();

      if (productIds && productIds.length) {
        productIds = new Set(productIds);
        const { products } = await this.getShopifyProducts(Array.from(productIds));
        return products;
      }
      return [];
    },
    async getShoprenterProductDetails(ids) {
      let productIds = ids || this.getProductIds();

      if (productIds && productIds.length) {
        productIds = new Set(productIds);
        const { products } = await this.getShoprenterProducts(Array.from(productIds));
        return products.map((product) => {
          return { ...product, type: 'shoprenter' };
        });
      }
      return [];
    },
    mergeProductDetailsWithRules(rules, products, shopType) {
      rules.forEach((rule, ruleIndex) => {
        if (products.length && rule.type === 'visitorCartRevamp') {
          rule.options.expressions.forEach((orGroup, orIndex) => {
            orGroup.forEach((andItem, andIndex) => {
              let values = [];
              if (shopType === 'shopify') {
                values = products.filter((product) => andItem.value.includes(product.id));
              } else if (shopType === 'shoprenter') {
                values = products.filter((product) => andItem.value.includes(product.variant));
              }
              rules[ruleIndex].options.expressions[orIndex][andIndex].value = values.length
                ? values
                : andItem.value;
            });
          });
        }
      });
    },
    mergeProductDetailsWithSavedRules(products, shopType) {
      this.mergeProductDetailsWithRules(this.campaign.frontendRules, products, shopType);
    },
  },
};
