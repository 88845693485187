export default {
  methods: {
    isOnWrongPage(variants) {
      const numberOfPages = Math.ceil(variants.length / this.pagination.limit);
      return this.pagination.page > numberOfPages && numberOfPages !== 0;
    },
    getChanceToWinVariants(rawData, type = 'default') {
      const isDefault = type === 'default';
      const variantData = rawData?.variants?.map((variant) => {
        return {
          impressions: { allType: isDefault ? variant.impressions : variant.uniqueVisitorCount },
          conversions: { allType: isDefault ? variant.conversions : variant.goalCount },
          id: variant.variantId,
        };
      });
      if (type !== 'default') return variantData;
      return variantData.filter((variant) => !variant.isControlVariant);
    },

    async getChanceToWin(variants, goalId, date) {
      const variantsWithImpression = variants.filter((variant) => variant.impressions.allType > 0);
      this.loadingChanceToWin = true;
      try {
        let chanceToWin = {};
        if (variantsWithImpression.length > 0 && this.campaignID) {
          chanceToWin = (
            await this.$axios.post('/reports/chance-to-win', {
              campaigns: [{ variants: variantsWithImpression, id: this.campaignID }],
            })
          ).data;
        }
        if (!this.chanceToWin[goalId]) {
          this.chanceToWin[goalId] = {};
        }
        const chanceToWinForSelectedGoal = {
          ...this.chanceToWin[goalId],
          [date]: chanceToWin,
        };
        this.chanceToWin = {
          ...this.chanceToWin,
          [goalId]: chanceToWinForSelectedGoal,
        };
      } catch (e) {}
      this.loadingChanceToWin = false;
    },

    getBaselineVariant(variants, goalId) {
      let baseline;
      const controlVariant = variants.find((variant) => variant.isControlVariant);
      if (controlVariant && goalId !== 'defaultGoal') {
        baseline = controlVariant;
      } else {
        const variantList = JSON.parse(JSON.stringify(variants));
        baseline = variantList.sort(
          (variantA, variantB) => new Date(variantA.createdAt) - new Date(variantB.createdAt),
        )[0];
      }

      return baseline;
    },

    hasNoViewsYet({ variant, goalId }) {
      const viewedPropName = goalId === 'defaultGoal' ? 'impressions' : 'conversions';
      return variant[viewedPropName] === 0 && variant.conversions === 0;
    },

    getUplift({ variants, baseline, date, goalId }) {
      const variantGoalData = [...variants];
      let baseLineData = baseline;

      if (goalId !== 'defaultGoal') {
        const baselineInGoals = variants?.find((v) => v._id === baseline._id);
        if (!baselineInGoals && baseline) {
          variantGoalData.push(baseline);
        } else {
          baseLineData = baselineInGoals;
        }
      }

      const upliftForGoal = variantGoalData.reduce((upLiftList, variant) => {
        let upLift = 'baseline';
        if (variant._id !== baseline._id) {
          if (this.hasNoViewsYet({ variant, goalId })) {
            upLift = null;
          } else {
            upLift = baseLineData.conversionRate
              ? (variant.conversionRate - baseLineData.conversionRate) / baseLineData.conversionRate
              : null;
          }
        }
        upLiftList[variant._id] = upLift;

        return upLiftList;
      }, {});

      const upliftForSelectedGoal = {
        ...this.uplift[goalId],
        [date]: upliftForGoal,
      };
      this.uplift = {
        ...this.uplift,
        [goalId]: upliftForSelectedGoal,
      };
    },
  },
};
