<template lang="pug">
om-modal(name="variant-rename" width="420px" @beforeOpen="beforeOpen" @opened="opened" headerBorder)
  template(#modal-header)
    .row
      .col
        om-heading(h5) {{ $t('renameVariant') }}
    .brand-modal-action-icon
      span.cursor-pointer(@click="$modal.hide('variant-rename')" title="close" aria-hidden="true")
        close-icon(:width="12" :height="12")
  template(#modal-body)
    input#variantNameInput.form-control.form-control-lg(
      @keyup.enter="updateVariant"
      :class="{ 'is-invalid': $v.name.$error }"
      type="text"
      :placeholder="$t('newNamePlaceholder')"
      v-model.trim="name"
      :aria-label="$t('newNamePlaceholder')"
    )
  template(#modal-footer)
    .d-flex.justify-content-end
      om-button(primary @click="updateVariant") {{ $t('apply') }}
</template>

<script>
  import RENAME_VARIANT from '@/graphql/RenameVariant.gql';
  import { minLength, required } from 'vuelidate/lib/validators';

  export default {
    data() {
      return {
        variantId: null,
        name: '',
      };
    },

    validations: {
      name: {
        required,
        minLength: minLength(2),
      },
    },

    methods: {
      beforeOpen(event) {
        this.variantId = event.params.variantId;
        this.name = event.params.name;
      },
      opened() {
        document.getElementById('variantNameInput').focus();
      },
      updateVariant() {
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }

        this.$apollo
          .mutate({
            mutation: RENAME_VARIANT,
            variables: {
              input: {
                _id: this.variantId,
                name: this.name,
              },
            },
          })
          .then(() => {
            this.$emit('renameSuccess');
            this.$modal.hide('variant-rename');
          });
      },
    },
  };
</script>
