<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='30', height='27', viewBox='0 0 30 27')
  g(:fill='color', fill-rule='evenodd')
    path(d='M28.588.059H1.392C.623.059 0 .682 0 1.45v18.37c0 .77.623 1.393 1.392 1.393h24.576l4.012 5.872V1.45c0-.769-.623-1.392-1.392-1.392m-.835 2.226V19.884a2.227 2.227 0 0 0-1.785-.897H2.227V2.285h25.526')
    path(d='M16.785 16.97l-.96-2.167-1.636 2.167h-2.278L15 13.126l-1.735-3.564h2.022l.887 2.092 1.56-2.092h2.283l-3.006 3.767 1.786 3.64z')
    path(d='M8.646 18.384c-.037 0-.074 0-.111-.002a4.743 4.743 0 0 1-.987-.144l-.318-.075.283-1.77.365.082c.204.045.414.075.623.087h.02c.34 0 .606-.098.815-.3.227-.22.367-.514.429-.898l1.206-6.921H9.783l.301-1.72h1.17l.087-.614c.136-.85.474-1.528 1.005-2.017.553-.506 1.259-.762 2.098-.762h.05c.266 0 .59.043.99.13l.319.07-.257 1.774-.366-.076a3.914 3.914 0 0 0-.626-.078c-.348.002-.62.104-.829.312-.224.224-.36.52-.416.904l-.053.357h1.603l-.3 1.72h-1.593L11.73 15.65c-.148.848-.496 1.522-1.035 2.003-.547.485-1.236.73-2.048.73')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
