export const FIXED_STACK_CLASS = 'driver-fix-stacking';

export const removeAllFixedStacks = () => {
  const fixedStackElements = Array.from(document.querySelectorAll(`.${FIXED_STACK_CLASS}`) || []);
  fixedStackElements.forEach((fixedStackElement) => {
    fixedStackElement?.classList?.remove?.(FIXED_STACK_CLASS);
  });
};

export const positioningPopover = () => {
  const highlighted = document.querySelector('.driver-highlighted-element');
  const popover = document.getElementById('driver-popover-item');
  const { top: highlightedTop } = highlighted?.getBoundingClientRect?.() || {};
  if (highlightedTop) {
    popover.style.top = `${highlightedTop - 16 + window.scrollY}px`;
  }
};
