<template lang="pug">
div
  .row.mt-5.mb-4
    .col-6.col-lg-3.align-self-center
      h3 {{ $t('campaignStatistics') }}
    .col-6.col-lg-9.row.pr-0.justify-content-end.flex-xl-row.flex-column
      .col-auto.order-2.order-xl-1.py-1.pr-0.pl-4.d-flex.justify-content-end
        .col-auto.pr-0
          om-select#designVariants.variant-stat-select-box-sm(
            :label="$t(`designVariants`)"
            labelPosition="fill"
            :options="campaignVariants"
            :value="selectedVariant"
            @input="selectVariant($event)"
            optionKey="_id"
            optionText="name"
          )
      .col-auto.order-3.order-xl-2.py-1.pr-0.pl-4.d-flex.justify-content-end
        .col-auto.pr-0
          om-select#statByDevice.variant-stat-select-box-sm(
            :value="selectedDevice"
            :options="devices"
            :label="$t('deviceWord')"
            :placeholder="$t('multiCampaigns.selectDevice')"
            labelPosition="fill"
            @input="toggleDataset($event)"
          )
            template(#prepend-option="{ option }")
              om-device.w-3.flex-grow-0.mr-2(:type="option.key")
      .col-auto.pr-0.order-1.order-xl-3.py-1.pr-0.pl-4.d-flex.justify-content-end
        .col-auto.pr-0
          om-period-select#timePeriods.variant-stat-select-box(
            labelPosition="fill"
            :label="$t('timePeriods')"
            :options="timePeriods"
            :value="selectedInterval"
            :locale="$i18n.locale"
            @input="setInterval"
            maxDate="today"
          )
  .row.mx-0.brand-chart-tabs
    template(v-for="stat in stats")
      .brand-chart-tab(
        :class="{ 'col-12 col-sm-4': stats.length === 3, 'col-12 col-sm-2': stats.length === 5, active: stat.type == getSelectedStatBox }"
        @click="selectedStatBox = stat.type"
      )
        .brand-chart-tab-inner
          .brand-chart-tab-title.mb-2 {{ $t(stat.type) }}
          .brand-chart-tab-stat
            .brand-chart-tab-stat-number {{ getStatNumber(stat) }}
            .brand-chart-tab-stat-percent.ml-3(
              :class="{ positive: stat.percentage > 0, negative: stat.percentage < 0 }"
            ) {{ (stat.percentage > 0 ? '+' + stat.percentage : stat.percentage) + '%' }}
            .d-flex.flex-row.flex-wrap.justify-content-end
              .stat-percentage.mr-3
                i.fa.fa-desktop.mr-2
                | {{ stat.totalDesktop }}{{ stat.type === 'conversionRate' ? '%' : '' }}
                .stat-percentage.stat-percentage-sm(
                  :class="statClass(stat.percentageDesktop)"
                  :title="previousInterval"
                ) {{ (stat.percentageDesktop > 0 ? '+' + stat.percentageDesktop : stat.percentageDesktop) + '%' }}
              .stat-percentage
                i.fa.fa-mobile.mr-2
                | {{ stat.totalMobile }}{{ stat.type === 'conversionRate' ? '%' : '' }}
                .stat-percentage.stat-percentage-sm(
                  :class="statClass(stat.percentageMobile)"
                  :title="previousInterval"
                ) {{ (stat.percentageMobile > 0 ? '+' + stat.percentageMobile : stat.percentageMobile) + '%' }}
  .p-0(:class="{ 'brand-stat-wrapper-preview': noStats }")
    .pt-5.pb-4.brand-chart(style="background-color: white")
      line-chart(
        :chart-data="getChartData"
        :options="chartOptions"
        :height="220"
        :gradientBackground="false"
      )
</template>

<script>
  import moment from 'moment';
  import OmDevice from '@/components/OmDevice';
  import GET_TOTAL_STATISTICS from '@/graphql/GetTotalStatistics.gql';
  import genFakeChart from '@/mixins/genFakeChart';
  import chartColors from '@/mixins/chartColors';
  import { mapGetters } from 'vuex';
  import dateFormat from '@/mixins/dateFormat';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    components: {
      LineChart: () => import('@/components/Charts/LineChart'),
      OmDevice,
    },
    mixins: [genFakeChart, chartColors, dateFormat],

    props: {
      campaign: { type: Object },
      variants: { type: Array },
    },

    data() {
      return {
        visibleChart: 'all',
        interval: {
          from: moment().subtract(7, 'days'),
          to: moment(),
        },
        totalStatistics: { stats: [], labels: [] },
        datasetToShow: {
          unknown: true,
          all: true,
          desktop: true,
          mobile: true,
        },
        lastWeekInterval: {
          key: 'lastWeek',
          value: this.$t('lastWeek'),
          interval: {
            from: moment().subtract(7, 'days').toDate(),
            to: moment().toDate(),
          },
        },
        selectedInterval: {
          key: 'lastWeek',
          value: this.$t('lastWeek'),
          interval: {
            from: moment().subtract(7, 'days').toDate(),
            to: moment().toDate(),
          },
        },
        allVariantOption: { _id: 'all', name: this.$t('all') },
        selectedVariant: { _id: 'all', name: this.$t('all') },
        allDeviceOption: {
          key: 'desktop_and_mobile',
          value: this.$t(`device.all`),
        },
        selectedDevice: {
          key: 'desktop_and_mobile',
          value: this.$t(`device.all`),
        },
        needAggregated: false,
        selectedStatBox: undefined,
        popoverInstance: null,
      };
    },

    computed: {
      ...mapGetters(['domainRequestStatus']),
      campaignVariants() {
        let variants = JSON.parse(JSON.stringify(this.variants));
        const all = this.allVariantOption;

        variants = variants.map((variant) => {
          if (this.noVariantStat(variant._id)) {
            variant.disabled = this.noVariantStat(variant._id);
          }
          return variant;
        });

        variants.unshift(all);

        return variants;
      },
      devices() {
        const unknownDevice = {
          key: 'unknown',
          value: this.$t(`device.unknown`),
        };

        const devices = [
          {
            key: 'desktop_and_mobile',
            value: this.$t(`device.all`),
          },
          {
            key: 'desktop',
            value: this.$t(`device.desktop`),
          },
          {
            key: 'mobile',
            value: this.$t(`device.mobile`),
          },
        ];

        if (this.needAggregated && this.getSelectedStatBox !== 'conversionRate') {
          devices.unshift(unknownDevice);
        }

        return devices;
      },
      campaignId() {
        return this.$route.params.id;
      },
      noStats() {
        const stats = this.stats.find(({ type }) => type === 'impressions');
        // no stats if no impressions AND there is no change compared to the previous period
        return stats?.total === 0 && stats?.percentage === 0;
      },
      stats() {
        return this.totalStatistics.stats.filter(
          (s) => !['assistedRevenue', 'impressionValue'].includes(s.type),
        );
      },
      variantStats() {
        if (this.totalStatistics && this.totalStatistics.variants) {
          return this.totalStatistics.variants;
        }

        return {};
      },
      labels() {
        return this.totalStatistics
          ? this.totalStatistics.labels.map((d, index) =>
              index === 0 || index === this.totalStatistics.labels.length - 1
                ? ''
                : moment(d).format(this.shortDateFormat),
            )
          : [];
      },
      selectedVariantName() {
        if (this.visibleChart === 'all') return this.$t('all');
        const variant = this.variants.find((v) => v._id === this.visibleChart);
        if (variant) return variant.name;
        return this.$t('none');
      },
      chartOptions() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: false },
          tooltips: {
            mode: 'index',
            position: 'average',
            intersect: false,
            callbacks: {
              label: (item, data) => {
                const _dataset = data.datasets[item.datasetIndex];
                let label = _dataset.label || '';

                if (label) {
                  const value = _dataset.data[item.index];
                  const round = data.datasets[item.datasetIndex].roundValue;
                  const suffix = data.datasets[item.datasetIndex].suffix;

                  label += `: ${round ? value.toFixed(2) : value}`;

                  return `${label}${suffix}`;
                }

                return '';
              },
              footer: (items, data) => {
                const needSum = data.datasets[0] ? data.datasets[0].needSum : false;
                if (!needSum) return '';

                let sum = 0;
                const round = data.datasets[0] ? data.datasets[0].roundValue : false;

                items.forEach((item) => {
                  sum += data.datasets[item.datasetIndex].data[item.index];
                });

                return `${this.$t('sum')}: ${round ? sum.toFixed(2) : sum}`;
              },
            },
          },
          scales: {
            xAxes: [
              {
                gridLines: { display: false },
                ticks: {
                  beginAtZero: false,
                  maxTicksLimit: 10,
                  maxRotation: 0,
                  fontColor: '#BBBFC3',
                  padding: 10,
                },
                // ticks: { maxTicksLimit: 3, maxRotation: 0 },
                stacked: this.getSelectedStatBox !== 'conversionRate',
              },
            ],
            yAxes: [
              {
                ticks: {
                  maxTicksLimit: 6,
                  beginAtZero: true,
                  fontColor: '#BBBFC3',
                  callback: (value) => {
                    if (value % 1 === 0) {
                      return this.$options.filters.thousandSep(value);
                    }
                  },
                  padding: 20,
                },
                gridLines: {
                  drawBorder: false,
                  tickMarkLength: 0,
                },
                stacked: this.getSelectedStatBox !== 'conversionRate',
              },
            ],
          },
        };
      },
      getChartData() {
        const ret = { datasets: [], labels: [] };
        const isConvRateSelected = this.getSelectedStatBox === 'conversionRate';
        for (const stat of this.stats) {
          if (stat.type === this.getSelectedStatBox) {
            if (!this.noStats) {
              if (this.visibleChart === 'all') {
                const aggregated = _clone(stat.data);
                const desktopData = _clone(stat.desktop);
                const mobileData = _clone(stat.mobile);
                const unknown = aggregated.map((value, i) => {
                  if (value && (desktopData[i] || mobileData[i]))
                    return value - (desktopData[i] + mobileData[i]);
                  return value;
                });
                if (isConvRateSelected && this.datasetToShow.all) {
                  ret.datasets.push({
                    label: this.$t('all'),
                    data: aggregated,
                    backgroundColor: '#2CC898',
                    lineTension: 0,
                    noGradient: true,
                    roundValue: isConvRateSelected,
                    needSum: !isConvRateSelected,
                    suffix: isConvRateSelected ? '%' : '',
                  });
                }
                if (this.datasetToShow.desktop) {
                  ret.datasets.push({
                    label: this.$t('device.desktop'),
                    data: desktopData.map((value) => value || 0),
                    backgroundColor: '#86B3DF',
                    lineTension: 0,
                    noGradient: true,
                    roundValue: isConvRateSelected,
                    needSum: !isConvRateSelected,
                    suffix: isConvRateSelected ? '%' : '',
                  });
                }
                if (this.datasetToShow.mobile) {
                  ret.datasets.push({
                    label: this.$t('device.mobile'),
                    data: mobileData.map((value) => value || 0),
                    backgroundColor: '#F3AAA1',
                    lineTension: 0,
                    noGradient: true,
                    roundValue: isConvRateSelected,
                    needSum: !isConvRateSelected,
                    suffix: isConvRateSelected ? '%' : '',
                  });
                }
                // eslint-disable-next-line
                this.needAggregated = !isConvRateSelected && unknown.some((x) => !!x);
                if (!isConvRateSelected && this.needAggregated && this.datasetToShow.unknown) {
                  ret.datasets.push({
                    label: this.$t('device.unknown'),
                    data: unknown,
                    backgroundColor: '#b2b2b2',
                    lineTension: 0,
                    noGradient: true,
                    roundValue: isConvRateSelected,
                    needSum: !isConvRateSelected,
                    suffix: isConvRateSelected ? '%' : '',
                  });
                }
              }
              ret.labels = this.labels;
            } else {
              ret.datasets = [
                { data: this.genFakeChartData(this.interval), backgroundColor: '#2CC898' },
              ];
              ret.labels = this.genFakeChartLabels(this.interval);
            }
          }
        }

        if (this.totalStatistics && this.totalStatistics.variants) {
          for (const variant of this.variants) {
            if (this.visibleChart === variant._id) {
              ret.datasets = [];
              const datas = this.totalStatistics.variants[variant._id];
              if (datas && datas[this.getSelectedStatBox]) {
                const aggregated = datas[this.getSelectedStatBox];
                const mobileData = _clone(datas[`${this.getSelectedStatBox}Mobile`]);
                const desktopData = _clone(datas[`${this.getSelectedStatBox}Desktop`]);
                const unknown = aggregated.map((value, i) => {
                  if (value && (desktopData[i] || mobileData[i]))
                    return value - (desktopData[i] + mobileData[i]);
                  return value;
                });
                if (isConvRateSelected && this.datasetToShow.all) {
                  ret.datasets.push({
                    label: `${variant.name} - ${this.$t('all')}`,
                    data: _clone(datas[this.getSelectedStatBox]),
                    backgroundColor: '#2CC898',
                    lineTension: 0,
                    noGradient: true,
                    roundValue: isConvRateSelected,
                    needSum: !isConvRateSelected,
                    suffix: isConvRateSelected ? '%' : '',
                  });
                }
                if (this.datasetToShow.desktop) {
                  ret.datasets.push({
                    label: `${variant.name} - ${this.$t('device.desktop')}`,
                    data: _clone(desktopData),
                    backgroundColor: '#86B3DF',
                    lineTension: 0,
                    noGradient: true,
                    roundValue: isConvRateSelected,
                    needSum: !isConvRateSelected,
                    suffix: isConvRateSelected ? '%' : '',
                  });
                }
                if (this.datasetToShow.mobile) {
                  ret.datasets.push({
                    label: `${variant.name} - ${this.$t('device.mobile')}`,
                    data: _clone(mobileData),
                    backgroundColor: '#F3AAA1',
                    lineTension: 0,
                    noGradient: true,
                    roundValue: isConvRateSelected,
                    needSum: !isConvRateSelected,
                    suffix: isConvRateSelected ? '%' : '',
                  });
                }
                // eslint-disable-next-line
                this.needAggregated = !isConvRateSelected && unknown.some((x) => !!x);
                if (!isConvRateSelected && this.needAggregated && this.datasetToShow.unknown) {
                  ret.datasets.push({
                    label: `${variant.name} - ${this.$t('device.unknown')}`,
                    data: unknown,
                    backgroundColor: '#b2b2b2',
                    lineTension: 0,
                    noGradient: true,
                    roundValue: isConvRateSelected,
                    needSum: !isConvRateSelected,
                    suffix: isConvRateSelected ? '%' : '',
                  });
                }
              }
            }
          }
        }

        return ret;
      },
      getSelectedStatBox() {
        return this.selectedStatBox || 'impressions';
      },
      previousInterval() {
        const periodLengthInDays = moment(this.interval.to).diff(
          moment(this.interval.from),
          'days',
        );
        const [from, to] = [
          moment(this.interval.from).subtract(periodLengthInDays, 'days').format(this.dateFormat),
          moment(this.interval.to).subtract(periodLengthInDays, 'days').format(this.dateFormat),
        ];
        return `Compared to the previous period. (${from} - ${to})`;
      },
      timePeriods() {
        const today = moment().toDate();
        const timePeriods = [
          this.lastWeekInterval,
          {
            key: 'thisMonth',
            value: this.$t('thisMonth'),
            interval: {
              from: moment().subtract(moment().get('date'), 'days').toDate(),
              to: today,
            },
          },
          {
            key: 'lastMonth',
            value: this.$t('lastMonth'),
            interval: {
              from: moment().subtract(30, 'days').toDate(),
              to: today,
            },
          },
          {
            key: 'previousMonth',
            value: this.$t('previousMonth'),
            interval: {
              from: moment()
                .subtract(moment().get('date') + 30, 'days')
                .toDate(),
              to: moment().subtract(moment().get('date'), 'days').toDate(),
            },
          },
        ];
        return timePeriods;
      },
    },

    watch: {
      selectedInterval() {
        this.getTotalStatistics();
      },
      selectedVariant: {
        handler() {
          this.selectedDevice = this.allDeviceOption;
          this.setDatasetDefaults();
        },
        deep: true,
      },
    },

    async mounted() {
      this.getTotalStatistics();
    },

    methods: {
      async getTotalStatistics() {
        const { data } = await this.$apollo.query({
          query: GET_TOTAL_STATISTICS,
          variables: {
            campaignId: this.campaignId,
            interval: {
              from: this.interval.from.format('YYYY-MM-DD'),
              to: this.interval.to.format('YYYY-MM-DD'),
            },
            includeVariantData: true,
            ...(this.visibleChart !== 'all' ? { variants: [this.visibleChart] } : {}),
          },
        });
        if (data) {
          this.totalStatistics = data.totalStatistics;
        }
      },
      selectVariant(variant) {
        this.selectedVariant = variant;
        this.visibleChart = variant._id;
      },
      isImpressionsBig(stat) {
        return stat.total > 999999;
      },
      noVariantStat(id) {
        return !Object.keys(this.variantStats).includes(id);
      },
      setInterval(event) {
        this.interval.from = moment(event.interval.from);
        this.interval.to = moment(event.interval.to);
        this.selectedInterval = event;
      },
      statClass(value) {
        return value > 0 ? 'brand-color-positive' : 'brand-color-negative';
      },
      toggleDataset(event) {
        // eslint-disable-next-line
        this.selectedDevice = event;
        const type = event?.key;
        if (type === 'desktop_and_mobile') {
          this.datasetToShow = {
            unknown: true,
            all: true,
            desktop: true,
            mobile: true,
          };
        } else {
          this.datasetToShow = {
            unknown: false,
            all: false,
            desktop: false,
            mobile: false,
          };
          this.datasetToShow[type] = true;
        }
      },
      setDatasetDefaults() {
        this.datasetToShow = {
          unknown: true,
          all: true,
          desktop: true,
          mobile: true,
        };
      },
      getStatNumber(stat) {
        const total = this.$options.filters.thousandSep(stat.total);

        if (stat.type === 'conversionRate') {
          return `${total}%`;
        }

        if (stat.type === 'assistedRevenue' || stat.type === 'impressionValue') {
          return `$${total}`;
        }

        return total;
      },
    },
  };
</script>

<style lang="sass">
  @import './src/sass/components/_chart.sass'
  .variant-stat-select-box
    .select-content
      width: 20vw
      min-width: 100px
      @media screen and (min-width: 1200px)
        width: 15vw
    &-sm
      .select-content
        min-width: 150px
</style>
