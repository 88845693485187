<template lang="pug">
.row.align-items-center
  .col-2
    label.mb-0 {{ $t('deviceWord') }}:
  .col-8.col-md-6
    .d-flex.btn-group.btn-group-toggle.brand-settings-group(data-toggle='buttons')
      label.flex-grow-1.btn.brand-btn-switch(:class="{'active': currentRule.device === 'desktop'}")
        input(type='radio' name="device" autocomplete='off' value="desktop" v-model="currentRule.device")
        div
          desktop-svg(:color="currentRule.device === 'desktop' ? 'white' : '#aab1c1'")
        | {{ $t('device.desktop') }}
      label.flex-grow-1.btn.brand-btn-switch(:class="{'active': currentRule.device === 'mobile'}")
        input(type='radio' name="device" autocomplete='off' value="mobile" v-model="currentRule.device")
        div
          mobile-svg(:color="currentRule.device === 'mobile' ? 'white' : '#aab1c1'")
        | {{ $t('device.mobile') }}
      label.flex-grow-1.btn.brand-btn-switch(:class="{'active': currentRule.device === 'desktop_and_mobile'}")
        input(type='radio' name="device" autocomplete='off' value="desktop_and_mobile" v-model="currentRule.device")
        div
          desktop-svg(:color="currentRule.device === 'desktop_and_mobile' ? 'white' : '#aab1c1'").mr-2
          mobile-svg(:color="currentRule.device === 'desktop_and_mobile' ? 'white' : '#aab1c1'")
        | {{ $t('device.both') }}
</template>

<script>
  export default {
    props: {
      currentRule: { type: Object, required: true },
    },
  };
</script>
