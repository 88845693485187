<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='30', height='28', viewBox='0 0 30 28')
  g(:fill='color', fill-rule='evenodd')
    path(d='M11.354 24.137c0 .701-.571 1.27-1.276 1.27a1.273 1.273 0 0 1-1.277-1.27c0-.702.572-1.27 1.277-1.27s1.276.568 1.276 1.27m10.716 0c0-.702.571-1.27 1.276-1.27.705 0 1.277.568 1.277 1.27 0 .701-.572 1.27-1.277 1.27a1.273 1.273 0 0 1-1.276-1.27M28.81 5.69h-1.956c-1.986 0-3.971 0-5.956-.002l-.2 2.129h6.83c-.282 1.013-.545 2.015-.838 3.012-.55 1.957-1.113 3.92-1.67 5.878-.087.315-.14.577-.602.577-4.772-.023-9.55-.017-14.323-.017l-.263-.041-2.635-9.41h7.394l-.164-2.127c-2.396.001-4.792.004-7.19.013-.515 0-.725-.17-.843-.635-.245-.997-.527-1.993-.802-2.983-.17-.618-.556-.938-1.224-.927-1.083.018-2.172-.006-3.255.006C.416 1.176-.011 1.63 0 2.236c.012.606.434 1.002 1.107 1.008.673.006 1.347.035 2.02-.012.457-.035.638.134.756.56a4569.553 4569.553 0 0 0 4.73 16.942c.124.426.089.659-.31.95-1.434 1.054-1.633 3.059-.492 4.329 1.289 1.427 3.297 1.48 4.585.116.814-.862.931-1.887.656-3.012h7.273c.023.6-.041 1.194.093 1.736.381 1.503 1.98 2.517 3.426 2.226 1.856-.379 2.67-1.853 2.576-3.175-.128-1.713-1.428-2.89-3.08-2.89H10.862l-.457-1.637h14.979c.878-.006 1.107-.193 1.34-1.014.112-.396.23-.787.34-1.177.953-3.344 1.902-6.69 2.846-10.038.252-.898-.164-1.445-1.1-1.457')
    path(d='M18.669 10.129h-2.338L16 .129h3l-.331 10zm-1.174 2c.452 0 .816.141 1.092.423.275.282.413.643.413 1.082 0 .433-.138.79-.413 1.072-.276.282-.64.423-1.092.423-.445 0-.806-.141-1.082-.423-.275-.282-.413-.64-.413-1.072 0-.433.138-.792.413-1.077.276-.285.637-.428 1.082-.428z')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
