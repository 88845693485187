<template lang="pug">
svg(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :fill="color")
  path(
    d="M21 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2ZM11 20H4V10H11V20ZM20 20H13V10H20V20ZM20 8H4V4H20V8Z"
    :fill="color"
  )
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: '#505763',
      },
    },
  };
</script>
