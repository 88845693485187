import { mapState } from 'vuex';
import { track } from '@/services/xray';

export default {
  computed: {
    ...mapState(['impersonate']),
  },

  methods: {
    showUpgradePlanModal(trigger) {
      if (this.impersonate) {
        this.$modal.show('dialog', {
          title: this.$t('impersonateUpgrade'),
          buttons: [
            {
              title: 'OK',
              default: true,
              class: 'brand-btn brand-btn-primary brand-btn-inline-flex mx-auto',
            },
          ],
        });
      } else {
        this.$modal.show('upgrade-plan', { trigger });
        // every trigger is an array
        if (!Array.isArray(trigger)) return;
        let upgradeName = '';
        if (trigger.length > 1) {
          upgradeName = trigger.indexOf('jsEvent') > -1 ? 'advancedTriggers' : 'advancedRules';
        } else {
          upgradeName = trigger.join(',');
        }
        track('upgradeModalShown', { trigger: upgradeName });
      }
    },
  },
};
