<template lang="pug">
om-table(
  :items="subscribers"
  :columns="columns"
  :columnClasses="columnClasses"
  :allItemsCount="subscriberCount"
  :isSearchResult="isSearchResult"
  :selectable="selectable"
  :paginate="paginate"
  :pagination="pagination"
  :needColumnTitle="true"
  :loadingContent="loadingSubscribers"
  @selectedIdsChange="$emit('selectedIdsChange', $event)"
  @sortingChange="$emit('sortingChange', $event)"
  @paginationChange="$emit('paginationChange', $event)"
)
  template(
    v-for="col in columns"
    :slot="col.key"
    v-if="col.key.includes('customFields')"
    slot-scope="{ rowData }"
  )
    span.subscriber-table-value(
      v-tooltip="col.key.includes('customFields') ? {autoHide: false, content: getRowData(rowData.row, col.key)} : ''"
    ) {{ getRowData(rowData.row, col.key) }}
  template(slot="contact" slot-scope="{ rowData }")
    .d-flex.align-items-center
      om-avatar.mr-3(
        :name="{ firstName: rowData.row.firstName, lastName: rowData.row.lastName }"
        :email="rowData.row.email"
        colorize
      )
      .d-flex.flex-column.brand-avatar-info
        .brand-avatar-name {{ getFullNameByLocale(rowData.row.firstName, rowData.row.lastName, $i18n.locale) }}
        .brand-avatar-email {{ rowData.row.email }}
  template(slot="date" slot-scope="{ rowData }")
    span(v-html="formatDateTime(rowData.row.date)")
  template(slot="syncStatus" slot-scope="{ rowData }")
    .subscriber-table-integration-error(
      v-if="hasIntegrationError(rowData.row)"
      @click="showIntegrationErrorDetails(rowData.row)"
    )
      integration-error-indicator(:text="$t('syncStatus.' + getIntegrationErrorText(rowData.row))")
    template(v-else)
      span {{ $t('syncStatus.' + rowData.row.syncStatus) }}
  template(slot="loading")
    skeleton-loader(type="subscriber" :rows="skeletonRowNumber")
</template>
<script>
  import { mapGetters } from 'vuex';
  import dateFormat from '@/mixins/dateFormat';
  import tableMixin from '@/mixins/table';
  import fullName from '@/mixins/fullName';
  import OmAvatar from '@/components/Avatar';
  import IntegrationErrorIndicator from '@/components/IntegrationErrorIndicator.vue';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import { ERROR_CODES } from '@om/integrations';

  export default {
    components: {
      OmAvatar,
      IntegrationErrorIndicator,
      SkeletonLoader,
    },
    mixins: [dateFormat, fullName, tableMixin],
    props: {
      subscribers: { type: Array, required: true },
      subscriberCount: { type: Number, required: true },
      loadingSubscribers: { tybe: Boolean, default: false },
      availableColumns: { type: Array },
      sortable: { type: Boolean, default: false },
      fields: { type: Array, default: () => [] },
      isSearchResult: { type: Boolean, default: false },
      selectable: { type: Boolean, default: false },
      columnClasses: { type: Object },
      paginate: { type: Boolean, default: true },
      pagination: { type: Object },
    },
    computed: {
      ...mapGetters(['integrations']),
      customFieldColumns() {
        const result = [];
        const fieldKeys = new Set();
        this.subscribers.forEach((s) => {
          const keys = Object.keys(s.customFields || []);
          keys.forEach((k) => fieldKeys.add(k));
        });
        const transKey = (key) =>
          key
            .split('_')
            .map((p, i) => (i ? `${p.charAt(0).toUpperCase()}${p.substring(1)}` : p))
            .join('');
        for (const key of fieldKeys.keys()) {
          if (['coupon_code', 'coupon_title'].includes(key)) {
            result.push({
              header: this.$t(transKey(key)),
              key: `customFields.${key}`,
              sortable: false,
            });
          } else {
            const field = this.fields.find((f) => f.customId === key);
            if (field) {
              result.push({
                header: field.name,
                key: `customFields.${field.customId}`,
                sortable: false,
              });
            }
          }
        }
        return result;
      },
      columns() {
        let columns = [
          { header: this.$t('contact'), key: 'contact', sortable: false },
          { header: this.$t('syncStatus.title'), key: 'syncStatus', sortable: false },
          // { header: this.$t('email'), key: 'email', sortable: this.sortable },
          { header: this.$t('campaign'), key: 'campaign', sortable: false },
          { header: this.$t('domain'), key: 'domain', sortable: false },
          { header: this.$t('date'), key: 'date', sortable: this.sortable },
        ];

        if (this.availableColumns) {
          columns = columns.filter((c) => this.availableColumns.includes(c.key));
          if (this.availableColumns.includes('customFields')) {
            columns = columns.concat(this.customFieldColumns);
          }
        }

        return columns;
      },
      skeletonRowNumber() {
        return this.subscriberCount > 30 ? 30 : this.subscriberCount ? this.subscriberCount : 5;
      },
    },
    methods: {
      showIntegrationErrorDetails(subscriber) {
        const { integrationErrors } = subscriber;
        this.$modal.show('integration-error-details', {
          errors: integrationErrors,
          campaignId: subscriber.id,
        });
      },
      hasIntegrationError(subscriber) {
        if (subscriber.integrationErrors && subscriber.integrationErrors.length > 0) {
          subscriber.syncStatus = 'failed';
          return subscriber.integrationErrors.length > 0;
        }
        return false;
      },
      getIntegrationErrorText(subscriber) {
        const { integrationErrors, syncStatus } = subscriber;

        const isSoftError = (errorCode) =>
          Object.values(ERROR_CODES.SUBSCRIBER).includes(errorCode);

        for (const { errorCode } of integrationErrors) {
          if (!errorCode) continue;

          if (!isSoftError(errorCode)) {
            return errorCode;
          }
        }

        return (
          integrationErrors.find(({ errorCode }) => isSoftError(errorCode))?.errorCode || syncStatus
        );
      },
    },
  };
</script>

<style lang="sass" scoped>
  .subscriber-table-integration-error
    white-space: nowrap
    cursor: pointer
    display: flex
    align-items: center
  .subscriber-table-value
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
</style>
