import { AVG_TIME_ON_PAGE_TRIGGER } from '@/utils/features';

const triggeringEvents = {
  exitIntent: { device: 'desktop' },
  click: { options: { expression: [{ selector: '', device: 'desktop_and_mobile' }] } },
  scrollDown: { device: 'desktop_and_mobile', options: { percentage: 0 } },
  inactivity: { device: 'desktop_and_mobile', options: { delay: 1 } },
  timed: { device: 'desktop_and_mobile', options: { delay: 1 } },
  javascriptEvent: { options: { expression: [{ eventName: '', device: 'desktop_and_mobile' }] } },
};

const frontendRules = {
  pageViewerType: { options: { value: '' } },
  // 'source': {options: {expression: [{source: 'custom', operator: 'equals', value: ''}]}},
  source: { options: { expression: [] } },
  country: { options: { countries: [] } },
  subscribers: { options: { type: 'exclude', lists: [{ id: 'all' }] } },

  minimumPageVisit: { options: { operator: 'atLeast', value: 2 } },
  // 'viewedPage': {options: {expressions: [[{operator: 'equals', value: ''}]]}},
  viewedPage: ({ store, domain, isEmbeddedV3, isDynamicContent, JFFlag }) => {
    const isPlatformSpecific =
      store.getters.isActiveShoprenterDomain(domain) || store.getters.isActiveShopifyDomain(domain);
    const isNewCampaignType = isEmbeddedV3 || isDynamicContent;
    if (isPlatformSpecific && (!isNewCampaignType || (isNewCampaignType && JFFlag))) {
      return { options: { expressions: [[{ pageType: 'page', operator: 'equals', value: '' }]] } };
    }
    return { options: { expression: [{ operator: 'equals', value: '' }] } };
  },
  viewedPageV2: {
    options: {
      includeExpressions: [],
      excludeExpressions: [],
    },
  },
  klaviyoSegment: { options: { expression: { in: [], notIn: [] } } },
  previouslyViewedPage: { options: { expressions: [[{ operator: 'equals', value: '' }]] } },
  enhancedPageViews: {
    options: {
      expression: [
        'and',
        {
          type: 'pageView',
          attribute: 'url',
          operator: 'contains',
          value: '',
          count: 0,
          comparator: 'gt',
          interval: 86400,
        },
      ],
    },
  },
  notViewedPageRecent: {
    options: {
      expression: [{ operator: 'equals', value: '', secondsToBlock: 0 }],
    },
  },

  timeBasedActualPage: { options: { value: 0 } },
  timeBasedSession: { options: { value: 0 } },
  // 'schedule': {options: {from: null, to: null}},
  maximumPopupDisplay: { options: { time: '', unit: 'second', value: '' } },

  visitorAttribute: {
    options: {
      expressions: [
        [{ attributeName: 'utm_campaign', attributeType: 'string', operator: 'equals', value: '' }],
      ],
    },
  },
  visitorCart: {
    options: {
      expressions: [
        [
          {
            for: 'cart',
            attributeName: '_cart_total',
            attributeType: 'number',
            operator: 'greaterThanEquals',
            value: 0,
          },
          {
            for: 'cart',
            attributeName: '_number_of_cart_items',
            attributeType: 'number',
            operator: 'greaterThanEquals',
            value: 0,
          },
          {
            for: 'cart',
            attributeName: '_number_of_cart_item_kinds',
            attributeType: 'number',
            operator: 'greaterThanEquals',
            value: 0,
          },
        ],
      ],
    },
  },
  visitorCartRevamp: {
    options: {
      expressions: [
        [
          {
            for: 'cartValue',
            attributeName: '_cart_total',
            attributeType: 'number',
            operator: 'greaterThan',
            value: '',
            type: 'string',
          },
        ],
      ],
    },
  },
  visitorCartV3: {
    options: {
      expressions: [
        {
          for: 'cartValue',
          attributeOperator: 'greaterThan',
          value: 0,
          type: 'number',
        },
      ],
    },
  },
  campaignProgressState: {
    options: {
      expressions: [
        [{ campaignId: '', state: 'notShowed', messageType: 'campaign', visitType: 'visit' }],
      ],
    },
  },
  ipBlock: {
    options: {
      expression: [{ type: 'specific', value: '' }],
    },
  },
  cookie: {
    options: {
      expressions: [[{ cookieName: '', cookieValueType: 'string', operator: 'equals', value: '' }]],
    },
  },
  aBlock: { options: { value: 'enabled' } },
  loggedIn: { options: { value: 'no' } },
};

const flagBasedRuleDefaults = {};

const flagBasedEvent = {
  [AVG_TIME_ON_PAGE_TRIGGER]: {
    avgTimeOnPage: { device: 'mobile' },
  },
};

const featureBasedEvents = (features) => {
  let events = { ...triggeringEvents };

  features.forEach((feature) => {
    if (flagBasedEvent[feature]) {
      events = {
        ...events,
        ...flagBasedEvent[feature],
      };
    }
  });

  return events;
};

const featureBasedRules = (features) => {
  let rules = { ...frontendRules };

  features.forEach((feature) => {
    if (flagBasedRuleDefaults[feature]) {
      rules = {
        ...rules,
        ...flagBasedRuleDefaults[feature],
      };
    }
  });

  return rules;
};

export default (features) => {
  return {
    events: featureBasedEvents(features),
    frontendRules: featureBasedRules(features),
  };
};
