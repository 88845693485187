<template lang="pug">
BlockColumnsWithIcon.personalized-experiences(
  icon="/admin/svg/personalization-block-icon.svg"
  :rightColumn="false"
)
  template(#left)
    .d-flex.mb-4.align-items-center
      om-body-text.mr-2.personalized-experiences-title(bt400lg) {{ $t('personalizedExperiences.title') }}
    .row.d-flex.justify-content-between
      .col-12.col-xl-6.personalized-experiences-left
        om-body-text.mr-2.gray-600(bt400xs) {{ $t('personalizedExperiences.details') }}
      .col-12.col-xl-6.d-flex.align-items-end.justify-content-end.personalized-experiences-right
        om-button#personalizedExperienceReadMore.gray-700.mr-2(
          icon="book-alt"
          small
          @click="openUrl('readMore')"
        ) {{ $t('personalizedExperiences.buttons.readMore.title') }}
        om-button#personalizedExperienceWatch.gray-700.mr-2(
          icon="play-circle"
          small
          @click="openUrl('watch')"
        ) {{ $t('personalizedExperiences.buttons.watch.title') }}
        om-button#personalizedExperienceStart(
          secondary
          icon="star"
          :loading="loading"
          small
          @click="$emit('startPersonalizedExperiences')"
        ) {{ $t('personalizedExperiences.buttons.start') }}
</template>

<script>
  import BlockColumnsWithIcon from '@/components/CampaignSettings/Block/ColumnsWithIcon.vue';
  import Badge from '@/components/Badge.vue';
  import { UilCommentInfo } from '@iconscout/vue-unicons';
  import { mapState } from 'vuex';

  export default {
    components: { BlockColumnsWithIcon, Badge, UilCommentInfo },
    props: {
      campaign: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },
    data: () => ({ loading: false }),
    computed: {
      ...mapState(['account']),
    },
    methods: {
      openUrl(key) {
        const linkKey = `personalizedExperiences.buttons.${key}.link`;
        window.open(this.$t(linkKey), '_blank');
      },
      notifyOnError() {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.saveError'),
        });
        this.loading = false;
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .personalized-experiences
    & &-title
      font-size: 1.5rem
    & &-badge
      padding: 0 0.75rem
    &-right
      padding-top: 1.5rem
      @media (min-width: 992px)
        padding-top: 0
</style>
