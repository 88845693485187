import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import CHANGE_CAMPAIGN_STATUS from '@/graphql/ChangeCampaignStatus.gql';
import { get as _get } from 'lodash-es';
import moment from 'moment';
import { getPreviewVariant, getAccountIdFromCookie } from '@/util';
import VariantImage from '@/components/VariantImage.vue';
import upgradePlanModal from '@/mixins/upgradePlanModal';

export default {
  mixins: [upgradePlanModal],
  components: {
    VariantImage,
  },
  computed: {
    ...mapState({
      integrations: (state) => _get(state, 'account.settings.integrations', []),
    }),
    ...mapGetters(['isPageViewBasedPackage']),
  },
  methods: {
    getPreviewVariant(campaign) {
      return getPreviewVariant(campaign);
    },
    async toggleCampaignStatus(campaignId, status) {
      let campaign;
      let campaignIndex;
      for (let i = 0; i <= this.campaigns.length; i++) {
        if (this.campaigns[i]._id === campaignId) {
          campaign = this.campaigns[i];
          campaignIndex = i;
          break;
        }
      }

      if (status === 'active' && !campaign.domain && !campaign.domainId) {
        this.$router.push({
          name: 'campaign_variants',
          params: { userId: getAccountIdFromCookie(), id: campaign.id },
        });
        return;
      }

      this.$apollo
        .mutate({
          mutation: CHANGE_CAMPAIGN_STATUS,
          variables: {
            campaignId,
            status,
          },
        })
        .then(({ data: { changeCampaignStatus } }) => {
          if (!changeCampaignStatus.success) {
            if (changeCampaignStatus.message === 'upgrade') {
              if (!this.isPageViewBasedPackage) {
                this.$notify({
                  type: 'error',
                  text: this.$t('notifications.activeDomainLimitReached'),
                });
                return;
              }

              this.showUpgradePlanModal(changeCampaignStatus.triggers);
            } else if (changeCampaignStatus.message) {
              this.$notify({
                type: 'error',
                text: this.$t(changeCampaignStatus.message),
              });
            }
          } else {
            Vue.set(this.campaigns[campaignIndex], 'status', status);

            if (this.$route.query && this.$route.query.status === 'active') {
              this.campaigns.splice(campaignIndex, 1);
            }

            if (this.$route.name === 'dashboard') {
              const topIndex = this.topCampaignsResponse.top.findIndex(
                (item) => item._id === campaignId,
              );
              const recentIndex = this.topCampaignsResponse.recent.findIndex((item) => {
                return item._id === campaignId;
              });

              if (topIndex !== -1) {
                this.topCampaignsResponse.top[topIndex].status = status;
                this.topCampaignsResponse.top.splice(topIndex, 1);
              }
              if (recentIndex !== -1) {
                this.topCampaignsResponse.recent[recentIndex].status = status;
              }
            }
          }
        });
    },

    goToCampaign(campaignId) {
      this.$router.push({
        name: 'campaign_variants',
        params: { userId: getAccountIdFromCookie(), id: campaignId },
      });
    },

    isScheduleExpired(schedule) {
      return moment().isAfter(moment(schedule.to));
    },

    domainRequestStatus(domain) {
      return this.$store.getters.domainRequestStatus(domain);
    },

    noActiveVariant(variants) {
      return variants.every((v) => v.status !== 'active');
    },

    hasScheduleFrom(campaigns, index) {
      return _get(campaigns[index], 'schedule.from');
    },
    isToggleInactive(rowData) {
      return rowData.row.currentExperimentName ||
        this.hasScheduleFrom(this.campaigns, rowData.index)
        ? 'pointer-events:none'
        : '';
    },
  },
};
