<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='30', height='36', viewBox='0 0 30 36')
  g(:fill='color', fill-rule='evenodd')
    path(d='M8.35 21.34H6.77v-4.474l.015-.735.026-.804c-.263.272-.446.45-.548.534l-.86.714-.763-.984 2.411-1.983h1.3v7.732zM17.32 21.03h-5.546v-1.12l1.992-1.934c.59-.58.975-.983 1.155-1.207.181-.224.311-.432.391-.623.08-.191.12-.39.12-.595 0-.305-.088-.533-.264-.683-.175-.15-.41-.224-.702-.224-.308 0-.606.068-.896.203-.29.136-.591.329-.906.58l-.911-1.038c.39-.32.714-.546.97-.678.258-.132.538-.234.842-.305a4.48 4.48 0 0 1 1.02-.107c.495 0 .933.087 1.313.26.38.175.675.418.884.73.21.313.315.671.315 1.075 0 .35-.064.68-.192.988a3.769 3.769 0 0 1-.597.946c-.27.323-.745.784-1.425 1.381l-1.02.923v.073h3.457v1.356zM24.828 15.16c0 .49-.144.905-.431 1.247-.288.343-.691.578-1.21.707v.032c.612.078 1.077.27 1.392.575.315.305.473.716.473 1.233 0 .753-.265 1.338-.795 1.758-.53.419-1.287.628-2.27.628-.825 0-1.556-.14-2.193-.422V19.51c.294.153.618.278.971.374.354.096.703.145 1.05.145.53 0 .921-.093 1.174-.279.253-.185.38-.483.38-.893 0-.368-.146-.628-.437-.781-.291-.154-.755-.23-1.393-.23h-.576v-1.268h.587c.589 0 1.019-.08 1.29-.238.273-.16.409-.431.409-.816 0-.592-.36-.888-1.08-.888-.25 0-.504.042-.762.128a3.676 3.676 0 0 0-.86.444l-.743-1.14c.693-.513 1.519-.77 2.478-.77.787 0 1.407.164 1.863.492.455.328.683.785.683 1.37')
    path(d='M15 2.784c-8.284 0-15 6.715-15 15 0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.285-6.716-15-15-15m0 2.249c3.406 0 6.608 1.326 9.016 3.735a12.667 12.667 0 0 1 3.735 9.016c0 3.405-1.327 6.607-3.735 9.015A12.667 12.667 0 0 1 15 30.534a12.667 12.667 0 0 1-9.016-3.735 12.667 12.667 0 0 1-3.735-9.015c0-3.406 1.327-6.608 3.735-9.016A12.667 12.667 0 0 1 15 5.033')
    path(d='M17.338 3.238a.998.998 0 0 0-.476-.255L14.068.281a1.018 1.018 0 0 0-1.406 0 .939.939 0 0 0 0 1.36l2.462 2.38-2.462 2.38a.939.939 0 0 0 0 1.359c.194.187.449.281.703.281.254 0 .509-.094.703-.281l2.794-2.702a.998.998 0 0 0 .476-.255.94.94 0 0 0 .285-.782.94.94 0 0 0-.285-.783M12.971 32.329a.997.997 0 0 0 .476.255l2.794 2.702a1.018 1.018 0 0 0 1.406 0 .939.939 0 0 0 0-1.36l-2.461-2.38 2.461-2.38a.939.939 0 0 0 0-1.359 1.008 1.008 0 0 0-.703-.281c-.254 0-.508.094-.703.281l-2.794 2.702a.998.998 0 0 0-.476.255.94.94 0 0 0-.285.782.94.94 0 0 0 .285.783')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
