import moment from 'moment';
import i18n from '@/i18n';
import { daysBetween, isLastMonthPeriod, isToday, isYesterday } from '@/util';

export const dateRangeDateFormat = 'YYYY-MM-DD';

export const getDateRangeOptions = (dateFormat) => {
  return [
    {
      value: i18n.t('dateRange.yesterday'),
      key: 'yesterday',
      interval: {
        from: moment.utc().subtract(1, 'days').startOf('day').format(dateFormat),
        to: moment.utc().subtract(1, 'days').endOf('day').format(dateFormat),
      },
    },
    {
      value: i18n.t('dateRange.last7days'),
      key: 'past7days',
      interval: {
        from: moment.utc().subtract(6, 'days').startOf('day').format(dateFormat),
        to: moment.utc().subtract(0, 'days').endOf('day').format(dateFormat),
      },
    },
    {
      value: i18n.t('dateRange.last30days'),
      key: 'last30days',
      interval: {
        from: moment.utc().subtract(30, 'days').startOf('day').format(dateFormat),
        to: moment.utc().subtract(0, 'days').endOf('day').format(dateFormat),
      },
    },
    {
      value: i18n.t('dateRange.lastMonth'),
      key: 'lastMonth',
      interval: {
        from: moment.utc().subtract(1, 'month').startOf('month').format(dateFormat),
        to: moment.utc().subtract(1, 'month').endOf('month').format(dateFormat),
      },
    },
    {
      value: i18n.t('dateRange.allTime'),
      key: 'allTime',
      interval: {
        from: 'allTime',
        to: 'allTime',
      },
    },
  ];
};

export default {
  data() {
    const dateRangeOptionCustom = { value: i18n.t('dateRange.customDateRange'), key: 'custom' };
    return {
      dateRangeOptions: getDateRangeOptions(dateRangeDateFormat),
      dateRangeOptionCustom,
      dateRangeDateFormat,
    };
  },
  computed: {
    minimumSelectableDate() {
      return moment.utc().subtract(5, 'year').format('YYYY-MM-DD');
    },
    maximumSelectableDate() {
      return moment.utc().format('YYYY-MM-DD');
    },
  },

  methods: {
    convertDateFormat(date) {
      return moment.utc(date).format('YYYY-MM-DD');
    },
    calculateDateRangeFilter(from, to) {
      const filterValue = {
        interval: {
          from: this.convertDateFormat(from),
          to: this.convertDateFormat(to),
        },
        value: this.$t('dateRange.customDateRange'),
      };
      let key = 'custom';
      if (to === 'allTime') {
        key = 'allTime';
      } else if (from === to && isYesterday(to)) {
        key = 'yesterday';
      } else if (isToday(to)) {
        const values = {
          6: 'past7days',
          30: 'last30days',
        };
        const numberOfDays = daysBetween(from, to);
        key = values[numberOfDays] || 'custom';
      } else if (isLastMonthPeriod(from, to)) {
        key = 'lastMonth';
      }
      return {
        ...filterValue,
        key,
      };
    },
  },
};
