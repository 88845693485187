<template lang="pug">
om-modal(
  name="add-follow-up"
  :title="$t('followupCampaign.addFollowupCampaign')"
  :width="746"
  @beforeOpen="beforeOpen"
)
  template(slot="modal-body")
    .row
      .follow-up-text.col-md-6(
        v-html="$t('followupCampaign.explanationText', { link: 'https://support.optimonk.com/hc/en-us/articles/4422002586130-Discount-code-reminder' })"
      )
      .d-flex.justify-content-center.col-md-6
        lottie.follow-up-animation(filename="follow_up")
  template(slot="modal-footer")
    om-button.mr-4(ghost @click="cancel")
      span {{ $t('cancel') }}
    om-button#add-followup-campaign(
      primary
      :to="{ name: 'use_case_templates', params: { id: '5fb534910e4fb2001328a153' } }"
    )
      span {{ $t('followupCampaign.addAFollowupCampaign') }}
</template>

<script>
  export default {
    data() {
      return {
        followUpTemplatesUrl: '',
      };
    },
    computed: {},
    methods: {
      beforeOpen({ params: { followUpTemplatesUrl } }) {
        this.followUpTemplatesUrl = followUpTemplatesUrl;
      },
      cancel() {
        this.$modal.hide('add-follow-up');
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '../../sass/variables/_colors.sass'

  ::v-deep .brand-modal-header
    padding: 20px 40px
    border-bottom: 1px solid $om-gray-300

    .brand-modal-title
      font-size: 18px
      line-height: 24px
      color: $om-gray-800
      font-weight: bold

    .brand-modal-action-icon
      right: 20px 40px

  ::v-deep .brand-modal-body
    display: flex
    padding: 24px 40px

  ::v-deep .brand-modal-footer
    display: flex
    justify-content: flex-end
    padding: 20px 40px
    margin: 0
    border-top: 1px solid $om-gray-300

  .follow-up-text
    font-size: 16px
    line-height: 24px
    color: $om-gray-800

  .follow-up-animation
    width: 200px !important
    height: 200px !important
    margin: unset !important

  @media screen and (min-width: 768px)
    .follow-up-animation
      margin-right: -40px !important
</style>
