<template lang="pug">
svg(width='50', height='50', viewBox='0 0 50 50', fill='none', xmlns='http://www.w3.org/2000/svg')
  g(clip-path='url(#clip0)')
    path(fill-rule='evenodd', clip-rule='evenodd', d='M15.3741 28.7159C15.3741 28.5761 15.2605 28.4622 15.1203 28.4622H5.25835C5.11813 28.4622 5.00452 28.5761 5.00452 28.7159V36.8577C5.00452 36.9975 5.11813 37.1114 5.25835 37.1114H15.1203C15.2605 37.1114 15.3741 36.9975 15.3741 36.8577V28.7159Z', :fill="color")
    path(fill-rule='evenodd', clip-rule='evenodd', d='M17.3925 28.4622H27.3354L30.0468 37.1112H17.3925C17.2524 37.1112 17.1387 36.9975 17.1387 36.8574V28.716C17.1387 28.5759 17.2524 28.4622 17.3925 28.4622Z', :fill="color")
    path(fill-rule='evenodd', clip-rule='evenodd', d='M34.4892 19.1635C34.6293 19.1635 34.743 19.2772 34.743 19.4173V25.7412L25.0806 21.2016L26.9245 27.0833H5.25832C5.11822 27.0833 5.00452 26.9696 5.00452 26.8295V19.4173C5.00452 19.2772 5.11822 19.1635 5.25832 19.1635H34.4892Z', :fill="color")
    path(fill-rule='evenodd', clip-rule='evenodd', d='M2.81458 16.9032V36.8631C2.81458 38.1875 3.89047 39.2652 5.2128 39.2652H30.733L31.4632 41.5946H5.07727C2.28667 41.5946 0.024231 39.3284 0.024231 36.5332V7.52268C0.024231 4.7275 2.28641 2.4615 5.07727 2.46124H34.0401C36.831 2.4615 39.0931 4.7275 39.0931 7.52268V27.7905L36.7673 26.6979V16.9032H2.81458ZM13.5115 7.12014C12.7407 7.12014 12.1161 7.74578 12.1161 8.51787C12.1161 9.2897 12.7407 9.91533 13.5115 9.91533C14.2821 9.91533 14.9067 9.2897 14.9067 8.51787C14.9067 7.74578 14.2821 7.12014 13.5115 7.12014ZM9.79197 7.12014C9.02141 7.12014 8.39654 7.74578 8.39654 8.51787C8.39654 9.2897 9.02141 9.91533 9.79197 9.91533C10.5625 9.91533 11.1871 9.2897 11.1871 8.51787C11.1871 7.74578 10.5625 7.12014 9.79197 7.12014ZM6.07067 7.12014C5.29986 7.12014 4.67523 7.74578 4.67523 8.51787C4.67523 9.2897 5.29986 9.91533 6.07067 9.91533C6.84122 9.91533 7.46583 9.2897 7.46583 8.51787C7.46583 7.74578 6.84122 7.12014 6.07067 7.12014ZM36.7673 14.574V13.1765H2.81458V14.574H36.7673ZM2.81458 10.8471H36.7673V7.65873C36.7673 6.3341 35.6914 5.25669 34.3691 5.25669H5.2128C3.89047 5.25669 2.81458 6.3341 2.81458 7.65873V10.8471Z', :fill="color")
    path(d='M49.9059 34.7536L28.0143 24.4685L35.2499 47.5482L38.8556 40.9973L44.0503 46.9479L48.123 43.3925L42.9282 37.442L49.9059 34.7536Z', :fill="color")
  defs
    clippath#clip0
      rect(width='50', height='50', fill='white')
</template>

<script>
  import eventSvg from '@/mixins/eventSvg';

  export default {
    mixins: [eventSvg],
  };
</script>
