import CHANGE_CAMPAIGN_SETTINGS from '@/graphql/ChangeCampaignSettings.gql';
import { getBrandedClassString } from '@/components/Elements/Button';

export default {
  methods: {
    saveScheduleToDB(from, to) {
      return this.$apollo.mutate({
        mutation: CHANGE_CAMPAIGN_SETTINGS,
        variables: {
          input: {
            _id: this.currentCampaign?.id ?? this.$route.params.id,
            schedule: {
              from,
              to,
            },
          },
        },
      });
    },

    async saveSchedule(from, to, onScheduleSaved) {
      try {
        await this.saveScheduleToDB(from, to);
        onScheduleSaved();
        if (from && to) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.saveSuccess'),
          });
        } else {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.deleteSuccess'),
          });
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.saveError'),
        });
      }
    },

    async deleteSchedule(onSheduleDeleted) {
      this.$modal.show('dialog', {
        title: this.$t('scheduleModal.deleteSchedule'),
        text: this.$t('scheduleModal.deleteConfirmation'),
        buttons: [
          {
            title: this.$t('delete'),
            class: getBrandedClassString({ primary: true }, 'mr-3'),
            handler: async () => {
              await this.saveSchedule(null, null, onSheduleDeleted);
              onSheduleDeleted();
              this.$modal.hide('schedule');
              this.$modal.hide('dialog');
            },
          },
          {
            title: this.$t('cancel'),
            class: getBrandedClassString({ secondary: true }),
            default: true,
          },
        ],
      });
    },
  },
};
