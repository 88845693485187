<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white">
    <circle cx="21.2308" cy="20.7692" r="1.84615" :stroke="color" stroke-width="1.84615" />
    <circle cx="2.76923" cy="20.7692" r="1.84615" :stroke="color" stroke-width="1.84615" />
    <circle cx="12" cy="3.23078" r="1.84615" :stroke="color" stroke-width="1.84615" />
    <circle cx="12" cy="14.3077" r="6.46154" :stroke="color" stroke-width="1.84615" />
    <circle cx="12" cy="12.4616" r="1.84615" :stroke="color" stroke-width="1.84615" />
    <path
      d="M14.7692 19.8462C14.7692 18.3168 13.5294 17.0769 12 17.0769C10.4706 17.0769 9.23077 18.3168 9.23077 19.8462"
      :stroke="color"
      stroke-width="1.84615"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: '#505763',
      },
    },
  };
</script>
