<template lang="pug">
.comparer
  .form-text {{ variantLabel }}
  template(v-if="textIsTruncated(comparerText)")
    om-tooltip {{ removeTags(comparerText) }}
      template(slot="trigger")
        .comparer-text(:class="{ 'text-truncate': textIsTruncated }")
          span {{ removeTags(comparerText) }}
        span.comparer-text(v-if="textSuffix") {{ removeTags(textSuffixWithVersion) }}
  template(v-else)
    .comparer-text {{ removeTags(comparerTextWithSuffix) }}
</template>
<script>
  export default {
    props: {
      customLabel: {
        type: Boolean,
        default: false,
      },
      comparerName: {
        type: String,
        default: '',
      },
      comparerText: {
        type: String,
        default: '',
      },
      textSuffix: {
        type: String,
        default: '',
      },
    },
    computed: {
      variantLabel() {
        if (this.customLabel) {
          return this.comparerName;
        }

        return this.versionText(this.comparerName);
      },
      comparerTextWithSuffix() {
        if (this.textSuffix) {
          return `${this.comparerText} (${this.versionText(this.textSuffix)})`;
        }
        return this.comparerText;
      },
      textSuffixWithVersion() {
        return `(${this.versionText(this.textSuffix)})`;
      },
    },
    methods: {
      textIsTruncated(text) {
        return text.length > 60;
      },
      versionText(text) {
        const variant = text.split('V');

        if (variant[1] === '0') {
          return this.$t('smartAbTest.page.accordion.original');
        }
        return this.$t('smartAbTest.page.accordion.variant', { number: variant[1] });
      },
      removeTags(text) {
        return text.replace(/(<([^>]+)>)/gi, '');
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .comparer
    height: 3rem
    margin-left: .75rem
    padding: 0.35rem 0
    .form-text
      margin-top: 0
    &-text
      font-size: 0.85rem
      color: $om-gray-800
      font-weight: 500
      &.text-truncate
        max-width: 345px
</style>
