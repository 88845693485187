<template lang="pug">
.brand-device(:class="small ? 'brand-device-small' : ''")
  desktop-svg(:class="small ? 'mr-2' : 'mr-2'" :activeColor="activeColor" :active="type === 'desktop' || type === 'desktop_and_mobile'")
  mobile-svg(:activeColor="activeColor" :active="type === 'mobile' || type === 'desktop_and_mobile'")
</template>
<script>
  export default {
    props: {
      type: { type: String, required: true },
      activeColor: { type: String },
      small: { type: Boolean, default: false },
    },
  };
</script>

<style lang="sass">
  .brand-device
    &-small
      svg
        height: 1.25rem
</style>
