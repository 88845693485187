export const existsOperators = ['isSet', 'notSet'];
export const stringBaseOperators = ['equals', 'notEquals', 'contains', 'notContains'];
export const stringBaseOperatorsForUrl = ['equals', 'contains', 'startsWith', 'endsWith'];
export const stringOperators = [...stringBaseOperators, ...existsOperators];
export const numberBaseOperators = [
  'equals',
  'greaterThan',
  'lessThan',
  'greaterThanEquals',
  'lessThanEquals',
];
export const onlyNumberOperators = [...numberBaseOperators, 'interval'];
export const includeOperators = ['include', 'exclude'];
export const productOperators = ['id', 'name'];
export const numberOperators = [...onlyNumberOperators, ...existsOperators];
export const booleanOperators = ['is', 'isNot'];
export const allStringOperators = [
  ...stringOperators,
  'startsWith',
  'notStartsWith',
  'endsWith',
  'notEndsWith',
];
export const dateIntervalOperators = ['dateIsBetween', 'dateIsBetweenDates'];
export const relativeDateOperators = ['dateIsInLast', 'dateIsAtLeast', 'dateIsBetween'];
export const absoluteDateOperators = ['dateIsBefore', 'dateIsAfter', 'dateIsBetweenDates'];
export const dateOperators = [...relativeDateOperators, ...absoluteDateOperators];
