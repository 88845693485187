const streamToArray = async (stream) => {
  const result = [];
  return new Promise((resolve, reject) => {
    stream.on('data', (chunk) => {
      result.push(chunk);
    });
    stream.on('end', () => {
      resolve(result);
    });
    stream.on('error', (err) => {
      reject(err);
    });
  });
};

const streamToString = (stream, encoding = 'utf8') => {
  const chunks = [];
  return new Promise((resolve, reject) => {
    stream.on('data', (chunk) => chunks.push(Buffer.from(chunk)));
    stream.on('error', (err) => reject(err));
    stream.on('end', () => resolve(Buffer.concat(chunks).toString(encoding)));
  });
};

module.exports = { streamToArray, streamToString };
