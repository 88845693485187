<template lang="pug">
div
  template(v-for="(expressionGroup, expressionGroupIndex) in currentRule.options.expressions")
    template(v-for="(target, targetIndex) in expressionGroup")
      .d-flex.align-items-center.mb-3(ref="rule" :data-index="targetIndex")
        .settings-label {{ $t('filterOne') }}
        om-input.w-7.mx-3(
          small
          type="text"
          :id="`cookieName-${targetIndex}`"
          :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].cookieName.$error"
          v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].cookieName"
          :show-invalid-suffix="false"
        )
        .settings-label {{ $t('cookieCustomTwo') }}
        om-select.w-7.mx-2(
          size="small"
          :id="`cookieValueType-${targetIndex}`"
          @input="setCookieValueType($event, expressionGroupIndex, targetIndex)"
          :options="cookieValueTypes"
          :value="{ key: currentRule.options.expressions[expressionGroupIndex][targetIndex].cookieValueType, value: getCookieValueTypeText(currentRule.options.expressions[expressionGroupIndex][targetIndex].cookieValueType) }"
        )
        .settings-label {{ $t('and') }}
        om-select.w-10.ml-3.mx-2(
          :id="`operator-${targetIndex}`"
          size="small"
          @input="setOperator($event, expressionGroupIndex, targetIndex)"
          :value="{ key: currentRule.options.expressions[expressionGroupIndex][targetIndex].operator, value: getOperatorText(currentRule.options.expressions[expressionGroupIndex][targetIndex].operator) }"
          :options="createOptionArray(typeBasedOperators(expressionGroupIndex, targetIndex))"
        )
        template(
          v-if="currentRule.options.expressions[expressionGroupIndex][targetIndex].operator === 'interval'"
        )
          .d-flex.align-items-center
            om-input(
              :id="`operator-interval-value-${targetIndex}-0`"
              type="number"
              small
              :class="targetIndex === expressionGroup.length - 1 ? 'w-8' : 'w-8'"
              :value="getIntervalValue(currentRule.options.expressions[expressionGroupIndex][targetIndex].value, 0)"
              @input="setIntervalValue(expressionGroupIndex, targetIndex, 0, $event)"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error"
              :show-invalid-suffix="false"
            )
            span.mx-2.px-1.font-size-0--875.font-weight-500 {{ $t('and') }}
            om-input(
              :id="`cart-interval-value-${targetIndex}-1`"
              type="number"
              small
              :class="targetIndex === expressionGroup.length - 1 ? 'w-8' : 'w-8'"
              :value="getIntervalValue(currentRule.options.expressions[expressionGroupIndex][targetIndex].value, 1)"
              @input="setIntervalValue(expressionGroupIndex, targetIndex, 1, $event)"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error"
              :show-invalid-suffix="false"
            )
        template(v-else)
          om-input(
            :id="`cookieValue-${targetIndex}`"
            small
            type="text"
            v-if="needsInput(currentOperator(expressionGroupIndex, targetIndex))"
            :class="targetIndex === expressionGroup.length - 1 ? 'w-8' : 'w-8'"
            :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error"
            v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].value"
            :show-invalid-suffix="false"
          )
        .d-flex.flex-grow-1.justify-content-end
          .font-size-0--75.font-weight-bold.mx-2.flex-grow-1 {{ $t('andOperation') }}
          .brand-link.settings-label.mr-2.flex-grow-0.text-center(
            v-if="targetIndex === expressionGroup.length - 1"
            @click="addExpressionToGroup('expressions', expressionGroupIndex, { cookieName: '', cookieValueType: 'string', operator: 'equals', value: '' })"
          )
            fa-icon(variant="fa-plus-circle" size="1.5")
          .brand-link.settings-label(
            v-if="currentRule.options.expressions.length === 1 && expressionGroup.length === 1 && targetIndex === 0"
          )
          .brand-link.settings-label.flex-grow-0.text-right(
            v-else
            @click="removeExpressionFromGroup('expressions', expressionGroupIndex, targetIndex)"
          )
            fa-icon(variant="fa-trash" size="1.5")
    .d-flex.align-items-center.mb-3(
      v-if="expressionGroupIndex !== currentRule.options.expressions.length - 1"
    )
      .font-size-0--75.font-weight-bold.mr-2 {{ $t('orOperation') }}
      .brand-separator
  span.brand-link.settings-label(
    @click="addExpressionGroup('expressions', { cookieName: '', cookieValueType: 'string', operator: 'equals', value: '' })"
  )
    fa-icon(variant="fa-plus" :html="`&nbsp;${$t('addNew')}`")
</template>

<script>
  import { required, requiredIf } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import frontendRuleUtils from '@/mixins/frontendRuleUtils';
  import { isNumber } from '@om/common';
  import { stringOperators, numberOperators, existsOperators } from './statics';

  export default {
    mixins: [settingsValidation, expressionOperations, frontendRuleUtils],
    data() {
      return {
        cookieValueTypes: [
          { key: 'string', value: this.$t('text') },
          { key: 'number', value: this.$t('number') },
        ],
      };
    },
    methods: {
      getIntervalValue(value, index) {
        const values = `${value}`.split('-');
        if (values.length === 2) return values[index];
        return index === 0 ? values[0] : '';
      },
      setIntervalValue(expressionGroupIndex, targetIndex, index, value) {
        const currentValue =
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value || '-';
        const parts = currentValue.split('-');
        parts[index] = value;
        this.currentRule.options.expressions[expressionGroupIndex][
          targetIndex
        ].value = `${+parts[0]}-${+parts[1]}`;
      },
      setCookieValueType(event, expressionGroupIndex, targetIndex) {
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].cookieValueType =
          event.key;
        this.onTypeChange(expressionGroupIndex, targetIndex);
      },
      setOperator(event, expressionGroupIndex, targetIndex) {
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].operator =
          event.key;
        this.onOperatorChange(expressionGroupIndex, targetIndex);
      },
      getCookieValueTypeText(type) {
        return type === 'string' ? this.$t('text') : this.$t('number');
      },
      getOperatorText(key) {
        return this.$t(key);
      },
      currentOperator(groupIndex, index) {
        return this.currentRule.options.expressions[groupIndex][index].operator;
      },
      typeBasedOperators(groupIndex, index) {
        return this.currentRule.options.expressions[groupIndex][index].cookieValueType === 'string'
          ? stringOperators
          : numberOperators;
      },
      needsInput(operator) {
        return !existsOperators.includes(operator);
      },
      onTypeChange(groupIndex, index) {
        this.currentRule.options.expressions[groupIndex][index].operator =
          this.currentRule.options.expressions[groupIndex][index].cookieValueType === 'string'
            ? stringOperators[0]
            : numberOperators[0];
      },
      onOperatorChange(groupIndex, index) {
        if (!this.needsInput(this.currentOperator(groupIndex, index))) {
          this.currentRule.options.expressions[groupIndex][index].value = '';
        }
      },
    },
    validations: {
      currentRule: {
        options: {
          expressions: {
            $each: {
              $each: {
                cookieName: {
                  required,
                },
                value: {
                  required: requiredIf(function ({ operator }) {
                    return this.needsInput(operator);
                  }),
                  isCool(val, { cookieValueType, operator }) {
                    if (operator === 'interval') {
                      const parts = `${val}`.replace(';', '-').split('-');

                      if (parts[0] === '' || parts[1] === '') return false;

                      return (
                        /^-?[0-9]*$/.test(parts[0]) &&
                        isNumber(+parts[0]) &&
                        /^-?[0-9]*$/.test(parts[1]) &&
                        isNumber(+parts[1])
                      );
                    }

                    return cookieValueType === 'number' && !existsOperators.includes(operator)
                      ? /^-?[0-9]*$/.test(val)
                      : true;
                  },
                },
              },
            },
          },
        },
      },
    },
  };
</script>
