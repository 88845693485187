<template lang="pug">
tbody.skeleton-row.tbody
  tr.current-smart-test(v-for="index in rows" :key="index")
    td.px-0.pb-0(colspan="6")
      table.table
        tbody
          tr
            td.border-top.pt-5
              .d-flex
                vue-skeleton-loader(type="rect" width="100px" height="24px" :rounded="rounded")
            td.text-right.border-top.pt-5(colspan="5")
          tr
            td.py-1
              vue-skeleton-loader(type="rect" width="252px" height="12px" :rounded="rounded")

            td.py-0.text-right(colspan="5")
          tr.current-comparer
            td.pb-3(rowspan="2")
              .d-flex
                ComparerSigns
                .d-flex.flex-column
                  .skeleton-col
                    .skeleton-col-inner
                      vue-skeleton-loader(
                        type="rect"
                        width="81px"
                        height="12px"
                        :rounded="rounded"
                      )
                      vue-skeleton-loader.mt-2(
                        type="rect"
                        width="378px"
                        height="20px"
                        :rounded="rounded"
                      )
                  .skeleton-col
                    .skeleton-col-inner
                      vue-skeleton-loader(
                        type="rect"
                        width="81px"
                        height="12px"
                        :rounded="rounded"
                      )
                      vue-skeleton-loader.mt-2(
                        type="rect"
                        width="378px"
                        height="20px"
                        :rounded="rounded"
                      )
            td.w-35.align-middle
              vue-skeleton-loader.mt-2(type="rect" width="320px" height="20px" :rounded="rounded")
          tr.winner-current-false
            td.w-35.align-middle
              vue-skeleton-loader.mt-2(type="rect" width="320px" height="20px" :rounded="rounded")
</template>

<script>
  import ComparerSigns from '@/components/SmartABTest/ComparerSigns.vue';
  export default {
    components: { ComparerSigns },
    props: {
      rows: { type: Number },
      rounded: { type: Boolean },
      bottomLine: { type: Boolean },
    },
  };
</script>

<style lang="sass" scoped>
  .skeleton-row
    font-size: 0.85rem
    width: 100%
    &.tbody th,
    &.tbody td
      padding: 0.5rem
      border-top: 1px solid white
    .skeleton-col-inner
      height: 3rem
      margin-left: 0.75rem
      padding: 0.35rem 0
</style>
