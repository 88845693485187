<template lang="pug">
svg(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :fill="color")
  path(
    d="M5.83333 13.3337C6.16485 13.3337 6.4828 13.202 6.71722 12.9675C6.95164 12.7331 7.08333 12.4152 7.08333 12.0837C7.0874 12.0421 7.0874 12.0002 7.08333 11.9587L9.40833 9.63366H9.6H9.79167L11.1333 10.9753C11.1333 10.9753 11.1333 11.017 11.1333 11.042C11.1333 11.3735 11.265 11.6915 11.4994 11.9259C11.7339 12.1603 12.0518 12.292 12.3833 12.292C12.7149 12.292 13.0328 12.1603 13.2672 11.9259C13.5016 11.6915 13.6333 11.3735 13.6333 11.042V10.9753L16.6667 7.91699C16.9139 7.91699 17.1556 7.84368 17.3611 7.70633C17.5667 7.56898 17.7269 7.37375 17.8215 7.14535C17.9161 6.91694 17.9409 6.66561 17.8926 6.42313C17.8444 6.18065 17.7254 5.95792 17.5505 5.78311C17.3757 5.60829 17.153 5.48924 16.9105 5.44101C16.6681 5.39278 16.4167 5.41753 16.1883 5.51214C15.9599 5.60675 15.7647 5.76697 15.6273 5.97253C15.49 6.17809 15.4167 6.41977 15.4167 6.66699C15.4126 6.70856 15.4126 6.75042 15.4167 6.79199L12.4083 9.80033H12.275L10.8333 8.33366C10.8333 8.00214 10.7016 7.6842 10.4672 7.44978C10.2328 7.21535 9.91485 7.08366 9.58333 7.08366C9.25181 7.08366 8.93387 7.21535 8.69945 7.44978C8.46503 7.6842 8.33333 8.00214 8.33333 8.33366L5.83333 10.8337C5.50181 10.8337 5.18387 10.9654 4.94945 11.1998C4.71503 11.4342 4.58333 11.7521 4.58333 12.0837C4.58333 12.4152 4.71503 12.7331 4.94945 12.9675C5.18387 13.202 5.50181 13.3337 5.83333 13.3337ZM17.0833 16.667H2.91667V2.50033C2.91667 2.27931 2.82887 2.06735 2.67259 1.91107C2.51631 1.75479 2.30435 1.66699 2.08333 1.66699C1.86232 1.66699 1.65036 1.75479 1.49408 1.91107C1.3378 2.06735 1.25 2.27931 1.25 2.50033V17.5003C1.25 17.7213 1.3378 17.9333 1.49408 18.0896C1.65036 18.2459 1.86232 18.3337 2.08333 18.3337H17.0833C17.3043 18.3337 17.5163 18.2459 17.6726 18.0896C17.8289 17.9333 17.9167 17.7213 17.9167 17.5003C17.9167 17.2793 17.8289 17.0674 17.6726 16.9111C17.5163 16.7548 17.3043 16.667 17.0833 16.667Z"
    :fill="color"
  )
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: '#505763',
      },
    },
  };
</script>
