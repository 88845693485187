<template lang="pug">
div
  template(v-for="(expression, index) in expressions")
    .rule-box(:key="`visitor-cart-v3-${index}`")
      .d-flex.align-center
        om-select.mr-2.medium(
          :id="`cart-type-${index}`"
          size="medium"
          :value="getExpFor(expression)"
          @input="setExprFor(index, $event)"
          :options="ruleOptions"
        )
        template(v-if="expression.for !== 'productsInCart'")
          om-select.mr-2.medium(
            :id="`cart-operator-${index}`"
            size="medium"
            :options="visitorCartOptions(onlyNumberOperators, 'attributeOperators')"
            :value="getExpAttrOperator(expression)"
            @input="setExprAttrOperator(index, $event)"
          )
          template(v-if="expression.attributeOperator === 'interval'")
            .d-flex.align-items-center
              om-input.number-input(
                :id="`cart-interval-value-${index}-0`"
                type="number"
                medium
                :value="getIntervalValue(expression.value, 0)"
                @input="setIntervalValue(index, 0, $event)"
                :placeholder="$t('visitorCartV3.placeholders.value50')"
                :error="isInvalidExprValue(index, 'value')"
                :show-invalid-suffix="false"
              )
              span.mx-2.px-1.font-size-0--875.font-weight-500 {{ $t('and') }}
              om-input.number-input(
                :id="`cart-interval-value-${index}-1`"
                type="number"
                :value="getIntervalValue(expression.value, 1)"
                @input="setIntervalValue(index, 1, $event)"
                :placeholder="$t('visitorCartV3.placeholders.value50')"
                :error="isInvalidExprValue(index, 'value')"
                :show-invalid-suffix="false"
              )
          template(v-else)
            om-input.number-input(
              :id="`cart-value-${index}`"
              :placeholder="$t('visitorCartV3.placeholders.value50')"
              type="number"
              :value="getExpValue(expression)"
              @input="setExprValue(index, $event)"
              :error="isInvalidExprValue(index, 'value')"
              :show-invalid-suffix="false"
            )
        template(v-else)
          om-select.mr-2.small(
            :id="`cart-operator-${index}`"
            size="medium"
            :options="visitorCartOptions(includeOperators, 'operators')"
            :value="getExpOperator(expression)"
            @input="setExprOperator(index, $event)"
          )
          template(v-if="isSupportedPlatform")
            om-select.mr-2.large(
              :id="`cart-product-operator-${index}`"
              size="medium"
              :options="visitorCartOptions(productOperators, 'productOperators')"
              :value="getExpProdOperator(expression)"
              @input="setExprProdOperator(index, $event)"
              :error="isInvalidExprValue(index, 'value')"
            )
          template(v-else)
            .my-auto
              span.font-size-0--875.font-weight-500 {{ $t('visitorCartV3.any') }}
        .d-flex.align-items-center.ml-auto
          span.brand-link.settings-label.ml-2.font-size-0--875.absolute.trash(
            v-if="expressions.length > 1"
            @click="removeExpression('expressions', index)"
          )
            uil-trash-alt(size="1.5rem")
      template(v-if="expression.for === 'productsInCart'")
        template(v-if="isSupportedPlatform")
          template(v-if="expression.attributeName === 'id'")
            .d-flex.flex-wrap.product-box-wrapper
              om-chips.mt-2.mr-2(
                wrap
                :items="getCartItems(expression)"
                @remove="removeCartItem(index, $event)"
                removable
                contentKey="title"
                :style="'margin-top: -5px'"
              )
              .d-flex.align-items-center.justify-content-start.mt-2(
                @click="addItem('expressions', index)"
              )
                span.d-flex.brand-link
                  uil-plus-circle(size="1.75rem")
                span.brand-link.ml-2.font-size-0--875 {{ $t('selectItems') }}

          template(v-else)
            .mt-4.d-flex.align-items.flex-wrap
              .my-auto
                span.font-size-0--875.font-weight-500 {{ $t('visitorCartV3.whereV2', { operator: $t(`visitorCartV3.attributeOperators.${expression.attributeOperator}`) }) }}

              template(
                v-for="(_, productIndex) in expression.value.length ? expression.value : ['']"
              )
                om-input.special-input.ml-2(
                  :class="productIndex > 2 ? 'mt-2' : ''"
                  :id="`cart-value-${index}`"
                  type="text"
                  v-model.lazy="currentRule.options.expressions[index].value[productIndex]"
                  :placeholder="getInputPlaceholder(expression.type)"
                  :error="isInvalidExprValue(index, 'value')"
                  :show-invalid-suffix="false"
                )
                  template(slot="suffix" v-if="expression.value.length > 1")
                    .input-close.d-flex.align-center.justify-content-center.cursor-pointer(
                      @click="removeValueFromExpression(index, productIndex)"
                    )
                      uil-times.m-auto(size="1.5rem")
              .d-flex.align-items-center(@click="addItem('expressions', index, '')")
                span.d-flex.brand-link.my-auto.ml-2
                  uil-plus-circle(size="1.5rem")
                span.d-flex.brand-link.ml-1.font-size-0--875 {{ $t('or').toUpperCase() }}
        template(v-else)
          .d-flex.product-box-wrapper.mt-2.mr-2
            .d-flex.items-center.mr-2(style="height: 40px")
              span.my-auto.font-size-0--875.font-weight-500 {{ $t('visitorCartV3.where') }}
            om-input.mr-2(
              :id="`cart-attribute-name-${index}`"
              :placeholder="$t('visitorCartV3.placeholders.property')"
              :value="getExpAttrName(expression)"
              @input="setExprAttrName(index, $event)"
              type="text"
              :show-invalid-suffix="false"
            )
            .d-flex.items-center.mr-2(style="height: 40px")
              span.my-auto.font-size-0--875.font-weight-500 {{ $t('visitorCartV3.which') }}
            om-select.medium.mr-2(
              :id="`attributeType-${index}`"
              :options="attributeTypes"
              style="width: 6rem"
              :value="getExpType(expression)"
              @input="setExprType(index, $event)"
            )
            om-select.mr-2.medium(
              :id="`cart-operator-${index}`"
              size="medium"
              :options="visitorCartOptions(getOperatorOptions(expression), 'attributeOperators')"
              :value="getExpAttrOperator(expression)"
              @input="setExprAttrOperator(index, $event)"
            )
            template(v-if="expression.attributeOperator === 'interval'")
              .d-flex.align-items-center
                om-input.number-input(
                  :id="`cart-interval-value-${index}-0`"
                  :type="getInputType(expression.type)"
                  medium
                  :value="getIntervalValue(expression.value, 0)"
                  @input="setIntervalValue(index, 0, $event)"
                  :placeholder="getInputPlaceholder(expression.type)"
                  :error="isInvalidExprValue(index, 'value')"
                  :show-invalid-suffix="false"
                )
                span.mx-2.px-1.font-size-0--875.font-weight-500 {{ $t('and') }}
                om-input.number-input(
                  :id="`cart-interval-value-${index}-1`"
                  :type="getInputType(expression.type)"
                  :value="getIntervalValue(expression.value, 1)"
                  @input="setIntervalValue(index, 1, $event)"
                  :placeholder="getInputPlaceholder(expression.type)"
                  :error="isInvalidExprValue(index, 'value')"
                  :show-invalid-suffix="false"
                )
            template(v-else-if="isSingleInput(index)")
              om-input(
                :id="`cart-value-${index}`"
                :type="getInputType(expression.type)"
                v-model.lazy="currentRule.options.expressions[index].value"
                :placeholder="getInputPlaceholder(expression.type)"
                :error="isInvalidExprValue(index, 'value')"
                :show-invalid-suffix="false"
              )
            template(v-else-if="!isExistOperator(index)")
              .d-flex.flex-column
                template(
                  v-for="(_, productIndex) in expression.value.length ? expression.value : ['']"
                )
                  om-input.mb-2.special-input(
                    :id="`cart-value-${index}`"
                    :type="getInputType(expression.type)"
                    :placeholder="getInputPlaceholder(expression.type)"
                    v-model.lazy="currentRule.options.expressions[index].value[productIndex]"
                    :error="isInvalidExprValue(index, 'value')"
                    :show-invalid-suffix="false"
                  )
                    template(slot="suffix" v-if="expression.value.length > 1")
                      .input-close.d-flex.align-center.justify-content-center.cursor-pointer(
                        @click="removeValueFromExpression(index, productIndex)"
                      )
                        uil-times.m-auto(size="1.5rem")
              .d-flex.align-items-end(@click="addItem('expressions', index, '')")
                .mb-4.d-flex
                  span.d-flex.brand-link.ml-2
                    uil-plus-circle(size="1.5rem")
                  span.d-flex.brand-link.my-auto.ml-1.font-size-0--875 {{ $t('or').toUpperCase() }}
    .and-condition(v-if="expressions.length > index + 1")
      om-body-text.font-weight-bold.font-size-0--875.grey-700.pt-3.pb-3(bt500md) {{ $t('and') }}
  .d-flex.align-items-center.justify-content-start.mt-4
    span.d-flex.brand-link(
      @click="addExpression('expressions', JSON.parse(JSON.stringify(basicExp)))"
    )
      uil-plus-circle(size="1.75rem")
    span.brand-link.ml-2.font-size-0--875(
      @click="addExpression('expressions', JSON.parse(JSON.stringify(basicExp)))"
    ) {{ $t('visitorCartV3.addRule') }}
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import attributeTypes from '@/mixins/attributeTypes';
  import { required } from 'vuelidate/lib/validators';
  import { UilTrashAlt, UilTimes, UilPlusCircle } from '@iconscout/vue-unicons';
  import { RULE_CART_RULES_V3 } from '@/config/frontendRules/rules';
  import { arrayify, isNumber } from '@om/common';
  import OmBodyText from '@/components/Elements/BodyText/BodyText';
  import {
    stringOperators,
    numberOperators,
    onlyNumberOperators,
    existsOperators,
    includeOperators,
    productOperators,
    numberBaseOperators,
  } from './statics';

  export default {
    components: { OmBodyText, UilTrashAlt, UilTimes, UilPlusCircle },
    mixins: [settingsValidation, expressionOperations, attributeTypes],
    props: {
      isShopify: {
        type: Boolean,
      },
      isShoprenter: {
        type: Boolean,
      },
      isEditMode: {
        type: Boolean,
        default: false,
      },
      locale: {
        type: String,
      },
    },

    data() {
      const ruleOptions = [
        { key: 'cartValue', value: this.$t(`visitorCartV3.cartValue`) },
        { key: 'totalNumberOfProducts', value: this.$t(`visitorCartV3.totalNumberOfProducts`) },
        { key: 'productsInCart', value: this.$t(`visitorCartV3.productsInCart`) },
      ];

      return {
        stringOperators,
        numberOperators,
        onlyNumberOperators,
        numberBaseOperators,
        includeOperators,
        productOperators,
        existsOperators,
        ruleOptions,
        basicExp: {
          for: 'cartValue',
          attributeOperator: 'greaterThan',
          value: 0,
          type: 'number',
        },
      };
    },
    computed: {
      isSupportedPlatform() {
        return this.isShopify || this.isShoprenter;
      },
      expressions() {
        return this.currentRule.options.expressions;
      },
    },
    methods: {
      removeCartItem(index, item) {
        const expression = this.currentRule.options.expressions[index];
        const productIndex = expression.meta.products.findIndex(
          (product) => product.id === item.id,
        );
        if (productIndex !== -1) {
          const valueIndex = expression.value.findIndex(
            (id) => id === expression.meta.products[productIndex].id,
          );
          expression.meta.products.splice(productIndex, 1);
          expression.value.splice(valueIndex, 1);
        }
      },
      getCartItems(expression) {
        return expression.value.map((value) => {
          const product = expression.meta.products.find((product) => product.id === value);

          return product;
        });
      },
      isSingleInput(index) {
        const expression = this.currentRule.options.expressions[index];
        return (
          this.numberBaseOperators
            .filter((op) => op !== 'equals')
            .includes(expression.attributeOperator) && expression.type === 'number'
        );
      },
      isExistOperator(index) {
        return this.existsOperators.includes(
          this.currentRule.options.expressions[index].attributeOperator,
        );
      },
      addValueToExpression(index, value = '') {
        const newValue = [...arrayify(this.currentRule.options.expressions[index].value), value];
        if (newValue.length === 1) {
          newValue.push(value);
        }
        this.replaceExpression('expressions', index, {
          ...JSON.parse(JSON.stringify(this.currentRule.options.expressions[index])),
          value: newValue,
        });
      },
      removeValueFromExpression(index, valueIndex) {
        const valueCopy = JSON.parse(
          JSON.stringify(this.currentRule.options.expressions[index].value),
        );
        valueCopy.splice(valueIndex, 1);
        this.replaceExpression('expressions', index, {
          ...JSON.parse(JSON.stringify(this.currentRule.options.expressions[index])),
          value: valueCopy,
        });
      },
      addItem(fieldName, groupIndex, value) {
        if (this.isSupportedPlatform && value !== '') {
          this.$bus.$emit('openCatalog', {
            currentRules: this.currentRule,
            groupIndex,
            ruleType: RULE_CART_RULES_V3,
            isEditMode: this.isEditMode,
          });
        } else {
          this.addValueToExpression(groupIndex, value);
        }
      },
      getInputType(type) {
        if (type === 'string') {
          return 'text';
        }
        return type;
      },
      getInputPlaceholder(type) {
        if (type === 'string') {
          return this.$t('visitorCartV3.placeholders.value');
        }
        return this.$t('visitorCartV3.placeholders.value50');
      },
      getExpFor(expression) {
        return { key: expression.for, value: this.$t(`visitorCartV3.${expression.for}`) };
      },
      setExprFor(index, value) {
        if (value.key === 'productsInCart') {
          this.replaceExpression('expressions', index, {
            for: 'productsInCart',
            attributeName: this.isSupportedPlatform ? 'id' : 'name',
            attributeOperator: 'equals',
            operator: 'include',
            value: [],
            type: 'string',
          });
        } else {
          this.replaceExpression('expressions', index, {
            ...JSON.parse(JSON.stringify(this.basicExp)),
            for: value?.key,
          });
        }
      },
      getExpOperator(expression) {
        return {
          key: expression.operator,
          value: this.$t(`visitorCartV3.operators.${expression.operator}`),
        };
      },
      setExprOperator(index, value) {
        this.replaceExpression('expressions', index, {
          ...JSON.parse(JSON.stringify(this.currentRule.options.expressions[index])),
          operator: value?.key,
        });
      },
      getExpProdOperator(expression) {
        return {
          key: expression.attributeName,
          value: this.$t(`visitorCartV3.productOperators.${expression.attributeName}`),
        };
      },
      setExprProdOperator(index, value) {
        this.replaceExpression('expressions', index, {
          ...JSON.parse(JSON.stringify(this.currentRule.options.expressions[index])),
          attributeName: value?.key,
          attributeOperator: value?.key === 'id' ? 'equals' : 'contains',
          value: [],
          meta: {
            products: [],
          },
        });
      },
      getExpAttrOperator(expression) {
        return {
          key: expression.attributeOperator,
          value: this.$t(`visitorCartV3.attributeOperators.${expression.attributeOperator}`),
        };
      },
      setExprType(index, value) {
        this.replaceExpression('expressions', index, {
          ...JSON.parse(JSON.stringify(this.currentRule.options.expressions[index])),
          type: value?.key,
        });
        this.replaceExpression('expressions', index, {
          ...JSON.parse(JSON.stringify(this.currentRule.options.expressions[index])),
          attributeOperator: this.getOperatorOptions(
            this.currentRule.options.expressions[index],
          )[0],
          value: this.isArrayValue(index) ? [] : '',
        });
      },
      getExpType(expression) {
        return {
          key: expression.type,
          value: this.$t(`${expression.type}`),
        };
      },
      isArrayValue(index) {
        const expression = this.currentRule.options.expressions[index];

        return (
          expression.attributeOperator !== 'interval' &&
          !this.isSingleInput(index) &&
          !this.isExistOperator(index)
        );
      },
      setExprAttrOperator(index, value) {
        this.replaceExpression('expressions', index, {
          ...JSON.parse(JSON.stringify(this.currentRule.options.expressions[index])),
          attributeOperator: value?.key,
        });
        this.replaceExpression('expressions', index, {
          ...JSON.parse(JSON.stringify(this.currentRule.options.expressions[index])),
          value: this.isArrayValue(index) ? [] : '',
        });
      },
      setExprValue(index, value) {
        const isNumber = this.currentRule.options.expressions[index].type === 'number';
        if (isNumber) {
          value = +value;
        }

        this.currentRule.options.expressions[index].value = isNumber ? value || 0 : value || '';
      },
      getExpValue(expression) {
        return expression.value;
      },
      setExprAttrName(index, value) {
        this.replaceExpression('expressions', index, {
          ...JSON.parse(JSON.stringify(this.currentRule.options.expressions[index])),
          attributeName: value || '',
        });
      },
      getExpAttrName(expression) {
        return expression.attributeName;
      },
      getIntervalValue(value, index) {
        const values = `${value}`.split('-');
        if (values.length === 2) return values[index];
        return index === 0 ? values[0] : '';
      },
      setIntervalValue(exprIndex, index, value) {
        const currentValue = this.currentRule.options.expressions[exprIndex].value || '-';
        const parts = currentValue.split('-');
        parts[index] = value;
        this.currentRule.options.expressions[exprIndex].value = `${+parts[0]}-${+parts[1]}`;
      },
      isInvalidExprValue(index, prop) {
        return this.$v.currentRule.options.expressions.$each[index][prop].$error;
      },
      getOperatorOptions(expression) {
        if (expression.type === 'string') {
          return stringOperators;
        }

        return onlyNumberOperators;
      },
      visitorCartOptions(optionKeys, type) {
        return optionKeys.map((element) => ({
          key: element,
          value: this.$t(`visitorCartV3.${type}.${element}`),
        }));
      },
      needsInput(operator) {
        return !existsOperators.includes(operator);
      },
    },
    validations: {
      currentRule: {
        options: {
          expressions: {
            $each: {
              attributeOperator: { required },
              value: {
                isReq(val, expression) {
                  if (Array.isArray(val)) {
                    return !!val.length;
                  }
                  return !this.needsInput(expression.attributeOperator) || val !== '';
                },
                isCool(val, expression) {
                  if (!this.needsInput(expression.attributeOperator)) return true;
                  if (expression.attributeOperator === 'interval') {
                    const parts = `${val}`.replace(';', '-').split('-');

                    if (parts[0] === '' || parts[1] === '') return false;

                    return (
                      /^[0-9]*$/.test(parts[0]) &&
                      isNumber(+parts[0]) &&
                      /^[0-9]*$/.test(parts[1]) &&
                      isNumber(+parts[1])
                    );
                  }
                  if (Array.isArray(val) && val.length === 0) return false;
                  if (Array.isArray(val) && val.some((val) => val === '')) return false;

                  return expression.type === 'number'
                    ? val !== '' && arrayify(val).every((v) => /^[0-9]*$/.test(v) && isNumber(+val))
                    : val !== '';
                },
              },
            },
          },
        },
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../../sass/variables/variables'

  .rule-box
    position: relative
    background-color: $om-gray-100
    border: 1px solid $om-gray-300
    border-radius: 4px
    min-height: 72px
    padding: 16px
    .number-input
      ::v-deep
        .input-element
          text-align: left !important
    .product-box-wrapper
      max-width: 97%
      ::v-deep
        .chip
          padding: 8px
    .small
      width: 100%
      max-width: 160px
    .medium
      width: 100%
      max-width: 200px
    .large
      width: 100%
      max-width: 290px
  .brand-link.trash
    color: $om-gray-700
  .and-condition
    color: $om-gray-700
    text-transform: uppercase
  .special-input
    ::v-deep
      .input-suffix-slot
        border-left: $om-gray-300 solid 1px
        padding-right: 0
        padding-top: 0
        line-height: 2em
        height: calc(40px - 2px)
        width: 40px
  .input-close
    background-color: $om-gray-200
    height: 100%
    margin: auto
</style>
