<template lang="pug">
om-modal(
  name="product-catalog"
  :width="1040"
  color="light"
  :title="$t(`${title}`)"
  @beforeOpen="beforeOpen"
  @beforeClose="beforeClose"
  @close="cancel"
  :scrollable="true"
)
  template(slot="modal-header")
    .text-center
      .font-weight-bold.font-size-1--5 {{ $t(`${title}`) }}
    .brand-modal-action-icon.cursor-pointer(@click="cancel")
      close-icon(:width="14" :height="14" color="#C2C2C2")
  template(slot="modal-body")
    .row.mx-0.ecommerce-product-row
      .col-6.p-0.w-auto
        .ecommerce-product-input-wrap(:class="{ focused: focused }")
          input.ecommerce-product-input(
            @focus="focused = true"
            @blur="focused = false"
            v-model="search"
            @keyup.enter="searchOnEnter"
            :placeholder="type === 'shoprenter' ? $t('productTexts.label.searchBySku') : $t('productTexts.label.search')"
          )
          .ecommerce-product-input-button-wrap
            button.ecommerce-product-btn.font-size-0--75(
              v-if="isSearchResult"
              @click="resetSearch"
            )
              i.fa.fa-remove.remove
            button.ecommerce-product-btn(:disabled="!search" @click="productSearch")
              i.fa.fa-search
      .d-flex.col-6.align-items-center.justify-content-end
        fa-icon(variant="fa-exclamation-circle" size="1.5")
        .only-in-stock-are-shown-label.ml-2.font-weight-bold {{ $t('productTexts.label.onlyInStockAreShown') }}

    .ecommerce-product-container.justify-content-center.ecommerce-product-box(
      v-if="canShowProducts()"
      @scroll="handleScroll"
    )
      .row.row-cols-xl-5
        product-catalog-item(
          v-for="(product, i) in products"
          :product="product"
          :moneyFormat="moneyFormat"
          :key="`${type}-product-${i}`"
          @select="onSelect"
          :selected="isSelected(product)"
          @chooseProduct="handleProductSelect"
          @removeProduct="handleProductRemove"
          :isInEditor="!localCampaign"
          :productType="type"
          :isVariant="false"
        )

    .ecommerce-product-container.justify-content-center.ecommerce-product-box.position-relative(
      v-if="variants.length"
      @scroll="handleScroll"
    )
      .row.row-cols-xl-5
        product-catalog-item(
          v-for="(variant, i) in variants"
          :product="variant"
          :moneyFormat="moneyFormat"
          :key="`${type}-variant-${i}`"
          :selected="isSelectedVariant(variant)"
          @select="onSelect"
          @removeProduct="handleProductRemove"
          :isInEditor="!localCampaign"
          :productType="type"
          :isVariant="true"
        )

    .row.mt-3.mb-5.ecommerce-product-container.font-size-1.mx-0(
      v-if="isSearchResult && !variants.length && !products.length"
    )
      .col-12.ecommerce-product-variant-title.mb-2.text-center.px-5 {{ $t('productTexts.label.noResult') }}

    template(v-if="loading")
      .row.mt-3.mb-5.justify-content-center.mx-0.loading-wrapper
        .col-12.d-flex.justify-content-center.align-items-center
          i.fa.fa-spinner.fa-spin
  template(slot="modal-footer")
    .d-flex.justify-content-center(v-if="variants.length")
      om-button(ghost @click="back") {{ $t('back') }}
    .d-flex.justify-content-center(v-else)
      om-button(ghost :class="localCampaign ? 'mr-2' : ''" @click="cancel") {{ $t('cancel') }}
      om-button(primary v-if="localCampaign" @click="save") {{ $t('save') }}
</template>

<script>
  import { mapState } from 'vuex';
  import GET_SHOPIFY_PRODUCTS from '@/graphql/GetShopifyProducts.gql';
  import GET_SHOPIFY_VARIANTS from '@/graphql/GetShopifyVariants.gql';
  import GET_SHOPRENTER_PRODUCTS from '@/graphql/GetShoprenterProducts.gql';
  import ProductCatalogItem from './productCatalog/ProductCatalogItem.vue';

  const _c = (v) => JSON.parse(JSON.stringify(v));
  export default {
    components: { ProductCatalogItem },
    data: () => ({
      type: 'shopify',
      products: [],
      variants: [],
      loading: false,
      search: null,
      isSearchResult: false,
      page: 0,
      hasNextPage: true,
      cursor: null,
      moneyFormat: '',
      localCampaign: null,
      selectedProducts: [],
      loadingVariants: false,
      lastRemovedIndex: null,
      focused: false,
    }),
    computed: {
      ...mapState(['selectedElement', 'campaign']),
      getCampaign() {
        return (this.campaign && this.campaign.domain) || this.localCampaign.domain;
      },
      title() {
        return this.localCampaign ? 'productCatalog.cartRuleTitle' : 'productCatalog.title';
      },
    },
    methods: {
      canShowProducts() {
        if (this.loadingVariants) {
          return false;
        }
        return !this.variants.length || this.type === 'shoprenter';
      },
      isSelected(product) {
        if (this.type === 'shopify') {
          return (this.selectedProducts || []).some(
            (selectedProduct) =>
              selectedProduct &&
              (selectedProduct.id === product.id || selectedProduct === product.id),
          );
        }
        if (this.type === 'shoprenter') {
          return (this.selectedProducts || []).some(
            (selectedProduct) =>
              selectedProduct.variant === product.variant || selectedProduct === product.variant,
          );
        }
      },
      isSelectedVariant(variant) {
        if (this.selectedElement.data.products.length > 0) {
          return this.selectedElement.data.products.some((product) => {
            if (product) {
              return product.selectedVariant === variant.variants[0].id;
            }
            return false;
          });
        }
      },
      cancel() {
        this.search = null;
        this.isSearchResult = false;
        if (this.localCampaign) {
          this.$bus.$emit('cancelCatalog');
        }
        this.$modal.hide('product-catalog');
      },
      save() {
        this.$bus.$emit('saveProductSelection', this.selectedProducts);
        this.$modal.hide('product-catalog');
        this.search = null;
        this.isSearchResult = false;
      },
      back() {
        this.variants = [];
      },
      async beforeOpen({ params: { type, campaign, setProducts = [] } }) {
        this.type = type;
        this.page = 0;
        this.cursor = null;
        this.hasNextPage = true;
        this.localCampaign = campaign;
        this.selectedProducts = [...setProducts];

        if (this.type === 'shopify') {
          const { products, moneyFormat, cursor } = await this.getShopifyProducts();
          this.setProducts(products, setProducts);
          this.moneyFormat = moneyFormat;
          this.cursor = cursor;
        } else if (this.type === 'shoprenter') {
          const { products, moneyFormat } = await this.getShoprenterProducts();
          this.products = products;
          this.moneyFormat = moneyFormat;
        }
      },
      setProducts(products, setProducts) {
        if (this.localCampaign) {
          this.products = products;
        } else {
          this.products = products;
          if (setProducts && setProducts.length) {
            setProducts.forEach((product) => {
              if (product) {
                const selectedVariantId = product.selectedVariant;
                const selectedProductIndex = products.findIndex((product) =>
                  product.variants.some((variant) => variant.id === selectedVariantId),
                );
                const selectedProduct = products[selectedProductIndex];
                if (selectedProduct) {
                  selectedProduct.selectedVariantId = selectedVariantId;
                }
              }
            });
          }
        }
      },
      handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
          this.nextPage();
        }
      },
      getQueryInput() {
        const input = {
          criteria: {
            domain: this.getCampaign,
          },
          pagination: {
            page: this.page,
            cursor: this.cursor,
          },
        };
        if (this.search) {
          input.criteria.query = this.search;
        }
        return input;
      },
      async getShopifyProducts() {
        this.loading = true;
        const { data } = await this.$apollo.query({
          query: GET_SHOPIFY_PRODUCTS,
          variables: {
            input: this.getQueryInput(),
          },
        });
        this.loading = false;
        return data && data.response
          ? data.response
          : {
              products: [],
              moneyFormat: '',
            };
      },
      async getShopifyVariants(productId) {
        this.loading = true;
        this.loadingVariants = true;
        const { data } = await this.$apollo.query({
          query: GET_SHOPIFY_VARIANTS,
          variables: {
            input: {
              criteria: {
                domain: this.getCampaign,
                productId,
              },
            },
          },
        });
        this.loading = false;
        this.loadingVariants = false;
        return data && data.response
          ? data.response
          : {
              variants: [],
            };
      },
      async getShoprenterProducts() {
        this.loading = true;
        const { data } = await this.$apollo.query({
          query: GET_SHOPRENTER_PRODUCTS,
          variables: {
            input: this.getQueryInput(),
          },
        });
        this.loading = false;
        return data && data.response
          ? data.response
          : {
              products: [],
              moneyFormat: '',
            };
      },
      async productSearch() {
        this.page = 0;
        this.cursor = null;
        this.hasNextPage = true;
        if (this.type === 'shopify') {
          const { products, moneyFormat, hasNextPage } = await this.getShopifyProducts();
          this.products = products;
          this.moneyFormat = moneyFormat;
          this.hasNextPage = hasNextPage;
        } else if (this.type === 'shoprenter') {
          const { products, moneyFormat, hasNextPage } = await this.getShoprenterProducts();
          this.products = products;
          this.moneyFormat = moneyFormat;
          this.hasNextPage = hasNextPage;
        }
        this.variants = [];
        this.isSearchResult = true;
      },
      async resetSearch() {
        this.search = null;
        this.page = 0;
        this.cursor = null;
        this.hasNextPage = true;
        if (this.type === 'shopify') {
          const { products, moneyFormat, hasNextPage, cursor } = await this.getShopifyProducts();
          this.products = products;
          this.moneyFormat = moneyFormat;
          this.hasNextPage = hasNextPage;
          this.cursor = cursor;
        } else if (this.type === 'shoprenter') {
          const { products, moneyFormat, hasNextPage } = await this.getShoprenterProducts();
          this.products = products;
          this.moneyFormat = moneyFormat;
          this.hasNextPage = hasNextPage;
        }
        this.isSearchResult = false;
      },
      async nextPage() {
        if (!this.hasNextPage) return;
        this.page++;
        if (this.type === 'shoprenter') {
          const { products, hasNextPage } = await this.getShoprenterProducts();
          this.hasNextPage = hasNextPage;
          this.products = [...this.products, ...products];
        } else if (this.type === 'shopify') {
          const { products, cursor, hasNextPage } = await this.getShopifyProducts();
          this.hasNextPage = hasNextPage;
          this.products = [...this.products, ...products];
          this.cursor = cursor;
        }
      },
      handleProductSelect({ product }) {
        if (this.localCampaign) {
          this.onSelect({ product });
        } else {
          this.setVariants(product);
        }
      },
      handleProductRemove(id) {
        const productIndex = this.selectedProducts.findIndex(
          (product) => product && product.id === id,
        );
        this.lastRemovedIndex = productIndex;
        this.selectedProducts.splice(productIndex, 1);

        if (!this.localCampaign) {
          this.$store.commit('setProduct', { product: null });
        }
      },
      async setVariants(product) {
        const variantsResponse = await this.getShopifyVariants(product.id);
        product.variants = variantsResponse.variants;
        this.variants = variantsResponse.variants.map((variant) => {
          const prodCopy = _c(product);
          prodCopy.variants = [_c(variant)];
          return prodCopy;
        });
      },
      onReset() {
        this.variants = [];
      },
      onSelect({ product: selected }) {
        let product;
        let rawProduct;
        this.variants = [];
        switch (this.type) {
          case 'shopify':
            // eslint-disable-next-line
            const selectedId = selected.variants[0].id;
            rawProduct = this.products.find((product) => {
              return !!product.variants.find((variant) => variant.id === selectedId);
            });
            product = _c(rawProduct);
            product.selectedVariant = selectedId;
            break;
          case 'shoprenter':
            rawProduct = this.products.find((product) => product.variant === selected.variant);
            product = _c(rawProduct);
            // make fields of products consistent between platforms
            product.salePrice = product.originalPrice;
            delete product.originalPrice;
            break;
        }

        product.type = this.type;

        if (this.localCampaign) {
          if (this.lastRemovedIndex) {
            this.selectedProducts.splice(this.lastRemovedIndex, 0, product);
          } else {
            this.selectedProducts.push(product);
          }
        } else {
          this.$store.commit('setProduct', { product });
          this.$store.commit('setMoneyFormat', this.moneyFormat);
          this.beforeClose();
          this.$modal.hide('product-catalog');
        }
      },
      beforeClose() {
        this.products = [];
        this.variants = [];
      },
      searchOnEnter() {
        this.productSearch();
      },
    },
  };
</script>

<style lang="sass">
  [data-modal="product-catalog"]
    .brand-modal-body
      position: relative
  .loading-wrapper
    position: absolute
    width: 100%
    height: 100%
    top: 0
    background: #ffffff9e
    left: 0
    right: 0
    margin: 0
    z-index: 10
</style>
