<template>
  <svg width="24" height="25" viewBox="0 0 24 25" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3650_17103)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23 14.5V14.47C22.93 14.3 22.83 14.15 22.69 14.04C22.55 13.93 22.37 13.86 22.22 13.85L19.08 13.39L17.67 10.54C17.59 10.38 17.47 10.25 17.33 10.16C17.03 9.96996 16.64 9.96996 16.34 10.16C16.19 10.25 16.07 10.39 16 10.54L14.59 13.39L11.46 13.85C11.29 13.87 11.13 13.94 10.99 14.06C10.86 14.17 10.76 14.32 10.7 14.49C10.65 14.65 10.65 14.82 10.69 14.99C10.73 15.15 10.82 15.3 10.94 15.43L13.22 17.63L12.67 20.75C12.64 20.92 12.65 21.11 12.72 21.27C12.79 21.44 12.9 21.58 13.04 21.68C13.18 21.78 13.35 21.84 13.52 21.85C13.7 21.85 13.86 21.82 14.02 21.74L16.84 20.27L19.64 21.74C19.77 21.82 19.93 21.86 20.08 21.86C20.27 21.86 20.47 21.8 20.62 21.68C20.77 21.57 20.88 21.43 20.94 21.27C21 21.11 21.02 20.93 20.99 20.75L20.44 17.62L22.7 15.43C22.83 15.32 22.93 15.17 22.99 15C23.04 14.83 23.04 14.65 22.99 14.48L23 14.5ZM18.56 17.51L18.86 19.25L17.3 18.42C17.03 18.28 16.69 18.27 16.42 18.42L14.86 19.25L15.16 17.51C15.18 17.36 15.17 17.21 15.13 17.06C15.08 16.92 15 16.78 14.9 16.68L13.64 15.42L15.4 15.17C15.55 15.15 15.69 15.09 15.82 15C15.94 14.91 16.04 14.79 16.11 14.65L16.84 13.09L17.61 14.66C17.68 14.8 17.78 14.92 17.9 15.01C18.02 15.1 18.17 15.16 18.32 15.18L20.08 15.43L18.83 16.68C18.72 16.79 18.64 16.92 18.59 17.06C18.54 17.2 18.53 17.36 18.56 17.51Z"
        :fill="color"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.55 11.04C13.84 10.4 14 9.7 14 9C14 7.67 13.47 6.4 12.54 5.46C11.6 4.52 10.33 4 9 4C7.67 4 6.4 4.53 5.46 5.46C4.52 6.39 4 7.67 4 9C4 9.71 4.15 10.4 4.45 11.04C4.74 11.68 5.17 12.25 5.7 12.71C4.3 13.34 3.11 14.37 2.28 15.66C1.45 16.96 1 18.46 1 20C1 20.27 1.11 20.52 1.29 20.71C1.48 20.9 1.73 21 2 21C2.27 21 2.52 20.89 2.71 20.71C2.9 20.52 3 20.27 3 20C3 18.41 3.63 16.88 4.76 15.76C5.89 14.63 7.41 14 9 14C10.59 14 11.77 13.18 12.3 12.72C12.83 12.26 13.26 11.69 13.55 11.05V11.04ZM9 11.95C7.34 11.95 6 10.61 6 8.95C6 7.29 7.34 5.95 9 5.95C10.66 5.95 12 7.29 12 8.95C12 10.61 10.66 11.95 9 11.95Z"
        :fill="color"
      />
    </g>
    <defs>
      <clipPath id="clip0_3650_17103">
        <rect width="22.03" height="17.88" :fill="color" transform="translate(1 4)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: '#505763',
      },
    },
  };
</script>
