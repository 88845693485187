export default {
  data() {
    return {
      chartColors: [
        '#94CDFC',
        '#FFA69E',
        '#5DFDCB',
        '#857F74',
        '#FF7E6B',
        '#F2D398',
        '#8789C0',
        '#63E2C6',
        '#FFD23F',
        '#FCFCFC',
        '#F49F0A',
        '#1B4965',
      ],
    };
  },

  methods: {
    subtractLight(color, amount) {
      const cc = parseInt(color, 16) - amount;
      let c = cc < 0 ? 0 : cc;
      c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
      return c;
    },

    darken(color, amount) {
      color = color.indexOf('#') >= 0 ? color.substring(1, color.length) : color;
      amount = parseInt((255 * amount) / 100, 10);
      return `#${this.subtractLight(color.substring(0, 2), amount)}${this.subtractLight(
        color.substring(2, 4),
        amount,
      )}${this.subtractLight(color.substring(4, 6), amount)}`;
    },

    getChartColor(index) {
      if (this.chartColors[index]) return this.chartColors[index];
      const newIndex = index % this.chartColors.length;
      return this.darken(this.chartColors[newIndex], index);
    },
  },
};
