export const RULES_CATEGORIES = {
  TIME_AND_CONTEXT: 'timeAndContext',
  VISITORS: 'visitors',
  PAGES_AND_CUSTOM: 'pagesAndCustom',
};

// Time & Context - Eltöltött idő és Kontextus

export const RULE_FREQUENCY = 'maximumPopupDisplay'; // Gyakoriság
export const RULE_SPENT_ON_PAGE = 'timeBasedActualPage'; // Aktuális oldalon töltött idő
export const RULE_SPENT_ON_SITE = 'timeBasedSession'; // Teljes weboldalon töltött idő
export const RULE_ENGAGED_WITH_CAMPAIGNS = 'campaignProgressState'; // OptiMonk kampányok összekapcsolása

export const RULES_TIME_AND_CONTEXT = [
  RULE_FREQUENCY,
  RULE_SPENT_ON_PAGE,
  RULE_SPENT_ON_SITE,
  RULE_ENGAGED_WITH_CAMPAIGNS,
];

// Visitors - Eltöltött idő és Kontextus

export const RULE_RETURNING_OR_NEW = 'pageViewerType'; // Visszatérő / új
export const RULE_SOURCE = 'source'; // Forgalmi forrás
export const RULE_COUNTRY = 'country'; // Ország
export const RULE_SUBSCRIBERS = 'subscribers'; // Subscribers / Non-subscribers, Feliratkozók / Fel nem iratkozottak
export const RULE_BLOCK_IP_ADDRESSES = 'ipBlock'; // IP címek tiltása
export const RULE_DETECT_ADBLOCKER = 'aBlock'; // AdBlock észlelése
export const RULE_KLAVIYO_LIST_AND_SEGMENTS = 'klaviyoSegment'; // Klaviyo listák és szegmensek

export const RULE_CUSTOM_VARIABLES = 'visitorAttribute'; // Egyedi változók

export const RULES_VISITORS = [
  RULE_RETURNING_OR_NEW,
  RULE_SOURCE,
  RULE_COUNTRY,
  RULE_SUBSCRIBERS,
  RULE_BLOCK_IP_ADDRESSES,
  RULE_DETECT_ADBLOCKER,
  RULE_KLAVIYO_LIST_AND_SEGMENTS,
  RULE_CUSTOM_VARIABLES,
];

export const RULE_LOGGED_IN = 'loggedIn'; // Shopify logged in user, Shopify bejelentkezett vásárló

export const RULES_SHOPIFY_VISITORS = [...RULES_VISITORS, RULE_LOGGED_IN];

// Pages & Custom Rules

export const RULE_CURRENT_PAGE_URL = 'viewedPage'; // Aktuális oldal / URL
export const RULE_CURRENT_PAGE_URL_V2 = 'viewedPageV2';
export const RULE_PREVIOUSLY_VIEWED_PAGES = 'previouslyViewedPage'; // Korábban megtekintett oldalak
export const RULE_EXCLUDE_PAGE_VISITORS = 'notViewedPageRecent'; // Oldalak kizárása
export const RULE_NUMBER_OF_VISITED_PAGES = 'minimumPageVisit'; // Megtekintett oldalak száma
export const RULE_CART_RULES = 'visitorCart'; // Kosárra vonatkozó szabályok
export const RULE_CART_RULES_V2 = 'visitorCartRevamp'; // Kosárra vonatkozó szabályok
export const RULE_CART_RULES_V3 = 'visitorCartV3'; // Kosárra vonatkozó szabályok V3
export const RULE_COOKIE_SEGMENTATION = 'cookie'; // Cookie alapú szegmentáció

export const RULES_PAGES_AND_CUSTOM = [
  RULE_CURRENT_PAGE_URL,
  RULE_CURRENT_PAGE_URL_V2,
  RULE_PREVIOUSLY_VIEWED_PAGES,
  RULE_EXCLUDE_PAGE_VISITORS,
  RULE_NUMBER_OF_VISITED_PAGES,
  RULE_CART_RULES,
  RULE_CART_RULES_V2,
  RULE_CART_RULES_V3,
  RULE_COOKIE_SEGMENTATION,
];

export const PRESET_DEFAULT = {
  [RULES_CATEGORIES.TIME_AND_CONTEXT]: RULES_TIME_AND_CONTEXT,
  [RULES_CATEGORIES.VISITORS]: RULES_VISITORS,
  [RULES_CATEGORIES.PAGES_AND_CUSTOM]: RULES_PAGES_AND_CUSTOM,
};

export const PRESET_SHOPIFY = {
  ...PRESET_DEFAULT,
  [RULES_CATEGORIES.VISITORS]: RULES_SHOPIFY_VISITORS,
};

export const RULES_WIDE_MODAL = [
  RULE_EXCLUDE_PAGE_VISITORS,
  RULE_FREQUENCY,
  RULE_CUSTOM_VARIABLES,
  RULE_ENGAGED_WITH_CAMPAIGNS,
  RULE_COOKIE_SEGMENTATION,
  RULE_SUBSCRIBERS,
  RULE_COUNTRY,
  RULE_CART_RULES,
  RULE_CART_RULES_V2,
  RULE_CART_RULES_V3,
  RULE_CURRENT_PAGE_URL_V2,
];
