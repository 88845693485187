<template lang="pug">
svg(width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg")
  path(d="M10.9649 15.0027H7.06543L6.24867 17.3419H4.42987L8.23387 7.29373H9.80363L13.6146 17.3419H11.7888L10.9649 15.0027ZM7.55636 13.5952H10.474L9.01519 9.4268L7.55636 13.5952Z" :fill="color")
  path(d="M14.0466 17.3419V7.29373H17.4977C18.6367 7.29373 19.503 7.52104 20.0978 7.97686C20.6927 8.43269 20.9901 9.11113 20.9901 10.0134C20.9901 10.4727 20.8662 10.8873 20.6171 11.2548C20.3681 11.6235 20.0034 11.9085 19.5242 12.1111C20.0683 12.2583 20.4885 12.5351 20.7859 12.9426C21.0834 13.3502 21.2321 13.839 21.2321 14.409C21.2321 15.3525 20.9287 16.0769 20.3232 16.5833C19.7166 17.0886 18.8491 17.3419 17.7196 17.3419H14.0466ZM15.7969 12.8084V15.9485H17.7397C18.2885 15.9485 18.7169 15.8119 19.0261 15.541C19.3354 15.2701 19.49 14.892 19.49 14.409C19.49 13.3643 18.9553 12.8308 17.8848 12.8084H15.7969ZM15.7969 11.5245H17.5118C18.0559 11.5245 18.4809 11.4008 18.7878 11.1547C19.0946 10.9085 19.248 10.5599 19.248 10.1099C19.248 9.61289 19.1064 9.25364 18.822 9.03339C18.5387 8.81195 18.0973 8.70126 17.4977 8.70126H15.7969V11.5245Z" :fill="color")
  path(fill-rule="evenodd" clip-rule="evenodd" d="M18.1196 25.5291H7.53673L0.0534821 18.0612V7.49996L7.53673 0.0319824H18.1196L25.6028 7.49996V18.0612L23.4525 20.2075L28.6284 25.373C29.0768 25.8204 29.0768 26.5467 28.6284 26.994L27.0039 28.615C26.5555 29.0624 25.8279 29.0624 25.3795 28.615L20.2035 23.4495L18.1196 25.5291ZM17.1771 23.2582L23.3274 17.1205V8.44056L17.1771 2.30296H8.47932L2.32906 8.44056V17.1205L8.47932 23.2582H17.1771Z" :fill="color")
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
