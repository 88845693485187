export default {
  methods: {
    setOperatorForOneExpression(event, index) {
      this.currentRule.options.expression[index].operator = event.key;
    },
    createOptionArray(optionKeys, translationPrefix = '') {
      return optionKeys.map((operator) => {
        return { key: operator, value: this.$t(`${translationPrefix}${operator}`) };
      });
    },
  },
};
