<template lang="pug">
.mb-4
  toggle-button.brand-domain-integration(
    v-model="advancedMode"
    :sync="true"
    :labels="{ checked: 'Advanced', unchecked: 'Normal' }"
    :width="80"
    :height="24"
    :margin="2"
  )
  div(v-show="advancedMode")
    .flex-grow-0.mr-4.w-8
      label.settings-label.mt-2 Rule definition:
    .flex-grow-1
      #ruleExpression.min-height-7
  div(v-if="!advancedMode")
    div
      label.settings-label.mb-3 {{ $t('where') }}
    div(v-for="(expression, idx) in currentRule.options.expression.slice(1)")
      .d-flex
        .flex-grow-1
          .d-flex.align-items-center.mb-3
            select.form-control.flex-grow-0.mr-2(v-model="expression.attribute")
              option(v-for="attribute in attributes[expression.type]" :value="attribute.value") {{ attribute.key }}

            select.form-control.flex-grow-0.mr-2(v-model="expression.operator")
              option(v-for="operator in operators" :value="operator.value") {{ operator.key }}

            input.form-control.mr-2(type="text" v-model="expression.value")
        .flex-shrink-0
          label.settings-label.mt-2.mr-2 was visited
      .d-flex
        .flex-grow-0
          .d-flex.align-items-center.mb-3
            select.form-control.flex-grow-0.mr-2(v-model="expression.comparator")
              option(v-for="comparator in comparators" :value="comparator.value") {{ comparator.key }}

            input.form-control.mr-2(type="number" v-model.number="expression.count")
        .flex-shrink-0
          label.settings-label.mt-2.mr-2 times within
        .flex-grow-0
          .d-flex.align-items-center.mb-3
            input.form-control.mr-2(type="number" v-model.number="expression.interval")
        .flex-grow-0
          label.settings-label.mt-2 seconds

        .brand-link.settings-label
          .text-right(v-if="idx > 0" @click="removeExpression('expression', idx + 1)")
            fa-icon(variant="fa-trash" size="1.5")

      .font-size-0--75.font-weight-bold.mx-2.flex-grow-1.mb-3(
        v-if="idx !== currentRule.options.expression.length - 2"
      ) {{ $t('andOperation') }}

    span.brand-link.settings-label(
      @click="addExpression('expression', { type: 'pageView', attribute: 'url', operator: 'contains', value: '', interval: 86400, count: '', comparator: 'eq' })"
    )
      fa-icon(variant="fa-plus" :html="`&nbsp;${$t('addNew')}`")
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';

  export default {
    mixins: [settingsValidation, expressionOperations],

    data() {
      return {
        editor: null,
        advancedMode: false,
        attributes: {
          pageView: [
            { key: 'host', value: 'host' },
            { key: 'url', value: 'url' },
            { key: 'path', value: 'path' },
            { key: 'query', value: 'query' },
            { key: 'hash', value: 'hash' },
            { key: 'referrer', value: 'referrer' },
            { key: 'title', value: 'title' },
          ],
        },
        operators: [{ key: 'contains', value: 'contains' }],
        comparators: [
          { key: 'more than', value: 'gt' },
          { key: 'less than', value: 'lt' },
          { key: 'exactly', value: 'eq' },
        ],
      };
    },

    watch: {
      advancedMode(enabled) {
        if (this.editor && enabled) {
          this.editor.setValue(
            this.prettifyJSON(JSON.stringify(this.currentRule.options.expression)),
          );
        }
      },
    },

    mounted() {
      this.editor = this.initEditor(
        'ruleExpression',
        'json',
        this.prettifyJSON(JSON.stringify(this.currentRule.options.expression)),
      );

      this.editor.on('change', () => {
        const originalValue = this.editor.getValue();
        let value;
        try {
          value = JSON.parse(originalValue);
        } catch (_) {
          // err
        }
        this.currentRule.options.expression = value;
      });
    },

    beforeDestroy() {
      if (this.editor) {
        this.editor.destroy();
      }
    },

    methods: {
      initEditor(id, mode, defaultValue = null) {
        const editor = window.ace.edit(id);
        const session = editor.getSession();
        session.setMode(`ace/mode/${mode}`);
        session.setTabSize(2);
        if (defaultValue) {
          session.setValue(defaultValue);
        }
        editor.$blockScrolling = Infinity;
        return editor;
      },
      isValidJSON(value) {
        try {
          JSON.parse(value);
          return true;
        } catch (err) {
          return false;
        }
      },
      prettifyJSON(str) {
        return JSON.stringify(JSON.parse(str), null, 2);
      },
    },

    validations: {
      currentRule: {
        options: {
          expression: {
            isCool() {
              return true;
            },
          },
        },
      },
    },
  };
</script>

<style lang="sass">
  .min-height-7
    min-height: 10rem
</style>
