<template lang="pug">
svg(
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 580 430"
  ref="svg"
  :class="{ animating: animationActive }"
)
  g#inaktiv
    g#browser
      path(d="M8,0H572a8,8,0,0,1,8,8V30H0V8A8,8,0,0,1,8,0Z" fill="#dfe6ed")
      path(d="M0,30H580V422a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V30Z" fill="#f2f5f7")
      circle(cx="19.9" cy="15" r="5" fill="#f44e4e")
      circle(cx="35.9" cy="15" r="5" fill="#ffce00")
      circle(cx="51.8" cy="15" r="5" fill="#6fc144")
    g#page
      path(
        d="M33.3,46.1H66.5a8.5,8.5,0,0,1,8.4,8.4v.2a8.5,8.5,0,0,1-8.4,8.4H33.3a8.5,8.5,0,0,1-8.4-8.4v-.2A8.5,8.5,0,0,1,33.3,46.1Z"
        fill="#a9b2b7"
      )
      path(
        d="M401.9,50.6h152a4,4,0,0,1,4,4h0a4,4,0,0,1-4,4h-152a4,4,0,0,1-4-4h0A4,4,0,0,1,401.9,50.6Z"
        fill="#a9b2b7"
      )
      rect(y="76.7" width="580" height="160" fill="#a9b2b7")
      path(
        d="M73.6,253.5h124a8,8,0,0,1,8,8v84a8,8,0,0,1-8,8H73.6a8,8,0,0,1-8-8v-84A8,8,0,0,1,73.6,253.5Z"
        fill="#a9b2b7"
      )
      path(
        d="M228,253.5H352a8,8,0,0,1,8,8v84a8,8,0,0,1-8,8H228a8,8,0,0,1-8-8v-84A8,8,0,0,1,228,253.5Z"
        fill="#a9b2b7"
      )
      path(
        d="M382.4,253.5h124a8,8,0,0,1,8,8v84a8,8,0,0,1-8,8h-124a8,8,0,0,1-8-8v-84A8,8,0,0,1,382.4,253.5Z"
        fill="#a9b2b7"
      )
    g#dark
      path(
        d="M0,30H580V422a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V30Z"
        fill="#283035"
        opacity="0.7"
        style="isolation: isolate"
      )
    g#popup
      path#popup-bg(
        d="M97.1,96h384a8,8,0,0,1,8,8V348a8,8,0,0,1-8,8H97.1a8,8,0,0,1-8-8V104A8,8,0,0,1,97.1,96Z"
        fill="#fff"
      )
      g(opacity="0.7")
        path(
          d="M268.1,150.5H261l-3.9-15.4c-.2-.6-.4-1.7-.8-3.4a19.7,19.7,0,0,1-.6-3.5c-.1.8-.3,1.9-.6,3.5s-.6,2.7-.8,3.4l-3.9,15.4h-7l-7.5-29.3H242l3.7,16c.7,2.9,1.2,5.5,1.5,7.6a21.1,21.1,0,0,1,.5-3.5c.3-1.6.6-2.8.8-3.7l4.3-16.4h5.9l4.2,16.4c.2.7.4,1.8.7,3.4l.7,3.8q.1-1.5.6-3.9c.3-1.5.6-2.8.8-3.7l3.7-16h6.1Z"
          fill="#000222"
        )
        path(
          d="M292.9,150.5l-1.1-3.1h-.2a8.4,8.4,0,0,1-3.2,2.7,10.1,10.1,0,0,1-4.2.8,6.9,6.9,0,0,1-5.1-1.9,7.2,7.2,0,0,1-1.9-5.2,6.1,6.1,0,0,1,2.5-5.3c1.7-1.1,4.2-1.7,7.6-1.9h3.8v-1c0-2.2-1.1-3.4-3.4-3.4a15.6,15.6,0,0,0-6.3,1.7l-2.1-4.2a19.7,19.7,0,0,1,8.6-2,11.2,11.2,0,0,1,6.9,2,7.1,7.1,0,0,1,2.4,5.9v15Zm-1.8-10.4h-2.3a7,7,0,0,0-4,.9,2.9,2.9,0,0,0-1.3,2.7c0,1.7,1,2.6,3,2.6a5.2,5.2,0,0,0,3.4-1.2,4.5,4.5,0,0,0,1.2-3.3Z"
          fill="#000222"
        )
        path(
          d="M303.2,122.3c0-2,1.1-3,3.4-3s3.3,1,3.3,3a3.5,3.5,0,0,1-.8,2.2,3.8,3.8,0,0,1-2.5.8C304.3,125.3,303.2,124.3,303.2,122.3Zm6.4,28.2h-6.1V128.1h6.1Z"
          fill="#000222"
        )
        path(
          d="M325.4,146a13.4,13.4,0,0,0,3.9-.7v4.5a13.6,13.6,0,0,1-5.6,1.1,7.2,7.2,0,0,1-5.4-1.9c-1.1-1.2-1.6-3.1-1.6-5.5V132.7h-3v-2.6l3.4-2.1,1.8-4.7h3.9v4.8H329v4.6h-6.2v10.8a2.3,2.3,0,0,0,.7,1.9A3.1,3.1,0,0,0,325.4,146Z"
          fill="#000222"
        )
        path(
          d="M332.9,147.6a3.4,3.4,0,0,1,.9-2.5,3.7,3.7,0,0,1,2.7-.9,3.4,3.4,0,0,1,2.5.9,3.7,3.7,0,0,1,0,5,3.4,3.4,0,0,1-2.5.9,3.1,3.1,0,0,1-2.6-.9A3.5,3.5,0,0,1,332.9,147.6Zm6-6.8H334l-1-19.6h7Z"
          fill="#000222"
        )
      path(
        d="M135.5,169.3h309a5.5,5.5,0,0,1,5.5,5.5h0a5.5,5.5,0,0,1-5.5,5.5h-309a5.5,5.5,0,0,1-5.5-5.5h0A5.5,5.5,0,0,1,135.5,169.3Z"
        fill="#a9b2b7"
      )
      path(
        d="M135.5,187.9h309a5.5,5.5,0,0,1,5.5,5.5h0a5.5,5.5,0,0,1-5.5,5.5h-309a5.5,5.5,0,0,1-5.5-5.5h0A5.5,5.5,0,0,1,135.5,187.9Z"
        fill="#a9b2b7"
      )
      path(
        d="M136.9,235.8h139a5.5,5.5,0,0,1,5.5,5.5v19a5.5,5.5,0,0,1-5.5,5.5h-139a5.5,5.5,0,0,1-5.5-5.5v-19A5.5,5.5,0,0,1,136.9,235.8Z"
        fill="none"
        stroke="#a9b2b7"
        stroke-miterlimit="10"
        stroke-width="2"
      )
      path(
        d="M305.5,235.8h139a5.5,5.5,0,0,1,5.5,5.5v19a5.5,5.5,0,0,1-5.5,5.5h-139a5.5,5.5,0,0,1-5.5-5.5v-19A5.5,5.5,0,0,1,305.5,235.8Z"
        fill="none"
        stroke="#a9b2b7"
        stroke-miterlimit="10"
        stroke-width="2"
      )
      path(
        d="M215.5,283.5h149A5.5,5.5,0,0,1,370,289v29a5.5,5.5,0,0,1-5.5,5.5h-149A5.5,5.5,0,0,1,210,318V289A5.5,5.5,0,0,1,215.5,283.5Z"
        fill="#ed5a29"
      )
      g
        path(
          d="M238.7,308.5a4.3,4.3,0,0,1-1.7,3.8,7.5,7.5,0,0,1-4.8,1.4,12.5,12.5,0,0,1-5.1-1v-3.6a16.6,16.6,0,0,0,3.1,1.2l2.3.3a2.6,2.6,0,0,0,1.9-.5,1.8,1.8,0,0,0,.6-1.4,1.6,1.6,0,0,0-.3-.9,3,3,0,0,0-.8-.8l-2.3-1.2a12.8,12.8,0,0,1-2.5-1.5,6.5,6.5,0,0,1-1.3-1.6,5.4,5.4,0,0,1-.5-2.2,4.6,4.6,0,0,1,1.6-3.8,7.2,7.2,0,0,1,4.5-1.3,11.5,11.5,0,0,1,2.6.3,14.3,14.3,0,0,1,2.7.9l-1.3,3a9,9,0,0,0-2.3-.8l-1.9-.3a3,3,0,0,0-1.6.5,1.6,1.6,0,0,0-.6,1.3,2.9,2.9,0,0,0,.2.9,2.7,2.7,0,0,0,.8.7,9.2,9.2,0,0,0,2.4,1.3,8.1,8.1,0,0,1,3.4,2.4A5,5,0,0,1,238.7,308.5Z"
          fill="#fff"
        )
        path(
          d="M251.4,313.5l-.6-1.8h-.1a3.5,3.5,0,0,1-1.7,1.5,5.3,5.3,0,0,1-2.5.5,5,5,0,0,1-3.7-1.3,5,5,0,0,1-1.2-3.7v-8.9h3.7v8a3.3,3.3,0,0,0,.6,2.2,2.1,2.1,0,0,0,1.6.8,2.6,2.6,0,0,0,2.3-1.1,5.7,5.7,0,0,0,.7-3.4v-6.5h3.7v13.7Z"
          fill="#fff"
        )
        path(
          d="M265.7,299.6a4.5,4.5,0,0,1,3.8,1.9,8.4,8.4,0,0,1,1.4,5.1,8.2,8.2,0,0,1-1.5,5.3,4.4,4.4,0,0,1-3.8,1.8,4.3,4.3,0,0,1-3.8-1.7h-.2l-.6,1.5h-2.9v-19h3.7v4.4c0,.6,0,1.5-.1,2.7h.1A4.4,4.4,0,0,1,265.7,299.6Zm-1.2,3a2.2,2.2,0,0,0-2,.8,5.5,5.5,0,0,0-.7,2.8v.4a6,6,0,0,0,.7,3.2,2.2,2.2,0,0,0,2.1.9,2.2,2.2,0,0,0,1.8-1,6.7,6.7,0,0,0,.7-3.1,6.3,6.3,0,0,0-.7-3A2.3,2.3,0,0,0,264.5,302.6Z"
          fill="#fff"
        )
        path(
          d="M283.5,309.4a3.6,3.6,0,0,1-1.5,3.2c-1,.8-2.4,1.1-4.4,1.1a10.6,10.6,0,0,1-2.5-.2,6.6,6.6,0,0,1-2-.6v-3l2.4.8,2.3.3c1.3,0,2-.4,2-1.2a.9.9,0,0,0-.3-.7l-.9-.6-1.8-.8a10.1,10.1,0,0,1-2.3-1.2,6.9,6.9,0,0,1-1.1-1.3,5.7,5.7,0,0,1-.3-1.8,3.1,3.1,0,0,1,1.4-2.8,7,7,0,0,1,4-1,10,10,0,0,1,4.8,1.1l-1.1,2.6-1.9-.7a6.3,6.3,0,0,0-1.9-.3c-1,0-1.6.3-1.6.9a1.1,1.1,0,0,0,.5.9l2.4,1.1a10.1,10.1,0,0,1,2.3,1.2,2.8,2.8,0,0,1,1.1,1.3A4.7,4.7,0,0,1,283.5,309.4Z"
          fill="#fff"
        )
        path(
          d="M291.9,313.7c-4.2,0-6.4-2.3-6.4-7a7.5,7.5,0,0,1,1.8-5.3,6.4,6.4,0,0,1,4.9-1.8,10.4,10.4,0,0,1,4.3.9l-1.1,2.9-1.7-.6-1.5-.2c-1.9,0-2.9,1.4-2.9,4.1s1,4,2.9,4a6.4,6.4,0,0,0,2-.3l1.9-.9v3.2a6.4,6.4,0,0,1-1.9.8A9,9,0,0,1,291.9,313.7Z"
          fill="#fff"
        )
        path(
          d="M306.8,299.6h1.3l-.3,3.5h-1.1a4.1,4.1,0,0,0-2.8.9,3.5,3.5,0,0,0-1,2.5v7h-3.7V299.8H302l.6,2.3h.2a5.8,5.8,0,0,1,1.7-1.8A3.9,3.9,0,0,1,306.8,299.6Z"
          fill="#fff"
        )
        path(
          d="M310.4,296.3c0-1.2.7-1.8,2-1.8s2.1.6,2.1,1.8a2.4,2.4,0,0,1-.5,1.4,2.3,2.3,0,0,1-1.6.4C311.1,298.1,310.4,297.5,310.4,296.3Zm3.9,17.2h-3.7V299.8h3.7Z"
          fill="#fff"
        )
        path(
          d="M325.8,299.6a4.5,4.5,0,0,1,3.8,1.9,8.3,8.3,0,0,1,1.3,5.1,9,9,0,0,1-1.4,5.3,4.4,4.4,0,0,1-3.8,1.8,4.3,4.3,0,0,1-3.8-1.7h-.2l-.7,1.5h-2.8v-19h3.7v4.4c0,.6,0,1.5-.1,2.7h.1A4.4,4.4,0,0,1,325.8,299.6Zm-1.2,3a2.2,2.2,0,0,0-2,.8,5.3,5.3,0,0,0-.7,2.8v.4a5.2,5.2,0,0,0,.7,3.2,2.2,2.2,0,0,0,2,.9,2.3,2.3,0,0,0,1.9-1,6.6,6.6,0,0,0,.6-3.1,5.2,5.2,0,0,0-.6-3,2.3,2.3,0,0,0-1.9-1Z"
          fill="#fff"
        )
        path(
          d="M340.2,313.7a7.1,7.1,0,0,1-5.2-1.8,6.8,6.8,0,0,1-1.8-5.1,7.8,7.8,0,0,1,1.7-5.3,5.9,5.9,0,0,1,4.7-1.9,5.7,5.7,0,0,1,4.5,1.6,6,6,0,0,1,1.7,4.6v1.8H337a3.1,3.1,0,0,0,2.8,3.4h.6a8.5,8.5,0,0,0,2.3-.3l2.3-.8v2.9l-2.1.7A12.3,12.3,0,0,1,340.2,313.7Zm-.5-11.5a2.7,2.7,0,0,0-1.9.8,3.9,3.9,0,0,0-.8,2.1h5.3a3.2,3.2,0,0,0-.8-2.1A2.3,2.3,0,0,0,339.7,302.2Z"
          fill="#fff"
        )
        path(
          d="M348.3,311.7a1.7,1.7,0,0,1,.5-1.5,1.8,1.8,0,0,1,1.6-.5,3,3,0,0,1,1.6.5,2.7,2.7,0,0,1,.5,1.5,2.3,2.3,0,0,1-2.5,2.1,2.1,2.1,0,0,1-1.2-.5A1.8,1.8,0,0,1,348.3,311.7Zm3.6-4.1h-3l-.6-12h4.2Z"
          fill="#fff"
        )
    g#cursor
      polygon(
        points="351.8 345.3 322.3 315.3 322.3 357.4 330.9 349.9 337.4 365.9 347 362 340.5 346 351.8 345.3"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="3"
      )
    path#progress-bg(
      d="M511.2,405.3h-387a2.8,2.8,0,0,1-2.8-2.8V378.1a2.8,2.8,0,0,1,2.8-2.8h387a2.8,2.8,0,0,1,2.8,2.8v24.4A2.8,2.8,0,0,1,511.2,405.3Z"
      fill="#a9b2b7"
    )
    path#time(
      d="M510,405.3H125.3c-2.2,0-4-1.2-4-2.7V378c0-1.5,1.8-2.7,4-2.7H510c2.2,0,4,1.2,4,2.7v24.6C514,404.1,512.2,405.3,510,405.3Z"
      fill="#ed5a29"
    )
    g#Time-label
      path(d="M72.2,397.3H69.5V386.7H66v-2.3h9.7v2.3H72.2Z")
      path(
        d="M77.3,385c0-.9.5-1.3,1.5-1.3s1.5.4,1.5,1.3a1.3,1.3,0,0,1-.4,1,1.6,1.6,0,0,1-1.1.3C77.8,386.3,77.3,385.8,77.3,385Zm2.8,12.3H77.4v-9.8h2.7Z"
      )
      path(
        d="M91.7,397.3H89v-5.7a2.5,2.5,0,0,0-.4-1.6c-.2-.4-.6-.5-1.1-.5a1.6,1.6,0,0,0-1.5.8,4.9,4.9,0,0,0-.5,2.5v4.6H82.8v-9.8h2l.4,1.3h.1a2.9,2.9,0,0,1,1.1-1.1,3.3,3.3,0,0,1,1.7-.4,3.1,3.1,0,0,1,3,1.4h.2a3.2,3.2,0,0,1,1.2-1.1,3.3,3.3,0,0,1,1.7-.4,3.2,3.2,0,0,1,2.5.9,3.7,3.7,0,0,1,.9,2.7v6.4H94.9v-5.7a2.5,2.5,0,0,0-.4-1.6c-.2-.4-.6-.5-1.1-.5a1.7,1.7,0,0,0-1.5.7,4.2,4.2,0,0,0-.5,2.2v4.9Z"
      )
      path(
        d="M105,397.5a4.5,4.5,0,0,1-5-5,5.6,5.6,0,0,1,1.2-3.8,4.4,4.4,0,0,1,3.4-1.3,4.1,4.1,0,0,1,3.2,1.2,4.9,4.9,0,0,1,1.2,3.3v1.3h-6.3a2.5,2.5,0,0,0,.7,1.8,2.4,2.4,0,0,0,1.8.6l1.7-.2,1.7-.6v2.1l-1.5.5Zm-.3-8.3a1.7,1.7,0,0,0-1.3.5,3.4,3.4,0,0,0-.6,1.5h3.8a2.1,2.1,0,0,0-.5-1.5A2.4,2.4,0,0,0,104.7,389.2Z"
      )
</template>

<script>
  export default {
    props: {
      animationActive: { type: Boolean },
    },
  };
</script>

<style lang="sass" scoped>
  #time
    transform-origin: 21% center
    transform: scaleX(0)
  #dark
    opacity: 0
  #popup
    transform: translateY(-150%)
  .animating
    #time
      animation: timed-time-animation 3s forwards
    #dark
      animation: timed-dark-animation 0.1s 3s forwards
    #popup
      animation: timed-popup-animation 0.3s 3s forwards
      animation-timing-function: cubic-bezier(.17,.67,.53,1.2)


  @keyframes timed-time-animation
    from
      transform: scaleX(0)
    to
      transform: scaleX(1)
  @keyframes timed-dark-animation
    from
      opacity: 0
    to
      opacity: 1
  @keyframes timed-popup-animation
    from
      transform: translateY(-150%)
    to
      transform: translateY(0%)
</style>
