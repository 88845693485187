<template lang="pug">
.d-flex.col-12.col-lg-6
  om-select#pageViewerType(
    :label="$t('thoseVisitorsWho')"
    label-position="fill"
    :placeholder="$t('select')"
    :options="options"
    v-model="pageViewerType"
    :error="$v.currentRule.options.value.$error"
  )
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';

  export default {
    mixins: [settingsValidation],
    data() {
      return {
        options: [
          { key: 'firstTimeVisitor', value: this.$t('pageViewerType.firstTimeVisitor') },
          { key: 'recurrentVisitor', value: this.$t('pageViewerType.recurrentVisitor') },
        ],
      };
    },
    computed: {
      pageViewerType: {
        get() {
          const viewerType = this.currentRule.options.value;
          return this.findOption(viewerType);
        },
        set(v) {
          this.currentRule.options.value = v.key;
          return v;
        },
      },
    },
    mounted() {
      if (!this.currentRule.options.value) {
        this.pageViewerType = this.options[0];
      }
    },
    validations: {
      currentRule: {
        options: {
          value: {
            required,
          },
        },
      },
    },
    methods: {
      findOption(key) {
        return this.options.find((x) => x.key === key);
      },
    },
  };
</script>
