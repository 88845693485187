<template lang="pug">
Columns(v-bind="{ ...passProps }")
  template(v-for="(_, slot) in $slots" v-slot:[slot]="scope")
    slot(:name="slot" v-bind="{ ...scope }")
  template(#icon)
    img(:src="require(`@/assets${icon}`)")
</template>

<script>
  import Columns from './Columns.vue';

  export default {
    name: 'CampaignSettingsBlockColumnsWithIcon',
    components: { Columns },
    props: {
      icon: { type: String, required: true },
    },
    computed: {
      passProps() {
        const { icon, ...others } = this.$attrs;
        return others;
      },
    },
  };
</script>
