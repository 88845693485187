<template lang="pug">
.learn-more-section.mb-3
  .d-flex.align-items-center.font-size-1--5.mb-2
    i.fas.fa-info-circle.mr-2
    .font-weight-bold {{ $t('howItWorksTitle') }}
  .learn-more-section-text(
    v-html="$t(transKey)"
    :style="{ height: getHeight, whiteSpace: 'pre-wrap' }"
    :class="{ open: active, needsTransition: needsTransition }"
  )
  .d-flex.align-items-center.cursor-pointer.mt-2(v-if="needsTransition" @click="active = !active")
    .font-weight-bold.brand-color.mr-2.font-size-1 {{ $t('wantToLearnMore') }}
    i.fas.brand-color(:class="{ 'fa-chevron-up': active, 'fa-chevron-down': !active }")
  .learn-more-section-hidden(v-html="$t(transKey)")
</template>
<script>
  import learnMoreSectionTrans from '@/mixins/learnMoreSectionTrans';

  export default {
    mixins: [learnMoreSectionTrans],
    props: ['settingType', 'ruleType'],
    data() {
      return {
        active: false,
        calculatedHeight: 0,
      };
    },

    computed: {
      needsTransition() {
        return this.calculatedHeight > 130;
      },
      getHeight() {
        return this.active ? `${this.calculatedHeight}px` : undefined;
      },
    },

    mounted() {
      this.calculateHeight();

      window.addEventListener('resize', this.calculateHeight);
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.calculateHeight);
    },

    methods: {
      calculateHeight() {
        const element = document.querySelector('.learn-more-section-hidden');
        this.calculatedHeight = element.offsetHeight;
      },
    },
  };
</script>

<style lang="sass">
  .learn-more-section
    position: relative
    &-hidden
      position: absolute
      left: -10000px
      bottom: 0
      width: 100%
      white-space: pre-wrap
    &-text
      overflow: hidden
      transition: all 0.4s

      &.needsTransition
        height: 120px

        &:after
          position: absolute
          content: ""
          left: 0
          bottom: 25px
          height: 120px
          width: 100%
          background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)
          opacity: 1
          transition: all 0.4s

        &.open
          &:after
            opacity: 0
            pointer-events: none
</style>
