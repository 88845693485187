import {
  isTemplatesWithSsr,
  isCampaignsWithSsrEnabled,
  isModalsWithSsrEnabled,
  isChooserWithSsr,
} from '@/utils/features';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    foundDimensions: false,
    ssrBoxSelector: '.template-thumbnail-box',
    boxDimensions: {
      width: null,
      height: null,
    },
    frameRequested: false,
  }),
  computed: {
    ...mapGetters(['accountFeatures']),
    isTemplatesWithSsr() {
      return isTemplatesWithSsr(this.accountFeatures);
    },
    isChooserWithSsr() {
      return isChooserWithSsr(this.accountFeatures);
    },
    isCampaignsWithSsr() {
      return isCampaignsWithSsrEnabled(this.accountFeatures);
    },
    isModalsWithSsr() {
      return isModalsWithSsrEnabled(this.accountFeatures);
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    updateDimensions(overrides) {
      const {
        selector = false,
        property = 'boxDimensions',
        updateGuard = 'foundDimensions',
      } = overrides || {};
      if (this[updateGuard]) return;
      const box = document.querySelector(selector || this.ssrBoxSelector);
      if (box) {
        const { width, height } = box.getBoundingClientRect();
        this[property] = { width, height };
        this[updateGuard] = true;
      }
    },
    handleResize() {
      if (this.frameRequested) return;
      window.requestAnimationFrame(() => {
        this.foundDimensions = false;
        this.updateDimensions();
        this.frameRequested = false;
      });
      this.frameRequested = true;
    },
  },
};
