<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', xmlns:xlink='http://www.w3.org/1999/xlink', width='30', height='18', viewBox='0 0 30 18')
  defs
    path#a(d='M0 .036h29.958v17.776H0z')
  g(fill='none', fill-rule='evenodd')
    path(:fill='color', d='M8.27 12.188l-.74-2.625c-.09-.278-.251-.924-.486-1.937h-.046c-.2.915-.36 1.564-.476 1.947l-.76 2.614H4.415L2.812 6.564h1.242l.73 2.771c.165.685.282 1.272.351 1.76h.031c.035-.247.087-.531.158-.852.07-.32.132-.56.184-.72l.869-2.96h1.335l.843 2.96c.051.167.116.421.194.763.077.343.123.609.137.799h.041c.052-.417.173-1 .362-1.75l.74-2.771h1.221l-1.614 5.625H8.27zM17.645 12.188l-.74-2.625c-.09-.278-.251-.924-.486-1.937h-.046c-.2.915-.36 1.564-.476 1.947l-.76 2.614H13.79l-1.604-5.624h1.242l.73 2.771c.165.685.282 1.272.351 1.76h.031c.035-.247.087-.531.158-.852.07-.32.132-.56.184-.72l.869-2.96h1.334l.844 2.96c.051.167.116.421.194.763.077.343.123.609.137.799h.041c.052-.417.173-1 .362-1.75l.74-2.771h1.221l-1.614 5.625h-1.366zM27.02 12.188l-.74-2.625c-.09-.278-.251-.924-.486-1.937h-.046c-.2.915-.36 1.564-.476 1.947l-.76 2.614h-1.346l-1.604-5.624h1.242l.73 2.771c.165.685.282 1.272.351 1.76h.031c.035-.247.087-.531.158-.852.07-.32.132-.56.184-.72l.869-2.96h1.334l.844 2.96c.051.167.116.421.194.763.077.343.123.609.137.799h.041c.052-.417.173-1 .362-1.75l.74-2.771H30l-1.614 5.625H27.02z')
    g
      mask#b(:fill='color')
        use(xlink:href='#a')
      path(:fill='color', d='M29.958 15.887H2.661c-.362 0-.667-.296-.667-.645V2.607c0-.35.305-.645.667-.645h27.18V.036H2.66C1.198.036 0 1.193 0 2.606v12.636c0 1.414 1.198 2.57 2.661 2.57h27.297v-1.925z', mask='url(#b)')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
