<template lang="pug">
div
  template(v-for="(expressionGroup, expressionGroupIndex) in currentRule.options.expressions")
    .expression-group-container
      .target-group-container(v-for="(target, targetIndex) in expressionGroup")
        .target-group-row
          .white-space-nowrap.mr-2.settings-labels.target-group-main
            .settings-label {{ $t('appearIfVisitor') }}
          .campaignProgressState.target-group-main
            om-select.mx-1.w-20(
              :id="`campaignProgressState-${expressionGroupIndex}`"
              @input="setCampaignProgressState($event, expressionGroupIndex, targetIndex)"
              :options="campaignProgressStates"
              :value="{ key: currentRule.options.expressions[expressionGroupIndex][targetIndex].state, value: getCampaignProgressStateText(currentRule.options.expressions[expressionGroupIndex][targetIndex].state) }"
            )
            .settings-label.mx-1
              span(
                v-if="currentRule.options.expressions[expressionGroupIndex][targetIndex].state === 'filled' || currentRule.options.expressions[expressionGroupIndex][targetIndex].state === 'filledForm'"
              ) {{ $t('aCampaignAlternate') }}
              span(v-else) {{ $t('aCampaign') }}
        .target-group-row.mt-2
          template(v-if="isFilledFormSelected(expressionGroupIndex, targetIndex)")
            om-select.mr-1.w-19--5(
              :id="`selectInput-${expressionGroupIndex}`"
              searchable
              :options="fieldOptions"
              :placeholder="$t('frontendRules.campaignProgressState.turbo.selectInput')"
              @input="setField($event, expressionGroupIndex, targetIndex)"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].field.$error"
              :errorMessage="$t('fieldRequired')"
              :value="{ field: currentRule.options.expressions[expressionGroupIndex][targetIndex].field, key: currentRule.options.expressions[expressionGroupIndex][targetIndex].field.customId, value: currentRule.options.expressions[expressionGroupIndex][targetIndex].field.name }"
            )
            om-select.w-10--75.mx-1(
              :id="`operator-${expressionGroupIndex}`"
              :options="createOperatorOptionArray(stringBaseOperators)"
              @input="setOperator($event, expressionGroupIndex, targetIndex)"
              :value="{ key: currentRule.options.expressions[expressionGroupIndex][targetIndex].operator, value: getOperatorText(currentRule.options.expressions[expressionGroupIndex][targetIndex].operator) }"
            )
            om-input.w-14--75.mx-1(
              :id="`variable-${expressionGroupIndex}`"
              v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeValue"
              :show-invalid-suffix="false"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].attributeValue.$error"
            )
          template(v-else)
            om-select.mr-1.w-19--5(
              :id="`campaignProgressId-${expressionGroupIndex}`"
              searchable
              :options="campaignList"
              :placeholder="$t('frontendRules.campaignProgressState.turbo.any')"
              optionKey="id"
              optionText="name"
              :value="getCampaignId(expressionGroupIndex, targetIndex)"
              @input="setCampaignId($event, expressionGroupIndex, targetIndex)"
            )
            om-select.w-10--75.mx-1(
              :id="`campaignProgressStateMessage-${expressionGroupIndex}`"
              @input="setCampaignProgressStateMessage($event, expressionGroupIndex, targetIndex)"
              :options="campaignProgressStateMessages"
              :value="{ key: currentRule.options.expressions[expressionGroupIndex][targetIndex].messageType, value: getCampaignProgressStateMessageText(currentRule.options.expressions[expressionGroupIndex][targetIndex].messageType) }"
              :disabled="!!currentRule.options.expressions[expressionGroupIndex][targetIndex].campaignId"
            )
            om-select.w-14--75.mx-1(
              :id="`campaignProgressStateVisitor-${expressionGroupIndex}`"
              @input="setCampaignProgressStateVisitor($event, expressionGroupIndex, targetIndex)"
              :options="campaignProgressStateVisitors"
              :value="{ key: currentRule.options.expressions[expressionGroupIndex][targetIndex].visitType, value: getCampaignProgressStateVisitorText(currentRule.options.expressions[expressionGroupIndex][targetIndex].visitType) }"
            )
          .add-new-blocks.d-flex.flex-grow-1.justify-content-end
            om-link.delete-expression-from-group(
              v-if="canBeDeleted(expressionGroupIndex)"
              @click="removeExpressionFromGroup('expressions', expressionGroupIndex, targetIndex)"
              withIconLeft
            )
              template(slot="left-icon")
                UilTrashAlt.mr-2(size="20px")
            om-link.add-expression-to-group.mx-2(
              primary
              withIconLeft
              @click="addExpressionToGroup('expressions', expressionGroupIndex, newExpressionHash())"
            )
              template(slot="left-icon")
                UilPlusCircle.mr-1(size="20px")
              span {{ $t('and').toUpperCase() }}
            om-link.add-expression-group.mx-2(
              primary
              withIconLeft
              @click="addExpressionGroup('expressions', newExpressionHash())"
              v-if="isLastRuleItem(expressionGroupIndex, targetIndex)"
            )
              template(slot="left-icon")
                UilPlusCircle.mr-1(size="20px")
              span {{ $t('or').toUpperCase() }}

    .or-operator.p-4.font-size-0--875(
      v-if="expressionGroupIndex !== currentRule.options.expressions.length - 1"
    ) {{ $t('orOperation') }}
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import CAMPAIGN_LIST from '@/graphql/CampaignList.gql';
  import { mapGetters } from 'vuex';
  import { stringBaseOperators } from '@/components/CampaignSettings/Condition/statics';
  import ALL_USER_FIELDS from '@/graphql/AllUserFields.gql';
  import { requiredIf } from 'vuelidate/lib/validators';
  import { UilPlusCircle, UilTrashAlt } from '@iconscout/vue-unicons';
  import visitorAttributeShared from '@/mixins/visitorAttributeShared';

  export default {
    components: {
      UilPlusCircle,
      UilTrashAlt,
    },
    mixins: [settingsValidation, expressionOperations, visitorAttributeShared],
    props: {
      domain: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        campaignList: [],
        stringBaseOperators,
        campaignProgressStateVisitors: [
          {
            key: 'visit',
            value: this.$t(`frontendRules.campaignProgressState.turbo.visitType.visit`),
          },
          {
            key: 'ever',
            value: this.$t(`frontendRules.campaignProgressState.turbo.visitType.ever`),
          },
        ],
        campaignProgressStateMessages: [
          {
            key: 'campaign',
            value: this.$t(`frontendRules.campaignProgressState.turbo.messageType.campaign`),
          },
          {
            key: 'popup',
            value: this.$t(`frontendRules.campaignProgressState.turbo.messageType.popup`),
          },
          {
            key: 'sidebar',
            value: this.$t(`frontendRules.campaignProgressState.turbo.messageType.sidebar`),
          },
          {
            key: 'nanobar',
            value: this.$t(`frontendRules.campaignProgressState.turbo.messageType.nanobar`),
          },
          {
            key: 'embedded',
            value: this.$t(`frontendRules.campaignProgressState.turbo.messageType.embedded`),
          },
        ],
        campaignProgressStates: [
          {
            key: 'showed',
            value: this.$t(`frontendRules.campaignProgressState.turbo.states.showed`),
          },
          {
            key: 'notShowed',
            value: this.$t(`frontendRules.campaignProgressState.turbo.states.notShowed`),
          },
          {
            key: 'closed',
            value: this.$t(`frontendRules.campaignProgressState.turbo.states.closed`),
          },
          {
            key: 'notClosed',
            value: this.$t(`frontendRules.campaignProgressState.turbo.states.notClosed`),
          },
          {
            key: 'filled',
            value: this.$t(`frontendRules.campaignProgressState.turbo.states.filled`),
          },
          {
            key: 'notFilled',
            value: this.$t(`frontendRules.campaignProgressState.turbo.states.notFilled`),
          },
          {
            key: 'filledForm',
            value: this.$t(`frontendRules.campaignProgressState.turbo.states.filledForm`),
          },
        ],
        allUserFields: [],
        fieldOptions: [],
      };
    },
    computed: {
      ...mapGetters(['domains', 'getLocale']),
    },
    created() {
      this.migrateRulesToHaveMessageType();
      this.fetchCampaigns();
      this.fetchAllFields().then((fields) => {
        this.allUserFields = fields;
        this.fieldOptions = this.createFieldsOptionArray();
      });
    },
    methods: {
      async fetchAllFields() {
        try {
          const {
            data: { allFields },
          } = await this.$apollo.query({
            query: ALL_USER_FIELDS,
            variables: {
              excludeBuiltIn: true,
            },
          });

          return allFields;
        } catch (e) {
          console.error('Error during fetch fields', e.message);
          return [];
        }
      },
      isFilledFormSelected(groupIndex, index) {
        const target = this.currentRule.options.expressions[groupIndex][index];
        return target?.state === 'filledForm';
      },
      createFieldsOptionArray() {
        return this.allUserFields.map((field) => ({
          key: field.customId,
          value: field.name,
          field,
        }));
      },
      createOperatorOptionArray(optionKeys) {
        return optionKeys.map((key) => ({ key, value: this.getOperatorText(key) }));
      },
      setOperator(event, expressionGroupIndex, targetIndex) {
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].operator =
          event.key;
      },
      getOperatorText(key) {
        return this.$t(`frontendRules.campaignProgressState.turbo.operators.${key}`);
      },
      getCampaignProgressStateMessageText(key) {
        return this.$t(`frontendRules.campaignProgressState.turbo.messageType.${key}`);
      },
      getCampaignProgressStateText(key) {
        return this.$t(`frontendRules.campaignProgressState.turbo.states.${key}`);
      },
      getCampaignProgressStateVisitorText(key) {
        return this.$t(`frontendRules.campaignProgressState.turbo.messageType.${key}`);
      },
      setCampaignProgressStateMessage(event, expressionGroupIndex, targetIndex) {
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].messageType =
          event.key;
      },
      setField(event, expressionGroupIndex, targetIndex) {
        const target = this.currentRule.options.expressions[expressionGroupIndex][targetIndex];
        target.field = event.field;
      },
      setCampaignProgressState(event, expressionGroupIndex, targetIndex) {
        if (event.key === 'filledForm') {
          this.replaceExpressionInGroup('expressions', expressionGroupIndex, targetIndex, [
            {
              state: event.key,
              operator: 'equals',
              field: this.fieldOptions?.[0]?.field,
              attributeValue: '',
            },
          ]);
        } else {
          this.replaceExpressionInGroup('expressions', expressionGroupIndex, targetIndex, [
            {
              ...this.newExpressionHash(),
              state: event.key,
            },
          ]);
        }
      },
      setCampaignProgressStateVisitor(event, expressionGroupIndex, targetIndex) {
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].visitType =
          event.key;
      },
      async fetchCampaigns() {
        const currentDomain = this.domains.find((domain) => domain.domain === this.domain);

        const {
          data: { campaignList },
        } = await this.$apollo.query({
          query: CAMPAIGN_LIST,
          variables: {
            ...(currentDomain && {
              filter: {
                domainIds: [currentDomain._id],
              },
            }),
          },
        });

        let currentCampaign = campaignList.find(
          (c) => c.id && c.id === parseInt(this.$route.params.id, 10),
        );

        currentCampaign = currentCampaign
          ? {
              ...currentCampaign,
              name: `${this.$t('frontendRules.campaignProgressState.turbo.currentCampaign')} (${
                currentCampaign.name
              })`,
              id: 'CURRENT_CAMPAIGN',
            }
          : {
              name: this.$t('frontendRules.campaignProgressState.turbo.currentCampaign'),
              id: 'CURRENT_CAMPAIGN',
            };

        const campList = campaignList
          .filter((c) => c.id && c.id !== parseInt(this.$route.params.id, 10))
          .map((campaign) => {
            const { id, _id, name } = campaign;
            return {
              id,
              _id,
              name,
            };
          })
          .sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }),
          );

        this.campaignList = [
          { id: null, name: this.$t('frontendRules.campaignProgressState.turbo.any') },
          currentCampaign,
          ...campList,
        ];
      },
      newExpressionHash() {
        return {
          campaignId: null,
          state: 'notShowed',
          messageType: 'campaign',
          visitType: 'visit',
        };
      },
      getCampaignId(expressionGroupIndex, targetIndex) {
        const condition = this.currentRule.options.expressions[expressionGroupIndex][targetIndex];
        if (condition.campaignId === 'CURRENT_CAMPAIGN') {
          return {
            id: condition.campaignId,
            name: this.$t('frontendRules.campaignProgressState.turbo.currentCampaign'),
          };
        }
        return condition.campaignId
          ? {
              id: condition.campaignId,
              name: condition.campaignName,
            }
          : { id: null, name: this.$t('frontendRules.campaignProgressState.turbo.any') };
      },
      setCampaignId(event, expressionGroupIndex, targetIndex) {
        const condition = this.currentRule.options.expressions[expressionGroupIndex][targetIndex];
        condition.campaignId = event.id;
        condition.campaignName = event.name;
        condition.messageType = event.id ? 'campaign' : condition.messageType || 'campaign';
      },
      migrateRulesToHaveMessageType() {
        const rules = this.currentRule.options.expressions.map((optionEle) => {
          return optionEle.map((option) => {
            return {
              ...option,
              messageType: option.messageType || 'campaign',
              visitType: option.visitType || 'ever',
            };
          });
        });

        this.$set(this.currentRule.options, 'expressions', rules);
      },
    },
    validations: {
      currentRule: {
        options: {
          expressions: {
            $each: {
              $each: {
                field: {
                  required: requiredIf(function (condition) {
                    return condition.state === 'filledForm' && !condition.field;
                  }),
                },
                attributeValue: {
                  required: requiredIf(function (condition) {
                    return condition.state === 'filledForm' && !condition.attributeValue;
                  }),
                },
              },
            },
          },
        },
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .target-group-container
    margin-bottom: 0.5rem
    &:last-child
      margin-bottom: 0!important

  // todo emulálni, javítani
  ::v-deep .campaign-progress-state-row .error-message
    position: absolute

  .target-group-main,
  .target-group-row
    display: flex
    align-items: center

  .expression-group-container
    background-color: $om-gray-100
    border: 1px solid $om-gray-300
    padding: 1rem
    border-radius: 4px
</style>
