<template lang="pug">
svg(:width="size" :height="size" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg")
  path(
    d="M4.29002 4.96C4.38298 5.05373 4.49359 5.12812 4.61544 5.17889C4.7373 5.22966 4.86801 5.2558 5.00002 5.2558C5.13203 5.2558 5.26274 5.22966 5.3846 5.17889C5.50646 5.12812 5.61706 5.05373 5.71002 4.96L8.71002 1.96C8.80589 1.86676 8.88245 1.75556 8.93533 1.63273C8.98822 1.5099 9.0164 1.37786 9.01826 1.24414C9.02011 1.11043 8.99561 0.977655 8.94616 0.853407C8.8967 0.729158 8.82326 0.615867 8.73002 0.520002C8.63678 0.424137 8.52558 0.347576 8.40275 0.294689C8.27992 0.241802 8.14788 0.213625 8.01416 0.211768C7.88045 0.209911 7.74767 0.234409 7.62343 0.283865C7.49918 0.33332 7.38589 0.406763 7.29002 0.500002L5.00002 2.84L2.71002 0.500002C2.51641 0.311698 2.25593 0.208018 1.98588 0.211768C1.71583 0.215519 1.45833 0.326394 1.27002 0.520002C1.08172 0.71361 0.978037 0.974092 0.981788 1.24414C0.985539 1.5142 1.09641 1.7717 1.29002 1.96L4.29002 4.96ZM7.29002 6.04L5.00002 8.34L2.71002 6.04C2.51641 5.8517 2.25593 5.74802 1.98588 5.75177C1.71583 5.75552 1.45833 5.86639 1.27002 6.06C1.08172 6.25361 0.978037 6.51409 0.981788 6.78414C0.985539 7.0542 1.09641 7.3117 1.29002 7.5L4.29002 10.5C4.38298 10.5937 4.49359 10.6681 4.61544 10.7189C4.7373 10.7697 4.86801 10.7958 5.00002 10.7958C5.13203 10.7958 5.26274 10.7697 5.3846 10.7189C5.50646 10.6681 5.61706 10.5937 5.71002 10.5L8.71002 7.5C8.89832 7.3117 9.00411 7.0563 9.00411 6.79C9.00411 6.5237 8.89832 6.26831 8.71002 6.08C8.52172 5.8917 8.26632 5.78591 8.00002 5.78591C7.73372 5.78591 7.47832 5.8917 7.29002 6.08V6.04Z"
    :fill="fill"
  )
</template>

<script>
  export default {
    props: {
      size: {
        type: [Number, String],
        default: 18,
      },
      fill: {
        type: String,
        default: '#6D9AE7',
      },
    },
  };
</script>
