<template lang="pug">
.row.align-items-center
  .col-2
    label.mb-0 {{ $t('deviceWord') }}:
  .col-2.col-md-2
    .d-flex.btn-group.btn-group-toggle.brand-settings-group(data-toggle='buttons')
      label.flex-grow-1.btn.brand-btn-switch.active
        input(type='radio' name="device" autocomplete='off' value="mobile" v-model="currentRule.device")
        div
          mobile-svg(color="white")
        | {{ $t('device.mobile') }}
</template>

<script>
  // feature flag: AVG_TIME_ON_PAGE_TRIGGER
  import settingsValidation from '@/mixins/settingsValidation';

  export default {
    mixins: [settingsValidation],

    validations: {},
  };
</script>
