import ADD_VARIANT_TO_EXPERIENCE from '@/graphql/AddVariantToExperience.gql';
import ADD_DYNAMIC_CONTENT_TO_EXPERIENCE from '@/graphql/AddDynamicContentVariantToExperience.gql';
import { track } from '@/services/xray';

export default {
  methods: {
    async _addVariantToExperience(variant, experienceId, isDynamicContent = false) {
      await this.$apollo.query({
        query: isDynamicContent ? ADD_DYNAMIC_CONTENT_TO_EXPERIENCE : ADD_VARIANT_TO_EXPERIENCE,
        variables: {
          variantId: variant._id,
          experienceId,
        },
      });
      track('add-variant-to-experience', {
        experienceId,
        variantId: variant._id,
      });
      this.$bus.$emit('experiences:variantAdded', {
        variantId: variant._id,
        experienceId,
      });
      this.$modal.hide('add-variant-to-experience');
    },
  },
};
