<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='30', height='30', viewBox='0 0 30 30')
  g(:fill='color', fill-rule='evenodd')
    path(d='M10.807 18.024l-.555-2.005c-.068-.212-.19-.706-.366-1.48h-.034c-.15.7-.27 1.195-.358 1.488l-.571 1.997h-1.01l-1.205-4.298h.932l.548 2.118c.125.524.213.972.265 1.345h.023c.026-.19.065-.406.118-.651.054-.245.1-.428.138-.55l.653-2.262h1.003l.633 2.262c.04.127.088.321.146.583.058.262.093.465.103.61h.031c.039-.319.13-.764.272-1.337l.556-2.118h.917l-1.213 4.298h-1.026zM17.531 18.024l-.555-2.005c-.068-.212-.19-.706-.366-1.48h-.035c-.15.7-.269 1.195-.357 1.488l-.571 1.997h-1.01l-1.205-4.298h.932l.548 2.118c.125.524.213.972.265 1.345h.023c.026-.19.065-.406.118-.651.053-.245.1-.428.138-.55l.653-2.262h1.003l.633 2.262c.039.127.088.321.146.583.058.262.092.465.103.61h.03c.04-.319.13-.764.273-1.337l.556-2.118h.917l-1.213 4.298h-1.026zM24.255 18.024L23.7 16.02c-.068-.212-.19-.706-.366-1.48H23.3c-.15.7-.27 1.195-.357 1.488l-.571 1.997h-1.01l-1.205-4.298h.932l.548 2.118c.125.524.213.972.264 1.345h.024c.026-.19.065-.406.118-.651.053-.245.1-.428.138-.55l.653-2.262h1.003l.633 2.262c.039.127.088.321.146.583.058.262.092.465.103.61h.03c.04-.319.13-.764.273-1.337l.556-2.118h.917l-1.213 4.298h-1.026z')
    path(d='M23.114 20.777H6.26a.494.494 0 0 1-.496-.478v-9.362c0-.26.227-.478.496-.478h16.854V9.032H6.26c-1.09 0-1.98.857-1.98 1.905v9.362c0 1.048.89 1.905 1.98 1.905h16.854v-1.427z')
    path(d='M15 0C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15m0 2.05a12.908 12.908 0 0 1 9.157 3.793A12.908 12.908 0 0 1 27.949 15a12.909 12.909 0 0 1-3.793 9.157A12.908 12.908 0 0 1 15 27.949a12.908 12.908 0 0 1-9.157-3.793A12.91 12.91 0 0 1 2.051 15a12.909 12.909 0 0 1 3.793-9.157A12.908 12.908 0 0 1 15 2.052')
    path(d='M24.194 25.36L4.234 5.403l1.45-1.45 19.96 19.959z')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
