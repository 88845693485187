<template lang="pug">
.report-skeleton
  .skeleton-row.title-row
    .quarter-column-flex.justify-content-start
      vue-skeleton-loader.mr-2(type="circle" width="24px" height="24px" :rounded="rounded")
      vue-skeleton-loader.domain-name(type="rect" width="100%" height="24px" :rounded="rounded")
  .skeleton-row.header-row
    .quarter-column-flex
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
    .one-sixth-column(v-for="i in 6" :key="i")
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
  hr(v-if="bottomLine")
  .skeleton-row.sum-row
    .quarter-column-flex
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
    .one-sixth-column(v-for="i in 6" :key="i")
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
  hr(v-if="bottomLine")
  .skeleton-row(v-for="index in 2")
    .quarter-column-flex
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
    .one-sixth-column(v-for="i in 6" :key="i")
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
  hr(v-if="bottomLine")
  .skeleton-row.variants(v-for="index in 2")
    .quarter-column-flex
      img.mr-1.ml-1(:src="require(`@/assets/admin/svg/icons/u_angle-down.svg`)")
      vue-skeleton-loader.variant-loader(
        type="rect"
        width="calc(90% - 2rem)"
        height="24px"
        :rounded="rounded"
      )
    .one-sixth-column(v-for="i in 6" :key="i")
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
  hr(v-if="bottomLine")
  .skeleton-row(v-for="index in 2")
    .quarter-column-flex
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
    .one-sixth-column(v-for="i in 6" :key="i")
      vue-skeleton-loader(type="rect" width="90%" height="24px" :rounded="rounded")
  hr(v-if="bottomLine")
</template>

<script>
  export default {
    props: {
      rows: { type: Number },
      rounded: { type: Boolean },
      bottomLine: { type: Boolean },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../../sass/variables/_colors'
  .report-skeleton
    border: 1px solid $om-gray-400
    padding: 1.125rem 0 0 0.75rem
    border-radius: 4px

  .domain-name
    max-width: 100px

  hr
    margin: 0 0.5rem 0 0
  .skeleton-row
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0.625rem 1rem 0.625rem 0.5rem

    &.variants
      background: #F8F8F9

      .quarter-column-flex
        justify-content: flex-start

    &.title-row
      padding: 0 1rem 0 0.5rem

    &.header-row
      padding: 1.875rem 1rem 0.75rem 0.5rem

    &.sum-row
      padding: 1.375rem 1rem 1.375rem 0.5rem

  .quarter-column-flex
      width: 25%
      display: flex
      justify-content: space-between
  .one-sixth-column
    width: calc(100% / 6)
    display: flex
    justify-content: flex-end
  .skeleton-inner-row
    display: flex
    flex-direction: column
    justify-content: space-evenly
    width: calc( 100% - 45px )

  .content-right
    display: flex
    justify-content: flex-end
</style>
