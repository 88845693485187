<template lang="pug">
om-modal.create-new-goal(
  name="create-edit-goal"
  scrollable
  :clickToClose="true"
  :width="800"
  @beforeOpen="beforeOpen"
  @beforeClose="beforeClose"
)
  template(slot="modal-header")
    .w-100.text-center.font-weight-bold.font-size-1--5 {{ modalTitle }}
    .brand-separator.mt-3
    .brand-modal-action-icon.cursor-pointer(@click="cancel()")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    .d-flex.align-items-end
      om-input#newGoalName.w-50.new-goal-name(v-model="goalName" :label="$t('name')" type="text")
      om-select#selectGoalDomain.w-50.domain-select(
        v-model="selectedDomain"
        :options="domainOptions"
        optionText="text"
        :label="$t('domain')"
        :searchable="true"
        :placeholder="$t('chooseDomain')"
      )
    .flex-column.mt-5
      om-body-text.event-type-title.mb-3(bt700md) {{ $t('conversionGoals.createModal.eventType') }}
      om-select#selectEventType.w-100(
        v-model="selectedEvent"
        :options="eventOptions"
        optionText="text"
        :placeholder="$t('conversionGoals.eventType')"
        :disabled="!selectedDomain"
      )
      om-input#customEventName.w-100.mt-2(
        v-if="isCustomEvent"
        v-model="customEventName"
        :placeholder="$t('conversionGoals.customEventNamePlaceholder')"
        :error="$v.customEventName.$error"
      )
        template(slot="error" v-if="$v.customEventName.$error && !$v.customEventName.isCool")
          span {{ $t('conversionGoals.customEventAllowedCharacters') }}
        template(slot="error" v-if="$v.customEventName.$error && !$v.customEventName.maxLength")
          span {{ $t('conversionGoals.customEventMaxLength') }}
      .d-flex.mt-3(v-if="selectedEvent")
        .flex-column.w-100
          om-body-text.event-props-title.mb-2(v-if="!isCampaignEvent" bt500sm) {{ eventPropertiesTitle }}
          .d-flex.w-100.align-items-center(:class="{ 'operator-between': isSecondInputField }")
            om-select#selectGoalCampaign.w-100(
              v-if="isCampaignEvent"
              v-model="selectedCampaign"
              :options="campaignList"
              :placeholder="`${$t('select')} ${$t('campaign').toLowerCase()}`"
              searchable
              optionText="name"
              optionKey="id"
            )
            template(v-if="!isCustomEvent")
              om-select#selectGoalField.mr-2.field-select(
                v-if="isFieldEvent"
                v-model="selectedGoalField"
                :options="fieldOptions"
                optionText="text"
              )
            template(v-else)
              om-input#selectGoalField.mr-2.field-select(
                v-model="customEventFieldName"
                :placeholder="$t('conversionGoals.customEventFieldNamePlaceholder')"
                :disabled="!selectedDomain"
                :error="$v.customEventFieldName.$error"
              )
            om-select#selectGoalOperator.mr-3.operator-select(
              v-if="!isCampaignEvent"
              v-model="operator"
              :options="operatorOptions"
              optionText="text"
              :disabled="operatorOptions.length === 0 || !selectedDomain"
            )
            om-input#goalvalue.w-100(
              v-if="!isCampaignEvent"
              :type="inputType"
              v-model="goalValue"
              :prefix="inputPrefix"
              :error="$v.goalValue.$error"
              :disabled="!selectedDomain || isExistingCheckerGoal"
            )
            span.and-operator.font-size-0--875.ml-1(v-if="isSecondInputField") {{ $t('and') }}
            om-input#goalvalue-2.ml-1.w-100(
              v-if="isSecondInputField && !isCampaignEvent"
              :type="inputType"
              v-model="secondGoalValue"
              :error="$v.secondGoalValue.$error"
              :disabled="!selectedDomain || isExistingCheckerGoal"
            )
          .d-flex.w-100.align-items-center.justify-content-between(
            v-if="$v.customEventFieldName.$error || $v.goalValue.$error"
          )
            .input-wrapper-col(v-if="$v.customEventFieldName.$error")
              span.form-text.text-danger(v-if="!$v.customEventFieldName.isCool") {{ $t('conversionGoals.customEventAllowedCharacters') }}
              span.form-text.text-danger(v-else-if="!$v.customEventFieldName.maxLength") {{ $t('conversionGoals.customEventFieldMaxLength') }}
            .input-wrapper-col.goal-value-error(
              v-if="$v.goalValue.$error && !$v.goalValue.maxLength"
            )
              span.form-text.text-danger {{ $t('conversionGoals.customEventValueMaxLength') }}
          a.support-article(v-if="isCustomEvent" :href="supportArticleUrl" target="_blank") {{ $t('conversionGoals.supportArticle') }}
  template(slot="modal-footer")
    .brand-separator
    .d-flex.justify-content-center.mt-6
      om-button#cancelSaveGoal.mr-3(ghost @click="cancel()") {{ $t('cancel') }}
      om-button#saveGoal(primary :disabled="!canSave" @click="save()") {{ $t('save') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import startingSlashSettings from '@/mixins/startingSlashSettings';
  import { maxLength } from 'vuelidate/lib/validators';
  import goals from '@/mixins/goals';

  export default {
    mixins: [startingSlashSettings, goals],
    data() {
      return {
        goalName: null,
        selectedDomain: null,
        selectedEvent: null,
        selectedCampaign: null,
        selectedGoalField: null,
        customEventName: null,
        customEventFieldName: null,
        operator: null,
        goalValue: null,
        secondGoalValue: null,
        mode: 'create',
        goalToModify: null,
        goalId: null,
        isPrimary: false,
        campaignList: [],
      };
    },
    computed: {
      ...mapGetters(['domains', 'isShoprenterDomain', 'isShopifyDomain', 'getLocale']),
      domainOptions() {
        return this.domains.map((domain) => ({
          key: domain._id,
          value: domain._id,
          text: domain.domain,
        }));
      },
      isCampaignEvent() {
        return this.campaignEvents.includes(this.selectedEvent?.value);
      },
      isFieldEvent() {
        return this.fieldEvents.includes(this.selectedEvent?.value);
      },
      isPageView() {
        return this.selectedEvent?.key === 'pageView';
      },
      isCustomEvent() {
        return this.selectedEvent?.key === 'custom';
      },
      isExistingCheckerGoal() {
        return this.isCustomEvent && !this.customEventFieldName;
      },
      modalTitle() {
        const title = this.mode === 'edit' ? 'editTitle' : 'createTitle';
        return this.$t(`conversionGoals.createModal.${title}`);
      },
      eventPropertiesTitle() {
        const type = this.selectedEvent?.key === 'pageView' ? 'pageView' : 'eventProperties';
        return this.$t(`conversionGoals.createModal.eventPropertiesTitle.${type}`);
      },
      operatorOptions() {
        if (this.isCustomEvent) {
          return this.customEventFieldName
            ? this.mapOperatorOptions(this.selectedEvent.operators)
            : [];
        }

        if (this.selectedEvent?.fields && this.selectedGoalField) {
          const selectedField = this.selectedEvent.fields.find(
            (field) => field.key === this.selectedGoalField.key,
          );
          return this.mapOperatorOptions(selectedField?.operators);
        }
        if (this.selectedEvent?.operators) {
          return this.mapOperatorOptions(this.selectedEvent.operators);
        }
        return [];
      },
      fieldOptions() {
        if (this.isCustomEvent) {
          return [];
        }

        if (this.selectedEvent?.fields) {
          return this.mapFieldOptions(this.selectedEvent.fields);
        }
        return [];
      },
      filledSimpleInputs() {
        return this.goalName && this.selectedDomain && this.selectedEvent;
      },
      filledCampaignInputs() {
        return this.isCampaignEvent && this.selectedCampaign;
      },
      filledPageViewInputs() {
        return (
          this.isPageView &&
          this.operator &&
          (this.goalValue || (!this.goalValue && this.isPrefilledUrlInput))
        );
      },
      filledFieldEventInputs() {
        return (
          this.isFieldEvent &&
          this.selectedGoalField &&
          (this.goalValue || this.goalValue === 0) &&
          this.operator
        );
      },
      filledCustomEventInputs() {
        const isValidExpression =
          this.customEventFieldName && this.operator && (this.goalValue || this.goalValue === 0);
        return (
          this.isCustomEvent &&
          this.customEventName &&
          (this.isExistingCheckerGoal || isValidExpression)
        );
      },
      filledSecondInputIfNecessary() {
        if (this.operator?.key !== 'interval') return true;
        return this.secondGoalValue;
      },
      canSave() {
        return (
          this.filledSimpleInputs &&
          (this.filledCampaignInputs ||
            this.filledFieldEventInputs ||
            this.filledPageViewInputs ||
            this.filledCustomEventInputs) &&
          this.filledSecondInputIfNecessary
        );
      },
      inputType() {
        if (this.isPageView) return 'text';
        if (this.isCustomEvent && this.operator?.value === 'equals') return 'text';
        return this.onlyNumberOperators.includes(this.operator?.value) ? 'number' : 'text';
      },
      isShopifyOrShoprenter() {
        const domainName = this.selectedDomain?.text;
        return this.isShopifyDomain(domainName) || this.isShoprenterDomain(domainName);
      },
      eventOptions() {
        const events = this.isShopifyOrShoprenter ? this.events : this.defaultEventOptions;
        return events.map((event) => {
          return {
            ...event,
            text: this.$t(`conversionGoals.eventTypes.${event.key}`),
            fields: event.fields.map((field) => {
              return {
                ...field,
                text: this.$t(`conversionGoals.fieldNames.${field.key}`),
              };
            }),
          };
        });
      },
      isPrefilledUrlInput() {
        return this.needStartingSlash(this.operator?.key) && this.isPageView;
      },
      inputPrefix() {
        if (this.isPrefilledUrlInput) {
          const prefix = this.selectedDomain?.text || '';
          return `${prefix}/`;
        }
        return '';
      },
      isSecondInputField() {
        return this.operator?.key === 'interval';
      },
      eventInvalidForDomain() {
        return !this.isShopifyOrShoprenter && ['addToCart'].includes(this.selectedEvent?.key);
      },
      supportArticleUrl() {
        if (this.getLocale === 'en') {
          return 'https://support.optimonk.com/hc/en-us/articles/17600061069202-Custom-Conversion-Goal';
        }

        return 'https://support.optimonk.hu/hc/hu/articles/17603253555858-Egyedi-Konverzi%C3%B3s-C%C3%A9l';
      },
    },
    watch: {
      selectedEvent(newVal, oldVal) {
        if (!this.isFirstOpen(oldVal) || (this.mode === 'create' && this.isFirstOpen(oldVal))) {
          this.setDefaultsForEvent();
        }
        if (!this.isFirstOpen(oldVal) && (newVal?.value ?? '') === this.customEventType) {
          this.prefillCustomGoalData({ type: this.customEventType });
        }
      },
      selectedDomain(_, oldVal) {
        this.fetchCampaigns();
        if (!this.isFirstOpen(oldVal) && this.eventInvalidForDomain) {
          this.selectedEvent = null;
          this.resetDefaults();
        }
        this.selectedCampaign = null;
      },
      operator(newOperator) {
        if (newOperator?.key === 'interval' && typeof this.goalValue === 'string') {
          this.goalValue = null;
        }
      },
    },
    methods: {
      isFirstOpen(oldValue) {
        return !oldValue;
      },
      async prefillWithGoal(goal) {
        const {
          name,
          domainId,
          rules: { expressions },
        } = goal;
        const [rule] = expressions;
        const { type } = rule;

        this.goalName = name;
        this.selectedDomain = this.domainOptions.find((option) => option.key === domainId);

        if (type.startsWith(this.customEventPrefix)) {
          this.prefillCustomGoalData(rule);
        } else {
          this.prefillStandardGoalData(rule);
        }
      },
      prefillCustomGoalData({ type, field = null, operator = null, value = null }) {
        this.selectedEvent = this.events.find((event) => event.value === this.customEventType);
        this.customEventName = this.getCustomEventName(type);
        this.customEventFieldName = field;
        this.operator = operator ? this.mapOperatorOptions([operator])[0] : null;
        if (value || value === 0) this.prefillValueData({ value });
      },
      async prefillStandardGoalData({ type, field, operator, value }) {
        this.selectedEvent = this.events.find((event) => event.value === type);
        const goalField = this.selectedEvent.fields.find((f) => f.value === field);
        this.selectedGoalField = this.mapFieldOptions([goalField])[0];
        this.operator = this.mapOperatorOptions([operator])[0];

        if (this.campaignEvents.includes(this.selectedEvent?.value)) {
          await this.fetchCampaigns();
          this.selectedCampaign = this.campaignList.find(
            (campaign) => `${campaign.id}` === `${value}`,
          );
        }

        this.prefillValueData({ value });
      },
      prefillValueData({ value }) {
        if (Array.isArray(value)) {
          this.goalValue = value[0];
          this.secondGoalValue = value[1];
        } else if (this.isPrefilledUrlInput) {
          this.goalValue = this.getUrlWithoutOrigin(value);
        } else {
          this.goalValue = value;
        }
      },
      beforeOpen(event = {}) {
        const { params: { goal, mode, domainId, isPrimary } = {} } = event;
        this.isPrimary = isPrimary;
        if (mode) this.mode = mode;
        if (this.mode === 'create') {
          if (domainId) {
            this.selectedDomain = this.domainOptions.find((option) => option.key === domainId);
          } else {
            this.resetDefaults();
          }
        }
        if (goal && this.mode === 'edit') {
          this.goalId = goal._id;
          this.prefillWithGoal(goal);
        }
      },
      beforeClose() {
        this.mode = 'create';
        this.selectedEvent = null;
        this.goalName = null;
        this.isPrimary = false;
        this.selectedDomain = null;
        this.campaignList = [];
        this.resetDefaults();
      },
      mapFieldOptions(options) {
        if (!options) return [];
        return options.map((option) => {
          return {
            key: option.key,
            value: option.value,
            text: this.$t(`conversionGoals.fieldNames.${option.key}`),
          };
        });
      },
      mapOperatorOptions(options) {
        if (!options) return [];
        return options.map((option) => {
          return {
            key: option,
            value: option,
            text: this.$t(`${option}`),
          };
        });
      },
      addHttp(v) {
        return !v.startsWith('https://') ? `https://${v}` : v;
      },
      getValue() {
        if (this.isCampaignEvent) {
          return `${this.selectedCampaign.id}`;
        }
        if (this.operator?.key === 'interval') {
          return [parseFloat(this.goalValue, 10), parseFloat(this.secondGoalValue, 10)];
        }
        this.modifyValue(this.addHttp);
        return this.inputType === 'text' ? this.goalValue : parseFloat(this.goalValue, 10);
      },
      save() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }
        let field = this.selectedGoalField?.value;
        if (this.isCampaignEvent) {
          field = 'campaignId';
        } else if (this.isPageView) {
          field = 'url';
        } else if (this.isCustomEvent) {
          field = this.customEventFieldName;
        }

        let type = this.selectedEvent?.value;
        let operator = this.operator?.key;
        let value = this.getValue();

        if (this.isExistingCheckerGoal) {
          field = undefined;
          operator = undefined;
          value = undefined;
        }

        if (this.isCustomEvent) {
          type = `${this.customEventPrefix}${this.customEventName}`;
        }

        const goal = {
          name: this.goalName,
          domainId: this.selectedDomain.value,
          isPrimary: this.isPrimary,
          rules: {
            expressions: [
              {
                type,
                field,
                operator,
                value,
              },
            ],
          },
        };

        if (this.mode === 'edit') {
          this.$emit('goals:editGoal', { _id: this.goalId, ...goal });
        } else {
          this.$emit('goals:createGoal', goal);
        }
        this.selectedEvent = null;
        this.selectedDomain = null;
        this.goalName = null;
        this.$v.$reset();
        this.$modal.hide('create-edit-goal');
      },
      cancel() {
        this.selectedEvent = null;
        this.selectedDomain = null;
        this.goalName = null;
        this.resetDefaults();
        this.$modal.hide('create-edit-goal');
      },
      resetDefaults() {
        this.selectedCampaign = null;
        this.selectedGoalField = null;
        this.customEventName = null;
        this.customEventFieldName = null;
        this.operator = null;
        this.goalValue = null;
        this.secondGoalValue = null;
      },
      setDefaultsForEvent() {
        this.resetDefaults();
        this.selectedGoalField = this.fieldOptions[0];
        this.operator = this.operatorOptions[0];
      },
      getUrlWithoutOrigin(url) {
        const goalUrl = new URL(url);
        const urlWithoutOrigin = url.split(`${goalUrl.origin}/`);
        return urlWithoutOrigin[1];
      },
      modifyValue(callback) {
        if (this.needStartingSlash(this.operator?.key) && this.isPageView) {
          this.goalValue = callback(`${this.selectedDomain.text}/${this.goalValue || ''}`);
        }
      },
    },
    validations: {
      goalValue: {
        maxLength: maxLength(256),
        isCool(val, { secondGoalValue }) {
          if (!this.isSecondInputField) return true;
          return val < secondGoalValue;
        },
      },
      secondGoalValue: {
        maxLength: maxLength(256),
        isCool(val, { goalValue }) {
          if (!this.isSecondInputField) return true;
          return val > goalValue;
        },
      },
      customEventName: {
        maxLength: maxLength(64),
        isCool(val) {
          if (!this.isCustomEvent) return true;
          return !!val.match(/^[a-zA-Z0-9_]*$/);
        },
      },
      customEventFieldName: {
        maxLength: maxLength(128),
        isCool(val) {
          if (!this.isCustomEvent) return true;
          if (!val) return true;
          return !!val.match(/^[a-zA-Z0-9_]*$/);
        },
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .create-new-goal
      .brand-modal
        .brand-modal-header
          padding-bottom: 4px
        .brand-modal-footer
          padding-top: 12px
</style>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .event-type-title
    color: $om-gray-700

  .event-props-title
    color: $om-gray-700

  .new-goal-name
    margin-right: 10px
  .domain-select
    margin-left: 10px
  .operator-select,
  .field-select
    min-width: 272px

    .operator-between &
      min-width: 260px

  .input-element
    min-width: 50px

  .goal-value-error
    margin-left: auto

  .support-article
    font-size: 0.85rem
    text-decoration: underline
    display: block
    margin-top: 1rem
</style>
