<template lang="pug">
Base
  .campaign-settings-block-columns.row
    .campaign-settings-block-column.col-auto.campaign-settings-block-column.icon.d-flex.flex-column(
      v-if="$slots.icon"
      :class="columnClasses"
    )
      slot(name="icon")
    .campaign-settings-block-column.col.campaign-settings-block-column.left-column.d-flex.flex-column(
      v-if="leftColumn"
      :class="columnClasses"
    )
      slot(name="left")
    .campaign-settings-block-column.col.campaign-settings-block-column.right-column.d-flex.flex-column(
      v-if="rightColumn"
      :class="columnClasses"
    )
      slot(name="right")
</template>

<script>
  import Base from './Base.vue';

  export default {
    name: 'CampaignSettingsBlockColumns',
    components: { Base },
    props: {
      vCenter: { type: Boolean, default: true },
      leftColumn: { type: Boolean, default: true },
      rightColumn: { type: Boolean, default: true },
    },
    computed: {
      columnClasses() {
        return {
          'justify-content-center': this.vCenter,
        };
      },
    },
  };
</script>
