<template lang="pug">
.d-flex
  .flex-grow-0.mr-4.w-8
    label.settings-label.mt-2 {{ $t('pageUrl') }}:
  .flex-grow-1
    .brand-expression-groups
      .brand-expression-group.mb-3(
        v-for="(expressionGroup, groupIndex) in currentRule.options.expressions"
      )
        .brand-expression.mb-2(v-for="(expression, expressionIndex) in expressionGroup")
          om-select.w-10.mr-2(
            :id="`urlOperator-${expressionIndex}`"
            :options="createOptionArray(stringBaseOperatorsForUrl)"
            size="small"
            @input="setOperator($event, groupIndex, expressionIndex)"
            :value="{ key: currentRule.options.expressions[groupIndex][expressionIndex].operator, value: $t(currentRule.options.expressions[groupIndex][expressionIndex].operator) }"
          )
          om-input.w-15.mr-3(
            :id="`urlValue-${expressionIndex}`"
            :prefix="needStartingSlash(currentRule.options.expressions[groupIndex][expressionIndex].operator) ? domainWithDash : null"
            small
            type="text"
            :error="$v.currentRule.options.expressions.$each[groupIndex].$each[expressionIndex].value.$error"
            v-model="currentRule.options.expressions[groupIndex][expressionIndex].value"
            :show-invalid-suffix="false"
          )
          .d-flex.flex-grow-1.justify-content-start.min-width-7
            .font-size-0--75.font-weight-bold.mr-2.flex-grow-1 {{ $t('andOperation') }}
            .brand-link.settings-label.flex-grow-0(
              v-if="currentRule.options.expressions.length === 1 && expressionGroup.length === 1 && expressionIndex === 0"
            )
            .brand-link.settings-label.flex-grow-0.mx-2(
              v-if="expressionIndex === expressionGroup.length - 1"
              @click="addExpressionToGroup('expressions', groupIndex, { operator: 'equals', value: '' })"
            )
              fa-icon(variant="fa-plus-circle" size="1.5")
            .brand-link.settings-label.flex-grow-0(
              v-if="currentRule.options.expressions.length === 1 && expressionGroup.length === 1 && groupIndex === 0"
            )
            .brand-link.settings-label.flex-grow-0(
              v-else
              @click="removeExpressionFromGroup('expressions', groupIndex, expressionIndex)"
            )
              fa-icon(variant="fa-trash" size="1.5")
        .d-flex.align-items-center.py-2(
          v-if="groupIndex !== currentRule.options.expressions.length - 1"
        )
          .font-size-0--75.font-weight-bold.mr-2 {{ $t('orOperation') }}
          .brand-separator
      span.brand-link.settings-label(
        @click="addExpressionGroup('expressions', { operator: 'equals', value: '' })"
      )
        fa-icon(variant="fa-plus" :html="`&nbsp;${$t('addNew')}`")
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import urlCampaignSettings from '@/mixins/urlCampaignSettings';
  import frontendRuleUtils from '@/mixins/frontendRuleUtils';
  import { stringBaseOperatorsForUrl } from './statics';

  export default {
    mixins: [settingsValidation, expressionOperations, urlCampaignSettings, frontendRuleUtils],

    validations: {
      currentRule: {
        options: {
          expressions: {
            $each: {
              $each: {
                value: {
                  isCool(v, { operator }) {
                    return this.needStartingSlash(operator) || v.length;
                  },
                },
              },
            },
          },
        },
      },
    },
    data() {
      return {
        stringBaseOperatorsForUrl,
      };
    },
    computed: {
      expressionGroups() {
        return this.currentRule.options.expressions;
      },
    },
    methods: {
      setOperator(event, groupIndex, expressionIndex) {
        this.currentRule.options.expressions[groupIndex][expressionIndex].operator = event.key;
      },
    },
  };
</script>

<style lang="sass">
  .min-width-7
    min-width: 7rem
</style>
