export default {
  methods: {
    getFullNameByLocale(firstName, lastName, locale) {
      if (locale === undefined) {
        locale = this.$store.state.locale;
      }

      if (locale === 'hu') {
        return `${lastName} ${firstName}`;
      }
      return `${firstName} ${lastName}`;
    },
  },
};
