<template lang="pug">
.new-feature-tooltip
  .feature-info-header.position-absolute.mt-4
    span.p-2(@click="$emit('close')")
      uil-times.cursor-pointer(:size="'21px'")
  .feature-info-image(v-if="withImage")
    slot(name="image")
  .feature-info-content
    .feature-info-title
      slot(name="title") {{ 'Title of the info' }}
    .feature-info-text
      slot(name="text") {{ 'Text of the info' }}
</template>
<script>
  import { UilTimes } from '@iconscout/vue-unicons';

  export default {
    name: 'FeatureInfo',
    components: { UilTimes },
    props: ['withImage'],
  };
</script>
<style lang="sass">
  @import '@/sass/variables/variables'

  .new-feature-tooltip
    display: flex
    padding: 1rem
    .feature-info
      &-image
        margin-right: 1.5rem
      &-header
        right: -0.2rem
        top: -0.7rem
      &-title
        color: #505763
        font-size: 1rem
        font-weight: 700
        line-height: 1.5rem
        margin-bottom: 0.75rem
      &-text
        font-size: 0.875rem
        line-height: 1.25rem
</style>
