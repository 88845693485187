<template lang="pug">
div
  .row.align-items-center.mt-6
    .col-6
      .t2 {{ $t('feedbackStatistics.title') }}
    .row.col-6.form-group.d-flex.justify-content-end.align-items-center(
      v-if="Object.keys(statistics).length > 1"
    )
      .col-12.d-flex.justify-content-end
        span.feedback-variant-title.mr-3.d-flex.align-items-center {{ $t('feedbackStatistics.selectVariant') }}:
        popper(
          trigger="click"
          :options="{ placement: 'bottom' }"
          @created="popoverInstance = $event"
        )
          .popper.brand-popover.brand-select-popover
            button.brand-btn.brand-btn-select-popover(
              v-for="variant in statistics"
              :class="{ 'brand-btn-select-popover-disable': variant._id === selectedVariantId }"
              type="button"
              @click="changeVariantStat(variant._id)"
              :title="variant.variantName"
            ) {{ variant.variantName }}
          div(slot="reference")
            .brand-select-box.font-weight-bold.cursor-pointer(:title="variantName") {{ variantName }}
              i.fa.fa-angle-down(aria-hidden="true")

  .row.d-flex(v-for="inputId in Object.keys(selectedStat.inputs)")
    template(v-for="type in Object.keys(selectedStat.inputs[inputId].stats)")
      .card.w-100.mt-4
        .card-title.font-weight-bold.mt-4.mb-1.px-4 {{ selectedStat.inputs[inputId].question }} ({{ getSum(inputId, type) }})
        .card-body.feedback-card-body
          .row.h-2.d-flex.font-size-0--875.mb-3.mx-0(v-for="option in getOptions(inputId, type)")
            .progress.feedback-progress.col-8.p-0(
              style="position: relative; background-color: #dfedff"
            )
              .progress-bar.align-items-start.justify-content-center(
                role="progressbar"
                :style="`width: ${Math.round((option.count / getSum(inputId, type)) * 100)}%; background-color: #72AEFF`"
                :aria-valuenow="Math.round((option.count / getSum(inputId, type)) * 100)"
                aria-valuemin="0"
                :aria-valuemax="100"
              )
                .mx-2.text-left.font-weight-bold {{ Math.round((option.count / getSum(inputId, type)) * 100) }} %
              .h-2.d-flex.align-items-center(style="position: absolute; right: 0")
                .d-flex.flex-wrap.align-items-end.p-1.mr-2
                  div(style="color: black; width: 100%") {{ option.count }} {{ $t('feedbackStatistics.answers') }}
            .col-4.px-3.feedback-icon-style(
              v-if="!['radio', 'checkbox'].includes(type)"
              :class="`${type} ${type}-${option.optionId}`"
              :title="$t(`feedbackStatistics.${type}.${option.optionId}`)"
            )
            .col-4.px-3.d-flex.align-items-center.font-size-0--875(v-else) {{ option.value }}
</template>

<script>
  export default {
    props: {
      statistics: { type: Object },
    },
    data() {
      return {
        variantName: '',
        selectedVariantId: null,
        popoverInstance: null,
        selectedStat: { stats: {} },
      };
    },
    created() {
      const firstId = Object.keys(this.statistics)[0];
      this.variantName = this.statistics[firstId].variantName;
      this.selectedVariantId = this.statistics[firstId]._id;
      this.selectedStat = this.statistics[this.selectedVariantId];
    },
    updated() {
      this.variantName = this.statistics[this.selectedVariantId].variantName;
    },
    methods: {
      changeVariantStat(variantId) {
        this.variantName = this.statistics[variantId].variantName;
        this.selectedVariantId = variantId;
        this.selectedStat = this.statistics[this.selectedVariantId];
        this.popoverInstance.doClose();
      },
      getSum(inputId, type) {
        return this.selectedStat.inputs[inputId].stats[type].sum || 1;
      },
      getOptions(inputId, type) {
        if (type === 'stars') {
          return Object.values(this.selectedStat.inputs[inputId].stats[type].options).reverse();
        }
        return Object.values(this.selectedStat.inputs[inputId].stats[type].options);
      },
    },
  };
</script>
