<template lang="pug">
.d-flex.align-items-start
  loading-logo(v-if="loading")
  template(v-else)
    span.settings-label.py-2(:style="{ 'min-width': $i18n.locale === 'en' ? '112px' : '60px' }") {{ $t('frontendRules.subscribers.yourCampaignWill') }}
    om-select#subscriberTypes.ml-2(
      :style="{ 'min-width': '120px' }"
      :options="subscriberTypes"
      size="small"
      @input="setSubscriberType($event)"
      :value="{ key: currentRule.options.type, value: $t('frontendRules.subscribers.types.' + currentRule.options.type) }"
    )
    .col
      template(v-for="(condition, index) in currentRule.options.lists")
        .d-flex
          span.settings-label.py-2.px-2.text-right(
            :style="{ 'min-width': $i18n.locale === 'en' ? '240px' : '230px' }"
          ) {{ index === 0 ? $t('frontendRules.subscribers.label') : $t('frontendRuleLead.subscribers.orTo') }}
          .expression-row
            .lists
              om-select.mb-2.w-20(
                size="small"
                :id="`list-${index}`"
                :value="{ id: currentRule.options.lists[index].id, friendlyName: currentRule.options.lists[index].friendlyName }"
                :options="index === 0 ? listsWithAny : lists"
                :placeholder="$t('frontendRules.subscribers.selectAList')"
                optionKey="id"
                optionText="friendlyName"
                @input="onListChange($event, index)"
              )
              span.settings-label.py-2.px-2(v-if="$i18n.locale === 'hu'") {{ $t('frontendRules.subscribers.toList') }}
              span.delete-icon.brand-link.settings-label.ml-2(
                v-if="index > 0"
                @click="removeExpression('lists', index)"
              )
                fa-icon(variant="fa-trash" size="1.5")
            span.brand-link.settings-label(
              v-if="index === currentRule.options.lists.length - 1"
              @click="addExpression('lists', { id: '', name: '' })"
              :class="{ 'disabled-setting': isAllListSelected }"
            )
              fa-icon(variant="fa-plus" :html="`&nbsp;${$t('frontendRules.subscribers.addList')}`")
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import { mapGetters } from 'vuex';
  import GET_CAMPAIGN_INTEGRATION_LISTS from '@/graphql/GetCampaignIntegrationLists.gql';
  import { getConfigForIntegration } from '@om/integrations';
  import _ from 'lodash';

  export default {
    mixins: [settingsValidation, expressionOperations],
    data() {
      return {
        loading: false,
        lists: [],
        listsWithAny: [],
        subscriberTypes: [
          { key: 'exclude', value: this.$t('frontendRules.subscribers.types.exclude') },
          { key: 'include', value: this.$t('frontendRules.subscribers.types.include') },
        ],
      };
    },
    computed: {
      ...mapGetters(['integrations']),
      isAllListSelected() {
        return this.currentRule.options.lists[0].id === 'all';
      },
    },
    async created() {
      if (this.integrations.length) {
        this.loading = true;
        const {
          data: { lists },
        } = await this.$apollo.query({
          query: GET_CAMPAIGN_INTEGRATION_LISTS,
          variables: {
            integrations: this.integrations,
          },
        });
        this.lists = lists || [];

        // add the ones that have no listId
        this.integrations.forEach((integration) => {
          const config = getConfigForIntegration(integration.type);
          if (!config.listIdField) {
            this.lists.push({
              id: `default_${integration.type}_${integration.data.name}`,
              name: this.$t('frontendRules.subscribers.defaultList'),
              providerId: integration._id,
              type: integration.type,
              listName: integration.data.name,
            });
          }
        });

        // if the list is not found in campaigns anymore, append it to the array of lists
        this.currentRule.options.lists.forEach((list) => {
          if (list.id !== 'all') {
            const storedList = this.lists.find(
              (item) => item.id === list.id && item.type === list.type,
            );
            if (!storedList) this.lists.push(_.clone(list));
          }
        });

        // set user friendlyName
        this.lists.forEach((list) => {
          list.friendlyName = this.setListLabel(list);
        });

        this.listsWithAny = [...this.lists] || [];
        // add any lists index to start
        this.listsWithAny.unshift({
          id: 'all',
          name: this.$t('frontendRules.subscribers.anyList'),
          friendlyName: this.$t('frontendRules.subscribers.anyList'),
        });

        this.loading = false;
      }
    },
    methods: {
      setSubscriberType(event) {
        this.currentRule.options.type = event.key;
      },
      setListLabel(list) {
        return `${list.name} (${list.type.charAt(0).toUpperCase() + list.type.slice(1)}${
          list.listName ? `, ${list.listName}` : ''
        })`;
      },
      onListChange(event, index) {
        if (event.id === 'all') {
          this.currentRule.options.lists = [
            { id: 'all', friendlyName: this.$t('frontendRules.subscribers.anyList') },
          ];
        } else {
          this.currentRule.options.lists.splice(index, 1, event);
        }
      },
      beforeSaveCallback() {
        // remove duplicates and empty ones
        this.currentRule.options.lists = _.uniqBy(this.currentRule.options.lists, 'id').filter(
          (list) => list.id,
        );
      },
    },
  };
</script>

<style lang="sass" scoped>
  .disabled-setting
    color: #D5D8DD
    cursor: default
    pointer-events: none
  .lists
    display: flex
    position: relative
  .to-list
    margin-top: 7px
    margin-left: 12px
  .delete-icon
    position: absolute
    right: -30px
    top: 5px
  .expression-row
    width: 90%
    margin-right: 20px
</style>
