<template lang="pug">
div
  div(v-for="index in rows" :key="index")
    .skeleton-row
      vue-skeleton-loader(type="rect" width="100%" height="24px" :rounded="rounded")
    hr(v-if="bottomLine")
</template>

<script>
  export default {
    props: {
      rows: { type: Number },
      rounded: { type: Boolean },
      bottomLine: { type: Boolean },
    },
  };
</script>

<style lang="sass" scoped>
  hr
    margin: 0px
  .skeleton-row
    padding-top: 15px
    padding-bottom: 15px
</style>
