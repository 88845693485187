<template lang="pug">
.d-flex.align-items-center.settings-label
  div {{ $t('frequency1') }}
  om-input#maximumNumber.w-5.mx-2(
    type="number"
    :min="0"
    :error="$v.currentRule.options.value.$error"
    v-model.number="currentRule.options.value"
  )
  div {{ $t('frequency2') }}
  template(v-if="currentRule.options.value > 1")
    div {{ $t('frequency21') }}
    om-input#atLeastNumber.w-4.mx-2(
      type="number"
      :min="0"
      :error="$v.currentRule.options.time.$error"
      v-model.number="currentRule.options.time"
    )
    om-select#frequencyPeriod.w-8.mr-2(v-model="frequencyPeriod" :options="options")
    div {{ $t('frequency3') }}
  template(v-else)
    div .
</template>

<script>
  import { required, minValue, integer } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';

  export default {
    mixins: [settingsValidation],

    data() {
      return {
        options: [
          { key: 'second', value: this.$t('frsecond') },
          { key: 'minute', value: this.$t('frminute') },
          { key: 'hour', value: this.$t('frhour') },
          { key: 'day', value: this.$t('frday') },
        ],
      };
    },
    computed: {
      frequencyPeriod: {
        get() {
          const frequencyTime = this.currentRule.options.unit;
          return this.findOption(frequencyTime);
        },
        set(v) {
          const frequencyTime = this.findOption(v.key).key;
          this.currentRule.options.unit = frequencyTime;
          return v;
        },
      },
    },

    methods: {
      findOption(key) {
        return this.options.find((x) => x.key === key);
      },
    },

    validations: {
      currentRule: {
        options: {
          value: {
            required,
            minValue: minValue(1),
            integer,
          },
          time: {
            isCool(v) {
              return this.currentRule.options.value === 1 || v >= 1;
            },
          },
        },
      },
    },
  };
</script>
