<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='30', height='25', viewBox='0 0 30 25')
  g(:fill='color', fill-rule='evenodd')
    path(d='M8.973 12.857l-.752-2.8c-.09-.296-.255-.985-.494-2.066H7.68c-.203.976-.364 1.67-.483 2.078l-.773 2.788H5.058l-1.63-6H4.69l.74 2.957c.169.73.288 1.356.358 1.877h.032c.035-.264.088-.567.16-.909.072-.342.134-.598.187-.768l.882-3.157h1.356l.857 3.157c.052.178.118.449.197.814.079.365.125.65.14.852h.041c.053-.445.175-1.067.368-1.866l.752-2.957H12l-1.64 6H8.973zM17.544 7.991h.053c.131.542.242.985.333 1.33a4.38 4.38 0 0 1 .694-.577c.076-.06.154-.117.233-.172l-.513-1.715H16.85l-.973 3.157c-.058.17-.127.426-.206.768-.08.342-.138.645-.177.909h-.034a19.375 19.375 0 0 0-.394-1.877l-.817-2.957h-1.39l1.795 6h1.507l.851-2.788c.132-.409.31-1.102.533-2.078')
    path(d='M12.856 16.802H2.704a.69.69 0 0 1-.678-.682V2.744c0-.37.31-.683.678-.683h23.008V.023H2.704C1.217.023 0 1.247 0 2.743V16.12c0 1.497 1.217 2.721 2.704 2.721h10.152v-2.039z')
    path(d='M22.302 23.342c-3.385 0-6.138-2.715-6.138-6.052 0-3.337 2.753-6.051 6.138-6.051 3.384 0 6.138 2.714 6.138 6.051s-2.754 6.052-6.138 6.052m1.183-13.529V8.42h.645c.425 0 .769-.339.769-.757a.763.763 0 0 0-.769-.758h-3.657a.763.763 0 0 0-.768.758c0 .418.344.757.768.757h.645v1.394c-3.672.563-6.49 3.702-6.49 7.477 0 4.173 3.442 7.567 7.674 7.567 4.231 0 7.674-3.394 7.674-7.567 0-3.775-2.819-6.914-6.491-7.477')
    path(d='M22.893 17.353v-3.709c0-.434-.328-.787-.732-.787-.405 0-.732.353-.732.787v4.164a.5.5 0 0 0 .131.34l2.048 2.194a.696.696 0 0 0 1.036-.002.83.83 0 0 0-.002-1.114l-1.75-1.873z')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
