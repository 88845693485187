import GET_CAMPAIGNS_BY_INTEGRATION_IDS from '@/graphql/GetCampaignsByIntegrationIds.gql';
import { mapGetters } from 'vuex';
import { getIntegrationConfigs } from '@om/integrations';

const IMAGE_SIZE = 25;

export default {
  computed: {
    ...mapGetters(['integrations']),
    isAllReIntegrated() {
      return this.filteredIntegrations
        .map(({ _id }) => {
          return (
            this.isReintegrated(_id) ||
            (this.campaignsByStatus[_id] && this.campaignsByStatus[_id].sum === 0)
          );
        })
        .every((elem) => elem);
    },
    getDeprecatedIntegrations() {
      const integrationConfigs = getIntegrationConfigs();
      const deprecatedIntegrations = [];

      Object.entries(integrationConfigs).forEach(([integrationType, { deprecated }]) => {
        if (deprecated) {
          deprecatedIntegrations.push(integrationType);
        }
      });
      return deprecatedIntegrations;
    },
    savedDeprecatedIntegrations() {
      const deprecatedIntegrations = this.getDeprecatedIntegrations;

      const filtered = deprecatedIntegrations
        .map((deprecatedIntegration) =>
          this.integrations
            .filter(({ type, data }) => type === deprecatedIntegration && !data?.newIntegrationId)
            .map((i) => i.type),
        )
        .filter((i) => i.length)
        .map((i) => [...new Set(i)])
        .flat();

      return filtered;
    },
  },
  methods: {
    async getCampaignsByIntegrationId(integrationIds = []) {
      const {
        data: { campaigns },
      } = await this.$apollo.query({
        query: GET_CAMPAIGNS_BY_INTEGRATION_IDS,
        variables: {
          integrationIds,
        },
      });

      return campaigns;
    },
    isReintegrated(integrationId) {
      const { data } = this.filteredIntegrations.find(({ _id }) => _id === integrationId);
      return data.hasOwnProperty('newIntegrationId');
    },
    navigateToReIntegration(integrationType) {
      return { name: 'integrations', query: { integrationType } };
    },
    hasText(integrationType) {
      return this.$te(`integrations.${integrationType}.reIntegration.alert.title`);
    },
    getAlertTitle(integrationType) {
      return this.$t(`integrations.${integrationType}.reIntegration.alert.title`, {
        imageTag: this.createImageTag(integrationType),
      });
    },
    createImageTag(integrationType) {
      const image = document.createElement('img');
      image.src = this.getIntegrationLogo(integrationType);
      image.style.height = `${IMAGE_SIZE}px`;
      image.style.width = `${IMAGE_SIZE}px`;

      return image.outerHTML;
    },
    getIntegrationLogo(integrationType) {
      return require(`@om/integrations/src/assets/icons/${integrationType}.svg`);
    },
  },
};
