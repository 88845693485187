const daysFromNow = (days) => {
  const currentDate = Date.now();
  const milliseconds = days * 24 * 60 * 60 * 1000;
  return new Date(currentDate - milliseconds).toISOString();
};

const roundToHour = (date) => {
  const msInHour = 60 * 60 * 1000;
  return new Date(Math.floor(date.getTime() / msInHour) * msInHour).toISOString();
};

module.exports = {
  daysFromNow,
  roundToHour,
};
