<template lang="pug">
svg(width='28', height='29', viewBox='0 0 28 29', fill='none', xmlns='http://www.w3.org/2000/svg')
  path(d='M27.9431 13.8544C27.2623 14.2568 26.4836 14.5051 25.6438 14.5051C23.7956 14.5051 22.2134 13.3841 21.4588 11.7728C20.9466 11.9729 20.3968 12.0964 19.8166 12.0964C17.243 12.0964 15.1539 9.9397 15.1539 7.27961C15.1539 6.09223 15.5866 5.01866 16.2776 4.17858C14.9131 3.33822 13.9887 1.81483 13.9887 0.0544434C6.26469 0.0544434 0.00231934 6.52469 0.00231934 14.5051C0.00231934 22.486 6.26469 28.9558 13.9887 28.9558C21.7138 28.9558 27.9751 22.486 27.9751 14.5051C27.9751 14.2857 27.9522 14.0715 27.9431 13.8544ZM13.9887 26.5468C7.56225 26.5468 2.33308 21.1448 2.33308 14.5051C2.33308 8.52491 6.5744 3.54933 12.1107 2.61943C12.3884 3.37463 12.7901 4.07415 13.2988 4.69317C12.987 5.5159 12.8232 6.39352 12.8232 7.27961C12.8232 11.2638 15.9601 14.5051 19.8166 14.5051C20.0163 14.5051 20.2178 14.4957 20.4197 14.4762C21.674 15.9538 23.4585 16.8443 25.4107 16.9097C24.3292 22.4012 19.6186 26.5468 13.9887 26.5468Z', :fill='color')
  path(d='M20.9818 19.9241C20.9818 20.9216 20.1991 21.7303 19.2337 21.7303C18.2679 21.7303 17.4852 20.9216 17.4852 19.9241C17.4852 18.9264 18.2679 18.1177 19.2337 18.1177C20.1991 18.1177 20.9818 18.9264 20.9818 19.9241Z', :fill='color')
  path(d='M13.9887 22.3323C13.9887 23.3298 13.206 24.1385 12.2403 24.1385C11.2749 24.1385 10.4922 23.3298 10.4922 22.3323C10.4922 21.3346 11.2749 20.5259 12.2403 20.5259C13.206 20.5259 13.9887 21.3346 13.9887 22.3323Z', :fill='color')
  path(d='M15.1539 16.3112C15.1539 17.309 14.3712 18.1177 13.4059 18.1177C12.4401 18.1177 11.6574 17.309 11.6574 16.3112C11.6574 15.3138 12.4401 14.5051 13.4059 14.5051C14.3712 14.5051 15.1539 15.3138 15.1539 16.3112Z', :fill='color')
  path(d='M10.4922 9.08613C10.4922 10.0836 9.70924 10.8926 8.74379 10.8926C7.77834 10.8926 6.99536 10.0836 6.99536 9.08613C6.99536 8.08863 7.77834 7.27966 8.74379 7.27966C9.70924 7.27966 10.4922 8.08863 10.4922 9.08613Z', :fill='color')
  path(d='M8.1609 15.1068C8.1609 16.1046 7.3782 16.9133 6.41247 16.9133C5.44709 16.9133 4.66438 16.1046 4.66438 15.1068C4.66438 14.1093 5.44709 13.3007 6.41247 13.3007C7.3782 13.3007 8.1609 14.1093 8.1609 15.1068Z', :fill='color')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
