<template lang="pug">
svg(:width="size" :height="size" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg")
  path(
    d="M5.71002 6.03994C5.61706 5.94621 5.50646 5.87182 5.3846 5.82105C5.26274 5.77028 5.13203 5.74414 5.00002 5.74414C4.86801 5.74414 4.7373 5.77028 4.61544 5.82105C4.49359 5.87182 4.38298 5.94621 4.29002 6.03994L1.29002 9.03994C1.09641 9.22824 0.985539 9.48574 0.981788 9.7558C0.978037 10.0258 1.08172 10.2863 1.27002 10.4799C1.45833 10.6735 1.71583 10.7844 1.98588 10.7882C2.25593 10.7919 2.51641 10.6882 2.71002 10.4999L5.00002 8.15994L7.29002 10.4999C7.38298 10.5937 7.49359 10.6681 7.61544 10.7188C7.7373 10.7696 7.86801 10.7957 8.00002 10.7957C8.13203 10.7957 8.26274 10.7696 8.3846 10.7188C8.50646 10.6681 8.61706 10.5937 8.71002 10.4999C8.80375 10.407 8.87814 10.2964 8.92891 10.1745C8.97968 10.0527 9.00582 9.92195 9.00582 9.78994C9.00582 9.65793 8.97968 9.52722 8.92891 9.40536C8.87814 9.2835 8.80375 9.1729 8.71002 9.07994L5.71002 6.03994ZM2.71002 4.95994L5.00002 2.65994L7.29002 4.95994C7.38298 5.05367 7.49359 5.12806 7.61544 5.17883C7.7373 5.2296 7.86801 5.25574 8.00002 5.25574C8.13203 5.25574 8.26274 5.2296 8.3846 5.17883C8.50646 5.12806 8.61706 5.05367 8.71002 4.95994C8.80375 4.86698 8.87814 4.75637 8.92891 4.63452C8.97968 4.51266 9.00582 4.38195 9.00582 4.24994C9.00582 4.11793 8.97968 3.98722 8.92891 3.86536C8.87814 3.7435 8.80375 3.6329 8.71002 3.53994L5.71002 0.539939C5.61706 0.446211 5.50646 0.371816 5.3846 0.321048C5.26274 0.270279 5.13203 0.244141 5.00002 0.244141C4.86801 0.244141 4.7373 0.270279 4.61544 0.321048C4.49359 0.371816 4.38298 0.446211 4.29002 0.539939L1.29002 3.53994C1.10172 3.72824 0.99593 3.98364 0.99593 4.24994C0.99593 4.51624 1.10172 4.77164 1.29002 4.95994C1.47833 5.14824 1.73372 5.25403 2.00002 5.25403C2.26632 5.25403 2.52172 5.14824 2.71002 4.95994Z"
    :fill="fill"
  )
</template>

<script>
  export default {
    props: {
      size: {
        type: [Number, String],
        default: 18,
      },
      fill: {
        type: String,
        default: '#ED5A29',
      },
    },
  };
</script>
