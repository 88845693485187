<template lang="pug">
om-modal#startPersonalizationModal.start-personalization.centered-modal(
  name="start-personalization"
  :width="1200"
  :scrollable="true"
  :clickToClose="false"
)
  template(slot="modal-before-header")
    .brand-modal-before-header
      om-heading(h3 v-html="$t('experiences.modal.leftSide.beforeTitle')")
      om-body-text(bt400lg v-html="$t('experiences.modal.leftSide.beforeDescription')")
      img(src="@/assets/admin/svg/personalization.svg")
      om-heading(h3 v-html="$t('experiences.modal.leftSide.afterTitle')")
      om-body-text(bt400lg v-html="$t('experiences.modal.leftSide.afterDescription')")
  template(slot="header-title")
    om-heading(h1) {{ $t('experiences.modal.header') }}
  template(slot="modal-body")
    om-body-text(bt400lg v-html="$t('experiences.modal.description', { learnMoreLink })")
    om-body-text.my-4(bt400lg v-html="$t('experiences.modal.videoDescription')")
    .d-flex.align-items-center.justify-content-center
      .video
        iframe(
          width="528"
          height="298"
          :src="videoSrc"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        )
  template(slot="modal-footer")
    .d-flex.align-items-center.justify-content-end
      om-button#skipPersonalizationTour.mr-3(secondary @click="skipTour") {{ $t('experiences.modal.skip') }}
      om-button#startPersonalizationTour(primary @click="startTour") {{ $t('experiences.modal.startTour') }}
</template>
<script>
  import { createNamespacedHelpers } from 'vuex';
  import { steps, driverConfigs, TOUR_NAME } from '@/utils/productTours/experiences';

  const { mapActions: mapProductTourActions, mapMutations: mapProductTourMutations } =
    createNamespacedHelpers('productTour');
  export default {
    data() {
      return {
        learnMoreLink: 'https://support.optimonk.com/hc/en-us/articles/8805751837202',
        videoSrc: 'https://www.youtube.com/embed/bCs8dIeHru8',
      };
    },

    methods: {
      ...mapProductTourActions({
        initProductTour: 'init',
        startProductTour: 'start',
        defineProductTourSteps: 'defineSteps',
        storeProductTourStatus: 'storeStatus',
      }),
      ...mapProductTourMutations({
        setProductTourName: 'setName',
      }),
      skipTour() {
        this.storeProductTourStatus({ status: 'skipped', tour: TOUR_NAME });
        this.$modal.hide('start-personalization');
      },
      async startTour() {
        this.$modal.hide('start-personalization');
        await this.initProductTour({ driverConfigs });
        this.setProductTourName(TOUR_NAME);
        await this.defineProductTourSteps(steps);
        this.startProductTour();
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .start-personalization
    .brand-modal
      display: grid
      grid-template-areas: 'before-header header header' 'before-header body body' 'before-header footer footer'
      grid-template-columns: 33.33% 33.33% 33.33%

    .video
      filter: drop-shadow(0px 16px 40px rgba(0, 0, 0, 0.2)) drop-shadow(0px 0px 64px rgba(0, 0, 0, 0.04))
      border-radius: 4px
    .brand-modal-before-header
      grid-area: before-header
      background: $om-peach-100
      padding: 2.25rem
      display: flex
      align-items: center
      flex-direction: column
      text-align: center
      .body-text
        color: $om-gray-700
      img
        margin-top: auto
        margin-bottom: auto
    .brand-modal-header
      grid-area: header
      padding: 1.555rem 2.25rem 0 2.25rem
    .brand-modal-body
      grid-area: body
      padding: 0 2.25rem 1.715rem 2.25rem
      .body-text
        color: $om-gray-700
    .brand-modal-footer
      grid-area: footer
      padding: 0 2.25rem 2.25rem 2.25rem
      margin-bottom: 0
    @media screen and(max-width: 1200px)
      .v--modal
        width: 90%!important
        margin: 0 5%
      .video
        position: relative
        padding-bottom: 56.25%
        height: 0
        width: 100%
        iframe
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
</style>
