export default {
  props: {
    about: { type: String },
    type: { type: String },
  },
  data: () => ({ modalName: null }),
  methods: {
    onCloseClick() {
      this.$emit('track', { name: `${this.modalName}-close`, properties: { value: this.about } });
      this.$modal.hide(this.modalName);
      this.$emit('deselect');
    },
    getCommonTranslationPathFor(key) {
      return `newCampaignFlow.websiteOptimization.common.${key}`;
    },
    getTranslationPathFor(key) {
      return `newCampaignFlow.websiteOptimization.${this.type}.${this.about}.${key}`;
    },
    getModalTranslationPathFor(key) {
      return this.getTranslationPathFor(`modal.${key}`);
    },
  },
};
