import INSERT_CAMPAIGN_SHARE from '@/graphql/ShareCampaign.gql';

export default {
  methods: {
    async _addToClipboard(token) {
      if (token) {
        const resolved = this.$router.resolve({ name: 'campaign_share', params: { token } });
        if (resolved?.href) {
          const link = `${window.location.protocol}//${window.location.hostname}${resolved.href}`;
          await this.$copyText(link);
        }
      }
    },
    async _shareCampaign(campaign) {
      const { data: { token = null } = {} } = await this.$apollo.mutate({
        mutation: INSERT_CAMPAIGN_SHARE,
        variables: { campaign },
      });

      await this._addToClipboard(token);

      const type = token ? 'success' : 'error';
      const text = this.$t(`notifications.campaignShare.${type}`);

      this.$notify({ type, text });
    },
  },
};
