<template lang="pug">
svg(width='50', height='50', viewBox='0 0 50 50', fill='none', xmlns='http://www.w3.org/2000/svg')
  path(d='M41.5335 19.4C39.1329 11.6818 31.9347 6.06054 23.4384 6.06054C12.9842 6.06054 4.47116 14.5639 4.47116 25.0279C4.47116 35.4821 12.9842 43.9952 23.4384 43.9952C30.1878 43.9952 36.4741 40.3694 39.8638 34.5164C40.445 33.5115 41.7356 33.1667 42.7407 33.7479C43.7459 34.3294 44.0907 35.62 43.5092 36.6252C39.3711 43.7685 31.6756 48.2125 23.4287 48.2125C10.6393 48.2125 0.244202 37.8076 0.244202 25.0279C0.244202 12.2481 10.649 1.84326 23.4287 1.84326C34.0396 1.84326 43.0032 9.01116 45.7435 18.7633L49.894 18.1354L44.7798 26.7133L37.3577 20.0315L41.5335 19.4ZM23.4287 7.11479C22.266 7.11479 21.3199 8.06055 21.3199 9.22328V22.003C20.3641 22.673 19.7435 23.7767 19.7435 25.0179C19.7435 27.0479 21.399 28.703 23.4287 28.703C23.9902 28.703 24.5123 28.5651 24.9853 28.3485L32.3162 32.5755C32.6511 32.7724 33.0059 32.8612 33.3705 32.8612C34.0996 32.8612 34.8089 32.4867 35.1932 31.807C35.7747 30.8018 35.4299 29.5112 34.4247 28.9297L27.0841 24.6927C26.9856 23.5794 26.4041 22.6039 25.5372 21.993V9.22328C25.5372 8.06055 24.5914 7.11479 23.4287 7.11479ZM23.4287 26.6042C22.5614 26.6042 21.852 25.8948 21.852 25.0279C21.852 24.1609 22.5614 23.4512 23.4287 23.4512C24.2956 23.4512 25.005 24.1609 25.005 25.0279C25.005 25.8948 24.2956 26.6042 23.4287 26.6042Z', :fill="color")
</template>

<script>
  import eventSvg from '@/mixins/eventSvg';

  export default {
    mixins: [eventSvg],
  };
</script>
