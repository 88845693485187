<template lang="pug">
svg(
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 580 430"
  ref="svg"
  :class="{ animating: animationActive }"
)
  g#browser
    path(
      d="M8,0H572a8,8,0,0,1,8,8V30a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V8A8,8,0,0,1,8,0Z"
      fill="#dfe6ed"
    )
    path(
      d="M0,30H580a0,0,0,0,1,0,0V422a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V30a0,0,0,0,1,0,0Z"
      fill="#f2f5f7"
    )
    circle(cx="19.9" cy="15" r="5" fill="#f44e4e")
    circle(cx="35.9" cy="15" r="5" fill="#ffce00")
    circle(cx="51.8" cy="15" r="5" fill="#6fc144")
  g#page
    rect(x="24.9" y="46.1" width="50" height="17" rx="8.4" ry="8.4" fill="#a9b2b7")
    rect(x="397.9" y="50.6" width="160" height="8" rx="4" ry="4" fill="#a9b2b7")
    rect(x="66" y="367.4" width="448" height="20" rx="10" ry="10" fill="#a9b2b7")
    rect(x="66" y="394.3" width="448" height="20" rx="10" ry="10" fill="#a9b2b7")
    rect(y="76.7" width="580" height="160" fill="#a9b2b7")
    rect(x="65.6" y="253.5" width="140" height="100" rx="8" ry="8" fill="#a9b2b7")
    rect(x="220" y="253.5" width="140" height="100" rx="8" ry="8" fill="#a9b2b7")
    rect(x="374.4" y="253.5" width="140" height="100" rx="8" ry="8" fill="#a9b2b7")
  g#dark
    path(
      d="M0,30H580a0,0,0,0,1,0,0V422a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V30a0,0,0,0,1,0,0Z"
      fill="#283035"
      opacity="0.7"
    )
  g#popup
    rect#popup-bg(x="89.1" y="96" width="400" height="260" rx="8" ry="8" fill="#fff")
    g(opacity="0.7")
      path(
        d="M268.1,150.5H261l-3.9-15.4c-.2-.6-.4-1.7-.8-3.4a19,19,0,0,1-.6-3.5c-.1.8-.3,1.9-.6,3.5s-.6,2.7-.8,3.4l-3.9,15.4h-7l-7.5-29.3H242l3.7,16c.7,2.9,1.2,5.5,1.5,7.6a23.6,23.6,0,0,1,.5-3.5c.3-1.6.6-2.8.8-3.7l4.3-16.4h5.9l4.2,16.4c.2.7.4,1.8.7,3.4s.5,2.8.7,3.8q.1-1.5.6-3.9c.3-1.5.6-2.8.8-3.7l3.7-16h6.1Z"
        fill="#000222"
      )
      path(
        d="M292.9,150.5l-1.1-3.1h-.2a8.7,8.7,0,0,1-3.2,2.7,10.1,10.1,0,0,1-4.2.8,6.9,6.9,0,0,1-5.1-1.9,7.2,7.2,0,0,1-1.9-5.2,6.1,6.1,0,0,1,2.5-5.3c1.7-1.1,4.2-1.7,7.6-1.9h3.8v-1c0-2.2-1.1-3.4-3.4-3.4a15.7,15.7,0,0,0-6.3,1.7l-2.1-4.2a19.1,19.1,0,0,1,8.6-2,10.7,10.7,0,0,1,6.9,2,7.2,7.2,0,0,1,2.4,5.9v15Zm-1.8-10.4h-2.3a7.2,7.2,0,0,0-4,.9,3,3,0,0,0-1.3,2.7c0,1.7,1,2.6,3,2.6a4.9,4.9,0,0,0,3.4-1.2,4.5,4.5,0,0,0,1.2-3.3Z"
        fill="#000222"
      )
      path(
        d="M303.2,122.3c0-2,1.1-3,3.4-3s3.3,1,3.3,3a3.1,3.1,0,0,1-.8,2.2,3.7,3.7,0,0,1-2.5.8C304.3,125.3,303.2,124.3,303.2,122.3Zm6.4,28.2h-6.1V128.1h6.1Z"
        fill="#000222"
      )
      path(
        d="M325.4,146a12.4,12.4,0,0,0,3.9-.7v4.5a13.3,13.3,0,0,1-5.6,1.1,7.2,7.2,0,0,1-5.4-1.9c-1.1-1.2-1.6-3.1-1.6-5.5V132.7h-3v-2.6l3.4-2.1,1.8-4.7h3.9v4.8H329v4.6h-6.2v10.8a2.4,2.4,0,0,0,.7,1.9A2.9,2.9,0,0,0,325.4,146Z"
        fill="#000222"
      )
      path(
        d="M332.9,147.6a3.2,3.2,0,0,1,.9-2.5,3.7,3.7,0,0,1,2.7-.9,3.3,3.3,0,0,1,2.5.9,3.6,3.6,0,0,1,0,5,3.3,3.3,0,0,1-2.5.9,3.4,3.4,0,0,1-2.6-.9A3.4,3.4,0,0,1,332.9,147.6Zm6-6.8H334l-1-19.6h7Z"
        fill="#000222"
      )
    rect(x="130" y="169.3" width="320" height="11" rx="5.5" ry="5.5" fill="#a9b2b7")
    rect(x="130" y="187.9" width="320" height="11" rx="5.5" ry="5.5" fill="#a9b2b7")
    rect(
      x="131.4"
      y="235.8"
      width="150"
      height="30"
      rx="5.5"
      ry="5.5"
      fill="none"
      stroke="#a9b2b7"
      stroke-miterlimit="10"
      stroke-width="2"
    )
    rect(
      x="300"
      y="235.8"
      width="150"
      height="30"
      rx="5.5"
      ry="5.5"
      fill="none"
      stroke="#a9b2b7"
      stroke-miterlimit="10"
      stroke-width="2"
    )
    rect(x="210" y="283.5" width="160" height="40" rx="5.5" ry="5.5" fill="#ed5a29")
    g
      path(
        d="M238.7,308.5a4.4,4.4,0,0,1-1.7,3.8,7.2,7.2,0,0,1-4.8,1.4,11.9,11.9,0,0,1-5.1-1v-3.6a13.8,13.8,0,0,0,3.1,1.2l2.3.3a2.9,2.9,0,0,0,1.9-.5,1.7,1.7,0,0,0,.6-1.4,1.6,1.6,0,0,0-.3-.9,2,2,0,0,0-.8-.8l-2.3-1.2a10.9,10.9,0,0,1-2.5-1.5,6.5,6.5,0,0,1-1.3-1.6,5.4,5.4,0,0,1-.5-2.2,4.6,4.6,0,0,1,1.6-3.8,6.9,6.9,0,0,1,4.5-1.3,10.9,10.9,0,0,1,2.6.3,17.1,17.1,0,0,1,2.7.9l-1.3,3a10.6,10.6,0,0,0-2.3-.8l-1.9-.3a2.5,2.5,0,0,0-1.6.5,1.8,1.8,0,0,0-.6,1.3,2,2,0,0,0,.2.9,1.9,1.9,0,0,0,.8.7,10.8,10.8,0,0,0,2.4,1.3,9,9,0,0,1,3.4,2.4A4.8,4.8,0,0,1,238.7,308.5Z"
        fill="#fff"
      )
      path(
        d="M251.4,313.5l-.6-1.8h-.1a3.5,3.5,0,0,1-1.7,1.5,5.3,5.3,0,0,1-2.5.5,4.8,4.8,0,0,1-3.7-1.3,5,5,0,0,1-1.2-3.7v-8.9h3.7v8a3.4,3.4,0,0,0,.6,2.2,1.8,1.8,0,0,0,1.6.8,2.6,2.6,0,0,0,2.3-1.1,5.9,5.9,0,0,0,.7-3.4v-6.5h3.7v13.7Z"
        fill="#fff"
      )
      path(
        d="M265.7,299.6a4.4,4.4,0,0,1,3.8,1.9,8.6,8.6,0,0,1,1.4,5.1,8.4,8.4,0,0,1-1.5,5.3,4.5,4.5,0,0,1-3.8,1.8,4.4,4.4,0,0,1-3.8-1.7h-.2l-.6,1.5h-2.9v-19h3.7v4.4c0,.6,0,1.5-.1,2.7h.1A4.5,4.5,0,0,1,265.7,299.6Zm-1.2,3a2.2,2.2,0,0,0-2,.8,6,6,0,0,0-.7,2.8v.4a6.3,6.3,0,0,0,.7,3.2,2.3,2.3,0,0,0,2.1.9,2.1,2.1,0,0,0,1.8-1,6.5,6.5,0,0,0,.7-3.1,5.8,5.8,0,0,0-.7-3A2.2,2.2,0,0,0,264.5,302.6Z"
        fill="#fff"
      )
      path(
        d="M283.5,309.4a3.7,3.7,0,0,1-1.5,3.2c-1,.8-2.4,1.1-4.4,1.1a9.4,9.4,0,0,1-2.5-.2,6.6,6.6,0,0,1-2-.6v-3a13.5,13.5,0,0,0,2.4.8l2.3.3c1.3,0,2-.4,2-1.2a.9.9,0,0,0-.3-.7l-.9-.6-1.8-.8a15.4,15.4,0,0,1-2.3-1.2,4.5,4.5,0,0,1-1.1-1.3,4.8,4.8,0,0,1-.3-1.8,3.2,3.2,0,0,1,1.4-2.8,6.8,6.8,0,0,1,4-1,10,10,0,0,1,4.8,1.1l-1.1,2.6-1.9-.7a5.8,5.8,0,0,0-1.9-.3c-1,0-1.6.3-1.6.9a1.1,1.1,0,0,0,.5.9l2.4,1.1a9.6,9.6,0,0,1,2.3,1.2,3.3,3.3,0,0,1,1.1,1.3A4.7,4.7,0,0,1,283.5,309.4Z"
        fill="#fff"
      )
      path(
        d="M291.9,313.7c-4.2,0-6.4-2.3-6.4-7a7.4,7.4,0,0,1,1.8-5.3,6.4,6.4,0,0,1,4.9-1.8,10,10,0,0,1,4.3.9l-1.1,2.9-1.7-.6-1.5-.2c-1.9,0-2.9,1.4-2.9,4.1s1,4,2.9,4a6.4,6.4,0,0,0,2-.3l1.9-.9v3.2a6,6,0,0,1-1.9.8A7.9,7.9,0,0,1,291.9,313.7Z"
        fill="#fff"
      )
      path(
        d="M306.8,299.6h1.3l-.3,3.5h-1.1a4.1,4.1,0,0,0-2.8.9,3.4,3.4,0,0,0-1,2.5v7h-3.7V299.8H302l.6,2.3h.2a5.8,5.8,0,0,1,1.7-1.8A3.9,3.9,0,0,1,306.8,299.6Z"
        fill="#fff"
      )
      path(
        d="M310.4,296.3c0-1.2.7-1.8,2-1.8s2.1.6,2.1,1.8a2.2,2.2,0,0,1-.5,1.4,2.5,2.5,0,0,1-1.6.4C311.1,298.1,310.4,297.5,310.4,296.3Zm3.9,17.2h-3.7V299.8h3.7Z"
        fill="#fff"
      )
      path(
        d="M325.8,299.6a4.4,4.4,0,0,1,3.8,1.9,8.6,8.6,0,0,1,1.3,5.1,8.4,8.4,0,0,1-1.4,5.3,4.5,4.5,0,0,1-3.8,1.8,4.4,4.4,0,0,1-3.8-1.7h-.2l-.7,1.5h-2.8v-19h3.7v4.4c0,.6,0,1.5-.1,2.7h.1A4.5,4.5,0,0,1,325.8,299.6Zm-1.2,3a2.2,2.2,0,0,0-2,.8,5,5,0,0,0-.7,2.8v.4a5.4,5.4,0,0,0,.7,3.2,2.3,2.3,0,0,0,2,.9,2.2,2.2,0,0,0,1.9-1,6.6,6.6,0,0,0,.6-3.1,5.9,5.9,0,0,0-.6-3A2.2,2.2,0,0,0,324.6,302.6Z"
        fill="#fff"
      )
      path(
        d="M340.2,313.7a7.3,7.3,0,0,1-5.2-1.8,6.9,6.9,0,0,1-1.8-5.1,7.7,7.7,0,0,1,1.7-5.3,5.8,5.8,0,0,1,4.7-1.9,5.8,5.8,0,0,1,4.5,1.6,6.4,6.4,0,0,1,1.7,4.6v1.8H337a3.1,3.1,0,0,0,3.4,3.4,8.5,8.5,0,0,0,2.3-.3,12.7,12.7,0,0,0,2.3-.8v2.9l-2.1.7A10.6,10.6,0,0,1,340.2,313.7Zm-.5-11.5a2.6,2.6,0,0,0-1.9.8,4,4,0,0,0-.8,2.1h5.3a2.9,2.9,0,0,0-.8-2.1A2.2,2.2,0,0,0,339.7,302.2Z"
        fill="#fff"
      )
      path(
        d="M348.3,311.7a1.8,1.8,0,0,1,.5-1.5,2.1,2.1,0,0,1,1.6-.5,2.5,2.5,0,0,1,1.6.5,2.5,2.5,0,0,1,.5,1.5,2.3,2.3,0,0,1-3.7,1.6A2.1,2.1,0,0,1,348.3,311.7Zm3.6-4.1h-3l-.6-12h4.2Z"
        fill="#fff"
      )
  g#cursor
    polygon(
      points="351.8 345.3 322.3 315.3 322.3 357.4 330.9 349.9 337.4 365.9 347 362 340.5 346 351.8 345.3"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="3"
    )
</template>

<script>
  export default {
    props: {
      animationActive: { type: Boolean },
    },
  };
</script>

<style lang="sass" scoped>
  #dark
    opacity: 0
  #popup
    transform: translateY(-150%)
  .animating
    #cursor
      animation: click-cursor-in-animation 1s, click-cursor-first-click-animation 0.3s 1s, click-cursor-out-animation 1s 1.3s, click-cursor-second-click-animation 0.3s 2.3s
      transform-origin: 55% 80%
    #dark
      animation: click-dark-animation 0.1s 2.5s forwards
    #popup
      animation: click-popup-animation 0.3s 2.5s forwards
      animation-timing-function: cubic-bezier(.17,.67,.53,1.2)

  @keyframes click-cursor-in-animation
    from
      transform: translate(0, 0)
    to
      transform: translate(-282px, -260px)
  @keyframes click-cursor-first-click-animation
    from
      transform: translate(-282px, -260px) scale(1)
    50%
      transform: translate(-282px, -260px) scale(0.4)
    to
      transform: translate(-282px, -260px) scale(1)
  @keyframes click-cursor-out-animation
    from
      transform: translate(-282px, -260px)
    to
      transform: translate(0, 0)
  @keyframes click-cursor-second-click-animation
    from
      transform: scale(1)
    50%
      transform: scale(0.4)
    to
      transform: scale(1)
  @keyframes click-dark-animation
    from
      opacity: 0
    to
      opacity: 1
  @keyframes click-popup-animation
    from
      transform: translateY(-150%)
    to
      transform: translateY(0%)
</style>
