<template lang="pug">
.col-6.col-sm-4.col-md-3.col-xl
  .ecommerce-product(
    :class="{ 'shopify-product-variants': !singleVariant, 'active-product': selected }"
  )
    .ecommerce-product-top
      .ecommerce-product-image.d-flex.justify-content-center.align-items-center
        img.om-image(:src="imageSrc")
      .ecommerce-product-name.font-weight-bold(v-html="product ? product.title : ''")
      .ecommerce-product-variant-name.mb-2(
        v-if="isVariant || product.selectedVariantId"
        v-html="getProp('title')"
      )
      .ecommerce-product-content(:class="{ 'mb-4': selected && isInEditor }")
        .ecommerce-product-sku(v-if="canShowDetail && getProp('sku')")
          span.mr-1 {{ $t('productTexts.title.sku') }}:
          span.ecommerce-product-sku-ellipsis(
            :class="$i18n.locale === 'en' ? 'ecommerce-product-sku-ellipsis-en' : 'ecommerce-product-sku-ellipsis-hu'"
          ) {{ getProp('sku') }}
        .ecommerce-product-price-wrap(v-if="productType === 'shopify'")
          .ecommerce-product-price.ecommerce-product-original-price(
            v-if="canShowDetail && getProp('salePrice')"
            v-html="getMoney(getProp('salePrice'))"
          )
          .ecommerce-product-price(
            v-if="canShowDetail && getProp('price')"
            v-html="getMoney(getProp('price'))"
          )
        .ecommerce-product-price-wrap.mb-1(v-else)
          .ecommerce-product-price.ecommerce-product-original-price(
            v-if="product.originalPrice"
            v-html="getMoney(product.originalPrice)"
          )
          .ecommerce-product-price(v-html="getMoney(product.price)")
    .ecommerce-product-footer.d-flex.align-items-center.justify-content-center.align-self-end.mt-2.brand-link.stretched-link(
      v-if="!selected"
      @click="handleSelection"
    )
      add-svg.mr-2(:width="20" :height="20")
      span.font-size-0--875 {{ $t(select) }}
    .ecommerce-product-footer.d-flex.align-items-center.justify-content-center.align-self-end.mt-2.brand-link.stretched-link(
      v-if="selected"
      @click="handleRemove"
    )
      i.mr-2.far.fa-times-circle.font-size-1--25
      span.font-size-0--875 {{ $t('productCatalog.remove') }}
    .ecommerce-product-footer.d-flex.align-items-center.justify-content-center.align-self-end.mt-2.brand-link.stretched-link(
      v-if="selected && product.selectedVariantId && product.variants.length > 1"
      @click="handleChange"
    )
      i.mr-2.far.fa-times-circle
      span.font-weight-bold.font-size-0--875 {{ $t('productCatalog.changeVariant') }}
</template>

<script>
  import { stripTags } from '@/util';
  import AddSvg from '@/components/Svg/Icons/Add';

  const noImage = 'https://cdn-content.optimonk.com/shared-assets/om_picture.svg';
  export default {
    components: {
      AddSvg,
    },
    props: {
      product: { type: Object, required: true },
      moneyFormat: { type: String, default: '{{amount}}' },
      selected: { type: Boolean, default: false },
      isInEditor: { type: Boolean, default: false },
      productType: { type: String, required: true },
      isVariant: { type: Boolean, required: true },
    },
    data() {
      return {
        noImage,
      };
    },
    computed: {
      firstImageSrc() {
        return this.product.images.length ? this.product.images[0].src : this.noImage;
      },
      imageSrc() {
        if (this.productType === 'shoprenter') {
          return this.product.imgUrl;
        }
        if (!this.singleVariant) {
          if (this.product.selectedVariantId) {
            const selectedVariant = this.product.variants.find(
              (variant) => variant.id === this.product.selectedVariantId,
            );
            if (selectedVariant.image && selectedVariant.image.src) {
              return selectedVariant.image.src;
            }
          }
          if (this.product.images.length) {
            return this.product.images[0].src;
          }
        }
        if (!this.product.images.length) return this.noImage;
        return this.product.variants[0].image
          ? this.product.variants[0].image.src
          : this.product.images[0].src;
      },
      singleVariant() {
        return (
          this.productType === 'shoprenter' ||
          (this.product.variants && this.product.variants.length === 1)
        );
      },
      canShowDetail() {
        return (
          this.productType === 'shoprenter' || this.singleVariant || this.product.selectedVariantId
        );
      },
      select() {
        if (this.isInEditor && !this.singleVariant && this.productType === 'shopify') {
          return 'productCatalog.selectVariant';
        }
        return 'productCatalog.select';
      },
    },
    methods: {
      handleChange() {
        this.$emit('chooseProduct', { product: this.product });
      },
      handleSelection() {
        const product = this.product;
        if (this.singleVariant) {
          this.$emit('select', { product });
        } else {
          this.$emit('chooseProduct', { product });
        }
      },
      handleRemove() {
        this.$emit('removeProduct', this.product.id);
      },
      getProp(prop) {
        if (this.productType === 'shoprenter') {
          return this.product[prop];
        }
        let variantIndex = 0;
        if (this.product.selectedVariantId) {
          variantIndex = this.product.variants.findIndex(
            (variant) => variant.id === this.product.selectedVariantId,
          );
        }

        const result =
          this.product && this.product.variants && this.product.variants[variantIndex]
            ? this.product.variants[variantIndex][prop]
            : '';
        if (result === 'Default Title') {
          return '';
        }
        return result;
      },
      getMoney(money) {
        return stripTags(this.moneyFormat.replace(/{{.*amount.*}}/gi, money));
      },
    },
  };
</script>
<style lang="sass" scoped>
  .ecommerce-product-image
    min-width: 100%
  .brand-link:hover
    text-decoration: none
    span
      text-decoration: underline
  .brand-link i
    font-size: 1.25rem
  .ecommerce-product-sku-ellipsis
    overflow: hidden
    text-overflow: ellipsis
    max-width: 90px
    white-space: nowrap
    display: inline-block
    vertical-align: text-bottom
    @media screen and (min-width: 768px) and (max-width: 1199px)
      max-width: 100px
    @media screen and (min-width: 575px) and (max-width: 768px)
      max-width: 12vw
    @media screen and (min-width: 1400px)
      max-width: 123px
    @media screen and (max-width: 575px)
      max-width: 20.125vw
    &-hu
      max-width: 60px
      @media screen and (min-width: 768px) and (max-width: 1199px)
        max-width: 73px
      @media screen and (min-width: 575px) and (max-width: 768px)
        max-width: 8vw
      @media screen and (min-width: 1400px)
        max-width: 95px
      @media screen and (max-width: 575px)
        max-width: 18.125vw
</style>
