<template lang="pug">
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 1.38462C4.76697 1.38462 1.38462 4.76697 1.38462 9C1.38462 13.233 4.76697 16.6154 9 16.6154C13.233 16.6154 16.6154 13.233 16.6154 9C16.6154 4.76697 13.233 1.38462 9 1.38462ZM0 9C0 4.00226 4.00226 0 9 0C13.9977 0 18 4.00226 18 9C18 13.9977 13.9977 18 9 18C4.00226 18 0 13.9977 0 9ZM13.1818 6.66431C13.4522 6.93467 13.4522 7.37302 13.1818 7.64338L8.56646 12.2588C8.2961 12.5291 7.85775 12.5291 7.58739 12.2588L4.81816 9.48954C4.54779 9.21917 4.54779 8.78083 4.81816 8.51046C5.08852 8.2401 5.52686 8.2401 5.79723 8.51046L8.07692 10.7902L12.2028 6.66431C12.4731 6.39395 12.9115 6.39395 13.1818 6.66431Z" :fill="color"/>
  </svg>
</template>

<script>
  export default {
    props: {
      color: { type: String, default: '#828282' },
    },
  };
</script>
