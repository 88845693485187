import { mapGetters } from 'vuex';
import { createDCCampaignFromCache } from '@/utils/createDCCampaign';

export default {
  computed: {
    ...mapGetters(['databaseId', 'hasSmartNewCampaignFeature', 'hasSmartAbTestFeature']),
    routeCampaignCreate() {
      return this.hasSmartNewCampaignFeature
        ? { name: 'new_campaign_flow' }
        : { name: 'templates' };
    },
  },
  methods: {
    async createOrInsertCodeRedirect({
      userId,
      domain = null,
      hasRequestDate = false,
      needCacheChanges = false,
      redirectPath = '', // always starts with "/"
    }) {
      if (
        userId === this.databaseId &&
        domain &&
        (hasRequestDate || domain.platform === 'shopify')
      ) {
        const dcURL = await createDCCampaignFromCache({
          url: this.$route.query.url,
          userId: this.$route.params.userId,
          domain: this.url.domain,
          lastEditUrl: redirectPath,
          needCacheChanges,
        });
        window.location.href = dcURL;
      } else {
        this.$router.push({
          name: 'code-insert-domain',
          query: { create: 'sab', url: this.$route.query.url, domain: this.url.domain },
        });
      }
    },
  },
};
