<template lang="pug">
om-modal(
  name="old-campaign-preview"
  modalClasses="template-preview-modal template-preview-modal-old"
  width="100%"
  height="100%"
  color="light"
  @beforeOpen="beforeOpen"
  @beforeClose="beforeClose"
  @opened="opened"
)
  template(slot="modal-header")
    .row.align-items-center.w-100.om-editor-page-controller-holder
      .col
      .col.popup.d-flex.justify-content-center
        .om-editor-page-controller.slide-indicator(
          v-for="i in pages.length"
          :key="i"
          :class="{ selected: i - 1 === selectedPageIndex }"
          @click="selectPage(i - 1)"
        )
          .om-editor-page-controller-title
            span {{ i }}
          i.fas.fa-chevron-right(v-if="i < pages.length")
      .col.d-flex.justify-content-end
        .col-8.d-flex.justify-content-end
          .template-actions
        .col-1.d-flex
          .d-flex.justify-content-end.align-items-center.cursor-pointer.px-2(
            @click="$modal.hide('old-campaign-preview')"
          )
            close-icon(:width="12" :height="12" color="white")
  template(slot="modal-body")
    .template-prev-holder
      .template-prev
        loading-logo.loading-logo(ref="loadingLogo")
        iframe#preview-iframe(:style="overlay")
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import runtimeConfig from '@/config/runtime';
  import GET_OLD_VARIANT_SETTINGS from '@/graphql/GetOldVariantSettings.gql';

  const cdnUrl = runtimeConfig.VUE_APP_CDN_URL;

  const initialData = () => {
    return {
      variantId: null,

      iframedoc: null,

      html: '',
      pages: [],

      selectedPageIndex: 0,

      submitButtons: [],
      redirectButtons: [],

      settings: {},
    };
  };

  export default {
    data() {
      return initialData();
    },

    computed: {
      ...mapGetters(['databaseId']),
      contentUrl() {
        return `${cdnUrl}/public/${this.databaseId}/${this.variantId}/content.html`;
      },
      overlay() {
        return this.settings
          ? `background: rgba(${this.settings.overlayColor}, ${
              this.settings.overlayOpacity || 0.75
            })`
          : '#365459';
      },
    },

    methods: {
      async beforeOpen(event) {
        Object.assign(this.$data, initialData());

        this.campaignId = this.$route.params.id;
        this.variantId = event.params.variantId;

        await this.fetchSettings();
        await this.buildHTML();

        this.initIframe();
      },

      opened() {
        this.$nextTick(() => {
          document.querySelector('html').style.overflowY = null;
        });
      },

      beforeClose() {
        this.redirectButtons.forEach((b) => {
          b.removeEventListener('click', this.showRedirectTooltip);
        });

        this.submitButtons.forEach((s) => s.removeEventListener('click', this.preventButtonSubmit));

        const tooltipClose = this.iframedoc.querySelector('.OM-redirect-tooltip-close');

        if (tooltipClose) {
          tooltipClose.removeEventListener('click', this.hideRedirectTooltip);
        }
      },

      async fetchSettings() {
        const {
          data: { settings },
        } = await this.$apollo.query({
          query: GET_OLD_VARIANT_SETTINGS,
          variables: {
            campaignId: parseInt(this.campaignId, 10),
            variantId: this.variantId,
          },
        });

        this.settings = settings || {};
      },

      async buildHTML() {
        let { data: html } = await axios.get(this.contentUrl);

        html = this.injectScaffoldIfNeeded(html);
        html = this.injectTooltipStyle(html);

        this.html = html;
      },

      injectScaffoldIfNeeded(html) {
        if (!html.includes('<html>')) {
          html = `<html>
          <head></head>
          <body>
            ${html}
          </body>
        </html>`;
        }

        return html;
      },

      injectTooltipStyle(html) {
        const tooltipStyle = `
        <style>
          body {
            overflow: hidden;
          }

          .OM-redirect-tooltip {
            background-color: #555;
            color: #fff;
            text-align: center;
            padding: 5px 15px;
            padding-right: 30px;
            border-radius: 6px;
            height: auto;
            width: auto;
            position: absolute;
            top: -46%;
            z-index: 9999999999999;
          }

          .OM-redirect-tooltip-text {
            height: auto;
            width: auto;
            white-space: nowrap;
          }

          .OM-redirect-tooltip-close {
            position: absolute;
            right: 9px;
            cursor: pointer;
          }

          .OM-redirect-tooltip-close:hover {
            opacity: 0.8;
          }

          .OM-redirect-tooltip::after {
            content: "";
            position: absolute;
            top: 99%;
            left: 50%;
            margin-left: -8px;
            border-width: 8px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
          }
        </style>
      `;

        const index = html.indexOf('<head>');

        return html.slice(0, index + 6) + tooltipStyle + html.slice(index);
      },

      initIframe() {
        const iframe = document.getElementById('preview-iframe');
        const iframedoc = iframe.contentDocument || iframe.contentWindow.document;

        this.iframedoc = iframedoc;

        iframedoc.body.innerHTML = this.html;

        this.pages = Array.from(iframedoc.querySelectorAll('.OM-box-container'));

        this.preventButtonSubmits();
        this.createRedirectTooltip();
        this.addRedirectButtonTooltips();
        this.addPopupCloseToPages();
        this.selectPage(0);

        setTimeout(() => {
          this.$refs.loadingLogo.$el.style.visibility = 'hidden';
          iframe.style.visibility = 'visible';
        }, 300);
      },

      addRedirectButtonTooltips() {
        this.redirectButtons = Array.from(
          this.iframedoc.querySelectorAll(`[data-action='redirect']`),
        );
        this.redirectButtons.forEach((b) => {
          b.addEventListener('click', this.showRedirectTooltip);
        });
      },

      preventButtonSubmits() {
        this.submitButtons = Array.from(this.iframedoc.querySelectorAll('input[type=submit]'));
        this.submitButtons.forEach((s) => s.addEventListener('click', this.preventButtonSubmit));
      },

      preventButtonSubmit(e) {
        e.preventDefault();
      },

      createRedirectTooltip() {
        const tooltip = this.iframedoc.createElement('div');
        tooltip.style.left = 999999;
        tooltip.style.bottom = 999999;
        tooltip.classList.add('OM-redirect-tooltip');
        const tooltipText = this.iframedoc.createElement('span');
        tooltipText.classList.add('OM-redirect-tooltip-text');
        const tooltipClose = this.iframedoc.createElement('span');
        tooltipClose.classList.add('OM-redirect-tooltip-close');
        tooltipClose.innerHTML = '&times;';
        tooltip.appendChild(tooltipText);
        tooltip.appendChild(tooltipClose);
        const body = this.iframedoc.querySelector('body');
        body.style.position = 'relative';
        body.appendChild(tooltip);
        tooltipClose.addEventListener('click', this.hideRedirectTooltip);
      },

      showRedirectTooltip(event) {
        const element = event.currentTarget;
        const redirectUrl = element.getAttribute('data-redirecturl');

        const { x, y, width } = element.getBoundingClientRect();
        const tooltipText = this.iframedoc.querySelector('.OM-redirect-tooltip-text');

        if (tooltipText) {
          const tooltipHolder = tooltipText.parentNode;

          tooltipText.innerHTML = redirectUrl;
          tooltipHolder.style.bottom = null;
          tooltipHolder.style.top = y - 36;
          tooltipHolder.style.left = x - tooltipHolder.clientWidth / 2 + width / 2;
        }
      },

      hideRedirectTooltip() {
        const tooltipElement = this.iframedoc.querySelector('.OM-redirect-tooltip');

        if (tooltipElement) {
          tooltipElement.style.left = 999999;
          tooltipElement.style.bottom = 999999;
        }
      },

      addPopupCloseToPages() {
        const closeElement = this.iframedoc.querySelector('.OM-popup-close');
        if (closeElement) {
          const closeClone = closeElement.cloneNode(true);
          closeElement.parentNode.removeChild(closeElement);

          this.pages.forEach((page) => {
            const omBox = Array.from(page.children)[0];
            page.insertBefore(closeClone.cloneNode(true), omBox);
          });
        }
      },

      selectPage(selectedIndex) {
        this.pages.forEach((p, pageIndex) => {
          if (pageIndex === selectedIndex) {
            p.style.display = '';
            p.classList.add('actual');
          } else {
            p.style.display = 'none';
            p.classList.remove('actual');
          }
        });

        this.selectedPageIndex = selectedIndex;
      },
    },
  };
</script>

<style lang="sass">
  .template-preview-modal-old.v--modal-box
    .brand-modal
      overflow: auto
      max-height: unset
      &-header
        display: flex
        padding: 0 1.25rem !important
        background: #365459 !important
        color: white !important
        box-shadow: 0 0 1px #51747A
        padding: 0 !important
        z-index: 10
        .slide-indicator
          color: white
      &-body
        height: 100vh
        padding-bottom: 0
        padding: 0 !important
      &-footer
        padding: 0 !important
    .om-editor-page-controller-holder
      min-height: 3.4375rem
    .om-editor-page-controller-title
      padding-top: .9375rem
      padding-bottom: .9375rem !important
      transition: 0.5s cubic-bezier(0.27, 1.64, 0.32, 0.95)
      svg > path
          transition: 0.5s cubic-bezier(0.27, 1.64, 0.32, 0.95)
      &:hover
        color: #EB5A29
        svg > path[fill="white"]
          fill: #EB5A29
    .om-editor-page-controller
      i.fa-chevron-right
        padding-bottom: 0
    .template-preview-name
      padding: 0 1.5rem
      font-size: 0.9375rem
      color: #B6C6C9
    .template-prev
      height: 100%
      width: 100%
      background: #365459
      transition: height 0.4s linear
      border: none
      overflow: auto

      &-holder
        height: 100%
        width: 100%
        background: #365459

      .loading-logo
        position: absolute
        left: 50%
        transform: translateX(-50%)

      iframe
        visibility: hidden
        width: 100%
        height: 100%
        border: none
        background: #365459

        body
          margin: 0

      &-mobile
        width: 370px
        margin: 0 auto
        background-color: #365459
        display: flex
        align-items: center
        position: relative

        iframe
          margin: auto
          width: 340px
          height: 672px
          position: absolute
          left: 13px
          z-index: 10
        &-frame
          position: absolute
          content: ''
          width: 370px
          height: 792px
          background-image: url('../../assets/admin/svg/pixel.svg')
          background-repeat: no-repeat
          background-size: 100%

  @media screen and (max-width: 1600px)
    .template-preview-modal-old
      .template-prev-holder-mobile
        overflow: auto
      .template-prev-mobile-frame,
      .template-prev-mobile iframe
        margin: 3rem 0 3rem
  @media screen and (max-width: 1400px)
    .template-preview-modal-old
      .template-prev-mobile-frame,
      .template-prev-mobile iframe
        margin: 7.5rem 0 3rem
      .template-prev
        height: calc(100vh - 3rem)
  @media screen and (max-width: 767px)
      .v--modal-overlay
        .template-preview-modal-old.v--modal-box
          width: 100% !important
</style>
