<template lang="pug">
pre.line-numbers
  code(:class="langClass" v-html="highlighted()")
  om-button.brand-copy-code.position-absolute(
    primary
    v-if="copyToClipboard"
    v-clipboard:copy="codeStr"
    v-clipboard:success="onCopy"
    v-clipboard:error="onError"
    :title="$t('copyInsertCodeTitle')"
  ) {{ $t('copy') }}
</template>

<script>
  import Prism from 'prismjs';
  import 'prismjs/plugins/line-numbers/prism-line-numbers';

  export default {
    props: {
      lang: { default: 'markup' },
      codeStr: { type: String, required: true },
      copyToClipboard: { type: Boolean, default: true },
    },

    computed: {
      langClass() {
        return `lang-${this.lang}`;
      },
    },

    watch: {
      codeStr() {
        this.$nextTick(() => {
          Prism.highlightAll();
        });
      },
    },

    mounted() {
      Prism.highlightAll();
    },

    methods: {
      highlighted() {
        return Prism.highlight(this.codeStr, Prism.languages[this.lang], this.lang);
      },

      onCopy() {
        this.$notify({
          type: 'success',
          text: this.$t('notifications.insertCodeCopySuccess'),
        });
      },

      onError() {
        this.$notify({
          type: 'error',
          text: this.$t('notifications.insertCodeCopyError'),
        });
      },
    },
  };
</script>
