import { removeAllFixedStacks, positioningPopover } from '../scrollableHelpers';

export { default as steps } from './steps';

export const TOUR_NAME = 'personalizedExperiences';

export const driverConfigs = {
  opacity: 0,
  padding: 16,
  showButtons: false,
  allowClose: false,
  onHighlightStarted() {
    document.querySelector('html').style.height = 'max-content';
    document.querySelector('body').style.height = 'max-content';
    window.addEventListener('scroll', positioningPopover);
  },
  onHighlighted(step) {
    removeAllFixedStacks();
    setTimeout(() => {
      const { height } = step.stage.node.getBoundingClientRect();
      step.popover.tipNode.style.top = `${height / 2 - 5}px`;
      positioningPopover();
    }, 300);
  },
  onReset() {
    const scrolled = window.scrollY;
    document.querySelector('html').style.height = null;
    document.querySelector('body').style.height = null;
    window.removeEventListener('scroll', positioningPopover);
    // set scrolled distance on window to the body
    document.querySelector('body').scrollTo({ top: scrolled });
  },
};
