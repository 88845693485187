<template lang="pug">
.pb-5
  .learn-more-section-v2.mb-4.p-4
    .row
      .col-6.d-flex
        UilGraduationCap.col-auto.px-0(size="1.25rem")
        om-body-text.pl-2.learn-more-section-v2-text(bt400xs)(v-html="$t(transKey)")
      .col-6.d-flex.justify-content-end
        om-link.read-more(
          withIconLeft
          v-if="supportArticle"
          @click="supportArticleClick"
          secondary
        )
          span {{ $t('readMore') }}
          template(slot="left-icon")
            UilBookAlt.mr-2(size="1.5rem")
        om-link.ml-3.watch-video(withIconLeft v-if="video" @click="videoClick" secondary)
          span {{ $t('watchVideo') }}
          template(slot="left-icon")
            UilPlayCircle.mr-2(size="1.5rem")
</template>
<script>
  import { mapGetters } from 'vuex';
  import learnMoreSectionTrans from '@/mixins/learnMoreSectionTrans';
  import { UilGraduationCap, UilBookAlt, UilPlayCircle } from '@iconscout/vue-unicons';

  export default {
    components: { UilGraduationCap, UilBookAlt, UilPlayCircle },
    mixins: [learnMoreSectionTrans],
    props: ['settingType', 'ruleType'],
    computed: {
      ...mapGetters(['getLocale']),
      transKeyRoot() {
        return this.transKey.split('.desc')[0];
      },
      supportArticle() {
        return this.$t(`${this.transKeyRoot}.supportArticle`);
      },
      video() {
        return this.$t(`${this.transKeyRoot}.video`);
      },
    },
    methods: {
      supportArticleClick() {
        return window.open(this.supportArticle, '_blank');
      },
      videoClick() {
        return window.open(this.video, '_blank');
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '../sass/variables/_colors'
  .learn-more-section-v2
    color: $om-gray-700
    background: $om-gray-100
    border-radius: 4px
</style>
