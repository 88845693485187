<template lang="pug">
div
  device-chooser(:currentRule.sync="currentRule")
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import DeviceChooser from './DeviceChooser.vue';

  export default {
    components: {
      DeviceChooser,
    },
    mixins: [settingsValidation],
  };
</script>
