<template lang="pug">
.brand-tag(:class="classes")
  .brand-tag-before
    slot(name="before")
  .brand-tag-content
    slot
  .brand-tag-actions
    .brand-tag-remove(v-if="removable" @click="$emit('remove')")
      slot(name="remove")
        remove-svg
  .brand-tag-after
    slot(name="after")
</template>

<script>
  import RemoveSvg from './RemoveSvg.vue';

  export default {
    components: { RemoveSvg },

    props: {
      inline: {
        type: Boolean,
        default: true,
      },
      removable: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      classes() {
        return {
          'brand-tag-inline': this.inline,
          'brand-tag-removable': this.removable,
        };
      },
    },
  };
</script>

<style lang="sass">
  .brand-tag
    display: flex
    align-items: center
    flex-shrink: 1
    min-width: 0px
    height: 18px
    border-radius: 3px
    padding-left: 6px
    padding-right: 6px
    font-size: 14px
    line-height: 120%
    color: rgb(55, 53, 47)
    background: rgba(237, 90, 41, 0.5)

    &-inline
      display: inline-flex
    &-removable
      padding-right: 0

    &-remove
      user-select: none
      transition: background 20ms ease-in 0s
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      flex-grow: 0
      flex-shrink: 0
      margin-left: 2px
      margin-right: 2px
      width: 18px
      height: 18px
</style>
