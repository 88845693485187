<template lang="pug">
div
  div(v-for="index in rows" :key="index")
    .skeleton-row
      .quarter-column-flex
        vue-skeleton-loader(type="circle" width="35px" height="35px" :rounded="rounded")
        .skeleton-inner-row
          vue-skeleton-loader(type="rect" width="100%" height="12px" :rounded="rounded")
          vue-skeleton-loader(type="rect" width="100%" height="12px" :rounded="rounded")
      .three-quarter-column
        vue-skeleton-loader(type="rect" width="90%" height="12px" :rounded="rounded")
    hr(v-if="bottomLine")
</template>

<script>
  export default {
    props: {
      rows: { type: Number },
      rounded: { type: Boolean },
      bottomLine: { type: Boolean },
    },
  };
</script>

<style lang="sass" scoped>
  hr
    margin: 0px
  .skeleton-row
    display: flex
    align-items: center
    justify-content: space-between
    padding-top: 15px
    padding-bottom: 15px
  .quarter-column-flex
      width: 25%
      display: flex
      justify-content: space-between
      height: 45px
  .three-quarter-column
    width: 75%
    display: flex
    justify-content: flex-end
  .skeleton-inner-row
    display: flex
    flex-direction: column
    justify-content: space-evenly
    width: calc( 100% - 45px )
  .content-right
    display: flex
    justify-content: flex-end
</style>
