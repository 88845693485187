<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='30', height='18', viewBox='0 0 30 18')
  g(:fill='color', fill-rule='evenodd')
    path(d='M20.186 1.873a5.21 5.21 0 0 1 5.207 5.202 5.21 5.21 0 0 1-5.207 5.202 5.21 5.21 0 0 1-5.207-5.202 5.21 5.21 0 0 1 5.207-5.202m9.545 14.041L25.578 11.6a7.011 7.011 0 0 0 1.653-4.524c0-3.881-3.16-7.038-7.045-7.038-3.884 0-7.045 3.157-7.045 7.038 0 3.88 3.16 7.038 7.045 7.038 1.458 0 2.848-.44 4.036-1.274l4.185 4.348c.175.181.41.281.662.281a.919.919 0 0 0 .662-1.554M7.08 9.684l-.6-2.126c-.072-.226-.204-.749-.394-1.57H6.05a22.65 22.65 0 0 1-.386 1.578l-.616 2.118h-1.09L2.658 5.127h1.006l.59 2.245c.135.555.23 1.03.286 1.426h.025c.028-.2.07-.43.128-.69.057-.26.107-.454.149-.583l.704-2.398h1.081l.683 2.398c.042.134.094.34.157.618.063.277.1.493.111.647h.034c.042-.338.14-.81.293-1.418l.6-2.245h.989L8.186 9.684H7.08z')
    path(d='M14.105 9.684l-.6-2.126c-.072-.226-.203-.749-.393-1.57h-.038a22.65 22.65 0 0 1-.386 1.578l-.616 2.118h-1.09L9.685 5.127h1.005l.591 2.245c.134.555.23 1.03.285 1.426h.025c.028-.2.071-.43.128-.69.058-.26.107-.454.149-.583l.704-2.398h1.081l.684 2.398c.041.134.094.34.157.618.062.277.1.493.11.647h.034c.042-.338.14-.81.294-1.418l.599-2.245h.989l-1.308 4.557h-1.106z')
    path(d='M21.487 10.063l-.699-2.48c-.085-.263-.238-.873-.46-1.832h-.043c-.19.866-.34 1.48-.45 1.842l-.719 2.47h-1.271l-1.516-5.316h1.174l.689 2.62c.156.647.267 1.201.332 1.663h.03c.032-.234.082-.503.149-.805.067-.303.125-.53.173-.68l.822-2.798h1.261l.797 2.798c.05.157.11.397.184.72.073.324.116.576.13.755h.038c.05-.394.163-.945.343-1.653l.699-2.62h1.154l-1.526 5.316h-1.29z')
    path(d='M20.127 12.528H2.117a.527.527 0 0 1-.53-.51V2.033c0-.276.242-.51.53-.51h18.01V0H2.117C.953 0 0 .914 0 2.032v9.987c0 1.117.953 2.032 2.117 2.032h18.01v-1.523z')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
