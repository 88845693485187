<template lang="pug">
svg(:width='size', :height='size', viewBox='0 0 18 18', fill='none', xmlns='http://www.w3.org/2000/svg')
  path(fill-rule='evenodd', clip-rule='evenodd', d='M8.75 1.75C4.71977 1.75 1.5 4.96977 1.5 9C1.5 13.0302 4.71977 16.25 8.75 16.25C12.7802 16.25 16 13.0302 16 9C16 4.96977 12.7802 1.75 8.75 1.75ZM0 9C0 4.14134 3.89134 0.25 8.75 0.25C13.6087 0.25 17.5 4.14134 17.5 9C17.5 13.8587 13.6087 17.75 8.75 17.75C3.89134 17.75 0 13.8587 0 9ZM8.75 4.69444C9.16421 4.69444 9.5 5.03023 9.5 5.44444V8.25H12.3056C12.7198 8.25 13.0556 8.58579 13.0556 9C13.0556 9.41421 12.7198 9.75 12.3056 9.75H9.5V12.5556C9.5 12.9698 9.16421 13.3056 8.75 13.3056C8.33579 13.3056 8 12.9698 8 12.5556V9.75H5.19444C4.78023 9.75 4.44444 9.41421 4.44444 9C4.44444 8.58579 4.78023 8.25 5.19444 8.25H8V5.44444C8 5.03023 8.33579 4.69444 8.75 4.69444Z', fill='#ED5A29')
</template>

<script>
  export default {
    props: {
      size: {
        type: Number,
        default: 18,
      },
    },
  };
</script>
