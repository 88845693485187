<template lang="pug">
.brand-avatar(:class="big ? 'brand-avatar-big': ''" :style="background")
  slot(v-if="!img")
    div(v-if="name.firstName || name.lastName || email") {{ getMonogram }}
    div(v-else)
      i.fas.fa-user
</template>
<script>
  import fullName from '@/mixins/fullName';

  const ColorHash = require('color-hash');

  export default {
    mixins: [fullName],

    props: {
      big: {
        type: Boolean,
        default: false,
      },
      img: {
        type: String,
        default: null,
      },
      name: {
        type: Object,
        default: null,
      },
      email: {
        type: String,
        default: null,
      },
      colorize: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      background() {
        return {
          background: this.img
            ? `url(${this.img}) center center / contain no-repeat`
            : this.colorize
            ? this.colorHash.hex(this.getMonogram)
            : '#9198A9',
        };
      },
      getMonogram() {
        if (!this.name.firstName.length && !this.name.lastName.length && this.email) {
          return this.email.charAt(0).toUpperCase();
        }

        const [firstPart, lastPart] = this.getFullNameByLocale(
          this.name.firstName,
          this.name.lastName,
          this.$i18n.locale,
        ).split(' ');
        return firstPart.charAt(0).toUpperCase() + lastPart.charAt(0).toUpperCase();
      },
    },

    created() {
      this.colorHash = new ColorHash({ lightness: [0.35, 0.5, 0.65] });
    },
  };
</script>

<style lang="sass">
  .brand-avatar
    height: 2.125rem
    width: 2.125rem
    min-width: 2.125rem
    border-radius: 100%
    background-color: #9198A9
    color: white
    display: flex
    align-items: center
    justify-content: center
    font-size: 0.75rem
    font-weight: 500
    &-info
      padding-left: 3.125rem
    + .brand-avatar-info
      padding-left: 0
      margin-right: 20px

    &-big
      height: 4.625rem
      width: 4.625rem
      font-size: 1.625rem
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15)

    &-name
      font-size: 1rem
      color: var(--brand-primary-color)
      font-weight: 400
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      max-width: 14.25rem
    &-email
      font-size: 0.8125rem
      color: #969BA0
      font-weight: 400
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      max-width: 14.25rem
</style>
