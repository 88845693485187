<template lang="pug">
svg(width='35', height='19', viewBox='0 0 35 19', fill='none', xmlns='http://www.w3.org/2000/svg')
  path(d='M5.53062 10.1799H4.34607V4.31737L2.59846 4.9293V3.90144L5.37858 2.85303H5.53062V10.1799Z', :fill='color')
  path(d='M11.2062 2.84363V3.85096H11.0592C10.3943 3.86123 9.86209 4.04867 9.46361 4.41326C9.06597 4.77699 8.82956 5.29392 8.75437 5.96233C9.13614 5.55153 9.62402 5.34526 10.218 5.34526C10.8479 5.34526 11.3441 5.57291 11.7083 6.02736C12.0717 6.48267 12.2538 7.06893 12.2538 7.78784C12.2538 8.52985 12.0408 9.13067 11.6147 9.59026C11.1895 10.0498 10.6307 10.2801 9.93897 10.2801C9.22723 10.2801 8.65079 10.0113 8.20804 9.47299C7.76612 8.93466 7.54474 8.22946 7.54474 7.3565V6.94056C7.54474 5.66022 7.84967 4.65718 8.45783 3.93143C9.06682 3.20567 9.94229 2.84363 11.0843 2.84363H11.2062ZM9.92893 6.32864C9.66745 6.32864 9.42856 6.40393 9.21219 6.55456C8.99499 6.70433 8.83542 6.90546 8.73434 7.15622V7.52681C8.73434 8.06856 8.84543 8.49905 9.06764 8.81828C9.28902 9.13751 9.5764 9.29755 9.92893 9.29755C10.2815 9.29755 10.5605 9.16147 10.766 8.89102C10.9715 8.62058 11.0743 8.26456 11.0743 7.82294C11.0743 7.38133 10.9698 7.02186 10.761 6.74457C10.5522 6.46728 10.2748 6.32864 9.92893 6.32864Z', :fill='color')
  path(d='M17.1909 4.80436C17.1909 5.1621 17.1007 5.48047 16.922 5.75947C16.7424 6.03848 16.4976 6.25842 16.1876 6.41846C16.5594 6.59562 16.8459 6.83869 17.0464 7.14593C17.2469 7.45318 17.3471 7.8015 17.3471 8.1892C17.3471 8.83108 17.135 9.33945 16.7114 9.71602C16.2871 10.0917 15.7223 10.28 15.0173 10.28C14.3097 10.28 13.7425 10.0909 13.3164 9.71346C12.8904 9.33604 12.6782 8.82766 12.6782 8.1892C12.6782 7.79808 12.7793 7.4472 12.9814 7.13567C13.1836 6.825 13.4676 6.58621 13.8327 6.41846C13.5261 6.25842 13.2838 6.03848 13.1059 5.75947C12.9279 5.48047 12.8394 5.1621 12.8394 4.80436C12.8394 4.18216 13.0349 3.68919 13.4267 3.3246C13.8185 2.96001 14.3473 2.77771 15.0123 2.77771C15.6814 2.77771 16.2118 2.96001 16.6036 3.3246C16.9954 3.68919 17.1909 4.18216 17.1909 4.80436ZM16.1584 8.12845C16.1584 7.76814 16.0531 7.4763 15.8426 7.25378C15.6321 7.03126 15.3539 6.91999 15.0081 6.91999C14.6622 6.91999 14.3849 7.03041 14.1777 7.25122C13.9705 7.47202 13.8669 7.76472 13.8669 8.12845C13.8669 8.48619 13.9688 8.77203 14.1735 8.98599C14.3773 9.19995 14.6589 9.30693 15.0173 9.30693C15.3765 9.30693 15.6563 9.2034 15.8568 8.99628C16.0573 8.78917 16.1584 8.49989 16.1584 8.12845ZM16.0064 4.84886C16.0064 4.53134 15.9161 4.27032 15.7374 4.06406C15.5578 3.85866 15.3163 3.75594 15.0123 3.75594C14.709 3.75594 14.4692 3.85352 14.293 4.04951C14.1167 4.24464 14.029 4.51166 14.029 4.84886C14.029 5.1835 14.1175 5.44967 14.2955 5.64651C14.4734 5.84336 14.714 5.94261 15.0173 5.94261C15.3213 5.94261 15.5619 5.84336 15.7399 5.64651C15.917 5.44967 16.0064 5.1835 16.0064 4.84886Z', :fill='color')
  path(d='M18.7163 8.89612C18.9377 8.89612 19.109 8.96031 19.2301 9.08869C19.3504 9.21792 19.4114 9.3788 19.4114 9.57308C19.4114 9.76393 19.3504 9.92142 19.2301 10.0472C19.109 10.1722 18.9377 10.2347 18.7163 10.2347C18.5041 10.2347 18.3362 10.173 18.2118 10.0498C18.0881 9.92569 18.0255 9.7665 18.0255 9.57308C18.0255 9.3788 18.0865 9.21792 18.2068 9.08869C18.3279 8.96031 18.4975 8.89612 18.7163 8.89612Z', :fill='color')
  path(d='M23.8362 10.1799H22.6516V4.31737L20.904 4.9293V3.90144L23.685 2.85303H23.8362V10.1799Z', :fill='color')
  path(d='M29.1844 7.12624C28.7867 7.55673 28.3214 7.77241 27.7893 7.77241C27.1627 7.77241 26.664 7.54818 26.2914 7.09801C25.9197 6.64869 25.7334 6.05732 25.7334 5.32557C25.7334 4.84373 25.8286 4.40809 26.0174 4.01697C26.207 3.62585 26.4761 3.32118 26.8253 3.1038C27.1744 2.88641 27.5787 2.77771 28.039 2.77771C28.7566 2.77771 29.3264 3.05243 29.7474 3.60017C30.1684 4.14877 30.379 4.88222 30.379 5.80226V6.14289C30.379 7.46003 30.0883 8.46479 29.5077 9.15717C28.9271 9.84869 28.0599 10.1996 26.9088 10.2099H26.7668V9.20168H26.938C27.6397 9.19226 28.1777 9.01851 28.5528 8.68302C28.9279 8.34667 29.1384 7.82803 29.1844 7.12624ZM28.039 6.81987C28.2771 6.81987 28.501 6.74969 28.7099 6.60934C28.9187 6.46898 29.08 6.27468 29.1944 6.02734V5.55149C29.1944 5.01317 29.0866 4.58013 28.8711 4.25235C28.6556 3.92456 28.3765 3.76109 28.034 3.76109C27.6915 3.76109 27.4183 3.90572 27.2145 4.19499C27.0107 4.48427 26.9088 4.84885 26.9088 5.29047C26.9088 5.74834 27.0115 6.11808 27.217 6.3988C27.4225 6.67951 27.6965 6.81987 28.039 6.81987Z', :fill='color')
  path(d='M27.4966 3.97289C30.4655 4.00089 33.3353 5.93746 34.4393 8.72803C35.6092 11.6856 34.6517 15.368 32.1155 17.3816C30.0914 18.9886 27.2587 19.4433 24.828 18.5376C22.5634 17.6939 20.7339 15.674 20.1482 13.2823C19.5633 10.8939 20.2501 8.23217 21.893 6.42903C23.299 4.88603 25.3318 3.99246 27.4 3.97289C27.4322 3.97274 27.4644 3.97274 27.4966 3.97289ZM22.5751 7.98303C22.2811 8.37646 22.0355 8.80689 21.8502 9.26689C21.2206 10.8302 21.3002 12.6753 22.0603 14.1773C23.0628 16.1585 25.19 17.4692 27.4092 17.4832C28.6689 17.4912 29.9112 17.0627 30.9393 16.3273L22.5751 7.98303ZM32.3394 14.9525C32.7865 14.3475 33.122 13.658 33.3049 12.9112C33.7652 11.0313 33.2298 8.92917 31.9399 7.49617C30.7925 6.2216 29.0917 5.49217 27.4092 5.4976C26.1877 5.50903 24.9865 5.92046 23.987 6.61975L32.3394 14.9525Z', :fill='color')
  path(d='M18.876 13.1897C13.749 13.1979 8.62199 13.1767 3.49526 13.1266C1.71235 13.0979 0.0942656 11.5167 0.0649828 9.61229C0.0465764 7.63672 0.0465764 5.66101 0.0649828 3.68558C0.0930106 1.86644 1.61502 0.201865 3.49526 0.171293C12.3257 0.0848647 21.1569 0.143291 29.9878 0.171005C31.7859 0.18872 33.4112 1.78744 33.4403 3.68558C33.4447 4.14129 33.4479 4.597 33.4501 5.05272C33.0605 4.68429 32.6384 4.35158 32.1884 4.05972L32.1855 3.70186C32.1675 2.53558 31.1836 1.46858 29.9798 1.45672C21.1669 1.42901 12.3539 1.45672 3.541 1.45672C2.38572 1.46029 1.33126 2.48915 1.31983 3.71658C1.31397 5.67629 1.30156 7.63629 1.31996 9.596C1.33781 10.762 2.32158 11.8293 3.52552 11.841C8.60038 11.857 13.6754 11.8831 18.7502 11.886C18.7615 12.3293 18.8041 12.7646 18.876 13.1897Z', :fill='color')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
