import moment from 'moment';
import shopifyAppExtension from '@/mixins/shopifyAppExtension';
import { mapGetters } from 'vuex';

export default {
  mixins: [shopifyAppExtension],
  computed: {
    ...mapGetters(['shopSettingsByDomain']),
  },
  methods: {
    getVariant({ alert }) {
      return alert?.links?.find(({ type }) => type === 'Variant');
    },
    getEditorLink({ alert, userId, campaignId }) {
      const variant = this.getVariant({ alert });
      if (!variant) return '#';

      return `/${userId}/variant/${campaignId}/${variant.id}/edit`;
    },
    getInsertCodeLink({ userId, campaignId, campaign }) {
      const resolvedRoute = this.$router.resolve({
        name: 'settings-code-insert-domain',
        params: { userId },
        query: { campaign: campaignId, domain: campaign?.domain },
      });
      return resolvedRoute.route.fullPath;
    },
    getShop({ campaign }) {
      if (!campaign) return null;
      return this.shopSettingsByDomain(campaign.domain);
    },
    getStoreAdminLink({ campaign }) {
      const shop = this.getShop({ campaign });
      if (!shop) return '#';
      return `https://${shop.live_domain}/admin`;
    },
    getFilteredLeadsLink({ userId, campaignId }) {
      const resolvedRoute = this.$router.resolve({
        name: 'subscribers',
        params: { userId, campaignId },
        query: {
          syncStatus: 'failed',
          dateStart: moment().subtract(1, 'week').startOf('day').unix(),
        },
      });
      return resolvedRoute.route.fullPath;
    },
    getInsertCodePage({ userId, campaignId, campaign }) {
      const resolvedRoute = this.$router.resolve({
        name: 'settings-code-insert-domain',
        params: { userId },
        query: { campaign: campaignId, domain: campaign?.domain },
      });
      return resolvedRoute.route.fullPath;
    },
    getIntegrationSetupLink({ userId, campaignId, alert }) {
      const resolvedRoute = this.$router.resolve({
        name: 'campaign_settings',
        params: { userId, id: campaignId },
        query: {
          alert: alert._id,
          activeBox: 'integration',
        },
      });
      return resolvedRoute.route.fullPath;
    },
    getAlertOptions({ alert, userId, campaignId, campaign }) {
      return {
        editorLink: this.getEditorLink({ alert, userId, campaignId }),
        insertCodeLink: this.getInsertCodeLink({ userId, campaignId, campaign }),
        storeAdminLink: this.getStoreAdminLink({ campaign }),
        recartLoginLink: 'https://app.recart.com/login',
        manyChatLoginLink: 'https://manychat.com/login',
        shopMessageLoginLink: 'https://login.shopmessage.me/login',
        filteredLeadsLink: this.getFilteredLeadsLink({ userId, campaignId }),
      };
    },
    getAlertFixes({ alert, userId, campaignId, campaign }) {
      const shop = this.getShop({ campaign });
      return {
        CountdownInThePast: this.getEditorLink({ alert, userId, campaignId }),
        CampaignAlmostOutOfDiscountCodes: this.getEditorLink({ alert, userId, campaignId }),
        CampaignOutOfDiscountCodes: this.getEditorLink({ alert, userId, campaignId }),
        InsertCodeMissing: this.getInsertCodeLink({ userId, campaignId, campaign }),
        InsertCodeV3Missing_nonShopify: this.getInsertCodePage({ userId, campaignId, campaign }),
        InsertCodeV3Missing_shopify: {
          action: this.toggleShopifyAppExtension,
          params: [shop?.myshopify_domain, true],
        },
        IntegrationError: this.getFilteredLeadsLink({ userId, campaignId }),
        ManychatCodeMissing: 'https://manychat.com/login',
        NoActiveChange: null,
        NoRequestFromDomain: this.getInsertCodeLink({ userId, campaignId, campaign }),
        ProductOutOfStock: this.getEditorLink({ alert, userId, campaignId }),
        RecartCodeMissing: 'https://app.recart.com/login',
        ShopMessageCodeMissing: 'https://login.shopmessage.me/login',
      };
    },
    getPrimaryFix({ alert, userId, campaignId, campaign }) {
      if (/^IntegrationError.{1,}/.test(alert.type)) {
        return {
          action: () =>
            this.$router.push(this.getIntegrationSetupLink({ userId, campaignId, alert })),
          params: [],
        };
      }
      return this.getAlertFixes({ alert, userId, campaignId, campaign })[alert.type];
    },
  },
};
