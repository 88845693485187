<template lang="pug">
om-modal#schedule-modal(
  name="schedule"
  color="light"
  :scrollable="true"
  :title="$t('scheduleModal.title')"
  @beforeOpen="beforeOpen"
  @closed="resetAllInput"
  :width="650"
)
  template(slot="modal-body")
    .flex.align-items-center
      .col-12.px-0
        .d-flex
          .col-auto.px-0
        .d-flex.mb-3.pb-3
          .col-4.px-0
            label.settings-label-larger.p-0.mr-auto.ml-0.mt-2 {{ $t('scheduleModal.timezone') }}
          .col-8.px-0
            om-select#timezone.timezone-select(searchable :options="timezones" v-model="timezone")
      .col-12.px-0
        .d-flex
          .col-auto.px-0
        .d-flex.align-items-center.mb-3.pb-3
          .col-4.px-0
            label.settings-label-larger.p-0.mr-auto.ml-0.mt-2 {{ $t('scheduleModal.startDate') }}
          .col-8.px-0
            .d-flex
              om-date-time#startDateTime.mr-2(
                :locale="getLocale"
                :error="$v.startDate.$error"
                :value="startDate"
                minDate="today"
                @datetime="applySchedule({ name: 'fromDate', date: $event })"
              )
              om-time-picker#startTime.ml-2(
                :value.sync="startTime.dateObject"
                :locale="getLocale"
                :error="$v.startDate.$error"
                @change="setStartTime($event)"
              )
      .col-12.px-0
        .mt-2.pt-1.pb-4
          .col-4.px-0
            label.settings-label-larger.p-0.mr-2 {{ $t('scheduleModal.endDate') }}
          .d-flex.mt-1.mx-0
            .col-4.mt-2.px-0.ml-0.mr-auto
              .form-check.mb-3
                input#off.form-check-input(
                  type="radio"
                  name="schdeule-radio"
                  value="off"
                  v-model="scheduleRadio"
                )
                label.form-check-label.ml-2(for="off") {{ $t('scheduleModal.never') }}
              .form-check
                input#on.form-check-input(
                  type="radio"
                  name="schdeule-radio"
                  value="on"
                  v-model="scheduleRadio"
                )
                label.form-check-label.ml-2(for="on") {{ $t('scheduleModal.on') }}
            .col-8.mt-4.px-0
              .d-flex.mt-1.mx-0
                om-date-time#endDateTime.mr-2(
                  :locale="getLocale"
                  :error="$v.endDate.$error"
                  :disabled="isDisabled"
                  :value="endDate"
                  minDate="today"
                  @datetime="applySchedule({ name: 'toDate', date: $event })"
                )
                om-time-picker#endTime.ml-2(
                  :value.sync="endTime.dateObject"
                  :disabled="isDisabled"
                  :locale="getLocale"
                  :error="$v.endDate.$error"
                  @change="setEndTime($event)"
                )
      om-slide-down.bt-1.bb-1.px-0(
        :title="$t('scheduleModal.dailySchedule')"
        :show="showDailySchedule"
      )
        .d-flex.mb-3.pb-3
          .col-4.px-0
            label.settings-label-larger.p-0.mr-auto.ml-0.mt-2 {{ $t('scheduleModal.timezone') }}
          .col-8.px-0
            om-select#dailyTimezone.timezone-select(
              searchable
              :options="timezones"
              v-model="dailyTimezone"
              :disabled="isDailyDisabled"
            )
        .d-flex
          .col-4.px-0
            .form-check.mb-3
              input#everyDay.form-check-input(
                type="radio"
                name="daily-schdeule-radio"
                value="everyDay"
                v-model="dailySchedule"
              )
              label.form-check-label.ml-2(for="everyDay") {{ $t('scheduleModal.everyDay') }}
            .form-check
              input#specificDay.form-check-input(
                type="radio"
                name="daily-schdeule-radio"
                value="specificDay"
                v-model="dailySchedule"
              )
              label.form-check-label.ml-2(for="specificDay") {{ $t('scheduleModal.specificDay') }}
          .col-8.px-0
            .d-flex.mb-3.pb-3
              span.col.px-1(v-for="(day, index) in days")
                span.schedule-repeat-on-date.cursor-pointer.align-items-center.form-control(
                  :style="isDailyDisabled ? 'pointer-events: none;' : ''"
                  @click="toggleDay(index + 1)"
                  :class="{ 'is-invalid': $v.daysOfWeek.$error, 'active-unclickable': isDailyDisabled, active: isSelectedDay(index + 1) }"
                ) {{ day }}
            .d-flex.mb-3.pb-3
              om-time-picker#dailyTimeFrom.mr-2(
                :value.sync="fromTime.dateObject"
                :locale="getLocale"
                :label="$t('dateTime.dateFrom')"
                :disabled="isDailyDisabled"
                @change="dailyTimeFrom($event)"
              )
              .flex-column.mt-1.pt-6 -
              om-time-picker#dailyTimeTo.ml-2(
                :value.sync="toTime.dateObject"
                :label="$t('dateTime.dateTo')"
                :disabled="isDailyDisabled"
                :locale="getLocale"
                @change="dailyTimeTo($event)"
              )
      template.d-flex.mt-4.pb-4(slot="modal-footer")
        .col-6.px-0
          .row.delete-schedule.mx-0(v-if="schedule && schedule.from")
            om-button.pl-0(ghost icon="trash-alt" @click="deleteSchedule") {{ $t('scheduleModal.deleteSchedule') }}
        .col-6.px-0
          .d-flex.justify-content-end
            om-button.mr-2(ghost @click="cancel") {{ $t('cancel') }}
            om-button(primary @click="save") {{ $t('save') }}
      .end-section.mt-6
        learn-more-section(settingType="scheduleModal" ruleType="learnMoreSection")
</template>

<script>
  import LearnMoreSection from '@/components/LearnMoreSection.vue';
  import CHANGE_CAMPAIGN_SETTINGS from '@/graphql/ChangeCampaignSettings.gql';
  import { mapGetters } from 'vuex';
  import dateFormat from '@/mixins/dateFormat';
  import moment from 'moment';
  import momentTZ from 'moment-timezone';
  import { required } from 'vuelidate/lib/validators';
  import * as _ from 'lodash';

  const SCHEDULE_RADIO_OPTIONS = { ON: 'on', OFF: 'off' };
  const SCHEDULE_DAILY_RADIO_OPTIONS = { SPECIFIC_DAY: 'specificDay', EVERY_DAY: 'everyDay' };
  const MAX_NUMBER_OF_DAYS = 7;
  const DATE_FORMAT = 'YYYY-MM-DD';
  const { HUN_HOUR_FORMAT, EN_HOUR_FORMAT } = { HUN_HOUR_FORMAT: 'H', EN_HOUR_FORMAT: 'h' };
  const DATE_TIMES = { hour: null, minutes: null, ampm: null, dateObject: null };
  const VISITOR_BASED_TZ_TYPE = 'visitor-based';
  const UTC_TZ_KEY = 'UTC';

  export default {
    components: {
      LearnMoreSection,
    },
    mixins: [dateFormat],

    props: {
      schedule: {
        type: Object,
      },
      campaignId: { type: Number },
    },
    data() {
      return {
        isDisabled: true,
        isDailyDisabled: true,
        scheduleRadio: SCHEDULE_RADIO_OPTIONS.OFF,
        dailySchedule: SCHEDULE_DAILY_RADIO_OPTIONS.EVERY_DAY,
        daysOfWeek: _.range(0, MAX_NUMBER_OF_DAYS),
        timezone: null,
        dailyTimezone: null,
        startDate: null,
        startTime: _.clone(DATE_TIMES),
        endTime: _.clone(DATE_TIMES),
        fromTime: _.clone(DATE_TIMES),
        toTime: _.clone(DATE_TIMES),
        endDate: null,
        days: [
          this.$t('daysShort.monday'),
          this.$t('daysShort.tuesday'),
          this.$t('daysShort.wednesday'),
          this.$t('daysShort.thursday'),
          this.$t('daysShort.friday'),
          this.$t('daysShort.saturday'),
          this.$t('daysShort.sunday'),
        ],
      };
    },
    computed: {
      ...mapGetters(['getLocale']),
      isEnLocale() {
        return this.getLocale === 'en';
      },
      isSelectedDay() {
        return (dayIndex) => {
          // hack for sunday
          if (dayIndex === 7) {
            dayIndex = 0;
          }
          return this.daysOfWeek.indexOf(dayIndex) !== -1;
        };
      },
      timezones() {
        const timeZones = momentTZ.tz.names();
        const uniqueTimezones = [];
        timeZones.forEach((timezone) => {
          if (
            !uniqueTimezones.some((uniqueTimeZone) => uniqueTimeZone === timezone) &&
            timezone !== UTC_TZ_KEY
          ) {
            uniqueTimezones.push(timezone);
          }
        });
        const timeZoneOptions = uniqueTimezones.map((timezone) => {
          const offset = momentTZ.tz(timezone).format('Z');
          const name = `GMT${offset} ${timezone}`;
          return {
            key: timezone,
            value: name,
          };
        });
        return [
          { key: VISITOR_BASED_TZ_TYPE, value: this.$t('scheduleModal.visitorBased') },
          { key: UTC_TZ_KEY, value: this.$t('scheduleModal.utc') },
          ...timeZoneOptions.sort((a, b) => (a.key > b.key ? 1 : -1)),
        ];
      },
      defaultTimezone() {
        const localeTimezone = momentTZ.tz.guess();
        if (this.timezones.find((tz) => tz.key === localeTimezone)) {
          return localeTimezone;
        }
        return UTC_TZ_KEY;
      },
      timezoneOffset() {
        const localeUtcOffset = momentTZ().utcOffset();

        if (this.timezone?.key === VISITOR_BASED_TZ_TYPE) {
          return 0 - localeUtcOffset;
        }

        const tzUtcOffset = momentTZ
          .utc()
          .tz(this.timezone?.key ?? UTC_TZ_KEY)
          .utcOffset();

        return tzUtcOffset - localeUtcOffset;
      },
      showDailySchedule() {
        return !this.isDailyDisabled;
      },
    },

    watch: {
      scheduleRadio(scheduleOptionValue) {
        if (scheduleOptionValue === SCHEDULE_RADIO_OPTIONS.ON) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
          this.endDate = null;
        }
      },
      dailySchedule(dailyScheduleOptionValue) {
        if (dailyScheduleOptionValue === SCHEDULE_DAILY_RADIO_OPTIONS.SPECIFIC_DAY) {
          this.isDailyDisabled = false;
        } else {
          this.isDailyDisabled = true;
          this.daysOfWeek = _.range(0, 7);
        }
      },
    },
    methods: {
      dailyTimeFrom(event) {
        this.fromTime = this.formatDefaultDateTime(event);
      },
      dailyTimeTo(event) {
        this.toTime = this.formatDefaultDateTime(event);
      },
      setStartTime(event) {
        this.startTime = this.formatDefaultDateTime(event);
      },
      setEndTime(event) {
        this.endTime = this.formatDefaultDateTime(event);
      },
      addTzOffset(date) {
        return momentTZ(date).add(this.timezoneOffset, 'minutes');
      },
      subTzOffset(date) {
        return momentTZ(date).subtract(this.timezoneOffset, 'minutes');
      },
      beforeOpen() {
        this.$v.$reset();
        const schedule = this.schedule;
        const current = new Date();

        this.timezone = schedule.tz
          ? this.getTimezoneSelectOption(schedule.tz)
          : this.getTimezoneSelectOption(this.defaultTimezone);

        const startDate = this.addTzOffset(schedule.from ?? current);
        this.startDate = startDate.toDate();
        this.startTime = {
          hour: startDate.format(this.isEnLocale ? EN_HOUR_FORMAT : HUN_HOUR_FORMAT),
          minutes: startDate.format('mm'),
          ampm: startDate.format('a'),
          dateObject: startDate.toDate(),
        };

        const endDate = this.addTzOffset(schedule.to ?? current);

        if (schedule.to) {
          this.scheduleRadio = SCHEDULE_RADIO_OPTIONS.ON;
          this.isDisabled = false;
          this.endDate = endDate.toDate();
          this.endTime = {
            hour: endDate.format(this.isEnLocale ? EN_HOUR_FORMAT : HUN_HOUR_FORMAT),
            minutes: endDate.format('mm'),
            ampm: endDate.format('a'),
            dateObject: this.endDate,
          };
        } else {
          this.endDate = null;
          this.endTime = {
            hour: endDate.format(this.isEnLocale ? EN_HOUR_FORMAT : HUN_HOUR_FORMAT),
            minutes: endDate.format('mm'),
            ampm: endDate.format('a'),
            dateObject: endDate.toDate(),
          };
        }

        this.fromTime = this.getDefaultDateTime();
        this.toTime = this.getDefaultDateTime();
        this.dailyTimezone = this.getTimezoneSelectOption(this.defaultTimezone);

        if (schedule.repeatsOn) {
          const hasDailySchedule = schedule.repeatsOn[0];
          if (
            schedule.repeatsOn.length > 0 &&
            hasDailySchedule.fromTime &&
            hasDailySchedule.toTime
          ) {
            this.dailySchedule = SCHEDULE_DAILY_RADIO_OPTIONS.SPECIFIC_DAY;
            this.isDailyDisabled = false;
            this.daysOfWeek = schedule.repeatsOn[0].daysOfWeek;

            const fromTime = moment(schedule.repeatsOn[0].fromTime, 'hh:mm a');
            this.fromTime = this.formatDefaultDateTime(fromTime.toDate());

            const toTime = moment(schedule.repeatsOn[0].toTime, 'hh:mm a');
            this.toTime = this.formatDefaultDateTime(toTime.toDate());
            this.dailyTimezone = schedule.repeatsOn[0].tz
              ? this.getTimezoneSelectOption(schedule.repeatsOn[0].tz)
              : this.getTimezoneSelectOption(VISITOR_BASED_TZ_TYPE);
          }
        }
      },

      createDateTime(date, time) {
        const dateStr = moment(date).format(DATE_FORMAT);
        const timeStr = this.timeToStr(time);
        return moment(`${dateStr} ${timeStr}`, this.longDateTimeFormat).toDate();
      },

      timeToStr(obj) {
        return this.isEnLocale
          ? `${obj.hour}:${obj.minutes} ${obj.ampm}`
          : `${obj.hour}:${obj.minutes}`;
      },

      deleteSchedule() {
        this.$emit('deleteSchedule');
      },

      resetAllInput() {
        this.daysOfWeek = _.range(0, 7);
        this.startDate = null;
        this.scheduleRadio = SCHEDULE_RADIO_OPTIONS.OFF;
        this.dailySchedule = SCHEDULE_DAILY_RADIO_OPTIONS.EVERY_DAY;
        this.startTime = _.clone(DATE_TIMES);
        this.endTime = _.clone(DATE_TIMES);
        this.fromTime = _.clone(DATE_TIMES);
        this.toTime = _.clone(DATE_TIMES);
        this.endDate = null;
      },
      applySchedule({ name, date }) {
        switch (name) {
          case 'fromDate':
            this.startDate = date;
            break;

          case 'toDate':
            this.endDate = date;
            break;
        }
      },

      toggleDay(dayIndex) {
        // hack for sunday
        if (dayIndex === 7) {
          dayIndex = 0;
        }

        const dayIndexPosition = this.daysOfWeek.indexOf(dayIndex);

        if (this.daysOfWeek.length === 1 && dayIndexPosition !== -1) {
          return;
        }

        if (dayIndexPosition !== -1) {
          this.daysOfWeek.splice(dayIndexPosition, 1);
        } else {
          this.daysOfWeek.splice(dayIndex, 0, dayIndex);
        }
      },

      createTime(time) {
        const timeFormat = this.timeFormat;
        if (time.hour && time.minutes) {
          return moment(`${time.hour}:${time.minutes} ${time.ampm}`, timeFormat);
        }
        return null;
      },

      changeCampaignSettings(fromTime, toTime) {
        return this.$apollo.mutate({
          mutation: CHANGE_CAMPAIGN_SETTINGS,
          variables: {
            input: {
              _id: this.campaignId ?? this.$route.params.id,
              schedule: {
                tz: this.timezone?.key ?? null,
                from: this.subTzOffset(
                  this.createDateTime(this.startDate, this.startTime),
                ).toDate(),
                to:
                  this.scheduleRadio === SCHEDULE_RADIO_OPTIONS.ON
                    ? this.subTzOffset(this.createDateTime(this.endDate, this.endTime)).toDate()
                    : null,
                repeatsOn: [
                  {
                    daysOfWeek: this.daysOfWeek,
                    fromTime,
                    toTime,
                    tz: this.dailyTimezone?.key ?? null,
                  },
                ],
              },
              status: 'active',
            },
          },
        });
      },

      async save() {
        try {
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.validationError'),
            });
          } else {
            let fromTime =
              this.dailySchedule === SCHEDULE_DAILY_RADIO_OPTIONS.SPECIFIC_DAY
                ? this.createTime(this.fromTime)
                : null;
            let toTime =
              this.dailySchedule === SCHEDULE_DAILY_RADIO_OPTIONS.SPECIFIC_DAY
                ? this.createTime(this.toTime)
                : null;

            if (fromTime) {
              fromTime = fromTime.format('HH:mm');
            }

            if (toTime) {
              toTime = toTime.format('HH:mm');
            }
            await this.changeCampaignSettings(fromTime, toTime);
            this.$emit('changeSchedule');
            this.$modal.hide('schedule');
            this.resetAllInput();
          }
        } catch (error) {
          console.error(error);
        }
      },

      cancel() {
        this.$modal.hide('schedule');
        this.resetAllInput();
      },

      formatDefaultDateTime(dateObject) {
        return {
          hour: moment(dateObject).format(this.isEnLocale ? EN_HOUR_FORMAT : HUN_HOUR_FORMAT),
          minutes: moment(dateObject).format('mm'),
          ampm: moment(dateObject).format('a'),
          dateObject,
        };
      },

      getDefaultDateTime() {
        const dateObject = new Date();
        return {
          hour: moment(dateObject).format(this.isEnLocale ? EN_HOUR_FORMAT : HUN_HOUR_FORMAT),
          minutes: moment(dateObject).format('mm'),
          ampm: moment(dateObject).format('a'),
          dateObject,
        };
      },

      getTimezoneSelectOption(key) {
        return this.timezones.find((tz) => tz.key === key);
      },
    },

    validations: {
      startDate: {
        required,
      },

      endDate: {
        isRadioOn() {
          if (
            !this.isDisabled ||
            moment(this.startDate).format(DATE_FORMAT) === moment(this.endDate).format(DATE_FORMAT)
          ) {
            return (
              this.endDate !== null &&
              this.createDateTime(this.endDate, this.endTime) >=
                this.createDateTime(this.startDate, this.startTime)
            );
          }
          return true;
        },
      },

      daysOfWeek: {
        isSpecificDay() {
          return this.isDailyDisabled || this.daysOfWeek.length > 0;
        },
      },

      endTime: {
        minutes: {
          isBigger() {
            if (
              moment(this.startDate).format(DATE_FORMAT) ===
                moment(this.endDate).format(DATE_FORMAT) &&
              this.startTime.hour === this.endTime.hour
            ) {
              return (
                this.isDisabled ||
                this.createDateTime(this.endDate, this.endTime) >=
                  this.createDateTime(this.startDate, this.startTime)
              );
            }
            return true;
          },
        },
      },
    },
  };
</script>
<style lang="sass" scoped>
  .settings-label-larger
    font-size: 0.875rem
    font-weight: 500
    color: #23262A
    &.label-inactive
      color: #8F97A4
  .brand-slide-down
    border-radius: 0
  .date-time-colon
    font-size: 1.5rem
    color: #505763
    font-weight: bold
    margin-bottom: 0.125rem
    margin-left: .125rem
    margin-right: .125rem
  .form-check-label
    font-size: 0.875rem
  .form-check-input
    border: 2px solid #D5D8DD
  .delete-schedule
    color: #8F97A4
    font-weight: bold
    font-size: 0.875rem
    label
      cursor: pointer
  input[type="radio"],
  input[type="checkbox"]
    width: 1.25rem
    height: 1.25rem
    &:checked
      border-color: red
</style>
<style lang="sass">
  .timezone-select
    .popper.select-popper
      width: 20rem
  .brand-slide-down-title
    color: #23262A
</style>
