export default {
  props: {
    currentRule: { type: Object, required: true },
    saveBtnClicked: { type: Number, required: true },
  },

  methods: {
    beforeSave() {
      if (this.beforeSaveCallback && typeof this.beforeSaveCallback === 'function') {
        this.beforeSaveCallback();
      }
    },
  },

  watch: {
    saveBtnClicked() {
      if (!this.$v) {
        this.beforeSave();
        this.$emit('canBeSaved');
        return;
      }

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.beforeSave();
        this.$emit('canBeSaved');
      }
    },
  },
};
