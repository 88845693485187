<template lang="pug">
svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
  path(d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H5.64L5.06 17C4.88446 17.304 4.79205 17.6489 4.79205 18C4.79205 18.3511 4.88446 18.696 5.06 19C5.23705 19.3067 5.49235 19.5609 5.79982 19.7366C6.10729 19.9123 6.45589 20.0032 6.81 20H13.27C13.6207 19.9996 13.9652 19.9071 14.2688 19.7315C14.5725 19.556 14.8246 19.3037 15 19C15.1755 18.696 15.268 18.3511 15.268 18C15.268 17.6489 15.1755 17.304 15 17L14.41 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM6.77 18L8 16H12L13.2 18H6.77ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V12H18V13ZM18 10H2V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V10Z" :fill="color")
</template>

<script>
  export default {
    props: ['color'],
  };
</script>
