export default {
  data() {
    return {
      attributeTypes: [
        { key: 'string', value: this.$t('text') },
        { key: 'number', value: this.$t('number') },
      ],
    };
  },
};
