const identifier = require('./identifier');
const utils = require('./utils');
const streams = require('./streams');
const date = require('./date');
const slugify = require('./slugify');

module.exports = {
  ...identifier,
  ...utils,
  ...streams,
  ...date,
  ...slugify,
};
