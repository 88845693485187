<template lang="pug">
.row.klaviyo-segment-modal-row
  form.d-flex.w-100.mb-6(autocomplete="off")
    .d-flex.mt-auto.mr-3(
      :class="{ 'flex-column': currentKlaviyoIntegration }"
      v-if="isIntegrationShown"
    )
      label.mt-auto.form-label.d-block(v-if="currentKlaviyoIntegration")
        span {{ $t('frontendRules.klaviyoSegment.integration') }}
      .form-label-block.d-inline-block(v-if="!currentKlaviyoIntegration") {{ $t('frontendRules.klaviyoSegment.integration') }}
      om-select#displayVisitorsInListSelect.d-inline-block.integration-select(
        v-model="currentKlaviyoIntegration"
        :options="klaviyoIntegrationOptions"
        label-position="fill"
        :placeholder="$t('frontendRules.klaviyoSegment.selectIntegration')"
        @input="setSegmentAndList() && resetTargetForm()"
        :error="$v.publicApiKey.$error"
      )
    template(v-if="isPublicKeyShown")
      om-input#publicKey.mb-auto.w-25(
        v-model="publicApiKey"
        type="text"
        :valid="isIntegrationSuccess"
        :error="$v.publicApiKey.$error"
        :placeholder="$t('frontendRules.klaviyoSegment.addPublicKey')"
        :label="$t('frontendRules.klaviyoSegment.publicKeyLabel')"
        :show-invalid-suffix="false"
      )
        template(slot="error" v-if="isIntegrationError")
          span {{ $t('frontendRules.klaviyoSegment.invalidApikey') }}
    template(v-if="isPrivateKeyShown")
      om-input#privateKey.w-77.mb-auto.ml-3(
        v-model="privateApiKey"
        type="text"
        :valid="isIntegrationSuccess"
        :placeholder="$t('frontendRules.klaviyoSegment.addPrivateKey')"
        :label="$t('frontendRules.klaviyoSegment.privateKeyLabel')"
        :show-invalid-suffix="false"
      )
        template(slot="error" v-if="$v.privateApiKey.$error && !$v.privateApiKey.isReq")
          span {{ $t('requiredField') }}
        template(slot="error" v-if="isIntegrationError")
          span {{ $t('frontendRules.klaviyoSegment.invalidApikey') }}
    om-button#connectKlaviyoBtn.mt-auto.ml-3.w-8.connect-btn(
      v-if="isConnectBtnShown"
      secondary
      large
      :loading="isLoading"
      tag="button"
      :disabled="isIntegrationSuccess"
      @click.prevent="connectKlaviyo()"
    ) {{ $t('frontendRules.klaviyoSegment.connect') }}
  a.mb-6.brand-link-underline.font-size-1(
    href="https://www.klaviyo.com/account#api-keys-tab"
    target="_blank"
    v-if="isLinkShown"
  ) {{ $t('frontendRules.klaviyoSegment.findApiKeys') }}
  .brand-separator.mb-6(v-if="isSeparatorShown")
  .d-flex.flex-column.w-100.align-content-center.mb-6
    .d-flex.radio-row
      input#klaviyoDontShowRadio.form-radio-input(
        v-model="isTargetVisitor"
        type="radio"
        name="klavioy-radio"
        autocomplete="off"
        :value="false"
        :disabled="!isAllowedToSelectSegment"
        @change="resetTargetForm"
      )
      label.mb-5.d-flex.align-items-center(for="klaviyoDontShowRadio")
        span {{ $t('frontendRules.klaviyoSegment.dontDisplayProfiles') }}
          om-tooltip.ml-1.mb-0(iconSize="16px" transition="fade")
            span {{ $t('frontendRules.klaviyoSegment.tooltip.dontDisplayProfiles') }}
    .d-flex.radio-row
      input#klaviyoTargetVisitorRadio.form-radio-input(
        v-model="isTargetVisitor"
        type="radio"
        name="klaviyo-radio"
        autocomplete="off"
        :value="true"
        :disabled="!isAllowedToSelectSegment"
      )
      label(for="klaviyoTargetVisitorRadio")
        span {{ $t('frontendRules.klaviyoSegment.displayProfiles') }}
      .ml-4.target-visitor-selector(v-if="isTargetVisitor && isAllowedToSelectSegment")
        .d-flex.flex-column
          .d-flex.justify-center.align-start.checkbox-row
            om-checkbox#displayVisitorsInListCheckbox.form-checkbox-input(
              v-model="isDisplayVisitorChecked"
              name="klaviyo-checkbox"
              :error="$v.isDisplayVisitorChecked.$error"
              autocomplete="off"
              @input="resetCurrentTargetList"
            )
            .d-block
              label.ml-2.mb-0.d-flex.align-items-center(for="displayVisitorsInListCheckbox")
                span {{ $t('frontendRules.klaviyoSegment.displayLists') }}
                  om-tooltip.ml-1.mb-0(iconSize="16px" transition="fade")
                    span {{ $t('frontendRules.klaviyoSegment.tooltip.displayLists') }}
          .error-msg(v-if="$v.isDisplayVisitorChecked.$error && !$v.isDisplayVisitorChecked.isReq")
            span.text-danger {{ $t('frontendRules.klaviyoSegment.chooseAtLeastOneSegment') }}
          om-select#displayVisitorsInListSelect.mt-2(
            v-if="isDisplayVisitorChecked"
            v-model="currentTargetList"
            label-position="fill"
            :placeholder="$t('select')"
            :options="integrationSegmentsAndList"
            multiple
            :error="$v.currentTargetList.$error"
          )
          .error-msg(v-if="$v.currentTargetList.$error && !$v.currentTargetList.minLength")
            span.text-danger {{ $t('frontendRules.klaviyoSegment.selectAtLeastOneSegment') }}
          .d-flex.justify-center.align-start.checkbox-row(
            :class="{ checked: isDisplayVisitorChecked }"
          )
            om-checkbox#dontDisplayVisitorsInListCheckbox.form-checkbox-input(
              v-model="isExcludedVisitorChecked"
              name="klaviyo-checkbox"
              :error="$v.isExcludedVisitorChecked.$error"
              autocomplete="off"
              @input="resetCurrentExcludedTargetList"
            )
            label.ml-2.mb-0.d-flex.align-items-center(for="dontDisplayVisitorsInListCheckbox")
              span {{ $t('frontendRules.klaviyoSegment.dontDisplayLists') }}
                om-tooltip.ml-1.mb-0(iconSize="16px" transition="fade")
                  span {{ $t('frontendRules.klaviyoSegment.tooltip.dontDisplayLists') }}
          .error-msg(
            v-if="$v.isExcludedVisitorChecked.$error && !$v.isExcludedVisitorChecked.isReq"
          )
            span.text-danger {{ $t('frontendRules.klaviyoSegment.chooseAtLeastOneSegment') }}
          om-select#dontDisplayVisitorsInListSelect.mt-2(
            v-if="isExcludedVisitorChecked"
            v-model="currentExcludedTargetList"
            label-position="fill"
            :placeholder="$t('select')"
            :options="integrationExcludeSegmentsAndList"
            multiple
            :error="$v.currentExcludedTargetList.$error"
          )
          .error-msg(
            v-if="$v.currentExcludedTargetList.$error && !$v.currentExcludedTargetList.minLength"
          )
            span.text-danger {{ $t('frontendRules.klaviyoSegment.selectAtLeastOneSegment') }}
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import { mapActions, mapGetters } from 'vuex';
  import ADD_GLOBAL_INTEGRATION from '@/graphql/AddGlobalIntegration.gql';
  import EDIT_GLOBAL_INTEGRATION from '@/graphql/EditGlobalIntegration.gql';
  import GET_KLAVIYO_SEGMENTS_AND_LISTS from '@/graphql/GetKlaviyoSegmentsAndList.gql';

  export default {
    mixins: [settingsValidation, expressionOperations],
    data: () => ({
      publicApiKey: null,
      privateApiKey: null,
      currentKlaviyoIntegration: null,
      loading: false,
      segmentAndList: [],
      isTargetVisitor: false,
      isDisplayVisitorChecked: false,
      isExcludedVisitorChecked: false,
      currentTargetList: [],
      currentExcludedTargetList: [],
      ruleCondition: {
        existingProfile: 'existingProfile',
        groupMember: 'groupMember',
      },
      isIntegrationError: false,
      isIntegrationSuccess: false,
    }),

    computed: {
      ...mapGetters(['integrations']),
      isLoading() {
        return this.loading;
      },
      klaviyoIntegration() {
        if (this.isOnlyOneIntegration && !this.isPublicKeyMissing) {
          return this.klaviyoIntegrations?.[0];
        }

        return this.currentKlaviyoIntegration?.integration;
      },
      klaviyoIntegrations() {
        return this.integrations.filter((integration) => integration.type === 'klaviyo');
      },
      klaviyoIntegrationOptions() {
        return this.klaviyoIntegrations.map((integration) => ({
          key: integration._id,
          value: integration.data.name,
          integration,
        }));
      },
      isPublicKeyMissing() {
        return !this.currentKlaviyoIntegration?.integration?.data?.publicApiKey;
      },
      hasPublicKeyInList() {
        return this.klaviyoIntegrations.every((integration) => integration?.data?.publicApiKey);
      },
      integrationSegmentsAndList() {
        return this.segmentAndList
          .map((item) => ({
            key: item.id,
            value: item.name,
            item,
          }))
          .filter(
            (item) =>
              !this.currentExcludedTargetList.some(
                (targetItem) => targetItem.item.id === item.item.id,
              ),
          )
          .sort((a, b) => (a.value > b.value ? 1 : -1));
      },
      integrationExcludeSegmentsAndList() {
        return this.segmentAndList
          .map((item) => ({
            key: item.id,
            value: item.name,
            item,
          }))
          .filter(
            (item) =>
              !this.currentTargetList.some((targetItem) => targetItem.item.id === item.item.id),
          )
          .sort((a, b) => (a.value > b.value ? 1 : -1));
      },
      isAllowedToSelectSegment() {
        if (this.isOnlyOneIntegration && this.klaviyoIntegrations?.[0]?.data?.publicApiKey) {
          return true;
        }

        return !!this.klaviyoIntegration?.data?.publicApiKey;
      },
      isIntegrationShown() {
        return this.isManyIntegration || (!this.hasPublicKeyInList && this.isOnlyOneIntegration);
      },
      isMissingPublicKeyCase() {
        return this.isPublicKeyMissing && this.klaviyoIntegration;
      },
      isPublicKeyShown() {
        return this.isMissingPublicKeyCase || !this.hasIntegration || this.isIntegrationSuccess;
      },
      isConnectBtnShown() {
        return this.isMissingPublicKeyCase || !this.hasIntegration || this.isIntegrationSuccess;
      },
      isPrivateKeyShown() {
        return (
          !this.hasIntegration ||
          (!this.isPublicKeyMissing && !this.klaviyoIntegration && !this.isIntegrationSuccess)
        );
      },
      isLinkShown() {
        return !this.hasPublicKeyInList || this.isEmptyIntegration;
      },
      isSeparatorShown() {
        return this.isManyIntegration || this.isEmptyIntegration || !this.hasPublicKeyInList;
      },
      hasIntegration() {
        return this.klaviyoIntegrations.length;
      },
      isEmptyIntegration() {
        return this.klaviyoIntegrations.length === 0;
      },
      isOnlyOneIntegration() {
        return this.klaviyoIntegrations.length === 1;
      },
      isManyIntegration() {
        return this.klaviyoIntegrations.length > 1;
      },
    },
    async mounted() {
      this.fetchAccount();
      if (this.klaviyoIntegrations.length) {
        this.setCurrentIntegration();

        this.createFakeSegmentAndListFromRule();
        this.setCurrentTargetList();

        await this.setSegmentAndList();
        this.setCurrentTargetList();
      }
    },
    methods: {
      ...mapActions(['fetchAccount']),
      restoreTargetList(targetList, list, expressionKey) {
        this[targetList] = this[list].filter((target) => {
          return this.currentRule.options.expression[expressionKey].some(
            (item) => item.id === target.item.id,
          );
        });
      },
      setCheckbox(checkBoxModel, targetList) {
        if (targetList.length) {
          this[checkBoxModel] = true;
        }
      },
      setCurrentIntegration() {
        this.currentKlaviyoIntegration = this.klaviyoIntegrationOptions.find(
          (option) => option.integration._id === this.currentRule.options.integrationId,
        );

        if (!this.currentKlaviyoIntegration) {
          this.currentKlaviyoIntegration = this.klaviyoIntegrationOptions.find(
            (option) =>
              option.integration.data.publicApiKey === this.currentRule.options.publicApiKey,
          );
        }
      },
      setCurrentTargetList() {
        if (this.currentRule.options.condition === this.ruleCondition.groupMember) {
          this.restoreTargetList('currentTargetList', 'integrationSegmentsAndList', 'in');
          this.restoreTargetList(
            'currentExcludedTargetList',
            'integrationExcludeSegmentsAndList',
            'notIn',
          );

          this.isTargetVisitor = true;
          this.setCheckbox('isDisplayVisitorChecked', this.currentTargetList);
          this.setCheckbox('isExcludedVisitorChecked', this.currentExcludedTargetList);
        }
      },
      async setSegmentAndList() {
        this.segmentAndList = [];
        const klaviyoIntegration = this.klaviyoIntegration || this.klaviyoIntegrations?.[0];

        const { data } = await this.$apollo.query({
          query: GET_KLAVIYO_SEGMENTS_AND_LISTS,
          variables: {
            integrationId: klaviyoIntegration?._id,
          },
        });
        const { segmentsAndList } = data;
        this.segmentAndList = segmentsAndList;
      },
      resetApiKeys() {
        this.publicApiKey = null;
        this.privateApiKey = null;
      },
      async resetTargetForm() {
        this.resetCurrentTargetList();
        this.resetCurrentExcludedTargetList();
        this.isDisplayVisitorChecked = false;
        this.isExcludedVisitorChecked = false;
      },
      resetCurrentTargetList() {
        this.currentTargetList = [];
      },
      resetCurrentExcludedTargetList() {
        this.currentExcludedTargetList = [];
      },
      async connectKlaviyo() {
        this.$v.$touch();
        let result = null;
        this.isTargetVisitor = false;
        if (this.$v.$invalid) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.validationError'),
          });
          return;
        }
        this.loading = true;

        if (this.klaviyoIntegration) {
          result = await this.$apollo.mutate({
            mutation: EDIT_GLOBAL_INTEGRATION,
            variables: {
              integrationId: this.klaviyoIntegration._id,
              input: {
                type: 'klaviyo',
                data: {
                  ...this.klaviyoIntegration.data,
                  publicApiKey: this.publicApiKey,
                  apiKey: this.klaviyoIntegration?.data?.apiKey,
                },
              },
            },
          });
        } else {
          result = await this.$apollo.mutate({
            mutation: ADD_GLOBAL_INTEGRATION,
            variables: {
              input: {
                type: 'klaviyo',
                data: {
                  publicApiKey: this.publicApiKey,
                  apiKey: this.privateApiKey,
                  name: `Klaviyo ${this.klaviyoIntegrations.length + 1}`,
                },
              },
            },
          });
        }

        if (result?.data?.addIntegration?.success || result?.data?.editIntegration?.success) {
          this.isIntegrationSuccess = true;

          setTimeout(async () => {
            await this.fetchAccount();
            await this.setSegmentAndList();
            this.resetApiKeys();
            this.resetTargetForm();
            this.isIntegrationSuccess = false;
            this.$v.$reset();
          }, 3000);
        } else {
          this.isIntegrationError = true;
        }

        this.loading = false;
        this.currentKlaviyoIntegration = this.klaviyoIntegrationOptions[0];
      },
      mapTargetList(list) {
        return list.map(({ item }) => ({
          id: item.id,
          type: item.listType,
          name: item.name,
        }));
      },
      setExistingProfileCondition(klaviyoIntegration) {
        this.currentRule.options = {
          condition: this.ruleCondition.existingProfile,
          publicApiKey: klaviyoIntegration.data.publicApiKey,
          integrationId: klaviyoIntegration._id,
          expression: false,
        };
      },
      setGroupMemberCondition(klaviyoIntegration) {
        this.currentRule.options = {
          condition: this.ruleCondition.groupMember,
          publicApiKey: klaviyoIntegration.data.publicApiKey,
          integrationId: klaviyoIntegration._id,
          expression: false,
        };

        this.currentRule.options.expression = {
          in: this.mapTargetList(this.currentTargetList),
          notIn: this.mapTargetList(this.currentExcludedTargetList),
        };
      },
      beforeSaveCallback() {
        if (!this.isAllowedToSelectSegment) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.saveError'),
          });
          throw new Error('Cannot save');
        }
        const klaviyoIntegration = this.klaviyoIntegration || this.klaviyoIntegrations?.[0];
        this.setExistingProfileCondition(klaviyoIntegration);
        if (this.isTargetVisitor) {
          this.setGroupMemberCondition(klaviyoIntegration);
        }
      },
      createFakeSegmentAndListFromRule() {
        if (this.currentRule.options.condition === this.ruleCondition.groupMember) {
          this.segmentAndList = [
            ...this.currentRule.options.expression.in,
            ...this.currentRule.options.expression.notIn,
          ];
        }
      },
    },
    validations: {
      publicApiKey: {
        isReq(val) {
          if (
            this.isOnlyOneIntegration &&
            this.klaviyoIntegration &&
            !val &&
            this.isPublicKeyMissing
          ) {
            return false;
          }

          if (this.isOnlyOneIntegration) {
            return true;
          }

          if (this.klaviyoIntegration?.data?.publicApiKey) {
            return true;
          }
          return !!val;
        },
      },
      currentTargetList: {
        minLength(val) {
          if (!this.isDisplayVisitorChecked) {
            return true;
          }
          return val.length > 0;
        },
      },
      currentExcludedTargetList: {
        minLength(val) {
          if (!this.isExcludedVisitorChecked) {
            return true;
          }
          return val.length > 0;
        },
      },
      isDisplayVisitorChecked: {
        isReq(val) {
          if (this.isMissingPublicKeyCase) {
            return true;
          }
          if (!this.isTargetVisitor && !val) {
            return true;
          }
          if (this.isExcludedVisitorChecked || this.isDisplayVisitorChecked) {
            return true;
          }
          return !!val;
        },
      },
      isExcludedVisitorChecked: {
        isReq(val) {
          if (this.isMissingPublicKeyCase) {
            return true;
          }

          if (!this.isTargetVisitor && !val) {
            return true;
          }

          if (this.isDisplayVisitorChecked || this.isExcludedVisitorChecked) {
            return true;
          }

          return !!val;
        },
      },
      privateApiKey: {
        isReq(val) {
          if (this.isOnlyOneIntegration) {
            return true;
          }

          if (this.klaviyoIntegration?.data?.apiKey) {
            return true;
          }

          return !!val;
        },
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .klaviyo-segment-modal-row
      .form-text.text-danger
        position: absolute
        z-index: 10
      .error-msg
        margin-left: 2.1rem
        .text-danger
          position: absolute
          z-index: 10
          font-weight: 400
          font-size: 0.75rem
          line-height: 16px
      .select + .error-msg
        margin-left: 0
      .ds-tooltip
        position: relative
        top: 4px
        .popper
          min-width: 236px
      .target-visitor-selector
        width: 57%
      .form-checkbox-input
        width: 20px
        height: 20px
      .checkbox-row.checked
        margin-top: 1.75rem !important
      .checkbox-row:not(:first-child)
        margin-top: 1rem
      .checkbox-row
        font-weight: 400
        font-size: 12px
        line-height: 20px
      .w-78
        width: 78%
      .integration-select
        width: 196px
        marign-bottom: 1px
      .form-label
        font-size: 0.75rem
        margin-bottom: .25rem
        line-height: 1.3
        &-block
          font-size: 0.75rem
          line-height: 1.3
          white-space: nowrap
          margin: auto 1rem auto auto
      .campaign-display
        line-height: 24px
      .brand-link-underline
        color: unset
      .connect-btn
        max-width: 85px
        .d-inline-flex
          padding: 0 16px
      .connect-btn, #publicKey, #privateKey
        height: 40px
      #displayVisitorsInListSelect,#dontDisplayVisitorsInListSelect
        margin-top: 0
      .form-radio-input
        font-size: 12px
        &:checked, &:not(:checked)
          position: absolute
          left: -9999px
        &:checked + label, &:not(:checked) + label
          max-width: 276px
          width: 43%
          font-weight: 400
          font-weight: 400
          font-size: 12px
          line-height: 20px
          height: 20px
          position: relative
          padding-left: 28px
          cursor: pointer
          display: inline-block
          color: $om-gray-700
        &:checked + label:before, &:not(:checked) + label:before
          content: ''
          position: absolute
          left: 0
          top: 0
          width: 20px
          height: 20px
          border: 1px solid $om-light-grey2
          border-radius: 100%
          background: #fff
        &:disabled + label:before
          background: $om-gray-200
        &:checked + label:after
          content: ''
          width: 12px
          height: 12px
          background: $om-orange-500
          position: absolute
          top: 4px
          left: 4px
          border-radius: 100%
          -webkit-transition: all 0.2s ease
          transition: all 0.2s ease
        &:disabled + label:after
          background: $om-gray-200
        &:not(:checked) + label:after
          content: ''
          width: 12px
          height: 12px
          background: $om-orange-500
          position: absolute
          top: 4px
          left: 4px
          border-radius: 100%
          -webkit-transition: all 0.2s ease
          transition: all 0.2s ease
          opacity: 0
          -webkit-transform: scale(0)
          transform: scale(0)

        &:checked + label:after
          opacity: 1
          -webkit-transform: scale(1)
          transform: scale(1)
</style>
