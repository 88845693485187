<template lang="pug">
div
  template(v-for="(expressionGroup, expressionGroupIndex) in currentRule.options.expressions")
    .expression-group-container
      .target-group-container(v-for="(target, targetIndex) in expressionGroup")
        .d-flex.align-items-center.selector-wrapper(ref="rule" :data-index="targetIndex")
          .custom-attribute-wrapper.d-flex(
            :class="{ 'is-custom': isCustomAttribute(expressionGroupIndex, targetIndex) }"
          )
            .attribute-slot-1
              om-select.w-14--75.mr-1(
                :id="`variableType-${targetIndex}`"
                :options="attributes"
                @input="setAttribute($event, expressionGroupIndex, targetIndex)"
                :value="getCurrentAttribute(expressionGroupIndex, targetIndex)"
              )
            .attribute-slot-2(v-if="isCustomAttribute(expressionGroupIndex, targetIndex)")
              om-select.w-11--25.mx-1(
                :id="`attributeType-${targetIndex}`"
                :options="attributeTypes"
                @input="setAttributeType($event, expressionGroupIndex, targetIndex)"
                :value="{ key: currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeType, value: getAttributeTypeText(currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeType) }"
              )
          .attribute-slot-1(v-if="isCustomAttribute(expressionGroupIndex, targetIndex)")
            om-input.w-14--75.mr-1(
              :id="`attributeName-${targetIndex}`"
              :show-invalid-suffix="false"
              v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeName"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].attributeName.$error"
            )
          .attribute-slot-2
            om-select.w-11--25.mx-1(
              :id="`operator-${targetIndex}`"
              :options="createOptionArray(typeBasedOperators(expressionGroupIndex, targetIndex))"
              @input="setOperator($event, expressionGroupIndex, targetIndex)"
              :value="{ key: currentOperator(expressionGroupIndex, targetIndex), value: getOperatorText(currentRule.options.expressions[expressionGroupIndex][targetIndex].operator) }"
            )
          .attribute-slot-3.w-19
            om-input.mx-1.attribute-flex-stretch(
              v-if="isTextInput(expressionGroupIndex, targetIndex)"
              :value="getInputValue(expressionGroupIndex, targetIndex, 0)"
              @input="setInputValue($event, expressionGroupIndex, targetIndex, 0)"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error"
              :id="`attributeValue0-${targetIndex}`"
              :prefix="getInputPrefix(expressionGroupIndex, targetIndex)"
              :class="[isSecondInputField(expressionGroupIndex, targetIndex) ? 'w-6' : 'w-19']"
              :type="currentAttributeType(expressionGroupIndex, targetIndex) === 'number' ? 'number' : 'text'"
              :show-invalid-suffix="false"
            )
            span.and-operator.font-size-0--875(
              v-if="isSecondInputField(expressionGroupIndex, targetIndex)"
            ) {{ $t('and') }}
            om-input.mx-1.attribute-flex-stretch(
              v-if="isSecondInputField(expressionGroupIndex, targetIndex)"
              :value="getInputValue(expressionGroupIndex, targetIndex, 1)"
              @input="setInputValue($event, expressionGroupIndex, targetIndex, 1)"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error"
              :id="`attributeValue1-${targetIndex}`"
              :type="currentAttributeType(expressionGroupIndex, targetIndex) === 'number' ? 'number' : 'text'"
              :show-invalid-suffix="false"
            )
            om-input.mx-1.attribute-flex-stretch(
              v-if="isRelativeDate(expressionGroupIndex, targetIndex)"
              :min="0"
              @input="setDateAttributeValue($event, expressionGroupIndex, targetIndex, 0)"
              :value="getDateAttributeValue(expressionGroupIndex, targetIndex, 0)"
              type="number"
              :id="`attributeValue-relativeDate0-${targetIndex}`"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error"
              :show-invalid-suffix="false"
            )
            span.and-operator.font-size-0--875(
              v-if="isRelativeDate(expressionGroupIndex, targetIndex) && isIntervalDateOperator(expressionGroupIndex, targetIndex)"
            ) {{ $t('and') }}
            om-input.mx-1.attribute-flex-stretch(
              v-if="isRelativeDate(expressionGroupIndex, targetIndex) && isIntervalDateOperator(expressionGroupIndex, targetIndex)"
              :min="0"
              @input="setDateAttributeValue($event, expressionGroupIndex, targetIndex, 1)"
              :value="getDateAttributeValue(expressionGroupIndex, targetIndex, 1)"
              type="number"
              :id="`attributeValue-relativeDate1${targetIndex}`"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error"
              :show-invalid-suffix="false"
            )
            om-select.mx-1.attribute-flex-stretch(
              v-if="isDateProperty(expressionGroupIndex, targetIndex) && isRelativeDate(expressionGroupIndex, targetIndex)"
              :id="`timePeriodSelect-${targetIndex}`"
              :options="timeUnitOptions"
              @input="setTimeUnit($event, expressionGroupIndex, targetIndex)"
              :value="getTimeUnit(expressionGroupIndex, targetIndex)"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].timeUnit.$error"
            )
            span.ago-operator.font-size-0--875.mr-1(
              v-if="isAgo(expressionGroupIndex, targetIndex)"
            ) ago
            om-date-time.absolute-date.mx-1.attribute-flex-stretch(
              v-if="isAbsoluteDate(expressionGroupIndex, targetIndex)"
              :value="getDateAttributeValue(expressionGroupIndex, targetIndex, 0)"
              @datetime="setDateAttributeValue($event, expressionGroupIndex, targetIndex, 0)"
              :locale="getLocale"
              :id="`attributeValue-absoluteDate0${targetIndex}`"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error"
              :key="currentRule.options.expressions[expressionGroupIndex][targetIndex].value[0]"
            )
            span.and-operator.font-size-0--875(
              v-if="isAbsoluteDate(expressionGroupIndex, targetIndex) && isIntervalDateOperator(expressionGroupIndex, targetIndex)"
            ) {{ $t('and') }}
            om-date-time.absolute-date.mx-1.attribute-flex-stretch(
              v-if="isAbsoluteDate(expressionGroupIndex, targetIndex) && isIntervalDateOperator(expressionGroupIndex, targetIndex)"
              :value="getDateAttributeValue(expressionGroupIndex, targetIndex, 1)"
              @datetime="setDateAttributeValue($event, expressionGroupIndex, targetIndex, 1)"
              :locale="getLocale"
              :id="`attributeValue-absoluteDate1${targetIndex}`"
              :error="$v.currentRule.options.expressions.$each[expressionGroupIndex].$each[targetIndex].value.$error"
              :key="currentRule.options.expressions[expressionGroupIndex][targetIndex].value[1]"
            )
            om-select.mx-1.attribute-flex-stretch(
              v-if="isSelect(expressionGroupIndex, targetIndex)"
              :id="`attributeValue-select-${targetIndex}`"
              searchable
              :options="getVariableOptions(expressionGroupIndex, targetIndex)"
              @input="setAttributeValue($event, expressionGroupIndex, targetIndex)"
              :value="getAttributeValue(expressionGroupIndex, targetIndex)"
              :ref="`${expressionGroupIndex}-${targetIndex}-searchableSelect`"
            )

          .add-new-blocks.d-flex.flex-grow-1.justify-content-end
            om-link.delete-expression-from-group(
              v-if="canBeDeleted(expressionGroupIndex)"
              @click="removeExpressionFromGroup('expressions', expressionGroupIndex, targetIndex)"
              withIconLeft
            )
              template(slot="left-icon")
                UilTrashAlt.mr-2(size="20px")
            om-link.add-expression-to-group.mx-2(
              primary
              withIconLeft
              @click="addExpressionToGroup('expressions', expressionGroupIndex, { attributeName: 'utm_campaign', attributeType: 'string', operator: 'equals', value: '' })"
            )
              template(slot="left-icon")
                UilPlusCircle.mr-1(size="20px")
              span {{ $t('and').toUpperCase() }}
            om-link.add-expression-group.mx-2(
              primary
              withIconLeft
              v-if="isLastRuleItem(expressionGroupIndex, targetIndex)"
              @click="addExpressionGroup('expressions', { attributeName: 'utm_campaign', attributeType: 'string', operator: 'equals', value: '' })"
            )
              template(slot="left-icon")
                UilPlusCircle.mr-1(size="20px")
              span {{ $t('or').toUpperCase() }}

    .or-operator.p-4.font-size-0--875(
      v-if="expressionGroupIndex !== currentRule.options.expressions.length - 1"
    ) {{ $t('or').toUpperCase() }}
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import attributeTypes from '@/mixins/attributeTypes';
  import startingSlashSettings from '@/mixins/startingSlashSettings';
  import visitorAttribute from '@/mixins/visitorAttribute';
  import { UilPlusCircle, UilTrashAlt } from '@iconscout/vue-unicons';
  import { required, requiredIf } from 'vuelidate/lib/validators';
  import visitorAttributeShared from '@/mixins/visitorAttributeShared';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  import {
    stringOperators,
    numberBaseOperators,
    existsOperators,
    dateIntervalOperators,
    relativeDateOperators,
    absoluteDateOperators,
    booleanOperators,
  } from './statics';

  export default {
    components: {
      UilPlusCircle,
      UilTrashAlt,
    },
    mixins: [
      settingsValidation,
      expressionOperations,
      attributeTypes,
      visitorAttribute,
      startingSlashSettings,
      visitorAttributeShared,
    ],
    props: {
      domain: {
        type: String,
      },
    },
    data: () => {
      return {
        numberOperators: [...numberBaseOperators, 'between'],
      };
    },
    computed: {
      ...mapGetters(['getLocale']),
    },
    created() {
      this.modifyExpressionValue(this.removeStartingSlash);
    },
    methods: {
      isFirstLandingPage(expressionGroupIndex, targetIndex) {
        return (
          this.currentAttributeName(expressionGroupIndex, targetIndex) === 'first_landing_page'
        );
      },
      isUTMVariable(expressionGroupIndex, targetIndex) {
        return this.utmVariables.includes(
          this.currentAttributeName(expressionGroupIndex, targetIndex),
        );
      },
      isTextInput(expressionGroupIndex, targetIndex) {
        const operator = this.currentOperator(expressionGroupIndex, targetIndex);
        return (
          this.needsInput(operator) &&
          (this.isCustomAttribute(expressionGroupIndex, targetIndex) ||
            this.isUTMVariable(expressionGroupIndex, targetIndex) ||
            this.isFirstLandingPage(expressionGroupIndex, targetIndex))
        );
      },
      isSelect(expressionGroupIndex, targetIndex) {
        return (
          !this.isTextInput(expressionGroupIndex, targetIndex) &&
          !this.isFirstLandingPage(expressionGroupIndex, targetIndex) &&
          !this.isDateProperty(expressionGroupIndex, targetIndex) &&
          this.needsInput(this.currentOperator(expressionGroupIndex, targetIndex))
        );
      },
      isPrefilledUrlInput(expressionGroupIndex, targetIndex) {
        return (
          this.needStartingSlash(this.currentOperator(expressionGroupIndex, targetIndex)) &&
          this.isFirstLandingPage(expressionGroupIndex, targetIndex)
        );
      },

      isIntervalDateOperator(expressionGroupIndex, targetIndex) {
        return (
          this.isDateProperty(expressionGroupIndex, targetIndex) &&
          dateIntervalOperators.includes(this.currentOperator(expressionGroupIndex, targetIndex))
        );
      },
      needsInput(operator) {
        return !existsOperators.includes(operator);
      },
      isStringAttribute(expressionGroupIndex, targetIndex) {
        return (
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeType ===
          'string'
        );
      },
      isSecondInputField(expressionGroupIndex, targetIndex) {
        return (
          this.isCustomAttribute(expressionGroupIndex, targetIndex) &&
          this.currentAttributeType(expressionGroupIndex, targetIndex) === 'number' &&
          this.currentOperator(expressionGroupIndex, targetIndex) === 'between'
        );
      },
      createOptionArray(optionKeys) {
        const isBooleanOperators =
          optionKeys.length === booleanOperators.length &&
          booleanOperators.every((operator) => optionKeys.includes(operator));
        if (isBooleanOperators) {
          return optionKeys.map((element) => ({
            key: element,
            value: this.$t(`frontendRules.visitorAttribute.operators.${element}`),
          }));
        }
        return optionKeys.map((element) => ({ key: element, value: this.$t(element) }));
      },
      typeBasedOperators(expressionGroupIndex, targetIndex) {
        const currentVariableType = this.getCurrentAttribute(expressionGroupIndex, targetIndex);

        if (this.isCustomAttribute(expressionGroupIndex, targetIndex)) {
          return this.isStringAttribute(expressionGroupIndex, targetIndex)
            ? stringOperators
            : this.numberOperators;
        }
        return currentVariableType.operators;
      },
      getVariableOptions(expressionGroupIndex, targetIndex) {
        return (
          this.attributes.find(
            (variable) =>
              variable.key === this.currentAttributeName(expressionGroupIndex, targetIndex),
          )?.options || []
        );
      },

      getAttributeTypeText(type) {
        return type === 'string' ? this.$t('text') : this.$t('number');
      },
      resetDateValues(expressionGroupIndex, targetIndex) {
        if (this.isRelativeDate(expressionGroupIndex, targetIndex)) {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].timeUnit =
            this.timeUnitOptions[0].key;
        } else {
          delete this.currentRule.options.expressions[expressionGroupIndex][targetIndex].timeUnit;
        }
      },
      resetToDefaultValue(expressionGroupIndex, targetIndex) {
        const defaultValue = this.isStringAttribute(expressionGroupIndex, targetIndex) ? '' : null;
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value =
          defaultValue;
      },
      resetValue(expressionGroupIndex, targetIndex) {
        if (this.isSelect(expressionGroupIndex, targetIndex)) {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value =
            this.getVariableOptions(expressionGroupIndex, targetIndex)[0].key;
        } else {
          this.resetToDefaultValue(expressionGroupIndex, targetIndex);
        }
      },
      resetOperators(expressionGroupIndex, targetIndex) {
        const currentAttribute = this.getCurrentAttribute(expressionGroupIndex, targetIndex);
        if (this.isCustomAttribute(expressionGroupIndex, targetIndex)) {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].operator =
            this.isStringAttribute(expressionGroupIndex, targetIndex)
              ? stringOperators[0]
              : this.numberOperators[0];
        } else {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeType =
            'string';
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].operator =
            currentAttribute.operators[0];
        }
      },
      resetSearchableSelect(expressionGroupIndex, targetIndex) {
        const ref = `${expressionGroupIndex}-${targetIndex}-searchableSelect`;
        this.$refs?.[ref]?.[0]?.resetOptions();
      },
      resetValues(expressionGroupIndex, targetIndex) {
        this.resetOperators(expressionGroupIndex, targetIndex);
        this.resetValue(expressionGroupIndex, targetIndex);
        this.resetDateValues(expressionGroupIndex, targetIndex);
        this.resetSearchableSelect(expressionGroupIndex, targetIndex);
      },
      setAttribute(event, expressionGroupIndex, targetIndex) {
        if (event.key !== 'custom_attribute') {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeName =
            event.key;
        } else {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeName =
            '';
        }

        this.resetValues(expressionGroupIndex, targetIndex);
      },
      getOperatorText(key) {
        return this.$t(key);
      },
      setDateValues(operator, expressionGroupIndex, targetIndex) {
        if (relativeDateOperators.includes(operator)) {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value = [
            null,
            null,
          ];
          if (!this.currentRule.options.expressions[expressionGroupIndex][targetIndex].timeUnit) {
            this.currentRule.options.expressions[expressionGroupIndex][targetIndex].timeUnit =
              this.timeUnitOptions[0].key;
          }
        }
        if (absoluteDateOperators.includes(operator)) {
          if (dateIntervalOperators.includes(operator)) {
            this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value.splice(
              0,
              1,
              this.getTimestampInUTC(new Date(), 0, operator),
            );
            this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value.splice(
              1,
              1,
              this.getTimestampInUTC(new Date(), 1, operator),
            );
          } else {
            this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value.splice(
              1,
              1,
              null,
            );
            this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value.splice(
              0,
              1,
              this.getTimestampInUTC(new Date(), 0, operator),
            );
          }
          delete this.currentRule.options.expressions[expressionGroupIndex][targetIndex].timeUnit;
        }
      },
      setOperator(event, expressionGroupIndex, targetIndex) {
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].operator =
          event.key;

        if (existsOperators.includes(event.key)) {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value = '';
        }
        this.setDateValues(event.key, expressionGroupIndex, targetIndex);

        if (this.isCustomAttribute(expressionGroupIndex, targetIndex)) {
          if (this.isSecondInputField(expressionGroupIndex, targetIndex)) {
            this.initDateValue(expressionGroupIndex, targetIndex);
          } else if (Array.isArray(this.currentValue(expressionGroupIndex, targetIndex))) {
            this.resetToDefaultValue(expressionGroupIndex, targetIndex);
          }
        }
      },
      onAttributeTypeChange(expressionGroupIndex, targetIndex) {
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].operator =
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeType ===
          'string'
            ? stringOperators[0]
            : this.numberOperators[0];

        if (Array.isArray(this.currentValue(expressionGroupIndex, targetIndex))) {
          this.resetToDefaultValue(expressionGroupIndex, targetIndex);
        }
      },
      setAttributeType(event, expressionGroupIndex, targetIndex) {
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].attributeType =
          event.key;
        this.onAttributeTypeChange(expressionGroupIndex, targetIndex);
      },
      setAttributeValue(event, expressionGroupIndex, targetIndex) {
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value = event.key;
      },
      getAttributeValue(expressionGroupIndex, targetIndex) {
        const value = this.currentValue(expressionGroupIndex, targetIndex);
        const options =
          this.attributes.find(
            (variable) =>
              variable.key === this.currentAttributeName(expressionGroupIndex, targetIndex),
          )?.options || [];
        return options.find((option) => option.key === value) || options[0];
      },
      initDateValue(expressionGroupIndex, targetIndex) {
        if (!Array.isArray(this.currentValue(expressionGroupIndex, targetIndex))) {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value = [
            null,
            null,
          ];
        }
      },
      getDateFromDateTime(dateTime) {
        const year = moment(dateTime).get('year');
        const month = moment(dateTime).get('month');
        const date = moment(dateTime).get('date');
        return { year, month, date };
      },
      getTimestampInUTC(dateEvent, index, operator) {
        const { year, month, date } = this.getDateFromDateTime(dateEvent);

        let timestamp = moment([year, month, date, 0, 0, 0]).utcOffset(0, true);
        timestamp =
          index === 1 || operator === 'dateIsAfter'
            ? timestamp.set({ hour: 23, minute: 59, second: 59 })
            : timestamp.set({ hour: 0, minute: 0, second: 0 });
        return timestamp.unix() * 1000;
      },
      setDateAttributeValue(event, expressionGroupIndex, targetIndex, dateIndex) {
        this.initDateValue(expressionGroupIndex, targetIndex);
        if (this.isRelativeDate(expressionGroupIndex, targetIndex)) {
          const periodType = this.timeUnitOptions.find(
            (period) => period.key === this.currentTimePeriod(expressionGroupIndex, targetIndex),
          );
          const timeInMilliseconds = event * 60 * 60 * 1000 * periodType.timeUnitMultiplier;

          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value.splice(
            dateIndex,
            1,
            timeInMilliseconds,
          );
        } else {
          const operator = this.currentOperator(expressionGroupIndex, targetIndex);
          const timestamp = this.getTimestampInUTC(event, dateIndex, operator);

          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value.splice(
            dateIndex,
            1,
            timestamp,
          );
        }
      },
      getDateAttributeValue(expressionGroupIndex, targetIndex, dateIndex) {
        const operator = this.currentOperator(expressionGroupIndex, targetIndex);
        if (this.isRelativeDate(expressionGroupIndex, targetIndex)) {
          const timeInMilliseconds = this.currentValue(expressionGroupIndex, targetIndex)[
            dateIndex
          ];
          const timeUnit = this.timeUnitOptions.find(
            (period) => period.key === this.currentTimePeriod(expressionGroupIndex, targetIndex),
          );
          if (!timeInMilliseconds) return null;
          return timeInMilliseconds / 60 / 60 / 1000 / timeUnit.timeUnitMultiplier;
        }

        if (!Array.isArray(this.currentValue(expressionGroupIndex, targetIndex))) {
          return this.getTimestampInUTC(new Date(), 0, operator);
        }

        if (
          typeof this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value[
            dateIndex
          ] !== 'number'
        ) {
          return this.getTimestampInUTC(new Date(), 0, operator);
        }
        let savedDate =
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value[dateIndex];
        savedDate = moment(savedDate).utc();
        const { year, month, date } = this.getDateFromDateTime(savedDate);
        savedDate = moment([year, month, date, 0, 0, 0]);
        return savedDate.toDate();
      },
      setNewMillisecondValues(expressionGroupIndex, targetIndex, oldTimeUnitKey) {
        const currentValues = this.currentValue(expressionGroupIndex, targetIndex);
        const newTimeUnitKey = this.timeUnitOptions.find(
          (period) => period.key === this.currentTimePeriod(expressionGroupIndex, targetIndex),
        );
        const oldTimeUnit = this.timeUnitOptions.find((period) => period.key === oldTimeUnitKey);
        currentValues.forEach((value, index) => {
          const convertedTime =
            (value / oldTimeUnit.timeUnitMultiplier) * newTimeUnitKey.timeUnitMultiplier;
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value.splice(
            index,
            1,
            convertedTime,
          );
        });
      },
      setTimeUnit(event, expressionGroupIndex, targetIndex) {
        const oldTimeUnitKey = this.currentTimePeriod(expressionGroupIndex, targetIndex);
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].timeUnit =
          event.key;
        this.setNewMillisecondValues(expressionGroupIndex, targetIndex, oldTimeUnitKey);
      },
      getTimeUnit(expressionGroupIndex, targetIndex) {
        return this.timeUnitOptions.find(
          (period) => period.key === this.currentTimePeriod(expressionGroupIndex, targetIndex),
        );
      },
      getInputPrefix(expressionGroupIndex, targetIndex) {
        if (this.isPrefilledUrlInput(expressionGroupIndex, targetIndex)) {
          return this.domainWithDash;
        }
        return '';
      },
      getInputValue(expressionGroupIndex, targetIndex, index) {
        if (!this.isSecondInputField(expressionGroupIndex, targetIndex)) {
          return this.currentValue(expressionGroupIndex, targetIndex);
        }
        return this.currentValue(expressionGroupIndex, targetIndex)[index];
      },
      setInputValue(event, expressionGroupIndex, targetIndex, index) {
        this.initDateValue(expressionGroupIndex, targetIndex);
        if (!this.isSecondInputField(expressionGroupIndex, targetIndex)) {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value = event;
        } else {
          this.currentRule.options.expressions[expressionGroupIndex][targetIndex].value.splice(
            index,
            1,
            event,
          );
        }
      },
      beforeSaveCallback() {
        this.modifyExpressionValue(this.addStartingSlash);
      },
      modifyExpressionValue(callback) {
        const toIterate =
          this.currentRule.options.expressions || this.currentRule.options.expression;

        toIterate.forEach((expressionGroup) => {
          const expGroupArray = Array.isArray(expressionGroup)
            ? expressionGroup
            : [expressionGroup];

          expGroupArray.forEach((expression) => {
            if (
              expression.attributeName &&
              expression.attributeName === 'first_landing_page' &&
              this.needStartingSlash(expression.operator)
            ) {
              expression.value = callback(expression.value);
            }
          });
        });
      },
    },
    validations: {
      currentRule: {
        options: {
          expressions: {
            $each: {
              $each: {
                attributeName: {
                  required,
                },
                value: {
                  required: requiredIf(function ({ attributeName, operator }) {
                    if (
                      attributeName === 'first_landing_page' &&
                      this.needStartingSlash(operator)
                    ) {
                      return false;
                    }
                    return this.needsInput(operator);
                  }),
                  isCool(val, { attributeName, attributeType, operator }) {
                    if (attributeType === 'number' && !existsOperators.includes(operator)) {
                      if (operator === 'between') {
                        return (
                          val.every((v) => /^-?[0-9]*$/.test(v)) &&
                          parseFloat(val[1]) > parseFloat(val[0])
                        );
                      }
                      return /^-?[0-9]*$/.test(val);
                    }
                    if (['first_visit_date', 'popup_last_seen'].includes(attributeName)) {
                      if (relativeDateOperators.includes(operator)) {
                        if (dateIntervalOperators.includes(operator)) {
                          return val.every((v) => v && v > 0) && val[0] < val[1];
                        }
                        return val[0] > 0;
                      }
                      if (absoluteDateOperators.includes(operator)) {
                        if (dateIntervalOperators.includes(operator)) {
                          return val[0] !== null && val[1] !== null && val[1] > val[0];
                        }
                        return val[0] !== null;
                      }
                    }
                    return true;
                  },
                },
                timeUnit: {
                  required: requiredIf(function ({ attributeName, operator }) {
                    return (
                      ['first_visit_date', 'popup_last_seen'].includes(attributeName) &&
                      relativeDateOperators.includes(operator)
                    );
                  }),
                },
              },
            },
          },
        },
      },
    },
  };
</script>
<style scoped lang="sass">
  @import '@/sass/variables/_colors.sass'
  .target-group-container
    margin-bottom: 0.5rem
    &:last-child
      margin-bottom: 0!important
  .selector-wrapper
    flex-wrap: wrap
    min-height: 48px
    .custom-attribute-wrapper.is-custom
      flex-basis: 100%
      margin-bottom: 0.5rem
  .expression-group-container
    background-color: $om-gray-100
    border: 1px solid $om-gray-300
    padding: 1rem
    border-radius: 4px
  .attribute-slot-3
    display: flex
    align-items: center
  .attribute-flex-stretch
    flex: 1 1 0%
</style>
