<template lang="pug">
svg(width='50', height='50', viewBox='0 0 50 50', fill='none', xmlns='http://www.w3.org/2000/svg')
  path(fill-rule='evenodd', clip-rule='evenodd', d='M3.69905 18.3399H46.6334V15.3944H3.69905V18.3399ZM3.69905 13.6265L46.6334 13.6277V10.6822H3.69905V13.6277V13.6265ZM17.2257 5.96747C16.2509 5.96747 15.4616 6.75923 15.4616 7.73532C15.4616 8.71141 16.2509 9.50194 17.2257 9.50194C18.2006 9.50194 18.9899 8.71141 18.9899 7.73532C18.9899 6.75923 18.2006 5.96747 17.2257 5.96747ZM12.5222 5.96747C11.5474 5.96747 10.7581 6.75923 10.7581 7.73532C10.7581 8.71141 11.5474 9.50194 12.5222 9.50194C13.4971 9.50194 14.2863 8.71141 14.2863 7.73532C14.2863 6.75923 13.4971 5.96747 12.5222 5.96747ZM7.81619 5.96747C6.84258 5.96747 6.05206 6.75923 6.05206 7.73532C6.05206 8.71141 6.84258 9.50194 7.81619 9.50194C8.79104 9.50194 9.58033 8.71141 9.58033 7.73532C9.58033 6.75923 8.79104 5.96747 7.81619 5.96747ZM43.1855 0.0762939H6.56051C3.031 0.0762939 0.170776 2.94147 0.170776 6.47593V43.1616C0.170776 46.696 3.031 49.5612 6.56051 49.5612H43.1855C46.7138 49.5612 49.574 46.696 49.574 43.1616V6.47593C49.574 2.94147 46.7138 0.0762939 43.1855 0.0762939ZM43.6012 3.61076C45.2725 3.61076 46.6334 4.97406 46.6334 6.64789V43.5785C46.6334 45.2536 45.2725 46.6156 43.6012 46.6156H6.73124C5.05988 46.6156 3.69905 45.2536 3.69905 43.5785V6.64789C3.69905 4.97406 5.05988 3.61076 6.73124 3.61076H43.6012Z', :fill="color")
  path(d='M18.3434 38.9601V34.961L11.6285 32.5038L18.3434 30.0224V26.0452L7.81229 30.6324V34.3973L18.3434 38.9601Z', :fill="color")
  path(d='M17.9929 43.5228H21.2675L30.0881 22.2778H26.8135L17.9929 43.5228Z', :fill="color")
  path(d='M30.0289 38.9601L40.5575 34.3973V30.6324L30.0289 26.0452V30.0688L36.7657 32.5259L30.0289 34.9829V38.9601Z', :fill="color")
</template>

<script>
  import eventSvg from '@/mixins/eventSvg';

  export default {
    mixins: [eventSvg],
  };
</script>
