const randomString = ({ prefix = '', length = 7, separator = '-' } = {}) => {
  return (prefix ? `${prefix}${separator}` : '') + Math.random().toString(36).substring(length);
};
const isNumber = (value) => {
  return typeof value === 'number' && !Number.isNaN(value);
};

const isBoolean = (value) => {
  return typeof value === 'boolean';
};

const arrayify = (item) => {
  if (Array.isArray(item)) {
    return item;
  }

  if (item) {
    return [item];
  }

  return [];
};

const escapeStringRegexp = (regexString) => {
  if (typeof regexString !== 'string') {
    return regexString;
  }

  return regexString.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
};

const isInstanceOfAny = (object, classes) => {
  return classes.some((cls) => object instanceof cls);
};
const hasUniqueElements = (array) => {
  return array.length === new Set(array).size;
};

function stripLeadingSlash(pathname) {
  return pathname.startsWith('/') ? pathname.slice(1) : pathname;
}

module.exports = {
  escapeStringRegexp,
  randomString,
  arrayify,
  isNumber,
  isBoolean,
  isInstanceOfAny,
  hasUniqueElements,
  stripLeadingSlash,
};
