import i18n from '@/i18n';

const STEPS_COUNT = 5;

const closeSvg = () => {
  return `
    <svg style="cursor:pointer;float:right" onclick="window.OMProductTour.fn('close')" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4099 16.0002L21.7099 11.7102C21.8982 11.5219 22.004 11.2665 22.004 11.0002C22.004 10.7339 21.8982 10.4785 21.7099 10.2902C21.5216 10.1019 21.2662 9.99609 20.9999 9.99609C20.7336 9.99609 20.4782 10.1019 20.2899 10.2902L15.9999 14.5902L11.7099 10.2902C11.5216 10.1019 11.2662 9.99609 10.9999 9.99609C10.7336 9.99609 10.4782 10.1019 10.2899 10.2902C10.1016 10.4785 9.99585 10.7339 9.99585 11.0002C9.99585 11.2665 10.1016 11.5219 10.2899 11.7102L14.5899 16.0002L10.2899 20.2902C10.1962 20.3831 10.1218 20.4937 10.071 20.6156C10.0203 20.7375 9.99414 20.8682 9.99414 21.0002C9.99414 21.1322 10.0203 21.2629 10.071 21.3848C10.1218 21.5066 10.1962 21.6172 10.2899 21.7102C10.3829 21.8039 10.4935 21.8783 10.6154 21.9291C10.7372 21.9798 10.8679 22.006 10.9999 22.006C11.132 22.006 11.2627 21.9798 11.3845 21.9291C11.5064 21.8783 11.617 21.8039 11.7099 21.7102L15.9999 17.4102L20.2899 21.7102C20.3829 21.8039 20.4935 21.8783 20.6154 21.9291C20.7372 21.9798 20.8679 22.006 20.9999 22.006C21.132 22.006 21.2627 21.9798 21.3845 21.9291C21.5064 21.8783 21.617 21.8039 21.7099 21.7102C21.8037 21.6172 21.8781 21.5066 21.9288 21.3848C21.9796 21.2629 22.0057 21.1322 22.0057 21.0002C22.0057 20.8682 21.9796 20.7375 21.9288 20.6156C21.8781 20.4937 21.8037 20.3831 21.7099 20.2902L17.4099 16.0002Z" fill="#505763"/>
    </svg>
  `;
};

const getStepTranslation = (step, key) => i18n.t(`productTour.experiences.steps.${step}.${key}`);

const createTwoColumnContent = (step, { title, description, next = 'next' }) => {
  const nextText = i18n.t(next);
  const prevText = i18n.t('previous');
  const stepText = i18n.t('productTour.page', { current: step, all: STEPS_COUNT });
  const prevButton =
    step > 1
      ? `<button class="btn btn-link btn-sm mr-4 tour-prev" onclick="window.OMProductTour.fn('previous')">${prevText}</button>`
      : '';

  return `
    <div class="row">
      <div class="col-auto tour-experience-illustration">
        <img src="${require(`@/assets/admin/svg/productTours/experiences/step_${step}.svg`)}">
      </div>
      <div class="col tour-experience-content">
        <div class="d-flex flex-column justify-content-between h-100">
          <div class="content">
            <div class="heading">${closeSvg()}${title}</div>
            <div class="description">${description}</div>
          </div>
          <div class="footer d-flex justify-content-between align-items-end">
            <span class="step-status">${stepText}</span>
            <div class="buttons d-flex">
              ${prevButton}
              <button class="btn btn-link btn-sm tour-next" onclick="window.OMProductTour.fn('${next}')">${nextText}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `.trim();
};

export default [
  {
    element: '.experience-name-title > span',
    popover: {
      className: 'tour-step-experience-name',
      title: ' ',
      description: createTwoColumnContent(1, {
        title: getStepTranslation('name', 'title'),
        description: getStepTranslation('name', 'description'),
      }),
      // 'With Experiences, you can define which version of your campaign you want to show to each sub-group of the target audience. You must set up at least two different Experiences for personalization (but the visitor will always meet only one of them).',
      position: 'right',
    },
  },
  {
    element: '.experience-add-variant',
    popover: {
      className: 'tour-step-experience-add-variant',
      title: ' ',
      description: createTwoColumnContent(2, {
        title: getStepTranslation('addVariant', 'title'),
        description: getStepTranslation('addVariant', 'description'),
      }),
      position: 'right',
    },
  },
  {
    element: '.experience-add-rule',
    popover: {
      className: 'tour-step-experience-targeting',
      title: ' ',
      description: createTwoColumnContent(3, {
        title: getStepTranslation('addRule', 'title'),
        description: getStepTranslation('addRule', 'description'),
      }),
      position: 'right',
    },
  },
  {
    element: '.priority-arrow-container',
    popover: {
      className: 'tour-step-experience-priority',
      title: ' ',
      description: createTwoColumnContent(4, {
        title: getStepTranslation('priority', 'title'),
        description: getStepTranslation('priority', 'description'),
      }),
      position: 'right',
    },
  },
  {
    element: '.how-it-works',
    popover: {
      className: 'tour-step-experience-how-it-works',
      title: ' ',
      description: createTwoColumnContent(5, {
        title: getStepTranslation('howItWorks', 'title'),
        description: getStepTranslation('howItWorks', 'description'),
        next: 'finish',
      }),
      position: 'right',
    },
  },
];
