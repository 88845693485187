import {
  RULES_CATEGORIES,
  RULE_BLOCK_IP_ADDRESSES,
  RULE_CART_RULES,
  RULE_COOKIE_SEGMENTATION,
  RULE_CURRENT_PAGE_URL,
  RULE_CURRENT_PAGE_URL_V2,
  RULE_CUSTOM_VARIABLES,
  RULE_DETECT_ADBLOCKER,
  RULE_ENGAGED_WITH_CAMPAIGNS,
  RULE_EXCLUDE_PAGE_VISITORS,
  RULE_FREQUENCY,
  RULE_SPENT_ON_PAGE,
  RULE_SPENT_ON_SITE,
  RULE_SUBSCRIBERS,
  RULE_KLAVIYO_LIST_AND_SEGMENTS,
  RULE_CART_RULES_V2,
} from './rules';

const newEmbeddedRulesFilter = (rules) => {
  rules[RULES_CATEGORIES.TIME_AND_CONTEXT] = rules[RULES_CATEGORIES.TIME_AND_CONTEXT].filter(
    (rule) => ![RULE_SPENT_ON_PAGE].includes(rule),
  );
  rules[RULES_CATEGORIES.VISITORS] = rules[RULES_CATEGORIES.VISITORS].filter(
    (rule) => ![RULE_BLOCK_IP_ADDRESSES, RULE_DETECT_ADBLOCKER].includes(rule),
  );
  return rules;
};

export const FILTERS_BY_TYPE = {
  embedded(rules) {
    rules[RULES_CATEGORIES.TIME_AND_CONTEXT] = rules[RULES_CATEGORIES.TIME_AND_CONTEXT].filter(
      (rule) =>
        ![
          RULE_FREQUENCY,
          RULE_SPENT_ON_PAGE,
          RULE_SPENT_ON_SITE,
          RULE_ENGAGED_WITH_CAMPAIGNS,
          RULE_CUSTOM_VARIABLES,
        ].includes(rule),
    );
    rules[RULES_CATEGORIES.PAGES_AND_CUSTOM] = rules[RULES_CATEGORIES.PAGES_AND_CUSTOM].filter(
      (rule) =>
        ![
          RULE_CURRENT_PAGE_URL,
          RULE_CURRENT_PAGE_URL_V2,
          RULE_EXCLUDE_PAGE_VISITORS,
          RULE_CART_RULES,
          RULE_COOKIE_SEGMENTATION,
          RULE_CUSTOM_VARIABLES,
        ].includes(rule),
    );
    rules[RULES_CATEGORIES.VISITORS] = rules[RULES_CATEGORIES.VISITORS].filter(
      (rule) => ![RULE_BLOCK_IP_ADDRESSES, RULE_DETECT_ADBLOCKER].includes(rule),
    );
    return rules;
  },
  embeddedv3: newEmbeddedRulesFilter,
  dynamic_content: newEmbeddedRulesFilter,
  default(rules) {
    return rules;
  },
};

export const FEATURE_EXPERIENCES = 'experiences';
export const CURRENT_URL_V1 = 'CURRENT_URL_V1';
export const CURRENT_URL_V2 = 'CURRENT_URL_V2';
export const VISITOR_CART_V3_UI = 'VISITOR_CART_V3_UI';
export const VISITOR_CART_V3 = 'VISITOR_CART_V3';
export const EXCLUDES_EXPERIENCES = [
  RULE_FREQUENCY,
  RULE_EXCLUDE_PAGE_VISITORS,
  RULE_SPENT_ON_PAGE,
];

export const REMOVE_JETFABRIC_EMBEDDED = 'jfEmbedded';
const EXCLUDES_JETFABRIC_EMBEDDED = [RULE_SUBSCRIBERS, RULE_KLAVIYO_LIST_AND_SEGMENTS];

export const FILTERS_BY_FEATURE = {
  [FEATURE_EXPERIENCES](rules) {
    rules[RULES_CATEGORIES.PAGES_AND_CUSTOM] = rules[RULES_CATEGORIES.PAGES_AND_CUSTOM].filter(
      (rule) => !EXCLUDES_EXPERIENCES.includes(rule),
    );
    rules[RULES_CATEGORIES.TIME_AND_CONTEXT] = rules[RULES_CATEGORIES.TIME_AND_CONTEXT].filter(
      (rule) => !EXCLUDES_EXPERIENCES.includes(rule),
    );
    return rules;
  },
  [CURRENT_URL_V1](rules) {
    rules[RULES_CATEGORIES.PAGES_AND_CUSTOM] = rules[RULES_CATEGORIES.PAGES_AND_CUSTOM].filter(
      (rule) => rule !== RULE_CURRENT_PAGE_URL_V2,
    );
    return rules;
  },
  [CURRENT_URL_V2](rules) {
    rules[RULES_CATEGORIES.PAGES_AND_CUSTOM] = rules[RULES_CATEGORIES.PAGES_AND_CUSTOM].filter(
      (rule) => rule !== RULE_CURRENT_PAGE_URL,
    );
    return rules;
  },
  [VISITOR_CART_V3](rules) {
    rules[RULES_CATEGORIES.PAGES_AND_CUSTOM] = rules[RULES_CATEGORIES.PAGES_AND_CUSTOM].filter(
      (rule) => rule !== RULE_CART_RULES_V2 && rule !== RULE_CART_RULES,
    );
    return rules;
  },
  [VISITOR_CART_V3_UI](rules) {
    rules[RULES_CATEGORIES.PAGES_AND_CUSTOM].push(RULE_CART_RULES_V2, RULE_CART_RULES);

    rules[RULES_CATEGORIES.PAGES_AND_CUSTOM] = [
      ...new Set(rules[RULES_CATEGORIES.PAGES_AND_CUSTOM]),
    ];

    return rules;
  },
  [REMOVE_JETFABRIC_EMBEDDED](rules) {
    rules[RULES_CATEGORIES.VISITORS] = rules[RULES_CATEGORIES.VISITORS].filter(
      (rule) => !EXCLUDES_JETFABRIC_EMBEDDED.includes(rule),
    );

    return rules;
  },
};
