import ssrMixin from '@/mixins/ssr';
import observableCollectionMixin from '@/mixins/observableCollection';

export default {
  data() {
    return {
      campaign: {},
      variants: [],
      ssrBoxSelector: '.template-preview',
      intersectionObserverRootMargin: '400px 0px 400px 0px',
      intersectionRoot: null,
    };
  },
  mixins: [ssrMixin, observableCollectionMixin],
  computed: {
    campaignHasControlVariant() {
      const controlVariant = this.variants.filter((variant) => {
        return !!variant?.isControlVariant;
      });

      return controlVariant.length;
    },
  },
  methods: {
    onObservable(event, selector) {
      if (!this.intersectionRoot && selector) {
        this.intersectionRoot = document.querySelector(selector);
      }
      this.addObservable(event.$el);
    },

    onOpened() {
      this.updateDimensions();
      this.$nextTick(() => {
        if (Array.isArray(this.$refs.variants)) {
          this.$refs.variants.forEach((variant) => {
            variant.shown = false;
            variant.onIntersecting();
          });
        } else {
          this.$refs.variants.shown = false;
          this.$refs.variants.onIntersecting();
        }
      });
    },
    beforeClose() {
      const { variants } = this.$refs;
      if (Array.isArray(variants)) {
        variants.forEach((variant) => {
          variant.shown = false;
        });
      } else {
        variants.shown = false;
      }
    },
  },
};
