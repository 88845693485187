import {
  stringOperators,
  booleanOperators,
  allStringOperators,
  dateOperators,
  dateIntervalOperators,
  absoluteDateOperators,
  relativeDateOperators,
} from '@/components/CampaignSettings/Condition/statics';
import momentTZ from 'moment-timezone';
import moment from 'moment';
import { languageCodes } from '@/config/language_codes';

export default {
  data() {
    return {
      utmVariables: ['utm_campaign', 'utm_medium', 'utm_source'],
      browserTypes: [
        { key: 'Mobile Safari', value: 'Mobile Safari' },
        { key: 'Safari', value: 'Safari' },
        { key: 'Chrome', value: 'Chrome' },
        { key: 'Firefox', value: 'Firefox' },
        { key: 'Facebook', value: 'Facebook' },
        { key: 'Instagram', value: 'Instagram' },
        { key: 'Samsung Browser', value: 'Samsung Browser' },
        { key: 'Edge', value: 'Edge' },
      ],
      osTypes: [
        { key: 'Android', value: 'Android' },
        { key: 'Windows', value: 'Windows' },
        { key: 'iOS', value: 'iOS' },
        { key: 'Mac OS', value: 'Mac OS' },
        { key: 'Linux', value: 'Linux' },
      ],
      months: [
        {
          key: 'January',
          value: this.$t('months.january'),
        },
        {
          key: 'February',
          value: this.$t('months.february'),
        },
        {
          key: 'March',
          value: this.$t('months.march'),
        },
        {
          key: 'April',
          value: this.$t('months.april'),
        },
        {
          key: 'May',
          value: this.$t('months.may'),
        },
        {
          key: 'June',
          value: this.$t('months.june'),
        },
        {
          key: 'July',
          value: this.$t('months.july'),
        },
        {
          key: 'August',
          value: this.$t('months.august'),
        },
        {
          key: 'September',
          value: this.$t('months.september'),
        },
        {
          key: 'October',
          value: this.$t('months.october'),
        },
        {
          key: 'November',
          value: this.$t('months.november'),
        },
        {
          key: 'December',
          value: this.$t('months.december'),
        },
      ],
      timeUnitOptions: [
        {
          key: 'hours',
          value: this.$t('hours'),
          timeUnitMultiplier: 1,
        },
        {
          key: 'days',
          value: this.$t('days'),
          timeUnitMultiplier: 24,
        },
        {
          key: 'weeks',
          value: this.$t('weeks'),
          timeUnitMultiplier: 24 * 7,
        },
      ],
      deviceTypes: [
        { key: 'desktop', value: this.$t('device.desktop') },
        { key: 'mobile', value: this.$t('device.mobile') },
      ],
    };
  },
  computed: {
    attributes() {
      return [
        {
          key: 'utm_campaign',
          value: this.$t('frontendRules.visitorAttribute.operators.utmCampaign'),
          operators: stringOperators,
        },
        {
          key: 'utm_medium',
          value: this.$t('frontendRules.visitorAttribute.operators.utmMedium'),
          operators: stringOperators,
        },
        {
          key: 'utm_source',
          value: this.$t('frontendRules.visitorAttribute.operators.utmSource'),
          operators: stringOperators,
        },
        {
          key: 'device_type',
          value: this.$t('frontendRules.visitorAttribute.operators.deviceType'),
          operators: booleanOperators,
          options: this.deviceTypes,
        },
        {
          key: 'os_name',
          value: this.$t('frontendRules.visitorAttribute.operators.osType'),
          operators: booleanOperators,
          options: this.osTypes,
        },
        {
          key: 'browser_name',
          value: this.$t('frontendRules.visitorAttribute.operators.browserType'),
          operators: booleanOperators,
          options: this.browserTypes,
        },
        {
          key: 'browser_language',
          value: this.$t('frontendRules.visitorAttribute.operators.browserLanguage'),
          operators: booleanOperators,
          options: this.localCodes,
        },
        {
          key: 'tz',
          value: this.$t('frontendRules.visitorAttribute.operators.timezone'),
          operators: booleanOperators,
          options: this.timezones,
        },
        {
          key: 'first_landing_page',
          value: this.$t('frontendRules.visitorAttribute.operators.firstLanding'),
          operators: allStringOperators,
        },
        {
          key: 'current_month_text_en',
          value: this.$t('frontendRules.visitorAttribute.operators.currentMonth'),
          operators: booleanOperators,
          options: this.months,
        },
        {
          key: 'first_visit_date',
          value: this.$t('frontendRules.visitorAttribute.operators.firstVisitDate'),
          operators: dateOperators,
        },
        {
          key: 'popup_last_seen',
          value: this.$t('frontendRules.visitorAttribute.operators.campaignLastSeen'),
          operators: dateOperators,
        },
        {
          key: 'custom_attribute',
          value: this.$t('frontendRules.visitorAttribute.operators.customAttribute'),
        },
      ];
    },
    timezones() {
      const countryCodes = momentTZ.tz.countries();
      const timeZones = countryCodes.map((code) => momentTZ.tz.zonesForCountry(code, true)).flat();
      const uniqueTimezones = [];
      timeZones.forEach((timezone) => {
        if (!uniqueTimezones.some((uniqueTimeZone) => uniqueTimeZone.name === timezone.name)) {
          uniqueTimezones.push(timezone);
        }
      });
      const timeZoneOptions = uniqueTimezones.map((zone) => {
        return {
          key: zone.name,
          value: `${zone.name} ${this.convertOffsetToHours(zone.offset)}`,
        };
      });
      return timeZoneOptions.sort((a, b) => (a.key > b.key ? 1 : -1));
    },
    localCodes() {
      const locale = this.$i18n.locale;
      const languages = new Intl.DisplayNames([locale], {
        languageDisplay: 'standard',
        type: 'language',
      });
      return languageCodes.map((code) => {
        return {
          key: code,
          value: languages.of(code),
        };
      });
    },
    frontendRule() {
      return this.currentRule || this.rule;
    },
  },

  methods: {
    currentValue(expressionGroupIndex, targetIndex) {
      return this.frontendRule.options.expressions[expressionGroupIndex][targetIndex].value;
    },
    currentOperator(expressionGroupIndex, targetIndex) {
      return this.frontendRule.options.expressions[expressionGroupIndex][targetIndex].operator;
    },
    currentTimePeriod(expressionGroupIndex, targetIndex) {
      return this.frontendRule.options.expressions[expressionGroupIndex][targetIndex].timeUnit;
    },
    currentAttributeName(expressionGroupIndex, targetIndex) {
      return this.frontendRule.options.expressions[expressionGroupIndex][targetIndex].attributeName;
    },
    currentAttributeType(expressionGroupIndex, targetIndex) {
      return this.frontendRule.options.expressions[expressionGroupIndex][targetIndex].attributeType;
    },
    getCurrentAttribute(expressionGroupIndex, targetIndex) {
      const variableType = this.attributes.find(
        (type) => type.key === this.currentAttributeName(expressionGroupIndex, targetIndex),
      );
      if (!variableType) {
        return {
          key: 'custom_attribute',
          value: this.$t('frontendRules.visitorAttribute.operators.customAttribute'),
        };
      }

      return variableType;
    },

    isCustomAttribute(expressionGroupIndex, targetIndex) {
      const currentVariableType = this.getCurrentAttribute(expressionGroupIndex, targetIndex);
      return currentVariableType.key === 'custom_attribute';
    },
    isDateProperty(expressionGroupIndex, targetIndex) {
      return ['first_visit_date', 'popup_last_seen'].includes(
        this.currentAttributeName(expressionGroupIndex, targetIndex),
      );
    },
    isRelativeDate(expressionGroupIndex, targetIndex) {
      return (
        this.isDateProperty(expressionGroupIndex, targetIndex) &&
        relativeDateOperators.includes(this.currentOperator(expressionGroupIndex, targetIndex))
      );
    },
    isAbsoluteDate(expressionGroupIndex, targetIndex) {
      return (
        this.isDateProperty(expressionGroupIndex, targetIndex) &&
        absoluteDateOperators.includes(this.currentOperator(expressionGroupIndex, targetIndex))
      );
    },

    convertOffsetToHours(offset) {
      const sign = offset > 0 ? '-' : offset < 0 ? '+' : '';
      return `UTC ${sign}${Math.round(Math.abs(offset) / 60)}`;
    },
    getRuleSummary(expressionGroupIndex, targetIndex) {
      return {
        name: this.getCurrentAttribute(expressionGroupIndex, targetIndex).value,
        operator: this.getCustomPropertyOperator(expressionGroupIndex, targetIndex),
        value: this.formatCustomPropertyvalue(expressionGroupIndex, targetIndex),
      };
    },
    stringifyDates(formattedDates, operator, timeUnit) {
      let formattedDate = '';
      if (dateIntervalOperators.includes(operator)) {
        formattedDate = `${formattedDates[0]} ${this.$t('and')} ${formattedDates[1]}`;
      } else {
        formattedDate = formattedDates[0];
      }
      formattedDate += timeUnit ? ` ${timeUnit.value}` : '';
      return formattedDate;
    },
    isAgo(expressionGroupIndex, targetIndex) {
      return (
        this.isRelativeDate(expressionGroupIndex, targetIndex) &&
        this.currentOperator(expressionGroupIndex, targetIndex) === 'dateIsAtLeast' &&
        this.$i18n.locale === 'en'
      );
    },
    formatCustomPropertyvalue(expressionGroupIndex, targetIndex) {
      const attributeName = this.currentAttributeName(expressionGroupIndex, targetIndex);
      const timeUnitKey = this.currentTimePeriod(expressionGroupIndex, targetIndex);
      const operator = this.currentOperator(expressionGroupIndex, targetIndex);
      const value = this.currentValue(expressionGroupIndex, targetIndex);

      let formattedDates;

      if (this.isAbsoluteDate(expressionGroupIndex, targetIndex)) {
        const dateFormat = this.$i18n.locale === 'en' ? 'MM/DD/YYYY' : 'YYYY/MM/DD';
        formattedDates = value.map((v) => moment(new Date(v)).utc().format(dateFormat));
        return this.stringifyDates(formattedDates, operator);
      }

      if (this.isRelativeDate(expressionGroupIndex, targetIndex)) {
        const timeUnit = this.timeUnitOptions.find((option) => option.key === timeUnitKey);
        formattedDates = value.map((v) => v / 60 / 60 / 1000 / timeUnit.timeUnitMultiplier);
        formattedDates = this.stringifyDates(formattedDates, operator, timeUnit);
        return `${formattedDates}${this.isAgo(expressionGroupIndex, targetIndex) ? ' ago' : ''}`;
      }

      if (attributeName === 'current_month_text_en') {
        return this.months.find((m) => m.key === value)?.value;
      }
      if (attributeName === 'browser_language') {
        return this.localCodes.find((code) => code.key === value)?.value;
      }
      if (attributeName === 'device_type') {
        return this.$t(`device.${value}`);
      }

      return value;
    },
    getCustomPropertyOperator(expressionGroupIndex, targetIndex) {
      const operator = this.currentOperator(expressionGroupIndex, targetIndex);
      if (this.$i18n.locale === 'hu') {
        if (operator === 'is') return '';
        if (operator === 'isNot') return this.$t('no').toLowerCase();
      }
      return this.$t(operator).toLowerCase();
    },
  },
};
