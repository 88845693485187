<template lang="pug">
.campaign-settings-block.card
  slot
</template>

<script>
  export default {
    name: 'CampaignSettingsBlockBase',
  };
</script>
