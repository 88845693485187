const ALPHABET_LENGTH = 26;

const generateNextCharId = (count) => {
  if (count < ALPHABET_LENGTH) {
    const letter = String.fromCharCode(65 + count);
    return letter;
  }

  return (
    generateNextCharId(count / ALPHABET_LENGTH - 1) + generateNextCharId(count % ALPHABET_LENGTH)
  );
};

module.exports = {
  generateNextCharId,
};
