<template lang="pug">
component(:is="camelCaseToDash(type)" :animationActive="isAnimationActive")
</template>

<script>
  import camelCaseToDash from '@/mixins/camelCaseToDash';
  import Click from './Click.vue';
  import ExitIntent from './ExitIntent.vue';
  import Timed from './Timed.vue';
  import ScrollDown from './ScrollDown.vue';
  import JavascriptEvent from './JavascriptEvent.vue';

  export default {
    components: {
      Click,
      ExitIntent,
      Timed,
      ScrollDown,
      Inactivity: Timed,
      JavascriptEvent,
      AvgTimeOnPage: Timed,
    },
    mixins: [camelCaseToDash],

    props: {
      type: { type: String, required: true },
      activeAnimation: { type: String },
    },

    computed: {
      isAnimationActive() {
        return this.type === this.activeAnimation;
      },
    },
  };
</script>
