import GET_RECOMMENDED_USE_CASES from '@/graphql/GetRecommendedUseCases.gql';
import GET_SIMILAR_TEMPLATES from '@/graphql/GetSimilarTemplates.gql';
import IS_WIZARD_RECOMMENDATION_ENABLED from '@/graphql/IsWizardRecommendationEnabled.gql';
import SET_WIZARD_PREFERENCE from '@/graphql/SetWizardPreference.gql';
import { pick } from 'lodash-es';

export function buildTemplateList(useCases, preferences, themeKit = false) {
  const templates = [];
  const extraTemplates = [];
  const addedUsecase = [];
  useCases.forEach((useCase) => {
    if (useCase._id === preferences.useCase) {
      return;
    }
    const preferredStyle = preferences.style || 'Clean';
    if (useCase.templates.length > 0) {
      if (themeKit) {
        const useCaseTemplates = useCase.templates.filter((template) => {
          return template?.template?.themeKit?.name === preferredStyle;
        });
        const interstitial = useCaseTemplates.find(({ type }) =>
          ['fullscreen', 'interstitial'].includes(type),
        );
        const popup = interstitial || useCaseTemplates.find(({ type }) => type === 'popup');
        const sidemessage = popup || useCaseTemplates.find(({ type }) => type === 'sidebar');
        const nanobar = sidemessage || useCaseTemplates.find(({ type }) => type === 'nanobar');
        const template = nanobar || useCaseTemplates.find(({ type }) => type === 'embedded');
        const templateValues = pick(useCase, ['name', 'targeting', 'useCase']);

        const realTemplate = Object.assign(template || useCaseTemplates[0] || {}, templateValues);

        templates.push(realTemplate);
        addedUsecase.push(useCase._id);
      } else {
        useCase.templates.forEach((template) => {
          if (addedUsecase.includes(useCase._id)) return;
          const templateValues = pick(useCase, ['name', 'targeting', 'useCase']);
          const thumbnailValues = pick(useCase.thumbnail, [
            'type',
            'universal',
            'isFullHeight',
            'overlayColor',
            'hasWheel',
          ]);
          template = Object.assign(template, templateValues, thumbnailValues);
          template.useCase = useCase._id;

          if (preferredStyle === template.theme) {
            templates.push(template);
            addedUsecase.push(useCase._id);
          }
        });
      }
    }
  });
  return [...templates, ...extraTemplates];
}

function buildSimilarTemplateList(useCase) {
  useCase.templates.forEach((template) => {
    template.name = useCase.name;
    template.useCase = useCase._id;
    template.targeting = useCase.targeting;
    template.thumbnail = useCase.previews;
  });
  return useCase.templates;
}

export default {
  computed: {
    onChooserPage() {
      return this.$route.name.includes('new_campaign'); // it will show rec slider on onboarding flow: new_campaign_onboarding
    },
    onNewCampaignOnboarding() {
      return this.$route.name === 'new_campaign_onboarding'; // it will show rec slider on onboarding flow: new_campaign_onboarding
    },
  },
  methods: {
    async handleSetRequest(key, value) {
      try {
        const { data: success } = await this.$apollo.query({
          query: SET_WIZARD_PREFERENCE,
          variables: { key, value },
        });
        return success;
      } catch (err) {
        return false;
      }
    },
    routeToSimilarTemplates(useCase, color) {
      if (!useCase) {
        return;
      }
      let useCaseId = useCase;
      if (typeof useCase === 'object') {
        useCaseId = useCase._id;
      }
      const query = { id: useCaseId };

      if (color) {
        query.color = color;
      }

      this.$router.push({
        name: 'similar_templates',
        query,
      });
    },
    async dismissWizardRecommendation() {
      return this.handleSetRequest('recommendationDismissed', true);
    },

    async isTemplateRecommendationEnabled() {
      if (this.$i18n.locale === 'hu') return false;
      if (this.onChooserPage || this.onNewCampaignOnboarding) return true;
      try {
        const {
          data: { isWizardRecommendationEnabled },
        } = await this.$apollo.query({
          query: IS_WIZARD_RECOMMENDATION_ENABLED,
        });
        return isWizardRecommendationEnabled;
      } catch (err) {
        return false;
      }
    },
    async fetchRecommendedUseCases(preferences) {
      const params = {
        ...preferences,
        customTheme: true,
      };
      const {
        data: { useCases },
      } = await this.$apollo.query({
        query: GET_RECOMMENDED_USE_CASES,
        variables: { params },
      });

      return useCases;
    },

    async getRecommendedUseCases(preferences) {
      const params = {
        ...preferences,
        customTheme: true,
      };
      const {
        data: { useCases },
      } = await this.$apollo.query({
        query: GET_RECOMMENDED_USE_CASES,
        variables: { params },
      });

      return buildTemplateList(useCases, preferences, true);
    },

    async getSimilarTemplates(preferences) {
      const {
        data: { useCase },
      } = await this.$apollo.query({
        query: GET_SIMILAR_TEMPLATES,
        variables: {
          id: preferences.useCase,
        },
      });

      return buildSimilarTemplateList(useCase);
    },
  },
};
