<template lang="pug">
.dashboard-stats-skeleton
  .stats-header.d-flex
    vue-skeleton-loader.selector(type="rect" width="210px" height="32px" :rounded="rounded")
    .d-flex.totals
      .total-block.d-flex(v-for="i in 5" :key="i")
        vue-skeleton-loader.total-stat(type="rect" width="100%" height="86px" :rounded="rounded")
        .vertical-line
  hr.header-content-separator
  .stats-content
    vue-skeleton-loader.skeleton-row(
      v-for="index in 6"
      type="rect"
      width="100%"
      height="36px"
      :key="index"
    )
</template>

<script>
  export default {
    props: {
      rounded: { type: Boolean },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../../sass/variables/_colors'
  .stats-header
    flex-direction: column

    .selector
      align-self: flex-end
      margin-bottom: 1.2rem

    .totals
      width: 90%

    .total-block
      width: 20%
      max-width: 175px

      &:first-child
        .total-stat
          margin-left: 0
      &:last-child
        .total-stat
          margin-right: 2.4rem

        .vertical-line
          display: none

    .total-stat
      margin-right: 1.2rem

    .vertical-line
      height: 86px
      width: 2px
      background-color: $om-gray-300
      margin-right: 1.2rem

  .header-content-separator
    margin: 1.2rem 0

  .skeleton-row
    margin-bottom: 2px
</style>
