<template lang="pug">
svg(width='50', height='50', viewBox='0 0 50 50', fill='none', xmlns='http://www.w3.org/2000/svg')
  path(d='M49.8061 36.1072L26.7731 14.9277L26.7578 46.218L33.7472 39.5303L37.8523 48.8881L44.2571 46.0786L40.152 36.7207L49.8061 36.1072Z', :fill="color")
  path(d='M24.9522 35.6334C23.7868 36.138 22.5618 36.5179 21.2943 36.753C15.7015 37.7892 9.66791 36.006 5.53124 32.0146C1.27238 27.9053 -0.742803 21.6149 0.335699 15.7943C1.38663 10.1235 5.35204 5.08069 10.6116 2.70124C12.9614 1.63839 15.5462 1.10428 18.1214 1.11246C22.6151 1.15475 27.0618 2.92882 30.3242 6.00266C34.3008 9.74963 36.4017 15.3826 35.8921 20.8207L32.8788 18.0499C32.7347 15.9282 32.1522 13.8373 31.1365 11.9688C28.5973 7.29683 23.438 4.20009 18.1025 4.14962C18.038 4.14962 17.9733 4.14962 17.9088 4.14962C12.5618 4.20032 7.40741 7.30921 4.8747 11.9688C2.64457 16.0719 2.50041 21.2469 4.50578 25.4716C7.28895 31.335 14.1118 34.9948 20.7408 33.7663C22.2123 33.4936 23.6342 32.9845 24.9539 32.2794L24.9522 35.6334Z', :fill="color")
  path(fill-rule='evenodd', clip-rule='evenodd', d='M24.9673 28.0545C24.7893 28.0283 24.6159 27.9439 24.4512 27.7837L18.0169 21.3493L11.5828 27.7837C11.5055 27.8589 11.4987 27.8605 11.4592 27.8893C11.0524 28.1881 10.4424 28.1556 10.0594 27.8005C9.36179 27.1208 8.61833 26.2346 9.31693 25.5178L15.7513 19.0834L9.31716 12.6495C9.24169 12.5722 9.24006 12.5651 9.21132 12.5259C8.91249 12.1194 8.94613 11.5081 9.3001 11.1261C9.74403 10.6705 10.1515 10.1243 10.708 10.071C11.0258 10.0406 11.3517 10.1581 11.583 10.3836L18.0169 16.8177L24.451 10.3836C24.5592 10.2782 24.5891 10.2628 24.6657 10.2175C24.8398 10.1147 25.0402 10.0637 25.2475 10.0663C25.8405 10.0873 26.2629 10.6546 26.7171 11.1086C27.0704 11.4712 27.115 12.0696 26.8435 12.4901L24.9758 10.7729L24.9741 14.3923L20.2828 19.0834L24.9694 23.7701L24.9673 28.0545Z', :fill="color")
</template>

<script>
  import eventSvg from '@/mixins/eventSvg';

  export default {
    mixins: [eventSvg],
  };
</script>
