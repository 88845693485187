import moment from 'moment';

export default {
  methods: {
    genFakeChartData(interval) {
      const intervalLength = interval.to.diff(interval.from, 'days') + 1;
      return Array.from({ length: intervalLength }, () =>
        Math.floor(Math.random() * intervalLength),
      );
    },

    genFakeChartLabels(interval) {
      const dateArray = [];
      let currDate = interval.from;
      while (currDate <= interval.to) {
        dateArray.push(
          moment(currDate).format(this.$store.state.locale === 'hu' ? 'MM-DD' : 'DD/MM'),
        );
        currDate = moment(currDate).add(1, 'days');
      }
      return dateArray;
    },
  },
};
