export default {
  methods: {
    getRowLineType(index) {
      const rulesLength = this.rules.length;
      const start = index === 0 && rulesLength > 1;
      const end = (index === rulesLength - 1 && rulesLength > 1) || rulesLength === 1;
      return {
        'brand-settings-summary-row-line': start || end,
        'brand-settings-summary-row-line-start': start,
        'brand-settings-summary-row-line-end': end,
      };
    },
  },
};
