<template lang="pug">
.brand-rule-box(:class="small ? 'brand-rule-box-small' : ''")
  .row.w-100
    .col-9.d-flex.align-items-center
      trigger-svg.svg-preview.flex-shrink-0(type="click")
      .brand-rule-box-content(v-if="!manuallyEmbedded")
        .brand-rule-box-title {{ $t(`settingsSummary.pointClickTitle`) }}
        .brand-rule-box-lead.mt-2(v-if="!isEmbeddedV3") {{ campaign.domain + position.path }}
        .brand-rule-box-lead.mt-1 {{ $t(`settingsSummary.pointAndClick.whereIsItInserted`, { position: $t(`settingsSummary.pointAndClick.${position.position}`), selector: position.selector }) }}
      .brand-rule-box-content(v-else)
        .brand-rule-box-title {{ $t('embeddedManually') }}
        .brand-rule-box-lead.mt-2 {{ $t(`confirmedManuallyEmbedded`) }}
    .col-3.d-flex.align-items-center(:class="!small ? 'justify-content-end' : ''")
      om-device(
        v-if="campaign.device !== undefined"
        :type="campaign.device"
        :activeColor="small ? 'black' : undefined"
        :small="small"
      )
</template>

<script>
  import TriggerSvg from '@/components/Svg/Trigger/TriggerSvg.vue';
  import OmDevice from '@/components/OmDevice.vue';
  import embeddedV3 from '@/mixins/embeddedV3';

  export default {
    components: {
      TriggerSvg,
      OmDevice,
    },

    mixins: [embeddedV3],

    props: {
      position: {
        type: Object,
      },
      campaign: {
        type: Object,
      },
      small: {
        type: Boolean,
        default: false,
      },
      manuallyEmbedded: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
