<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='30', height='27', viewBox='0 0 30 27')
  path(:fill='color', fill-rule='evenodd', d='M1.053 12.408c-.336 0-.654-.162-.85-.435a1.067 1.067 0 0 1 .228-1.481L14.385.204a1.04 1.04 0 0 1 1.241 0l13.92 10.267.022.015c.227.167.376.413.42.693a1.059 1.059 0 0 1-1.041 1.224 1.04 1.04 0 0 1-.62-.204L15.003 2.37l-13.33 9.833a1.04 1.04 0 0 1-.62.204zM18.063 27a1.06 1.06 0 0 1-1.047-.969.887.887 0 0 1-.008-.09v-7.463c0-1.114-.9-2.02-2.005-2.02a2.015 2.015 0 0 0-2.006 2.02v7.477a.91.91 0 0 1-.007.076 1.06 1.06 0 0 1-1.043.969H4.302c-.58 0-1.052-.475-1.052-1.06V12.909c0-.583.472-1.059 1.051-1.059.58 0 1.052.475 1.053 1.06V24.88h5.54v-6.403c0-2.283 1.843-4.14 4.109-4.14 2.265 0 4.109 1.857 4.109 4.14v6.403h5.545V12.914c0-.559.432-1.018.977-1.057a.282.282 0 0 1 .065-.008h.01c.58 0 1.052.475 1.052 1.06V25.94c0 .584-.472 1.059-1.052 1.059h-7.646z')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
