export default {
  computed: {
    isEmbedded() {
      return this.campaign?.templateType === 'embedded';
    },
    isEmbeddedV3() {
      return this.isEmbedded && this.campaign?.version === 3;
    },
  },
};
