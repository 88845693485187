import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isSubUser', 'hasAccountFeature']),
    transKey() {
      const ruleType = this.ruleType.replace('Revamp', '');
      const wlRule = ['click', 'visitorAttribute', 'visitorCart'];
      const key = `${this.settingType}.${ruleType}.desc`;

      return this.isSubUser && wlRule.includes(ruleType) ? `${key}WL` : key;
    },
  },
};
