<template lang="pug">
om-modal.add-variant-to-experience-modal(
  name="add-variant-to-experience"
  :width="640"
  color="light"
  scrollable
  :clickToClose="true"
  @beforeOpen="beforeOpen"
  @opened="onOpened"
  :headerBorder="true"
)
  template(slot="modal-header")
    .brand-modal-action-icon
      close-icon.cursor-pointer(
        @click.native="$modal.hide('add-variant-to-experience')"
        :width="14"
        :height="14"
        color="#C2C2C2"
      )
    om-heading(h5) {{ $t('experiences.addVariantToExperienceModal.header') }}
  template(slot="modal-body")
    .variant-to-experience-container.col-12.mx-0.px-0.d-flex(v-if="isDynamicContent")
      .d-flex.mb-4.flex-column.w-100
        om-heading(h6) {{ $t('abTestModal.addNewVariant') }}
        .d-flex.align-items-center
          img.variant-placeholder.mt-4.cursor-pointer(
            :src="require('@/assets/admin/svg/dynamic-content-placeholder.svg')"
            @click="addNewDynamicContentToExperience"
          )
          .mt-4.ml-3
            om-body-text.variant-name.cursor-pointer(
              bt400md
              @click.native="addNewDynamicContentToExperience"
            ) {{ $t('abTestModal.newVariant') }}
    .variant-to-experience-container.col-12.mx-0.px-0.d-flex(
      v-if="!hasControlVariantInExperienceGroup && currentExperienceHasVariants"
    )
      .d-flex.mb-4.flex-column.w-100
        om-heading(h6) {{ $t('abTestModal.addControlVariant') }}
        .d-flex.align-items-center
          img.variant-placeholder.mt-4.cursor-pointer(
            :src="require('@/assets/admin/svg/control-variant-placeholder.svg')"
            @click="createControlVariant"
          )
          .mt-4.ml-3
            om-body-text.variant-name.cursor-pointer(bt400md @click.native="createControlVariant") {{ $t('abTestModal.controlVariant') }}
            om-body-text.variant-name.variant-name-description.cursor-pointer(
              v-if="!isDynamicContent"
              bt400xs
              @click.native="createControlVariant"
            ) {{ $t('abTestModal.controlVariantDesc') }}
            om-body-text.variant-name.variant-name-description.cursor-pointer(
              v-else
              bt400xs
              @click.native="createControlVariant"
            ) {{ $t('abTestModal.dynamicContentDesc') }}
    .variant-to-experience-container.col-12.mx-0.px-0.d-flex
      .d-flex.mb-4.flex-column.w-100
        .experience.mb-6(v-for="(extendedExperience, index) in extendedExperiences" :key="index")
          om-heading(h6) {{ extendedExperience.name }}
          .experience-variant.d-flex.align-items-center(
            v-for="(variant, variantIndex) in extendedExperience.variants"
            :key="variantIndex"
          )
            variant-preview.mt-4(
              ref="variants"
              :key="variant._id"
              @observable="onObservable($event, '.add-variant-to-experience-modal')"
              :allowSsr="!!campaign.templateType"
              @inited="updateDimensions"
              :dimensions="boxDimensions"
              :updatedAt="campaign.updatedAt"
              :variant="variant"
              :templateName="campaign.templateName"
              :transparentOverlay="false"
              @click="addVariantToExperience(variant)"
              :templateType="campaign.templateType"
              removeHidden
              :ready="!!campaign.updatedAt"
            )
            .mt-4.ml-3
              om-body-text.variant-name.cursor-pointer(
                bt400md
                @click.native="addVariantToExperience(variant)"
              ) {{ variant.name }}
            .mt-4.ml-auto.px-1
              om-chip(small :color="variant.status === 'active' ? 'green' : 'secondary'") {{ variant.status === 'active' ? $t('active') : $t('inactive') }}
  template(slot="modal-footer")
</template>
<script>
  import CREATE_CONTROL_VARIANT from '@/graphql/CreateControlVariant.gql';
  import VariantPreview from '@/components/Template/VariantPreview.vue';
  import experiencesMixin from '@/mixins/experiences';
  import addVariantModalMixin from '@/mixins/addVariantModal';
  import NEW_VARIANT from '@/graphql/NewVariant.gql';
  import { mapMutations } from 'vuex';

  export default {
    components: {
      VariantPreview,
    },
    mixins: [experiencesMixin, addVariantModalMixin],
    data() {
      return {
        experiences: [],
        experienceId: null,
        hasControlVariantInExperienceGroup: false,
        currentExperience: null,
      };
    },
    computed: {
      isDynamicContent() {
        return this.campaign.templateType === 'dynamic_content';
      },
      currentExperienceHasVariants() {
        return !!this.currentExperience?.variants?.length;
      },
      variantsMap() {
        return this.variants.reduce((map, variant) => {
          map[variant._id] = variant;
          return map;
        }, {});
      },
      controlVariantsInCampaign() {
        const controlVariantsIds = [];
        const controlVariants = this.variants.filter((variant) => {
          return variant.status !== 'deleted' && variant.isControlVariant ? variant._id : false;
        });

        controlVariants.forEach((variant) => {
          controlVariantsIds.push(variant._id);
        });
        return controlVariantsIds;
      },
      extendedExperiences() {
        return this.experiences
          .filter((experience) => experience.variants.length)
          .map((experience) => {
            const variants = experience.variants
              .map((variantId) => {
                return this.variantsMap[variantId];
              })
              .filter((variant) => {
                return !variant?.isControlVariant;
              })
              .filter((variant) => !!variant);

            return { ...experience, variants };
          });
      },
    },
    methods: {
      ...mapMutations(['showAdminLoader']),
      checkControlVariantInExperienceGroup(experiences, experienceId, controlVariantsInCampaign) {
        const filteredArray = controlVariantsInCampaign.filter((value) =>
          this.currentExperience.variants.includes(value),
        );

        return filteredArray.length;
      },
      async beforeOpen(event) {
        const { campaign, variants = [], experienceId } = event.params;

        this.campaign = campaign;
        this.experiences = campaign.experiences;
        this.variants = variants;
        this.experienceId = experienceId;
        this.currentExperience = this.experiences.find((experience) => {
          return experience._id === experienceId;
        });

        this.hasControlVariantInExperienceGroup = this.checkControlVariantInExperienceGroup(
          this.experiences,
          this.experienceId,
          this.controlVariantsInCampaign,
        );
      },

      async createControlVariant() {
        try {
          await this.$apollo.mutate({
            mutation: CREATE_CONTROL_VARIANT,
            variables: {
              campaignId: this.campaign._id,
              experienceId: this.experienceId,
            },
          });

          this.$modal.hide('add-variant-to-experience');
          this.$emit('refetchExperiences', true);
        } catch (e) {
          this.$modal.hide('add-variant-to-experience');
          this.$emit('refetchExperiences', true);
          this.$notify({
            type: 'error',
            text: this.$t('notifications.controlVariantAddError'),
          });
        }
      },
      async addNewDynamicContentToExperience() {
        this.showAdminLoader(true);
        await this.$apollo.mutate({
          mutation: NEW_VARIANT,
          variables: { input: { _id: this.campaign?._id } },
        });
        this.$emit('addNewDynamicContentToExperience', { experienceId: this.experienceId });
        this.$modal.hide('add-variant-to-experience');
      },
      async addVariantToExperience(variant) {
        await this._addVariantToExperience(variant, this.experienceId);
        this.$emit('refetchExperiences', true);
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'

  .variant-name
    max-width: 300px
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  .variant-name-description
    color: $om-gray-600
</style>

<style lang="sass">
  @media screen and (max-width: 768px)
    .add-variant-to-experience-modal .v--modal
      width: 90% !important
      margin: 0 5%
</style>
