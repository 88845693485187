<template lang="pug">
div
  template(v-for="(expression, expressionIndex) in currentRule.options.expression")
    .d-flex.align-items-center.mb-3
      .settings-label {{ $t('urlOfTheSite') }}
      om-select#operator.w-10.mx-3(
        :options="createOptionArray(stringBaseOperators)"
        size="small"
        @input="setOperatorForOneExpression($event, expressionIndex)"
        :value="{ key: currentRule.options.expression[expressionIndex].operator, value: $t(currentRule.options.expression[expressionIndex].operator) }"
      )
      .settings-label :
      .w-20.mx-3
        om-input#urlValue(
          :prefix="domainPrefix(expressionIndex)"
          small
          type="text"
          :error="$v.currentRule.options.expression.$each[expressionIndex].value.$error"
          v-model="currentRule.options.expression[expressionIndex].value"
          :show-invalid-suffix="false"
        )
      template(v-if="!hideTiming")
        .settings-label {{ $t('blockingTime') }}
        om-input#blockingTime.w-5.ml-3(
          type="number"
          small
          :min="0"
          :error="$v.currentRule.options.expression.$each[expressionIndex].secondsToBlock.$error"
          v-model.number="currentRule.options.expression[expressionIndex].secondsToBlock"
          :show-invalid-suffix="false"
        )
      .brand-link.settings-label.ml-3(
        v-if="expressionLength > 1"
        @click="removeExpression('expression', expressionIndex)"
      )
        fa-icon(variant="fa-trash" size="1.5")
      .brand-link.settings-label.px-4.ml-3(v-else)
  span.brand-link.settings-label(
    @click="addExpression('expression', { operator: 'equals', value: '', secondsToBlock: 0 })"
  )
    fa-icon(variant="fa-plus" :html="`&nbsp;${$t('addNew')}`")
</template>

<script>
  import { required, minValue, integer } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import urlCampaignSettings from '@/mixins/urlCampaignSettings';
  import frontendRuleUtils from '@/mixins/frontendRuleUtils';
  import { stringBaseOperators } from './statics';

  export default {
    mixins: [settingsValidation, expressionOperations, urlCampaignSettings, frontendRuleUtils],
    props: {
      ruleset: { type: String, default: 'default' },
    },
    data() {
      return {
        stringBaseOperators,
      };
    },
    computed: {
      expressionLength() {
        return this.currentRule.options.expression.length;
      },
      hideTiming() {
        return this.ruleset === 'embedded';
      },
    },
    validations: {
      currentRule: {
        options: {
          expression: {
            $each: {
              value: {
                isCool(v, { operator }) {
                  return this.needStartingSlash(operator) || v.length;
                },
              },
              secondsToBlock: {
                required,
                minValue: minValue(0),
                integer,
              },
            },
          },
        },
      },
    },
  };
</script>
