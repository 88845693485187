<template lang="pug">
.integration-error-indicator
  img.integration-error-icon(:src="require('../assets/admin/svg/integration-error-icon.svg')")
  span.integration-error-text {{ text }}
</template>
<script>
  export default {
    props: {
      text: { type: String, default: '' },
    },
  };
</script>

<style lang="sass">
  .integration-error-indicator
    font-size: 12px
    display: flex
    justify-content: flex-end
    align-items: center

    .integration-error-icon
      margin-right: 6px

    .integration-error-text
      white-space: pre-wrap
      width: 100%
</style>
