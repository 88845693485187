<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 18C11.8022 18 11.6089 18.0586 11.4444 18.1685C11.28 18.2784 11.1518 18.4346 11.0761 18.6173C11.0004 18.8 10.9806 19.0011 11.0192 19.1951C11.0578 19.3891 11.153 19.5673 11.2929 19.7071C11.4327 19.847 11.6109 19.9422 11.8049 19.9808C11.9989 20.0194 12.2 19.9996 12.3827 19.9239C12.5654 19.8482 12.7216 19.72 12.8315 19.5556C12.9414 19.3911 13 19.1978 13 19C13 18.7348 12.8946 18.4804 12.7071 18.2929C12.5196 18.1054 12.2652 18 12 18ZM18 1H6C5.73478 1 5.48043 1.10536 5.29289 1.29289C5.10536 1.48043 5 1.73478 5 2V11C5 11.7956 5.31607 12.5587 5.87868 13.1213C6.44129 13.6839 7.20435 14 8 14H9V16.37C8.49083 16.9473 8.15907 17.6593 8.04453 18.4206C7.92999 19.1818 8.03753 19.9599 8.35425 20.6615C8.67097 21.3631 9.18341 21.9584 9.83009 22.376C10.4768 22.7936 11.2302 23.0158 12 23.0158C12.7698 23.0158 13.5232 22.7936 14.1699 22.376C14.8166 21.9584 15.329 21.3631 15.6458 20.6615C15.9625 19.9599 16.07 19.1818 15.9555 18.4206C15.8409 17.6593 15.5092 16.9473 15 16.37V14H16C16.7956 14 17.5587 13.6839 18.1213 13.1213C18.6839 12.5587 19 11.7956 19 11V2C19 1.73478 18.8946 1.48043 18.7071 1.29289C18.5196 1.10536 18.2652 1 18 1ZM12 21C11.5976 20.997 11.2055 20.8726 10.8749 20.6432C10.5443 20.4138 10.2906 20.0899 10.1469 19.7141C10.0033 19.3382 9.97635 18.9277 10.0697 18.5363C10.163 18.1448 10.3722 17.7906 10.67 17.52C10.7736 17.4265 10.8565 17.3124 10.9133 17.1849C10.9701 17.0575 10.9997 16.9195 11 16.78V14H13V16.78C13.0003 16.9195 13.0299 17.0575 13.0867 17.1849C13.1435 17.3124 13.2264 17.4265 13.33 17.52C13.6278 17.7906 13.837 18.1448 13.9303 18.5363C14.0236 18.9277 13.9967 19.3382 13.8531 19.7141C13.7094 20.0899 13.4557 20.4138 13.1251 20.6432C12.7945 20.8726 12.4024 20.997 12 21ZM17 11C17 11.2652 16.8946 11.5196 16.7071 11.7071C16.5196 11.8946 16.2652 12 16 12H8C7.73478 12 7.48043 11.8946 7.29289 11.7071C7.10536 11.5196 7 11.2652 7 11V10H17V11ZM17 8H7V3H17V8Z"
      :fill="color"
    />
  </svg>
</template>

<script>
  export default {
    props: ['color'],
  };
</script>
