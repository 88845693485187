import { needStartingSlash } from '@/util';

export default {
  props: {
    domain: {
      type: String,
    },
  },

  computed: {
    domainWithDash() {
      return `${this.domain}/`;
    },
  },

  methods: {
    removeStartingSlash(v) {
      return v.charAt(0) === '/' ? v.substr(1) : v;
    },

    addStartingSlash(v) {
      return v.charAt(0) !== '/' ? `/${v}` : v;
    },

    needStartingSlash,
  },
};
