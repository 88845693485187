<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.5C10.0222 2.5 8.08879 3.08649 6.4443 4.1853C4.79981 5.28412 3.51809 6.8459 2.76121 8.67317C2.00433 10.5004 1.8063 12.5111 2.19215 14.4509C2.578 16.3907 3.53041 18.1725 4.92894 19.5711C6.32746 20.9696 8.10929 21.922 10.0491 22.3079C11.9889 22.6937 13.9996 22.4957 15.8268 21.7388C17.6541 20.9819 19.2159 19.7002 20.3147 18.0557C21.4135 16.4112 22 14.4778 22 12.5C22 11.1868 21.7413 9.88642 21.2388 8.67317C20.7363 7.45991 19.9997 6.35752 19.0711 5.42893C18.1425 4.50035 17.0401 3.76375 15.8268 3.2612C14.6136 2.75866 13.3132 2.5 12 2.5ZM12 20.5C10.4178 20.5 8.87104 20.0308 7.55544 19.1518C6.23985 18.2727 5.21447 17.0233 4.60897 15.5615C4.00347 14.0997 3.84504 12.4911 4.15372 10.9393C4.4624 9.38743 5.22433 7.96197 6.34315 6.84315C7.46197 5.72433 8.88743 4.9624 10.4393 4.65372C11.9911 4.34504 13.5997 4.50346 15.0615 5.10896C16.5233 5.71447 17.7727 6.73984 18.6518 8.05544C19.5308 9.37103 20 10.9177 20 12.5C20 14.6217 19.1572 16.6566 17.6569 18.1569C16.1566 19.6571 14.1217 20.5 12 20.5ZM16 11.5H13V8.5C13 8.23478 12.8946 7.98043 12.7071 7.79289C12.5196 7.60536 12.2652 7.5 12 7.5C11.7348 7.5 11.4804 7.60536 11.2929 7.79289C11.1054 7.98043 11 8.23478 11 8.5V11.5H8C7.73479 11.5 7.48043 11.6054 7.2929 11.7929C7.10536 11.9804 7 12.2348 7 12.5C7 12.7652 7.10536 13.0196 7.2929 13.2071C7.48043 13.3946 7.73479 13.5 8 13.5H11V16.5C11 16.7652 11.1054 17.0196 11.2929 17.2071C11.4804 17.3946 11.7348 17.5 12 17.5C12.2652 17.5 12.5196 17.3946 12.7071 17.2071C12.8946 17.0196 13 16.7652 13 16.5V13.5H16C16.2652 13.5 16.5196 13.3946 16.7071 13.2071C16.8946 13.0196 17 12.7652 17 12.5C17 12.2348 16.8946 11.9804 16.7071 11.7929C16.5196 11.6054 16.2652 11.5 16 11.5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
  export default {
    props: ['color'],
  };
</script>
