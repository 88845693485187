<template lang="pug">
.body-text(:class="classes" v-bind="$attrs")
  slot
</template>

<script>
  import designSystemMixin from '../mixins/designSystem';

  export default {
    name: 'OmBodyText',
    mixins: [designSystemMixin],
    props: {
      bt400xs: {
        type: Boolean,
        default: false,
      },
      bt400sm: {
        type: Boolean,
        default: false,
      },
      bt400md: {
        type: Boolean,
        default: false,
      },
      bt400lg: {
        type: Boolean,
        default: false,
      },
      bt500xs: {
        type: Boolean,
        default: false,
      },
      bt500sm: {
        type: Boolean,
        default: false,
      },
      bt500md: {
        type: Boolean,
        default: false,
      },
      bt500lg: {
        type: Boolean,
        default: false,
      },
      bt700xs: {
        type: Boolean,
        default: false,
      },
      bt700sm: {
        type: Boolean,
        default: false,
      },
      bt700md: {
        type: Boolean,
        default: false,
      },
      bt700lg: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      classes() {
        return {
          'body-text-400-xs': this.bt400xs,
          'body-text-400-sm': this.bt400sm,
          'body-text-400-md': this.bt400md,
          'body-text-400-lg': this.bt400lg,
          'body-text-500-xs': this.bt500xs,
          'body-text-500-sm': this.bt500sm,
          'body-text-500-md': this.bt500md,
          'body-text-500-lg': this.bt500lg,
          'body-text-700-xs': this.bt700xs,
          'body-text-700-sm': this.bt700sm,
          'body-text-700-md': this.bt700md,
          'body-text-700-lg': this.bt700lg,
          'design-system': this.designSystem,
        };
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import 'bodyText.sass'
</style>
