<template lang="pug">
svg(
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 580 430"
  ref="svg"
  :class="{ animating: animationActive }"
)
  g(transform="matrix(1,0,0,1,-689,0)")
    g#jscode(transform="matrix(1,0,0,1,689.329,0)")
      rect(x="0" y="0" width="580" height="430" style="fill: none")
      clippath#_clip1
        rect(x="0" y="0" width="580" height="430")
      g(clip-path="url(#_clip1)")
        g#browser
          g(transform="matrix(1,0,0,1,580,0)")
            path(
              d="M0,30L-580,30L-580,8C-580,3.582 -576.418,0 -572,0L-8,0C-3.582,0 0,3.582 0,8L0,30Z"
              style="fill: #dfe6ed; fill-rule: nonzero"
            )
          g(transform="matrix(1,0,0,1,580,430)")
            path(
              d="M0,-400L-580,-400L-580,-8C-580,-3.582 -576.418,0 -572,0L-8,0C-3.582,0 0,-3.582 0,-8L0,-400Z"
              style="fill: #f2f5f7; fill-rule: nonzero"
            )
          g(transform="matrix(0.890501,0,0,0.890501,-654.61,0.342704)")
            circle(cx="757.626" cy="16.734" r="5.521" style="fill: #f44e4e")
          g(transform="matrix(0.890501,0,0,0.890501,-640.577,0.342704)")
            circle(cx="757.626" cy="16.734" r="5.521" style="fill: #ffce00")
          g(transform="matrix(0.890501,0,0,0.890501,-626.543,0.342704)")
            circle(cx="757.626" cy="16.734" r="5.521" style="fill: #6fc144")
        g#page
          g(transform="matrix(-1,-0,0,0.86465,580,-90.5189)")
            rect(x="0" y="193.344" width="580" height="160" style="fill: #a9b2b7")
          g(transform="matrix(-1,0,0,1.07739,580,49.3106)")
            path(
              d="M580,193.344L0,193.344C0,193.344 0,313.894 0,345.717C0,349.929 3.679,353.344 8.217,353.344C73.426,353.344 506.411,353.344 571.749,353.344C573.937,353.344 576.036,352.537 577.583,351.101C579.131,349.665 580,347.717 580,345.685C580,313.813 580,193.344 580,193.344Z"
              style="fill: #3c3c3c"
            )
          g(transform="matrix(1,0,0,1.30654,-689.329,-95.3421)")
            rect(x="689.329" y="257.999" width="580" height="12.684" style="fill: #697074")
          g(transform="matrix(1,0,0,1,-610.731,-1.94705)")
            path(
              d="M1165.27,252.122C1165.27,249.939 1163.49,248.166 1161.31,248.166L1125.21,248.166C1123.03,248.166 1121.26,249.939 1121.26,252.122C1121.26,254.305 1123.03,256.077 1125.21,256.077L1161.31,256.077C1163.49,256.077 1165.27,254.305 1165.27,252.122Z"
              style="fill: #a9b2b7"
            )
          g(transform="matrix(0.890501,0,0,0.890501,-654.61,235.084)")
            circle(cx="757.626" cy="16.734" r="5.521" style="fill: #f44e4e")
          g#cursor
            g(transform="matrix(2.27624,0,0,2.27624,-1603.66,-348.907)")
              path(
                d="M717.109,283.901L716.425,282.821L720.793,280.241L716.425,277.601L717.109,276.521L722.713,280.001L722.713,280.601L717.109,283.901Z"
                style="fill: #00cf00; fill-rule: nonzero"
              )
            g(transform="matrix(2.27624,0,0,2.27624,-1603.66,-278.868)")
              path(
                d="M717.109,283.901L716.425,282.821L720.793,280.241L716.425,277.601L717.109,276.521L722.713,280.001L722.713,280.601L717.109,283.901Z"
                style="fill: #00cf00; fill-rule: nonzero"
              )
            g(transform="matrix(2.27624,0,0,2.27624,-1603.66,-241.544)")
              path(
                d="M717.109,283.901L716.425,282.821L720.793,280.241L716.425,277.601L717.109,276.521L722.713,280.001L722.713,280.601L717.109,283.901Z"
                style="fill: #00cf00; fill-rule: nonzero"
              )
            g(transform="matrix(2.27624,0,0,2.27624,-1603.66,-315.321)")
              path(
                d="M717.109,283.901L716.425,282.821L720.793,280.241L716.425,277.601L717.109,276.521L722.713,280.001L722.713,280.601L717.109,283.901Z"
                style="fill: #00cf00; fill-rule: nonzero"
              )
          g(transform="matrix(0.821539,0,0,1,-531.961,-8.52651e-14)")
            rect(
              x="746.078"
              y="226.57"
              width="160.262"
              height="15.175"
              style="fill: #a9b2b7; fill-opacity: 0.596078"
            )
          g(transform="matrix(0.821539,0,0,1,-390.167,-8.52651e-14)")
            rect(
              x="746.078"
              y="226.57"
              width="160.262"
              height="15.175"
              style="fill: #a9b2b7; fill-opacity: 0.596078"
            )
          g(transform="matrix(0.821539,0,0,1,-248.374,-8.52651e-14)")
            rect(
              x="746.078"
              y="226.57"
              width="160.262"
              height="15.175"
              style="fill: #a9b2b7; fill-opacity: 0.596078"
            )
          g(transform="matrix(1,0,0,1,-689.329,0)")
            path(
              d="M756.651,53.807C756.651,49.927 753.501,46.777 749.62,46.777L713.457,46.777C709.576,46.777 706.426,49.927 706.426,53.807C706.426,57.688 709.576,60.838 713.457,60.838L749.62,60.838C753.501,60.838 756.651,57.688 756.651,53.807Z"
              style="fill: #a9b2b7"
            )
          g(transform="matrix(3.17911,0,0,0.543204,-1848.01,24.579)")
            path(
              d="M756.651,53.807C756.651,49.927 756.113,46.777 755.45,46.777L707.627,46.777C706.964,46.777 706.426,49.927 706.426,53.807C706.426,57.688 706.964,60.838 707.627,60.838L755.45,60.838C756.113,60.838 756.651,57.688 756.651,53.807Z"
              style="fill: #a9b2b7"
            )
        g(transform="matrix(2.27624,0,0,2.27624,-1581.38,-241.544)")
          path.line4(
            d="M716.449,279.929C716.449,279.201 716.525,278.563 716.677,278.015C716.829,277.467 717.043,277.013 717.319,276.653C717.595,276.293 717.925,276.023 718.309,275.843C718.693,275.663 719.121,275.573 719.593,275.573C720.601,275.573 721.377,275.927 721.921,276.635C722.465,277.343 722.737,278.441 722.737,279.929C722.737,280.665 722.661,281.307 722.509,281.855C722.357,282.403 722.143,282.859 721.867,283.223C721.591,283.587 721.259,283.859 720.871,284.039C720.483,284.219 720.053,284.309 719.581,284.309C718.565,284.309 717.789,283.935 717.253,283.187C716.717,282.439 716.449,281.353 716.449,279.929ZM721.201,279.929C721.201,279.801 721.199,279.679 721.195,279.563C721.191,279.447 721.185,279.333 721.177,279.221L718.237,281.897C718.485,282.625 718.937,282.989 719.593,282.989C720.105,282.989 720.501,282.745 720.781,282.257C721.061,281.769 721.201,280.993 721.201,279.929ZM717.985,279.929C717.985,280.049 717.987,280.163 717.991,280.271C717.995,280.379 718.001,280.489 718.009,280.601L720.937,277.925C720.697,277.237 720.245,276.893 719.581,276.893C719.069,276.893 718.675,277.139 718.399,277.631C718.123,278.123 717.985,278.889 717.985,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M724.465,282.821L726.301,282.821L726.301,277.577L724.717,278.789L723.997,277.829L726.733,275.741L727.789,275.741L727.789,282.821L729.589,282.821L729.589,284.141L724.465,284.141L724.465,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M730.849,279.929C730.849,279.201 730.925,278.563 731.077,278.015C731.229,277.467 731.443,277.013 731.719,276.653C731.995,276.293 732.325,276.023 732.709,275.843C733.093,275.663 733.521,275.573 733.993,275.573C735.001,275.573 735.777,275.927 736.321,276.635C736.865,277.343 737.137,278.441 737.137,279.929C737.137,280.665 737.061,281.307 736.909,281.855C736.757,282.403 736.543,282.859 736.267,283.223C735.991,283.587 735.659,283.859 735.271,284.039C734.883,284.219 734.453,284.309 733.981,284.309C732.965,284.309 732.189,283.935 731.653,283.187C731.117,282.439 730.849,281.353 730.849,279.929ZM735.601,279.929C735.601,279.801 735.599,279.679 735.595,279.563C735.591,279.447 735.585,279.333 735.577,279.221L732.637,281.897C732.885,282.625 733.337,282.989 733.993,282.989C734.505,282.989 734.901,282.745 735.181,282.257C735.461,281.769 735.601,280.993 735.601,279.929ZM732.385,279.929C732.385,280.049 732.387,280.163 732.391,280.271C732.395,280.379 732.401,280.489 732.409,280.601L735.337,277.925C735.097,277.237 734.645,276.893 733.981,276.893C733.469,276.893 733.075,277.139 732.799,277.631C732.523,278.123 732.385,278.889 732.385,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M738.049,279.929C738.049,279.201 738.125,278.563 738.277,278.015C738.429,277.467 738.643,277.013 738.919,276.653C739.195,276.293 739.525,276.023 739.909,275.843C740.293,275.663 740.721,275.573 741.193,275.573C742.201,275.573 742.977,275.927 743.521,276.635C744.065,277.343 744.337,278.441 744.337,279.929C744.337,280.665 744.261,281.307 744.109,281.855C743.957,282.403 743.743,282.859 743.467,283.223C743.191,283.587 742.859,283.859 742.471,284.039C742.083,284.219 741.653,284.309 741.181,284.309C740.165,284.309 739.389,283.935 738.853,283.187C738.317,282.439 738.049,281.353 738.049,279.929ZM742.801,279.929C742.801,279.801 742.799,279.679 742.795,279.563C742.791,279.447 742.785,279.333 742.777,279.221L739.837,281.897C740.085,282.625 740.537,282.989 741.193,282.989C741.705,282.989 742.101,282.745 742.381,282.257C742.661,281.769 742.801,280.993 742.801,279.929ZM739.585,279.929C739.585,280.049 739.587,280.163 739.591,280.271C739.595,280.379 739.601,280.489 739.609,280.601L742.537,277.925C742.297,277.237 741.845,276.893 741.181,276.893C740.669,276.893 740.275,277.139 739.999,277.631C739.723,278.123 739.585,278.889 739.585,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M746.065,282.821L747.901,282.821L747.901,277.577L746.317,278.789L745.597,277.829L748.333,275.741L749.389,275.741L749.389,282.821L751.189,282.821L751.189,284.141L746.065,284.141L746.065,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M753.265,282.821L755.101,282.821L755.101,277.577L753.517,278.789L752.797,277.829L755.533,275.741L756.589,275.741L756.589,282.821L758.389,282.821L758.389,284.141L753.265,284.141L753.265,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M759.649,279.929C759.649,279.201 759.725,278.563 759.877,278.015C760.029,277.467 760.243,277.013 760.519,276.653C760.795,276.293 761.125,276.023 761.509,275.843C761.893,275.663 762.321,275.573 762.793,275.573C763.801,275.573 764.577,275.927 765.121,276.635C765.665,277.343 765.937,278.441 765.937,279.929C765.937,280.665 765.861,281.307 765.709,281.855C765.557,282.403 765.343,282.859 765.067,283.223C764.791,283.587 764.459,283.859 764.071,284.039C763.683,284.219 763.253,284.309 762.781,284.309C761.765,284.309 760.989,283.935 760.453,283.187C759.917,282.439 759.649,281.353 759.649,279.929ZM764.401,279.929C764.401,279.801 764.399,279.679 764.395,279.563C764.391,279.447 764.385,279.333 764.377,279.221L761.437,281.897C761.685,282.625 762.137,282.989 762.793,282.989C763.305,282.989 763.701,282.745 763.981,282.257C764.261,281.769 764.401,280.993 764.401,279.929ZM761.185,279.929C761.185,280.049 761.187,280.163 761.191,280.271C761.195,280.379 761.201,280.489 761.209,280.601L764.137,277.925C763.897,277.237 763.445,276.893 762.781,276.893C762.269,276.893 761.875,277.139 761.599,277.631C761.323,278.123 761.185,278.889 761.185,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M766.849,279.929C766.849,279.201 766.925,278.563 767.077,278.015C767.229,277.467 767.443,277.013 767.719,276.653C767.995,276.293 768.325,276.023 768.709,275.843C769.093,275.663 769.521,275.573 769.993,275.573C771.001,275.573 771.777,275.927 772.321,276.635C772.865,277.343 773.137,278.441 773.137,279.929C773.137,280.665 773.061,281.307 772.909,281.855C772.757,282.403 772.543,282.859 772.267,283.223C771.991,283.587 771.659,283.859 771.271,284.039C770.883,284.219 770.453,284.309 769.981,284.309C768.965,284.309 768.189,283.935 767.653,283.187C767.117,282.439 766.849,281.353 766.849,279.929ZM771.601,279.929C771.601,279.801 771.599,279.679 771.595,279.563C771.591,279.447 771.585,279.333 771.577,279.221L768.637,281.897C768.885,282.625 769.337,282.989 769.993,282.989C770.505,282.989 770.901,282.745 771.181,282.257C771.461,281.769 771.601,280.993 771.601,279.929ZM768.385,279.929C768.385,280.049 768.387,280.163 768.391,280.271C768.395,280.379 768.401,280.489 768.409,280.601L771.337,277.925C771.097,277.237 770.645,276.893 769.981,276.893C769.469,276.893 769.075,277.139 768.799,277.631C768.523,278.123 768.385,278.889 768.385,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M774.865,282.821L776.701,282.821L776.701,277.577L775.117,278.789L774.397,277.829L777.133,275.741L778.189,275.741L778.189,282.821L779.989,282.821L779.989,284.141L774.865,284.141L774.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M782.065,282.821L783.901,282.821L783.901,277.577L782.317,278.789L781.597,277.829L784.333,275.741L785.389,275.741L785.389,282.821L787.189,282.821L787.189,284.141L782.065,284.141L782.065,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M788.449,279.929C788.449,279.201 788.525,278.563 788.677,278.015C788.829,277.467 789.043,277.013 789.319,276.653C789.595,276.293 789.925,276.023 790.309,275.843C790.693,275.663 791.121,275.573 791.593,275.573C792.601,275.573 793.377,275.927 793.921,276.635C794.465,277.343 794.737,278.441 794.737,279.929C794.737,280.665 794.661,281.307 794.509,281.855C794.357,282.403 794.143,282.859 793.867,283.223C793.591,283.587 793.259,283.859 792.871,284.039C792.483,284.219 792.053,284.309 791.581,284.309C790.565,284.309 789.789,283.935 789.253,283.187C788.717,282.439 788.449,281.353 788.449,279.929ZM793.201,279.929C793.201,279.801 793.199,279.679 793.195,279.563C793.191,279.447 793.185,279.333 793.177,279.221L790.237,281.897C790.485,282.625 790.937,282.989 791.593,282.989C792.105,282.989 792.501,282.745 792.781,282.257C793.061,281.769 793.201,280.993 793.201,279.929ZM789.985,279.929C789.985,280.049 789.987,280.163 789.991,280.271C789.995,280.379 790.001,280.489 790.009,280.601L792.937,277.925C792.697,277.237 792.245,276.893 791.581,276.893C791.069,276.893 790.675,277.139 790.399,277.631C790.123,278.123 789.985,278.889 789.985,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M795.649,279.929C795.649,279.201 795.725,278.563 795.877,278.015C796.029,277.467 796.243,277.013 796.519,276.653C796.795,276.293 797.125,276.023 797.509,275.843C797.893,275.663 798.321,275.573 798.793,275.573C799.801,275.573 800.577,275.927 801.121,276.635C801.665,277.343 801.937,278.441 801.937,279.929C801.937,280.665 801.861,281.307 801.709,281.855C801.557,282.403 801.343,282.859 801.067,283.223C800.791,283.587 800.459,283.859 800.071,284.039C799.683,284.219 799.253,284.309 798.781,284.309C797.765,284.309 796.989,283.935 796.453,283.187C795.917,282.439 795.649,281.353 795.649,279.929ZM800.401,279.929C800.401,279.801 800.399,279.679 800.395,279.563C800.391,279.447 800.385,279.333 800.377,279.221L797.437,281.897C797.685,282.625 798.137,282.989 798.793,282.989C799.305,282.989 799.701,282.745 799.981,282.257C800.261,281.769 800.401,280.993 800.401,279.929ZM797.185,279.929C797.185,280.049 797.187,280.163 797.191,280.271C797.195,280.379 797.201,280.489 797.209,280.601L800.137,277.925C799.897,277.237 799.445,276.893 798.781,276.893C798.269,276.893 797.875,277.139 797.599,277.631C797.323,278.123 797.185,278.889 797.185,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M802.849,279.929C802.849,279.201 802.925,278.563 803.077,278.015C803.229,277.467 803.443,277.013 803.719,276.653C803.995,276.293 804.325,276.023 804.709,275.843C805.093,275.663 805.521,275.573 805.993,275.573C807.001,275.573 807.777,275.927 808.321,276.635C808.865,277.343 809.137,278.441 809.137,279.929C809.137,280.665 809.061,281.307 808.909,281.855C808.757,282.403 808.543,282.859 808.267,283.223C807.991,283.587 807.659,283.859 807.271,284.039C806.883,284.219 806.453,284.309 805.981,284.309C804.965,284.309 804.189,283.935 803.653,283.187C803.117,282.439 802.849,281.353 802.849,279.929ZM807.601,279.929C807.601,279.801 807.599,279.679 807.595,279.563C807.591,279.447 807.585,279.333 807.577,279.221L804.637,281.897C804.885,282.625 805.337,282.989 805.993,282.989C806.505,282.989 806.901,282.745 807.181,282.257C807.461,281.769 807.601,280.993 807.601,279.929ZM804.385,279.929C804.385,280.049 804.387,280.163 804.391,280.271C804.395,280.379 804.401,280.489 804.409,280.601L807.337,277.925C807.097,277.237 806.645,276.893 805.981,276.893C805.469,276.893 805.075,277.139 804.799,277.631C804.523,278.123 804.385,278.889 804.385,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M810.049,279.929C810.049,279.201 810.125,278.563 810.277,278.015C810.429,277.467 810.643,277.013 810.919,276.653C811.195,276.293 811.525,276.023 811.909,275.843C812.293,275.663 812.721,275.573 813.193,275.573C814.201,275.573 814.977,275.927 815.521,276.635C816.065,277.343 816.337,278.441 816.337,279.929C816.337,280.665 816.261,281.307 816.109,281.855C815.957,282.403 815.743,282.859 815.467,283.223C815.191,283.587 814.859,283.859 814.471,284.039C814.083,284.219 813.653,284.309 813.181,284.309C812.165,284.309 811.389,283.935 810.853,283.187C810.317,282.439 810.049,281.353 810.049,279.929ZM814.801,279.929C814.801,279.801 814.799,279.679 814.795,279.563C814.791,279.447 814.785,279.333 814.777,279.221L811.837,281.897C812.085,282.625 812.537,282.989 813.193,282.989C813.705,282.989 814.101,282.745 814.381,282.257C814.661,281.769 814.801,280.993 814.801,279.929ZM811.585,279.929C811.585,280.049 811.587,280.163 811.591,280.271C811.595,280.379 811.601,280.489 811.609,280.601L814.537,277.925C814.297,277.237 813.845,276.893 813.181,276.893C812.669,276.893 812.275,277.139 811.999,277.631C811.723,278.123 811.585,278.889 811.585,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M818.065,282.821L819.901,282.821L819.901,277.577L818.317,278.789L817.597,277.829L820.333,275.741L821.389,275.741L821.389,282.821L823.189,282.821L823.189,284.141L818.065,284.141L818.065,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M824.449,279.929C824.449,279.201 824.525,278.563 824.677,278.015C824.829,277.467 825.043,277.013 825.319,276.653C825.595,276.293 825.925,276.023 826.309,275.843C826.693,275.663 827.121,275.573 827.593,275.573C828.601,275.573 829.377,275.927 829.921,276.635C830.465,277.343 830.737,278.441 830.737,279.929C830.737,280.665 830.661,281.307 830.509,281.855C830.357,282.403 830.143,282.859 829.867,283.223C829.591,283.587 829.259,283.859 828.871,284.039C828.483,284.219 828.053,284.309 827.581,284.309C826.565,284.309 825.789,283.935 825.253,283.187C824.717,282.439 824.449,281.353 824.449,279.929ZM829.201,279.929C829.201,279.801 829.199,279.679 829.195,279.563C829.191,279.447 829.185,279.333 829.177,279.221L826.237,281.897C826.485,282.625 826.937,282.989 827.593,282.989C828.105,282.989 828.501,282.745 828.781,282.257C829.061,281.769 829.201,280.993 829.201,279.929ZM825.985,279.929C825.985,280.049 825.987,280.163 825.991,280.271C825.995,280.379 826.001,280.489 826.009,280.601L828.937,277.925C828.697,277.237 828.245,276.893 827.581,276.893C827.069,276.893 826.675,277.139 826.399,277.631C826.123,278.123 825.985,278.889 825.985,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line4(
            d="M832.465,282.821L834.301,282.821L834.301,277.577L832.717,278.789L831.997,277.829L834.733,275.741L835.789,275.741L835.789,282.821L837.589,282.821L837.589,284.141L832.465,284.141L832.465,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
        g(transform="matrix(2.27624,0,0,2.27624,-1581.38,-278.868)")
          path.line3(
            d="M716.449,279.929C716.449,279.201 716.525,278.563 716.677,278.015C716.829,277.467 717.043,277.013 717.319,276.653C717.595,276.293 717.925,276.023 718.309,275.843C718.693,275.663 719.121,275.573 719.593,275.573C720.601,275.573 721.377,275.927 721.921,276.635C722.465,277.343 722.737,278.441 722.737,279.929C722.737,280.665 722.661,281.307 722.509,281.855C722.357,282.403 722.143,282.859 721.867,283.223C721.591,283.587 721.259,283.859 720.871,284.039C720.483,284.219 720.053,284.309 719.581,284.309C718.565,284.309 717.789,283.935 717.253,283.187C716.717,282.439 716.449,281.353 716.449,279.929ZM721.201,279.929C721.201,279.801 721.199,279.679 721.195,279.563C721.191,279.447 721.185,279.333 721.177,279.221L718.237,281.897C718.485,282.625 718.937,282.989 719.593,282.989C720.105,282.989 720.501,282.745 720.781,282.257C721.061,281.769 721.201,280.993 721.201,279.929ZM717.985,279.929C717.985,280.049 717.987,280.163 717.991,280.271C717.995,280.379 718.001,280.489 718.009,280.601L720.937,277.925C720.697,277.237 720.245,276.893 719.581,276.893C719.069,276.893 718.675,277.139 718.399,277.631C718.123,278.123 717.985,278.889 717.985,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M724.465,282.821L726.301,282.821L726.301,277.577L724.717,278.789L723.997,277.829L726.733,275.741L727.789,275.741L727.789,282.821L729.589,282.821L729.589,284.141L724.465,284.141L724.465,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M730.849,279.929C730.849,279.201 730.925,278.563 731.077,278.015C731.229,277.467 731.443,277.013 731.719,276.653C731.995,276.293 732.325,276.023 732.709,275.843C733.093,275.663 733.521,275.573 733.993,275.573C735.001,275.573 735.777,275.927 736.321,276.635C736.865,277.343 737.137,278.441 737.137,279.929C737.137,280.665 737.061,281.307 736.909,281.855C736.757,282.403 736.543,282.859 736.267,283.223C735.991,283.587 735.659,283.859 735.271,284.039C734.883,284.219 734.453,284.309 733.981,284.309C732.965,284.309 732.189,283.935 731.653,283.187C731.117,282.439 730.849,281.353 730.849,279.929ZM735.601,279.929C735.601,279.801 735.599,279.679 735.595,279.563C735.591,279.447 735.585,279.333 735.577,279.221L732.637,281.897C732.885,282.625 733.337,282.989 733.993,282.989C734.505,282.989 734.901,282.745 735.181,282.257C735.461,281.769 735.601,280.993 735.601,279.929ZM732.385,279.929C732.385,280.049 732.387,280.163 732.391,280.271C732.395,280.379 732.401,280.489 732.409,280.601L735.337,277.925C735.097,277.237 734.645,276.893 733.981,276.893C733.469,276.893 733.075,277.139 732.799,277.631C732.523,278.123 732.385,278.889 732.385,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M738.865,282.821L740.701,282.821L740.701,277.577L739.117,278.789L738.397,277.829L741.133,275.741L742.189,275.741L742.189,282.821L743.989,282.821L743.989,284.141L738.865,284.141L738.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M746.065,282.821L747.901,282.821L747.901,277.577L746.317,278.789L745.597,277.829L748.333,275.741L749.389,275.741L749.389,282.821L751.189,282.821L751.189,284.141L746.065,284.141L746.065,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M752.449,279.929C752.449,279.201 752.525,278.563 752.677,278.015C752.829,277.467 753.043,277.013 753.319,276.653C753.595,276.293 753.925,276.023 754.309,275.843C754.693,275.663 755.121,275.573 755.593,275.573C756.601,275.573 757.377,275.927 757.921,276.635C758.465,277.343 758.737,278.441 758.737,279.929C758.737,280.665 758.661,281.307 758.509,281.855C758.357,282.403 758.143,282.859 757.867,283.223C757.591,283.587 757.259,283.859 756.871,284.039C756.483,284.219 756.053,284.309 755.581,284.309C754.565,284.309 753.789,283.935 753.253,283.187C752.717,282.439 752.449,281.353 752.449,279.929ZM757.201,279.929C757.201,279.801 757.199,279.679 757.195,279.563C757.191,279.447 757.185,279.333 757.177,279.221L754.237,281.897C754.485,282.625 754.937,282.989 755.593,282.989C756.105,282.989 756.501,282.745 756.781,282.257C757.061,281.769 757.201,280.993 757.201,279.929ZM753.985,279.929C753.985,280.049 753.987,280.163 753.991,280.271C753.995,280.379 754.001,280.489 754.009,280.601L756.937,277.925C756.697,277.237 756.245,276.893 755.581,276.893C755.069,276.893 754.675,277.139 754.399,277.631C754.123,278.123 753.985,278.889 753.985,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M760.465,282.821L762.301,282.821L762.301,277.577L760.717,278.789L759.997,277.829L762.733,275.741L763.789,275.741L763.789,282.821L765.589,282.821L765.589,284.141L760.465,284.141L760.465,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M766.849,279.929C766.849,279.201 766.925,278.563 767.077,278.015C767.229,277.467 767.443,277.013 767.719,276.653C767.995,276.293 768.325,276.023 768.709,275.843C769.093,275.663 769.521,275.573 769.993,275.573C771.001,275.573 771.777,275.927 772.321,276.635C772.865,277.343 773.137,278.441 773.137,279.929C773.137,280.665 773.061,281.307 772.909,281.855C772.757,282.403 772.543,282.859 772.267,283.223C771.991,283.587 771.659,283.859 771.271,284.039C770.883,284.219 770.453,284.309 769.981,284.309C768.965,284.309 768.189,283.935 767.653,283.187C767.117,282.439 766.849,281.353 766.849,279.929ZM771.601,279.929C771.601,279.801 771.599,279.679 771.595,279.563C771.591,279.447 771.585,279.333 771.577,279.221L768.637,281.897C768.885,282.625 769.337,282.989 769.993,282.989C770.505,282.989 770.901,282.745 771.181,282.257C771.461,281.769 771.601,280.993 771.601,279.929ZM768.385,279.929C768.385,280.049 768.387,280.163 768.391,280.271C768.395,280.379 768.401,280.489 768.409,280.601L771.337,277.925C771.097,277.237 770.645,276.893 769.981,276.893C769.469,276.893 769.075,277.139 768.799,277.631C768.523,278.123 768.385,278.889 768.385,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M774.865,282.821L776.701,282.821L776.701,277.577L775.117,278.789L774.397,277.829L777.133,275.741L778.189,275.741L778.189,282.821L779.989,282.821L779.989,284.141L774.865,284.141L774.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M781.249,279.929C781.249,279.201 781.325,278.563 781.477,278.015C781.629,277.467 781.843,277.013 782.119,276.653C782.395,276.293 782.725,276.023 783.109,275.843C783.493,275.663 783.921,275.573 784.393,275.573C785.401,275.573 786.177,275.927 786.721,276.635C787.265,277.343 787.537,278.441 787.537,279.929C787.537,280.665 787.461,281.307 787.309,281.855C787.157,282.403 786.943,282.859 786.667,283.223C786.391,283.587 786.059,283.859 785.671,284.039C785.283,284.219 784.853,284.309 784.381,284.309C783.365,284.309 782.589,283.935 782.053,283.187C781.517,282.439 781.249,281.353 781.249,279.929ZM786.001,279.929C786.001,279.801 785.999,279.679 785.995,279.563C785.991,279.447 785.985,279.333 785.977,279.221L783.037,281.897C783.285,282.625 783.737,282.989 784.393,282.989C784.905,282.989 785.301,282.745 785.581,282.257C785.861,281.769 786.001,280.993 786.001,279.929ZM782.785,279.929C782.785,280.049 782.787,280.163 782.791,280.271C782.795,280.379 782.801,280.489 782.809,280.601L785.737,277.925C785.497,277.237 785.045,276.893 784.381,276.893C783.869,276.893 783.475,277.139 783.199,277.631C782.923,278.123 782.785,278.889 782.785,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M788.449,279.929C788.449,279.201 788.525,278.563 788.677,278.015C788.829,277.467 789.043,277.013 789.319,276.653C789.595,276.293 789.925,276.023 790.309,275.843C790.693,275.663 791.121,275.573 791.593,275.573C792.601,275.573 793.377,275.927 793.921,276.635C794.465,277.343 794.737,278.441 794.737,279.929C794.737,280.665 794.661,281.307 794.509,281.855C794.357,282.403 794.143,282.859 793.867,283.223C793.591,283.587 793.259,283.859 792.871,284.039C792.483,284.219 792.053,284.309 791.581,284.309C790.565,284.309 789.789,283.935 789.253,283.187C788.717,282.439 788.449,281.353 788.449,279.929ZM793.201,279.929C793.201,279.801 793.199,279.679 793.195,279.563C793.191,279.447 793.185,279.333 793.177,279.221L790.237,281.897C790.485,282.625 790.937,282.989 791.593,282.989C792.105,282.989 792.501,282.745 792.781,282.257C793.061,281.769 793.201,280.993 793.201,279.929ZM789.985,279.929C789.985,280.049 789.987,280.163 789.991,280.271C789.995,280.379 790.001,280.489 790.009,280.601L792.937,277.925C792.697,277.237 792.245,276.893 791.581,276.893C791.069,276.893 790.675,277.139 790.399,277.631C790.123,278.123 789.985,278.889 789.985,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M795.649,279.929C795.649,279.201 795.725,278.563 795.877,278.015C796.029,277.467 796.243,277.013 796.519,276.653C796.795,276.293 797.125,276.023 797.509,275.843C797.893,275.663 798.321,275.573 798.793,275.573C799.801,275.573 800.577,275.927 801.121,276.635C801.665,277.343 801.937,278.441 801.937,279.929C801.937,280.665 801.861,281.307 801.709,281.855C801.557,282.403 801.343,282.859 801.067,283.223C800.791,283.587 800.459,283.859 800.071,284.039C799.683,284.219 799.253,284.309 798.781,284.309C797.765,284.309 796.989,283.935 796.453,283.187C795.917,282.439 795.649,281.353 795.649,279.929ZM800.401,279.929C800.401,279.801 800.399,279.679 800.395,279.563C800.391,279.447 800.385,279.333 800.377,279.221L797.437,281.897C797.685,282.625 798.137,282.989 798.793,282.989C799.305,282.989 799.701,282.745 799.981,282.257C800.261,281.769 800.401,280.993 800.401,279.929ZM797.185,279.929C797.185,280.049 797.187,280.163 797.191,280.271C797.195,280.379 797.201,280.489 797.209,280.601L800.137,277.925C799.897,277.237 799.445,276.893 798.781,276.893C798.269,276.893 797.875,277.139 797.599,277.631C797.323,278.123 797.185,278.889 797.185,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M802.849,279.929C802.849,279.201 802.925,278.563 803.077,278.015C803.229,277.467 803.443,277.013 803.719,276.653C803.995,276.293 804.325,276.023 804.709,275.843C805.093,275.663 805.521,275.573 805.993,275.573C807.001,275.573 807.777,275.927 808.321,276.635C808.865,277.343 809.137,278.441 809.137,279.929C809.137,280.665 809.061,281.307 808.909,281.855C808.757,282.403 808.543,282.859 808.267,283.223C807.991,283.587 807.659,283.859 807.271,284.039C806.883,284.219 806.453,284.309 805.981,284.309C804.965,284.309 804.189,283.935 803.653,283.187C803.117,282.439 802.849,281.353 802.849,279.929ZM807.601,279.929C807.601,279.801 807.599,279.679 807.595,279.563C807.591,279.447 807.585,279.333 807.577,279.221L804.637,281.897C804.885,282.625 805.337,282.989 805.993,282.989C806.505,282.989 806.901,282.745 807.181,282.257C807.461,281.769 807.601,280.993 807.601,279.929ZM804.385,279.929C804.385,280.049 804.387,280.163 804.391,280.271C804.395,280.379 804.401,280.489 804.409,280.601L807.337,277.925C807.097,277.237 806.645,276.893 805.981,276.893C805.469,276.893 805.075,277.139 804.799,277.631C804.523,278.123 804.385,278.889 804.385,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M810.049,279.929C810.049,279.201 810.125,278.563 810.277,278.015C810.429,277.467 810.643,277.013 810.919,276.653C811.195,276.293 811.525,276.023 811.909,275.843C812.293,275.663 812.721,275.573 813.193,275.573C814.201,275.573 814.977,275.927 815.521,276.635C816.065,277.343 816.337,278.441 816.337,279.929C816.337,280.665 816.261,281.307 816.109,281.855C815.957,282.403 815.743,282.859 815.467,283.223C815.191,283.587 814.859,283.859 814.471,284.039C814.083,284.219 813.653,284.309 813.181,284.309C812.165,284.309 811.389,283.935 810.853,283.187C810.317,282.439 810.049,281.353 810.049,279.929ZM814.801,279.929C814.801,279.801 814.799,279.679 814.795,279.563C814.791,279.447 814.785,279.333 814.777,279.221L811.837,281.897C812.085,282.625 812.537,282.989 813.193,282.989C813.705,282.989 814.101,282.745 814.381,282.257C814.661,281.769 814.801,280.993 814.801,279.929ZM811.585,279.929C811.585,280.049 811.587,280.163 811.591,280.271C811.595,280.379 811.601,280.489 811.609,280.601L814.537,277.925C814.297,277.237 813.845,276.893 813.181,276.893C812.669,276.893 812.275,277.139 811.999,277.631C811.723,278.123 811.585,278.889 811.585,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M818.065,282.821L819.901,282.821L819.901,277.577L818.317,278.789L817.597,277.829L820.333,275.741L821.389,275.741L821.389,282.821L823.189,282.821L823.189,284.141L818.065,284.141L818.065,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M824.449,279.929C824.449,279.201 824.525,278.563 824.677,278.015C824.829,277.467 825.043,277.013 825.319,276.653C825.595,276.293 825.925,276.023 826.309,275.843C826.693,275.663 827.121,275.573 827.593,275.573C828.601,275.573 829.377,275.927 829.921,276.635C830.465,277.343 830.737,278.441 830.737,279.929C830.737,280.665 830.661,281.307 830.509,281.855C830.357,282.403 830.143,282.859 829.867,283.223C829.591,283.587 829.259,283.859 828.871,284.039C828.483,284.219 828.053,284.309 827.581,284.309C826.565,284.309 825.789,283.935 825.253,283.187C824.717,282.439 824.449,281.353 824.449,279.929ZM829.201,279.929C829.201,279.801 829.199,279.679 829.195,279.563C829.191,279.447 829.185,279.333 829.177,279.221L826.237,281.897C826.485,282.625 826.937,282.989 827.593,282.989C828.105,282.989 828.501,282.745 828.781,282.257C829.061,281.769 829.201,280.993 829.201,279.929ZM825.985,279.929C825.985,280.049 825.987,280.163 825.991,280.271C825.995,280.379 826.001,280.489 826.009,280.601L828.937,277.925C828.697,277.237 828.245,276.893 827.581,276.893C827.069,276.893 826.675,277.139 826.399,277.631C826.123,278.123 825.985,278.889 825.985,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M831.649,279.929C831.649,279.201 831.725,278.563 831.877,278.015C832.029,277.467 832.243,277.013 832.519,276.653C832.795,276.293 833.125,276.023 833.509,275.843C833.893,275.663 834.321,275.573 834.793,275.573C835.801,275.573 836.577,275.927 837.121,276.635C837.665,277.343 837.937,278.441 837.937,279.929C837.937,280.665 837.861,281.307 837.709,281.855C837.557,282.403 837.343,282.859 837.067,283.223C836.791,283.587 836.459,283.859 836.071,284.039C835.683,284.219 835.253,284.309 834.781,284.309C833.765,284.309 832.989,283.935 832.453,283.187C831.917,282.439 831.649,281.353 831.649,279.929ZM836.401,279.929C836.401,279.801 836.399,279.679 836.395,279.563C836.391,279.447 836.385,279.333 836.377,279.221L833.437,281.897C833.685,282.625 834.137,282.989 834.793,282.989C835.305,282.989 835.701,282.745 835.981,282.257C836.261,281.769 836.401,280.993 836.401,279.929ZM833.185,279.929C833.185,280.049 833.187,280.163 833.191,280.271C833.195,280.379 833.201,280.489 833.209,280.601L836.137,277.925C835.897,277.237 835.445,276.893 834.781,276.893C834.269,276.893 833.875,277.139 833.599,277.631C833.323,278.123 833.185,278.889 833.185,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M838.849,279.929C838.849,279.201 838.925,278.563 839.077,278.015C839.229,277.467 839.443,277.013 839.719,276.653C839.995,276.293 840.325,276.023 840.709,275.843C841.093,275.663 841.521,275.573 841.993,275.573C843.001,275.573 843.777,275.927 844.321,276.635C844.865,277.343 845.137,278.441 845.137,279.929C845.137,280.665 845.061,281.307 844.909,281.855C844.757,282.403 844.543,282.859 844.267,283.223C843.991,283.587 843.659,283.859 843.271,284.039C842.883,284.219 842.453,284.309 841.981,284.309C840.965,284.309 840.189,283.935 839.653,283.187C839.117,282.439 838.849,281.353 838.849,279.929ZM843.601,279.929C843.601,279.801 843.599,279.679 843.595,279.563C843.591,279.447 843.585,279.333 843.577,279.221L840.637,281.897C840.885,282.625 841.337,282.989 841.993,282.989C842.505,282.989 842.901,282.745 843.181,282.257C843.461,281.769 843.601,280.993 843.601,279.929ZM840.385,279.929C840.385,280.049 840.387,280.163 840.391,280.271C840.395,280.379 840.401,280.489 840.409,280.601L843.337,277.925C843.097,277.237 842.645,276.893 841.981,276.893C841.469,276.893 841.075,277.139 840.799,277.631C840.523,278.123 840.385,278.889 840.385,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M846.865,282.821L848.701,282.821L848.701,277.577L847.117,278.789L846.397,277.829L849.133,275.741L850.189,275.741L850.189,282.821L851.989,282.821L851.989,284.141L846.865,284.141L846.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M853.249,279.929C853.249,279.201 853.325,278.563 853.477,278.015C853.629,277.467 853.843,277.013 854.119,276.653C854.395,276.293 854.725,276.023 855.109,275.843C855.493,275.663 855.921,275.573 856.393,275.573C857.401,275.573 858.177,275.927 858.721,276.635C859.265,277.343 859.537,278.441 859.537,279.929C859.537,280.665 859.461,281.307 859.309,281.855C859.157,282.403 858.943,282.859 858.667,283.223C858.391,283.587 858.059,283.859 857.671,284.039C857.283,284.219 856.853,284.309 856.381,284.309C855.365,284.309 854.589,283.935 854.053,283.187C853.517,282.439 853.249,281.353 853.249,279.929ZM858.001,279.929C858.001,279.801 857.999,279.679 857.995,279.563C857.991,279.447 857.985,279.333 857.977,279.221L855.037,281.897C855.285,282.625 855.737,282.989 856.393,282.989C856.905,282.989 857.301,282.745 857.581,282.257C857.861,281.769 858.001,280.993 858.001,279.929ZM854.785,279.929C854.785,280.049 854.787,280.163 854.791,280.271C854.795,280.379 854.801,280.489 854.809,280.601L857.737,277.925C857.497,277.237 857.045,276.893 856.381,276.893C855.869,276.893 855.475,277.139 855.199,277.631C854.923,278.123 854.785,278.889 854.785,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M861.265,282.821L863.101,282.821L863.101,277.577L861.517,278.789L860.797,277.829L863.533,275.741L864.589,275.741L864.589,282.821L866.389,282.821L866.389,284.141L861.265,284.141L861.265,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M867.649,279.929C867.649,279.201 867.725,278.563 867.877,278.015C868.029,277.467 868.243,277.013 868.519,276.653C868.795,276.293 869.125,276.023 869.509,275.843C869.893,275.663 870.321,275.573 870.793,275.573C871.801,275.573 872.577,275.927 873.121,276.635C873.665,277.343 873.937,278.441 873.937,279.929C873.937,280.665 873.861,281.307 873.709,281.855C873.557,282.403 873.343,282.859 873.067,283.223C872.791,283.587 872.459,283.859 872.071,284.039C871.683,284.219 871.253,284.309 870.781,284.309C869.765,284.309 868.989,283.935 868.453,283.187C867.917,282.439 867.649,281.353 867.649,279.929ZM872.401,279.929C872.401,279.801 872.399,279.679 872.395,279.563C872.391,279.447 872.385,279.333 872.377,279.221L869.437,281.897C869.685,282.625 870.137,282.989 870.793,282.989C871.305,282.989 871.701,282.745 871.981,282.257C872.261,281.769 872.401,280.993 872.401,279.929ZM869.185,279.929C869.185,280.049 869.187,280.163 869.191,280.271C869.195,280.379 869.201,280.489 869.209,280.601L872.137,277.925C871.897,277.237 871.445,276.893 870.781,276.893C870.269,276.893 869.875,277.139 869.599,277.631C869.323,278.123 869.185,278.889 869.185,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line3(
            d="M875.665,282.821L877.501,282.821L877.501,277.577L875.917,278.789L875.197,277.829L877.933,275.741L878.989,275.741L878.989,282.821L880.789,282.821L880.789,284.141L875.665,284.141L875.665,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
        g(transform="matrix(2.27624,0,0,2.27624,-1581.38,-315.321)")
          path.line2(
            d="M717.265,282.821L719.101,282.821L719.101,277.577L717.517,278.789L716.797,277.829L719.533,275.741L720.589,275.741L720.589,282.821L722.389,282.821L722.389,284.141L717.265,284.141L717.265,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M723.649,279.929C723.649,279.201 723.725,278.563 723.877,278.015C724.029,277.467 724.243,277.013 724.519,276.653C724.795,276.293 725.125,276.023 725.509,275.843C725.893,275.663 726.321,275.573 726.793,275.573C727.801,275.573 728.577,275.927 729.121,276.635C729.665,277.343 729.937,278.441 729.937,279.929C729.937,280.665 729.861,281.307 729.709,281.855C729.557,282.403 729.343,282.859 729.067,283.223C728.791,283.587 728.459,283.859 728.071,284.039C727.683,284.219 727.253,284.309 726.781,284.309C725.765,284.309 724.989,283.935 724.453,283.187C723.917,282.439 723.649,281.353 723.649,279.929ZM728.401,279.929C728.401,279.801 728.399,279.679 728.395,279.563C728.391,279.447 728.385,279.333 728.377,279.221L725.437,281.897C725.685,282.625 726.137,282.989 726.793,282.989C727.305,282.989 727.701,282.745 727.981,282.257C728.261,281.769 728.401,280.993 728.401,279.929ZM725.185,279.929C725.185,280.049 725.187,280.163 725.191,280.271C725.195,280.379 725.201,280.489 725.209,280.601L728.137,277.925C727.897,277.237 727.445,276.893 726.781,276.893C726.269,276.893 725.875,277.139 725.599,277.631C725.323,278.123 725.185,278.889 725.185,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M731.665,282.821L733.501,282.821L733.501,277.577L731.917,278.789L731.197,277.829L733.933,275.741L734.989,275.741L734.989,282.821L736.789,282.821L736.789,284.141L731.665,284.141L731.665,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M738.865,282.821L740.701,282.821L740.701,277.577L739.117,278.789L738.397,277.829L741.133,275.741L742.189,275.741L742.189,282.821L743.989,282.821L743.989,284.141L738.865,284.141L738.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M745.249,279.929C745.249,279.201 745.325,278.563 745.477,278.015C745.629,277.467 745.843,277.013 746.119,276.653C746.395,276.293 746.725,276.023 747.109,275.843C747.493,275.663 747.921,275.573 748.393,275.573C749.401,275.573 750.177,275.927 750.721,276.635C751.265,277.343 751.537,278.441 751.537,279.929C751.537,280.665 751.461,281.307 751.309,281.855C751.157,282.403 750.943,282.859 750.667,283.223C750.391,283.587 750.059,283.859 749.671,284.039C749.283,284.219 748.853,284.309 748.381,284.309C747.365,284.309 746.589,283.935 746.053,283.187C745.517,282.439 745.249,281.353 745.249,279.929ZM750.001,279.929C750.001,279.801 749.999,279.679 749.995,279.563C749.991,279.447 749.985,279.333 749.977,279.221L747.037,281.897C747.285,282.625 747.737,282.989 748.393,282.989C748.905,282.989 749.301,282.745 749.581,282.257C749.861,281.769 750.001,280.993 750.001,279.929ZM746.785,279.929C746.785,280.049 746.787,280.163 746.791,280.271C746.795,280.379 746.801,280.489 746.809,280.601L749.737,277.925C749.497,277.237 749.045,276.893 748.381,276.893C747.869,276.893 747.475,277.139 747.199,277.631C746.923,278.123 746.785,278.889 746.785,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M753.265,282.821L755.101,282.821L755.101,277.577L753.517,278.789L752.797,277.829L755.533,275.741L756.589,275.741L756.589,282.821L758.389,282.821L758.389,284.141L753.265,284.141L753.265,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M760.465,282.821L762.301,282.821L762.301,277.577L760.717,278.789L759.997,277.829L762.733,275.741L763.789,275.741L763.789,282.821L765.589,282.821L765.589,284.141L760.465,284.141L760.465,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M767.665,282.821L769.501,282.821L769.501,277.577L767.917,278.789L767.197,277.829L769.933,275.741L770.989,275.741L770.989,282.821L772.789,282.821L772.789,284.141L767.665,284.141L767.665,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M774.865,282.821L776.701,282.821L776.701,277.577L775.117,278.789L774.397,277.829L777.133,275.741L778.189,275.741L778.189,282.821L779.989,282.821L779.989,284.141L774.865,284.141L774.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M782.065,282.821L783.901,282.821L783.901,277.577L782.317,278.789L781.597,277.829L784.333,275.741L785.389,275.741L785.389,282.821L787.189,282.821L787.189,284.141L782.065,284.141L782.065,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M788.449,279.929C788.449,279.201 788.525,278.563 788.677,278.015C788.829,277.467 789.043,277.013 789.319,276.653C789.595,276.293 789.925,276.023 790.309,275.843C790.693,275.663 791.121,275.573 791.593,275.573C792.601,275.573 793.377,275.927 793.921,276.635C794.465,277.343 794.737,278.441 794.737,279.929C794.737,280.665 794.661,281.307 794.509,281.855C794.357,282.403 794.143,282.859 793.867,283.223C793.591,283.587 793.259,283.859 792.871,284.039C792.483,284.219 792.053,284.309 791.581,284.309C790.565,284.309 789.789,283.935 789.253,283.187C788.717,282.439 788.449,281.353 788.449,279.929ZM793.201,279.929C793.201,279.801 793.199,279.679 793.195,279.563C793.191,279.447 793.185,279.333 793.177,279.221L790.237,281.897C790.485,282.625 790.937,282.989 791.593,282.989C792.105,282.989 792.501,282.745 792.781,282.257C793.061,281.769 793.201,280.993 793.201,279.929ZM789.985,279.929C789.985,280.049 789.987,280.163 789.991,280.271C789.995,280.379 790.001,280.489 790.009,280.601L792.937,277.925C792.697,277.237 792.245,276.893 791.581,276.893C791.069,276.893 790.675,277.139 790.399,277.631C790.123,278.123 789.985,278.889 789.985,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M796.465,282.821L798.301,282.821L798.301,277.577L796.717,278.789L795.997,277.829L798.733,275.741L799.789,275.741L799.789,282.821L801.589,282.821L801.589,284.141L796.465,284.141L796.465,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M803.665,282.821L805.501,282.821L805.501,277.577L803.917,278.789L803.197,277.829L805.933,275.741L806.989,275.741L806.989,282.821L808.789,282.821L808.789,284.141L803.665,284.141L803.665,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M810.865,282.821L812.701,282.821L812.701,277.577L811.117,278.789L810.397,277.829L813.133,275.741L814.189,275.741L814.189,282.821L815.989,282.821L815.989,284.141L810.865,284.141L810.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M817.249,279.929C817.249,279.201 817.325,278.563 817.477,278.015C817.629,277.467 817.843,277.013 818.119,276.653C818.395,276.293 818.725,276.023 819.109,275.843C819.493,275.663 819.921,275.573 820.393,275.573C821.401,275.573 822.177,275.927 822.721,276.635C823.265,277.343 823.537,278.441 823.537,279.929C823.537,280.665 823.461,281.307 823.309,281.855C823.157,282.403 822.943,282.859 822.667,283.223C822.391,283.587 822.059,283.859 821.671,284.039C821.283,284.219 820.853,284.309 820.381,284.309C819.365,284.309 818.589,283.935 818.053,283.187C817.517,282.439 817.249,281.353 817.249,279.929ZM822.001,279.929C822.001,279.801 821.999,279.679 821.995,279.563C821.991,279.447 821.985,279.333 821.977,279.221L819.037,281.897C819.285,282.625 819.737,282.989 820.393,282.989C820.905,282.989 821.301,282.745 821.581,282.257C821.861,281.769 822.001,280.993 822.001,279.929ZM818.785,279.929C818.785,280.049 818.787,280.163 818.791,280.271C818.795,280.379 818.801,280.489 818.809,280.601L821.737,277.925C821.497,277.237 821.045,276.893 820.381,276.893C819.869,276.893 819.475,277.139 819.199,277.631C818.923,278.123 818.785,278.889 818.785,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M825.265,282.821L827.101,282.821L827.101,277.577L825.517,278.789L824.797,277.829L827.533,275.741L828.589,275.741L828.589,282.821L830.389,282.821L830.389,284.141L825.265,284.141L825.265,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M832.465,282.821L834.301,282.821L834.301,277.577L832.717,278.789L831.997,277.829L834.733,275.741L835.789,275.741L835.789,282.821L837.589,282.821L837.589,284.141L832.465,284.141L832.465,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M839.665,282.821L841.501,282.821L841.501,277.577L839.917,278.789L839.197,277.829L841.933,275.741L842.989,275.741L842.989,282.821L844.789,282.821L844.789,284.141L839.665,284.141L839.665,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M846.865,282.821L848.701,282.821L848.701,277.577L847.117,278.789L846.397,277.829L849.133,275.741L850.189,275.741L850.189,282.821L851.989,282.821L851.989,284.141L846.865,284.141L846.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M853.249,279.929C853.249,279.201 853.325,278.563 853.477,278.015C853.629,277.467 853.843,277.013 854.119,276.653C854.395,276.293 854.725,276.023 855.109,275.843C855.493,275.663 855.921,275.573 856.393,275.573C857.401,275.573 858.177,275.927 858.721,276.635C859.265,277.343 859.537,278.441 859.537,279.929C859.537,280.665 859.461,281.307 859.309,281.855C859.157,282.403 858.943,282.859 858.667,283.223C858.391,283.587 858.059,283.859 857.671,284.039C857.283,284.219 856.853,284.309 856.381,284.309C855.365,284.309 854.589,283.935 854.053,283.187C853.517,282.439 853.249,281.353 853.249,279.929ZM858.001,279.929C858.001,279.801 857.999,279.679 857.995,279.563C857.991,279.447 857.985,279.333 857.977,279.221L855.037,281.897C855.285,282.625 855.737,282.989 856.393,282.989C856.905,282.989 857.301,282.745 857.581,282.257C857.861,281.769 858.001,280.993 858.001,279.929ZM854.785,279.929C854.785,280.049 854.787,280.163 854.791,280.271C854.795,280.379 854.801,280.489 854.809,280.601L857.737,277.925C857.497,277.237 857.045,276.893 856.381,276.893C855.869,276.893 855.475,277.139 855.199,277.631C854.923,278.123 854.785,278.889 854.785,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line2(
            d="M861.265,282.821L863.101,282.821L863.101,277.577L861.517,278.789L860.797,277.829L863.533,275.741L864.589,275.741L864.589,282.821L866.389,282.821L866.389,284.141L861.265,284.141L861.265,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
        g(transform="matrix(2.27624,0,0,2.27624,-1581.38,-348.907)")
          path.line1(
            d="M717.265,282.821L719.101,282.821L719.101,277.577L717.517,278.789L716.797,277.829L719.533,275.741L720.589,275.741L720.589,282.821L722.389,282.821L722.389,284.141L717.265,284.141L717.265,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M723.649,279.929C723.649,279.201 723.725,278.563 723.877,278.015C724.029,277.467 724.243,277.013 724.519,276.653C724.795,276.293 725.125,276.023 725.509,275.843C725.893,275.663 726.321,275.573 726.793,275.573C727.801,275.573 728.577,275.927 729.121,276.635C729.665,277.343 729.937,278.441 729.937,279.929C729.937,280.665 729.861,281.307 729.709,281.855C729.557,282.403 729.343,282.859 729.067,283.223C728.791,283.587 728.459,283.859 728.071,284.039C727.683,284.219 727.253,284.309 726.781,284.309C725.765,284.309 724.989,283.935 724.453,283.187C723.917,282.439 723.649,281.353 723.649,279.929ZM728.401,279.929C728.401,279.801 728.399,279.679 728.395,279.563C728.391,279.447 728.385,279.333 728.377,279.221L725.437,281.897C725.685,282.625 726.137,282.989 726.793,282.989C727.305,282.989 727.701,282.745 727.981,282.257C728.261,281.769 728.401,280.993 728.401,279.929ZM725.185,279.929C725.185,280.049 725.187,280.163 725.191,280.271C725.195,280.379 725.201,280.489 725.209,280.601L728.137,277.925C727.897,277.237 727.445,276.893 726.781,276.893C726.269,276.893 725.875,277.139 725.599,277.631C725.323,278.123 725.185,278.889 725.185,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M731.665,282.821L733.501,282.821L733.501,277.577L731.917,278.789L731.197,277.829L733.933,275.741L734.989,275.741L734.989,282.821L736.789,282.821L736.789,284.141L731.665,284.141L731.665,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M738.865,282.821L740.701,282.821L740.701,277.577L739.117,278.789L738.397,277.829L741.133,275.741L742.189,275.741L742.189,282.821L743.989,282.821L743.989,284.141L738.865,284.141L738.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M745.249,279.929C745.249,279.201 745.325,278.563 745.477,278.015C745.629,277.467 745.843,277.013 746.119,276.653C746.395,276.293 746.725,276.023 747.109,275.843C747.493,275.663 747.921,275.573 748.393,275.573C749.401,275.573 750.177,275.927 750.721,276.635C751.265,277.343 751.537,278.441 751.537,279.929C751.537,280.665 751.461,281.307 751.309,281.855C751.157,282.403 750.943,282.859 750.667,283.223C750.391,283.587 750.059,283.859 749.671,284.039C749.283,284.219 748.853,284.309 748.381,284.309C747.365,284.309 746.589,283.935 746.053,283.187C745.517,282.439 745.249,281.353 745.249,279.929ZM750.001,279.929C750.001,279.801 749.999,279.679 749.995,279.563C749.991,279.447 749.985,279.333 749.977,279.221L747.037,281.897C747.285,282.625 747.737,282.989 748.393,282.989C748.905,282.989 749.301,282.745 749.581,282.257C749.861,281.769 750.001,280.993 750.001,279.929ZM746.785,279.929C746.785,280.049 746.787,280.163 746.791,280.271C746.795,280.379 746.801,280.489 746.809,280.601L749.737,277.925C749.497,277.237 749.045,276.893 748.381,276.893C747.869,276.893 747.475,277.139 747.199,277.631C746.923,278.123 746.785,278.889 746.785,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M753.265,282.821L755.101,282.821L755.101,277.577L753.517,278.789L752.797,277.829L755.533,275.741L756.589,275.741L756.589,282.821L758.389,282.821L758.389,284.141L753.265,284.141L753.265,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M760.465,282.821L762.301,282.821L762.301,277.577L760.717,278.789L759.997,277.829L762.733,275.741L763.789,275.741L763.789,282.821L765.589,282.821L765.589,284.141L760.465,284.141L760.465,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M767.665,282.821L769.501,282.821L769.501,277.577L767.917,278.789L767.197,277.829L769.933,275.741L770.989,275.741L770.989,282.821L772.789,282.821L772.789,284.141L767.665,284.141L767.665,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M774.865,282.821L776.701,282.821L776.701,277.577L775.117,278.789L774.397,277.829L777.133,275.741L778.189,275.741L778.189,282.821L779.989,282.821L779.989,284.141L774.865,284.141L774.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M781.249,279.929C781.249,279.201 781.325,278.563 781.477,278.015C781.629,277.467 781.843,277.013 782.119,276.653C782.395,276.293 782.725,276.023 783.109,275.843C783.493,275.663 783.921,275.573 784.393,275.573C785.401,275.573 786.177,275.927 786.721,276.635C787.265,277.343 787.537,278.441 787.537,279.929C787.537,280.665 787.461,281.307 787.309,281.855C787.157,282.403 786.943,282.859 786.667,283.223C786.391,283.587 786.059,283.859 785.671,284.039C785.283,284.219 784.853,284.309 784.381,284.309C783.365,284.309 782.589,283.935 782.053,283.187C781.517,282.439 781.249,281.353 781.249,279.929ZM786.001,279.929C786.001,279.801 785.999,279.679 785.995,279.563C785.991,279.447 785.985,279.333 785.977,279.221L783.037,281.897C783.285,282.625 783.737,282.989 784.393,282.989C784.905,282.989 785.301,282.745 785.581,282.257C785.861,281.769 786.001,280.993 786.001,279.929ZM782.785,279.929C782.785,280.049 782.787,280.163 782.791,280.271C782.795,280.379 782.801,280.489 782.809,280.601L785.737,277.925C785.497,277.237 785.045,276.893 784.381,276.893C783.869,276.893 783.475,277.139 783.199,277.631C782.923,278.123 782.785,278.889 782.785,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M788.449,279.929C788.449,279.201 788.525,278.563 788.677,278.015C788.829,277.467 789.043,277.013 789.319,276.653C789.595,276.293 789.925,276.023 790.309,275.843C790.693,275.663 791.121,275.573 791.593,275.573C792.601,275.573 793.377,275.927 793.921,276.635C794.465,277.343 794.737,278.441 794.737,279.929C794.737,280.665 794.661,281.307 794.509,281.855C794.357,282.403 794.143,282.859 793.867,283.223C793.591,283.587 793.259,283.859 792.871,284.039C792.483,284.219 792.053,284.309 791.581,284.309C790.565,284.309 789.789,283.935 789.253,283.187C788.717,282.439 788.449,281.353 788.449,279.929ZM793.201,279.929C793.201,279.801 793.199,279.679 793.195,279.563C793.191,279.447 793.185,279.333 793.177,279.221L790.237,281.897C790.485,282.625 790.937,282.989 791.593,282.989C792.105,282.989 792.501,282.745 792.781,282.257C793.061,281.769 793.201,280.993 793.201,279.929ZM789.985,279.929C789.985,280.049 789.987,280.163 789.991,280.271C789.995,280.379 790.001,280.489 790.009,280.601L792.937,277.925C792.697,277.237 792.245,276.893 791.581,276.893C791.069,276.893 790.675,277.139 790.399,277.631C790.123,278.123 789.985,278.889 789.985,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M796.465,282.821L798.301,282.821L798.301,277.577L796.717,278.789L795.997,277.829L798.733,275.741L799.789,275.741L799.789,282.821L801.589,282.821L801.589,284.141L796.465,284.141L796.465,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M802.849,279.929C802.849,279.201 802.925,278.563 803.077,278.015C803.229,277.467 803.443,277.013 803.719,276.653C803.995,276.293 804.325,276.023 804.709,275.843C805.093,275.663 805.521,275.573 805.993,275.573C807.001,275.573 807.777,275.927 808.321,276.635C808.865,277.343 809.137,278.441 809.137,279.929C809.137,280.665 809.061,281.307 808.909,281.855C808.757,282.403 808.543,282.859 808.267,283.223C807.991,283.587 807.659,283.859 807.271,284.039C806.883,284.219 806.453,284.309 805.981,284.309C804.965,284.309 804.189,283.935 803.653,283.187C803.117,282.439 802.849,281.353 802.849,279.929ZM807.601,279.929C807.601,279.801 807.599,279.679 807.595,279.563C807.591,279.447 807.585,279.333 807.577,279.221L804.637,281.897C804.885,282.625 805.337,282.989 805.993,282.989C806.505,282.989 806.901,282.745 807.181,282.257C807.461,281.769 807.601,280.993 807.601,279.929ZM804.385,279.929C804.385,280.049 804.387,280.163 804.391,280.271C804.395,280.379 804.401,280.489 804.409,280.601L807.337,277.925C807.097,277.237 806.645,276.893 805.981,276.893C805.469,276.893 805.075,277.139 804.799,277.631C804.523,278.123 804.385,278.889 804.385,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M810.865,282.821L812.701,282.821L812.701,277.577L811.117,278.789L810.397,277.829L813.133,275.741L814.189,275.741L814.189,282.821L815.989,282.821L815.989,284.141L810.865,284.141L810.865,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M818.065,282.821L819.901,282.821L819.901,277.577L818.317,278.789L817.597,277.829L820.333,275.741L821.389,275.741L821.389,282.821L823.189,282.821L823.189,284.141L818.065,284.141L818.065,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M825.265,282.821L827.101,282.821L827.101,277.577L825.517,278.789L824.797,277.829L827.533,275.741L828.589,275.741L828.589,282.821L830.389,282.821L830.389,284.141L825.265,284.141L825.265,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M832.465,282.821L834.301,282.821L834.301,277.577L832.717,278.789L831.997,277.829L834.733,275.741L835.789,275.741L835.789,282.821L837.589,282.821L837.589,284.141L832.465,284.141L832.465,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M839.665,282.821L841.501,282.821L841.501,277.577L839.917,278.789L839.197,277.829L841.933,275.741L842.989,275.741L842.989,282.821L844.789,282.821L844.789,284.141L839.665,284.141L839.665,282.821Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M846.049,279.929C846.049,279.201 846.125,278.563 846.277,278.015C846.429,277.467 846.643,277.013 846.919,276.653C847.195,276.293 847.525,276.023 847.909,275.843C848.293,275.663 848.721,275.573 849.193,275.573C850.201,275.573 850.977,275.927 851.521,276.635C852.065,277.343 852.337,278.441 852.337,279.929C852.337,280.665 852.261,281.307 852.109,281.855C851.957,282.403 851.743,282.859 851.467,283.223C851.191,283.587 850.859,283.859 850.471,284.039C850.083,284.219 849.653,284.309 849.181,284.309C848.165,284.309 847.389,283.935 846.853,283.187C846.317,282.439 846.049,281.353 846.049,279.929ZM850.801,279.929C850.801,279.801 850.799,279.679 850.795,279.563C850.791,279.447 850.785,279.333 850.777,279.221L847.837,281.897C848.085,282.625 848.537,282.989 849.193,282.989C849.705,282.989 850.101,282.745 850.381,282.257C850.661,281.769 850.801,280.993 850.801,279.929ZM847.585,279.929C847.585,280.049 847.587,280.163 847.591,280.271C847.595,280.379 847.601,280.489 847.609,280.601L850.537,277.925C850.297,277.237 849.845,276.893 849.181,276.893C848.669,276.893 848.275,277.139 847.999,277.631C847.723,278.123 847.585,278.889 847.585,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
          path.line1(
            d="M853.249,279.929C853.249,279.201 853.325,278.563 853.477,278.015C853.629,277.467 853.843,277.013 854.119,276.653C854.395,276.293 854.725,276.023 855.109,275.843C855.493,275.663 855.921,275.573 856.393,275.573C857.401,275.573 858.177,275.927 858.721,276.635C859.265,277.343 859.537,278.441 859.537,279.929C859.537,280.665 859.461,281.307 859.309,281.855C859.157,282.403 858.943,282.859 858.667,283.223C858.391,283.587 858.059,283.859 857.671,284.039C857.283,284.219 856.853,284.309 856.381,284.309C855.365,284.309 854.589,283.935 854.053,283.187C853.517,282.439 853.249,281.353 853.249,279.929ZM858.001,279.929C858.001,279.801 857.999,279.679 857.995,279.563C857.991,279.447 857.985,279.333 857.977,279.221L855.037,281.897C855.285,282.625 855.737,282.989 856.393,282.989C856.905,282.989 857.301,282.745 857.581,282.257C857.861,281.769 858.001,280.993 858.001,279.929ZM854.785,279.929C854.785,280.049 854.787,280.163 854.791,280.271C854.795,280.379 854.801,280.489 854.809,280.601L857.737,277.925C857.497,277.237 857.045,276.893 856.381,276.893C855.869,276.893 855.475,277.139 855.199,277.631C854.923,278.123 854.785,278.889 854.785,279.929Z"
            style="fill: #00cf00; fill-rule: nonzero"
          )
        g#dark(transform="matrix(1,0,0,1,580,430)")
          path(
            d="M0,-400L-580,-400L-580,-8C-580,-3.582 -576.418,0 -572,0L-8,0C-3.582,0 0,-3.582 0,-8L0,-400Z"
            style="fill: #283035; fill-opacity: 0.596078; fill-rule: nonzero"
          )
        g#popup
          g(transform="matrix(1,0,0,1,481.111,96.0058)")
            path(
              d="M0,260L-384,260C-388.4,260 -392,256.399 -392,252L-392,8C-392,3.6 -388.4,0 -384,0L0,0C4.399,0 8,3.6 8,8L8,252C8,256.399 4.399,260 0,260"
              style="fill: #fff; fill-rule: nonzero"
            )
          g
            g(opacity="0.699997")
              g(transform="matrix(1,0,0,1,268.081,121.194)")
                path(
                  d="M0,29.269L-7.067,29.269L-11.031,13.894C-11.178,13.347 -11.428,12.216 -11.781,10.5C-12.135,8.786 -12.339,7.635 -12.392,7.047C-12.472,7.768 -12.672,8.926 -12.993,10.521C-13.313,12.116 -13.56,13.253 -13.733,13.934L-17.677,29.269L-24.724,29.269L-32.191,0L-26.085,0L-22.342,15.976C-21.688,18.926 -21.214,21.481 -20.92,23.644C-20.84,22.883 -20.657,21.705 -20.37,20.11C-20.083,18.516 -19.813,17.277 -19.559,16.396L-15.295,0L-9.429,0L-5.165,16.396C-4.979,17.131 -4.745,18.252 -4.464,19.76C-4.184,21.268 -3.971,22.562 -3.824,23.644C-3.69,22.602 -3.477,21.305 -3.183,19.749C-2.89,18.195 -2.623,16.937 -2.382,15.976L1.341,0L7.447,0L0,29.269Z"
                  style="fill: #000222; fill-rule: nonzero"
                )
              g(transform="matrix(1,0,0,1,291.144,138.392)")
                path(
                  d="M0,1.701L-2.362,1.781C-4.138,1.835 -5.459,2.155 -6.326,2.742C-7.194,3.329 -7.627,4.223 -7.627,5.425C-7.627,7.146 -6.64,8.007 -4.665,8.007C-3.25,8.007 -2.119,7.6 -1.271,6.786C-0.424,5.972 0,4.891 0,3.543L0,1.701ZM1.802,12.071L0.621,9.028L0.461,9.028C-0.567,10.323 -1.625,11.221 -2.713,11.721C-3.801,12.222 -5.219,12.472 -6.967,12.472C-9.116,12.472 -10.808,11.858 -12.042,10.63C-13.276,9.402 -13.894,7.654 -13.894,5.385C-13.894,3.009 -13.063,1.258 -11.401,0.13C-9.739,-0.998 -7.234,-1.622 -3.884,-1.742L0,-1.862L0,-2.843C0,-5.112 -1.161,-6.247 -3.483,-6.247C-5.272,-6.247 -7.374,-5.706 -9.79,-4.625L-11.812,-8.749C-9.236,-10.097 -6.38,-10.771 -3.243,-10.771C-0.24,-10.771 2.063,-10.117 3.664,-8.809C5.266,-7.501 6.066,-5.512 6.066,-2.843L6.066,12.071L1.802,12.071Z"
                  style="fill: #000222; fill-rule: nonzero"
                )
              g(transform="matrix(1,0,0,1,0,-160.225)")
                path(
                  d="M309.601,310.688L303.496,310.688L303.496,288.306L309.601,288.306L309.601,310.688ZM303.235,282.52C303.235,280.532 304.343,279.537 306.558,279.537C308.773,279.537 309.882,280.532 309.882,282.52C309.882,283.468 309.604,284.205 309.051,284.733C308.496,285.26 307.666,285.523 306.558,285.523C304.343,285.523 303.235,284.522 303.235,282.52"
                  style="fill: #000222; fill-rule: nonzero"
                )
              g(transform="matrix(1,0,0,1,325.437,128.182)")
                path(
                  d="M0,17.817C1.067,17.817 2.349,17.584 3.844,17.116L3.844,21.661C2.322,22.341 0.453,22.682 -1.762,22.682C-4.205,22.682 -5.983,22.065 -7.098,20.83C-8.212,19.596 -8.77,17.744 -8.77,15.275L-8.77,4.484L-11.691,4.484L-11.691,1.901L-8.328,-0.141L-6.566,-4.865L-2.663,-4.865L-2.663,-0.1L3.604,-0.1L3.604,4.484L-2.663,4.484L-2.663,15.275C-2.663,16.142 -2.42,16.783 -1.933,17.196C-1.445,17.61 -0.801,17.817 0,17.817"
                  style="fill: #000222; fill-rule: nonzero"
                )
              g(transform="matrix(1,0,0,1,338.931,131.444)")
                path(
                  d="M0,9.309L-4.885,9.309L-5.906,-10.25L1.021,-10.25L0,9.309ZM-5.986,16.156C-5.986,15.035 -5.686,14.187 -5.085,13.613C-4.484,13.039 -3.61,12.752 -2.463,12.752C-1.354,12.752 -0.497,13.046 0.11,13.633C0.717,14.221 1.021,15.061 1.021,16.156C1.021,17.21 0.714,18.041 0.1,18.648C-0.514,19.255 -1.368,19.559 -2.463,19.559C-3.584,19.559 -4.451,19.262 -5.064,18.668C-5.679,18.075 -5.986,17.237 -5.986,16.156"
                  style="fill: #000222; fill-rule: nonzero"
                )
          g(transform="matrix(1,0,0,1,444.474,169.259)")
            path(
              d="M0,11L-309,11C-312.025,11 -314.5,8.525 -314.5,5.5C-314.5,2.475 -312.025,0 -309,0L0,0C3.024,0 5.5,2.475 5.5,5.5C5.5,8.525 3.024,11 0,11"
              style="fill: #a9b2b7; fill-rule: nonzero"
            )
          g(transform="matrix(1,0,0,1,444.474,187.944)")
            path(
              d="M0,11L-309,11C-312.025,11 -314.5,8.525 -314.5,5.5C-314.5,2.475 -312.025,0 -309,0L0,0C3.024,0 5.5,2.475 5.5,5.5C5.5,8.525 3.024,11 0,11"
              style="fill: #a9b2b7; fill-rule: nonzero"
            )
          g(transform="matrix(1,0,0,1,275.851,235.797)")
            path(
              d="M0,30L-139,30C-142.025,30 -144.5,27.524 -144.5,24.5L-144.5,5.5C-144.5,2.475 -142.025,0 -139,0L0,0C3.025,0 5.5,2.475 5.5,5.5L5.5,24.5C5.5,27.524 3.025,30 0,30Z"
              style="fill: none; stroke: #a9b2b7; stroke-width: 2px"
            )
          g(transform="matrix(1,0,0,1,444.474,235.797)")
            path(
              d="M0,30L-139,30C-142.025,30 -144.5,27.524 -144.5,24.5L-144.5,5.5C-144.5,2.475 -142.025,0 -139,0L0,0C3.024,0 5.5,2.475 5.5,5.5L5.5,24.5C5.5,27.524 3.024,30 0,30Z"
              style="fill: none; stroke: #a9b2b7; stroke-width: 2px"
            )
          g(transform="matrix(1,0,0,1,364.474,283.492)")
            path(
              d="M0,40L-149,40C-152.025,40 -154.5,37.524 -154.5,34.5L-154.5,5.5C-154.5,2.475 -152.025,0 -149,0L0,0C3.024,0 5.5,2.475 5.5,5.5L5.5,34.5C5.5,37.524 3.024,40 0,40"
              style="fill: #ec5a29; fill-rule: nonzero"
            )
          g#subscribe
            g(transform="matrix(1,0,0,1,238.74,300.589)")
              path(
                d="M0,7.947C0,9.558 -0.58,10.828 -1.74,11.756C-2.899,12.683 -4.513,13.147 -6.58,13.147C-8.484,13.147 -10.168,12.789 -11.633,12.073L-11.633,8.557C-10.429,9.094 -9.41,9.473 -8.576,9.692C-7.741,9.913 -6.979,10.022 -6.287,10.022C-5.457,10.022 -4.82,9.864 -4.376,9.546C-3.933,9.229 -3.711,8.757 -3.711,8.13C-3.711,7.78 -3.809,7.469 -4.004,7.196C-4.199,6.924 -4.486,6.661 -4.865,6.409C-5.243,6.157 -6.014,5.754 -7.178,5.2C-8.269,4.688 -9.086,4.195 -9.631,3.723C-10.177,3.252 -10.612,2.702 -10.938,2.075C-11.263,1.449 -11.426,0.717 -11.426,-0.122C-11.426,-1.7 -10.891,-2.942 -9.821,-3.845C-8.75,-4.748 -7.271,-5.2 -5.383,-5.2C-4.456,-5.2 -3.571,-5.09 -2.728,-4.87C-1.886,-4.651 -1.005,-4.341 -0.085,-3.943L-1.306,-1.001C-2.258,-1.392 -3.045,-1.664 -3.668,-1.819C-4.291,-1.973 -4.903,-2.05 -5.505,-2.05C-6.222,-2.05 -6.771,-1.884 -7.153,-1.55C-7.536,-1.216 -7.727,-0.781 -7.727,-0.243C-7.727,0.09 -7.65,0.381 -7.495,0.629C-7.341,0.878 -7.094,1.117 -6.756,1.349C-6.419,1.581 -5.62,1.998 -4.358,2.6C-2.69,3.398 -1.546,4.198 -0.928,4.999C-0.31,5.801 0,6.783 0,7.947"
                style="fill: #fff; fill-rule: nonzero"
              )
            g(transform="matrix(1,0,0,1,251.35,300.089)")
              path(
                d="M0,13.403L-0.5,11.657L-0.696,11.657C-1.095,12.292 -1.66,12.783 -2.393,13.129C-3.125,13.474 -3.959,13.647 -4.895,13.647C-6.499,13.647 -7.707,13.218 -8.521,12.359C-9.334,11.501 -9.741,10.266 -9.741,8.655L-9.741,-0.244L-6.018,-0.244L-6.018,7.727C-6.018,8.712 -5.843,9.45 -5.493,9.942C-5.144,10.435 -4.586,10.68 -3.821,10.68C-2.779,10.68 -2.026,10.333 -1.562,9.637C-1.099,8.941 -0.867,7.788 -0.867,6.176L-0.867,-0.244L2.856,-0.244L2.856,13.403L0,13.403Z"
                style="fill: #fff; fill-rule: nonzero"
              )
            g(transform="matrix(1,0,0,1,264.509,305.668)")
              path(
                d="M0,-3.102C-0.92,-3.102 -1.591,-2.819 -2.014,-2.253C-2.438,-1.687 -2.657,-0.753 -2.673,0.548L-2.673,0.951C-2.673,2.416 -2.456,3.466 -2.021,4.101C-1.585,4.736 -0.896,5.052 0.049,5.052C0.813,5.052 1.422,4.701 1.874,3.997C2.325,3.294 2.551,2.269 2.551,0.927C2.551,-0.416 2.323,-1.423 1.868,-2.094C1.412,-2.766 0.789,-3.102 0,-3.102M1.196,-6.081C2.808,-6.081 4.069,-5.452 4.98,-4.194C5.892,-2.937 6.348,-1.213 6.348,0.976C6.348,3.23 5.878,4.975 4.937,6.212C3.998,7.45 2.718,8.068 1.099,8.068C-0.505,8.068 -1.762,7.486 -2.673,6.322L-2.93,6.322L-3.552,7.824L-6.396,7.824L-6.396,-11.17L-2.673,-11.17L-2.673,-6.751C-2.673,-6.19 -2.722,-5.29 -2.82,-4.053L-2.673,-4.053C-1.803,-5.405 -0.513,-6.081 1.196,-6.081"
                style="fill: #fff; fill-rule: nonzero"
              )
            g(transform="matrix(1,0,0,1,283.455,303.884)")
              path(
                d="M0,5.555C0,6.954 -0.486,8.021 -1.459,8.753C-2.431,9.486 -3.886,9.852 -5.823,9.852C-6.816,9.852 -7.662,9.784 -8.362,9.651C-9.062,9.516 -9.717,9.319 -10.327,9.059L-10.327,5.983C-9.636,6.308 -8.856,6.58 -7.989,6.8C-7.123,7.02 -6.36,7.129 -5.701,7.129C-4.35,7.129 -3.674,6.739 -3.674,5.957C-3.674,5.664 -3.764,5.427 -3.943,5.243C-4.122,5.061 -4.431,4.853 -4.871,4.621C-5.31,4.389 -5.896,4.118 -6.628,3.809C-7.678,3.369 -8.449,2.963 -8.941,2.588C-9.434,2.214 -9.792,1.785 -10.016,1.301C-10.24,0.817 -10.352,0.221 -10.352,-0.488C-10.352,-1.7 -9.882,-2.637 -8.941,-3.301C-8.002,-3.965 -6.669,-4.297 -4.944,-4.297C-3.3,-4.297 -1.701,-3.938 -0.146,-3.222L-1.27,-0.537C-1.953,-0.83 -2.592,-1.069 -3.186,-1.257C-3.78,-1.444 -4.387,-1.537 -5.005,-1.537C-6.104,-1.537 -6.653,-1.24 -6.653,-0.646C-6.653,-0.312 -6.476,-0.023 -6.122,0.221C-5.768,0.465 -4.993,0.826 -3.796,1.307C-2.73,1.739 -1.949,2.141 -1.453,2.516C-0.957,2.89 -0.59,3.321 -0.354,3.809C-0.118,4.297 0,4.879 0,5.555"
                style="fill: #fff; fill-rule: nonzero"
              )
            g(transform="matrix(1,0,0,1,291.902,299.588)")
              path(
                d="M0,14.148C-4.249,14.148 -6.373,11.816 -6.373,7.154C-6.373,4.834 -5.795,3.062 -4.639,1.837C-3.484,0.613 -1.828,0 0.328,0C1.908,0 3.324,0.309 4.576,0.927L3.479,3.808C2.893,3.573 2.348,3.379 1.842,3.229C1.338,3.079 0.834,3.003 0.328,3.003C-1.607,3.003 -2.576,4.378 -2.576,7.128C-2.576,9.798 -1.607,11.132 0.328,11.132C1.045,11.132 1.709,11.038 2.318,10.846C2.93,10.655 3.539,10.356 4.15,9.949L4.15,13.134C3.547,13.517 2.939,13.782 2.324,13.928C1.711,14.075 0.936,14.148 0,14.148"
                style="fill: #fff; fill-rule: nonzero"
              )
            g(transform="matrix(1,0,0,1,306.831,313.492)")
              path(
                d="M0,-13.904C0.505,-13.904 0.924,-13.867 1.258,-13.794L0.977,-10.302C0.676,-10.383 0.31,-10.425 -0.122,-10.425C-1.311,-10.425 -2.236,-10.119 -2.899,-9.509C-3.563,-8.898 -3.895,-8.045 -3.895,-6.945L-3.895,0L-7.617,0L-7.617,-13.647L-4.797,-13.647L-4.248,-11.352L-4.064,-11.352C-3.642,-12.117 -3.07,-12.733 -2.35,-13.202C-1.63,-13.67 -0.847,-13.904 0,-13.904"
                style="fill: #fff; fill-rule: nonzero"
              )
            g(transform="matrix(1,0,0,1,0,177.99)")
              path(
                d="M314.289,135.502L310.566,135.502L310.566,121.855L314.289,121.855L314.289,135.502ZM310.408,118.326C310.408,117.114 311.083,116.508 312.434,116.508C313.785,116.508 314.461,117.114 314.461,118.326C314.461,118.905 314.291,119.355 313.954,119.676C313.616,119.997 313.109,120.158 312.434,120.158C311.083,120.158 310.408,119.547 310.408,118.326"
                style="fill: #fff; fill-rule: nonzero"
              )
            g(transform="matrix(1,0,0,1,324.592,305.668)")
              path(
                d="M0,-3.102C-0.92,-3.102 -1.591,-2.819 -2.014,-2.253C-2.438,-1.687 -2.657,-0.753 -2.674,0.548L-2.674,0.951C-2.674,2.416 -2.456,3.466 -2.021,4.101C-1.585,4.736 -0.896,5.052 0.049,5.052C0.813,5.052 1.422,4.701 1.874,3.997C2.325,3.294 2.551,2.269 2.551,0.927C2.551,-0.416 2.323,-1.423 1.867,-2.094C1.412,-2.766 0.789,-3.102 0,-3.102M1.196,-6.081C2.808,-6.081 4.069,-5.452 4.98,-4.194C5.892,-2.937 6.348,-1.213 6.348,0.976C6.348,3.23 5.878,4.975 4.937,6.212C3.998,7.45 2.718,8.068 1.099,8.068C-0.505,8.068 -1.762,7.486 -2.674,6.322L-2.93,6.322L-3.553,7.824L-6.396,7.824L-6.396,-11.17L-2.674,-11.17L-2.674,-6.751C-2.674,-6.19 -2.723,-5.29 -2.82,-4.053L-2.674,-4.053C-1.803,-5.405 -0.513,-6.081 1.196,-6.081"
                style="fill: #fff; fill-rule: nonzero"
              )
            g(transform="matrix(1,0,0,1,339.668,311.087)")
              path(
                d="M0,-8.85C-0.79,-8.85 -1.408,-8.6 -1.855,-8.099C-2.304,-7.599 -2.56,-6.889 -2.625,-5.969L2.6,-5.969C2.583,-6.889 2.344,-7.599 1.879,-8.099C1.416,-8.6 0.789,-8.85 0,-8.85M0.524,2.649C-1.673,2.649 -3.39,2.043 -4.627,0.831C-5.864,-0.382 -6.482,-2.099 -6.482,-4.322C-6.482,-6.608 -5.911,-8.375 -4.768,-9.625C-3.624,-10.874 -2.043,-11.499 -0.025,-11.499C1.904,-11.499 3.405,-10.95 4.479,-9.851C5.554,-8.752 6.091,-7.235 6.091,-5.298L6.091,-3.492L-2.711,-3.492C-2.67,-2.433 -2.356,-1.607 -1.771,-1.013C-1.185,-0.419 -0.362,-0.122 0.695,-0.122C1.518,-0.122 2.295,-0.207 3.027,-0.378C3.76,-0.549 4.524,-0.822 5.322,-1.197L5.322,1.684C4.671,2.01 3.975,2.253 3.234,2.411C2.494,2.57 1.591,2.649 0.524,2.649"
                style="fill: #fff; fill-rule: nonzero"
              )
            g(transform="matrix(1,0,0,1,351.912,301.895)")
              path(
                d="M0,5.677L-2.979,5.677L-3.602,-6.25L0.623,-6.25L0,5.677ZM-3.65,9.851C-3.65,9.167 -3.467,8.651 -3.101,8.301C-2.734,7.951 -2.201,7.777 -1.502,7.777C-0.826,7.777 -0.304,7.955 0.067,8.314C0.437,8.671 0.623,9.184 0.623,9.851C0.623,10.494 0.436,11.001 0.061,11.371C-0.313,11.741 -0.834,11.927 -1.502,11.927C-2.186,11.927 -2.714,11.745 -3.088,11.383C-3.463,11.022 -3.65,10.511 -3.65,9.851"
                style="fill: #fff; fill-rule: nonzero"
              )
</template>

<script>
  export default {
    props: {
      animationActive: { type: Boolean },
    },

    mounted() {
      const svg = this.$refs.svg;
      const line1 = svg.querySelectorAll('.line1');
      const line2 = svg.querySelectorAll('.line2');
      const line3 = svg.querySelectorAll('.line3');
      const line4 = svg.querySelectorAll('.line4');

      const charDelay = 25;
      const lineDelay = 400;
      [line1, line2, line3, line4].forEach((lineEls, lineIndex) => {
        lineEls.forEach((el, index) => {
          el.style.animationDelay = `${lineDelay * lineIndex + index * charDelay}ms`;
        });
      });

      /*
      TweenLite.set(popup, { yPercent: -150 });
      TweenLite.set(dark, { autoAlpha: 0 });

      const tl = new TimelineMax({ paused: true });

      const t = tl.set(svg, { willChange: 'transform' });
      tl.staggerFrom(line1, 0.002, { autoAlpha: 0 }, 0.03)
        .staggerFrom(line2, 0.002, { autoAlpha: 0 }, 0.03, '-=.25')
        .staggerFrom(line3, 0.002, { autoAlpha: 0 }, 0.03, '-=.3')
        .staggerFrom(line4, 0.002, { autoAlpha: 0 }, 0.03, '-=.35');
      tl.to(dark, 0.03, { autoAlpha: 1 }, '-=.07').to(popup, 0.3, {
        yPercent: 0,
        ease: Back.easeOut.config(1.7),
      });
      this.t = t;
      */
    },
  };
</script>

<style lang="sass" scoped>
  #dark
    opacity: 0
  #popup
    transform: translateY(-150%)
  .line1,
  .line2,
  .line3,
  .line4
    opacity: 0
  .animating
    #dark
      animation: js-event-dark-animation 0.1s 1.5s forwards
    #popup
      animation: js-event-popup-animation 0.3s 1.5s forwards
      animation-timing-function: cubic-bezier(.17,.67,.53,1.2)
    .line1,
    .line2,
    .line3,
    .line4
      animation: js-event-line-animation 100ms forwards

  @keyframes js-event-dark-animation
    from
      opacity: 0
    to
      opacity: 1
  @keyframes js-event-popup-animation
    from
      transform: translateY(-150%)
    to
      transform: translateY(0%)
  @keyframes js-event-line-animation
    from
      opacity: 0
    to
      opacity: 1
</style>
