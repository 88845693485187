<template lang="pug">
div
  div(v-for="index in rows" :key="index")
    .skeleton-row
      .first-column
        vue-skeleton-loader.mt-2(type="rect" width="60%" height="12px" :rounded="rounded")
        vue-skeleton-loader.mt-2.mb-2(type="rect" width="60%" height="12px" :rounded="rounded")
      .second-column
        vue-skeleton-loader.mt-2(type="rect" width="60%" height="12px" :rounded="rounded")
        vue-skeleton-loader.mt-2.mb-2(type="rect" width="60%" height="12px" :rounded="rounded")
      .third-column
        vue-skeleton-loader(type="rect" width="100%" height="24px" :rounded="rounded")
    hr(v-if="bottomLine")
</template>

<script>
  export default {
    props: {
      rows: { type: Number },
      rounded: { type: Boolean },
      bottomLine: { type: Boolean },
    },
  };
</script>

<style lang="sass" scoped>
  hr
    margin: 0px
  .skeleton-row
    display: flex
    align-items: center
    justify-content: space-between
    padding-top: 11px
    padding-bottom: 11px
  .first-column
    flex-direction: column
    flex: 0 0 60%
    display: flex
    justify-content: flex-start
  .second-column
    display: flex
    flex-direction: column
    flex: 0 0 25%
  .third-column
    display: flex
    flex: 0 0 15%
</style>
