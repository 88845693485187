<template lang="pug">
div
  device-chooser.mb-4(:currentRule.sync="currentRule")
  .row
    .col.d-flex.align-items-center
      span {{ $t('inactivity1') }}
      om-input#inactivityTrigger.w-5.mx-3(
        type="number"
        :min="0"
        :error="$v.currentRule.options.delay.$error"
        v-model="currentRule.options.delay"
      )
      span {{ $t('inactivity2') }}
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import { required, minValue } from 'vuelidate/lib/validators';
  import DeviceChooser from './DeviceChooser.vue';

  export default {
    components: {
      DeviceChooser,
    },
    mixins: [settingsValidation],

    validations: {
      currentRule: {
        options: {
          delay: {
            required,
            minValue: minValue(0),
          },
        },
      },
    },
  };
</script>
