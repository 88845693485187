import Vue from 'vue';
import CHANGE_CAMPAIGN_SETTINGS from '@/graphql/ChangeCampaignSettings.gql';

export default {
  methods: {
    async changeCampaignSetting(fieldName, newVal, campaignId, onError) {
      const {
        data: { changeCampaignSettings: result },
      } = await this.$apollo.mutate({
        mutation: CHANGE_CAMPAIGN_SETTINGS,
        variables: {
          input: {
            _id: campaignId,
            [fieldName]: newVal,
          },
        },
      });

      if (result.success) {
        if (this.campaign) Vue.set(this.campaign, fieldName, newVal);
        if (this.campaigns?.length) {
          const campaignIndex = this.campaigns.findIndex((campaign) => campaign.id === campaignId);
          Vue.set(this.campaigns[campaignIndex], fieldName, newVal);
        }
      } else {
        this.$notify({
          type: 'error',
          text: this.$t(`notifications.${result.message}`),
        });
        onError();
      }
    },
  },
};
