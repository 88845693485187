<template lang="pug">
.card.ab-test-reporting
  .smart-ab-test-reporting-top.mb-5
    .d-flex.flex-grow-1.justify-content-sm-between.card-title
      .d-flex.align-items-center(style="gap: 0.5rem")
        div {{ $t('smartAbTest.page.title') }}
      .d-flex.a-b-test-actions.position-relative.align-items-center
        om-body-text.mr-2(bt400sm) {{ $t('smartAbTest.page.conversion') }}
        om-select#primary-goal-select.pl-1.primary-goal-select.mr-4(
          :options="primaryGoalOptions"
          v-model="activePrimaryGoal"
          optionKey="_id"
          optionText="name"
          extendable
          :disabled="hasConversion"
          :addNewText="$t('conversionGoals.addNewText')"
          @addNew="openCreateGoalModal(domainId, { isPrimary: true })"
        )
        om-button(primary @click="goToEditor") {{ $t('smartAbTest.page.editTest') }}
    vue-skeleton-loader.skeleton-smartABTest.mt-5(
      v-if="loadingSABTestReport"
      type="rect"
      width="100%"
      height="72px"
      :rounded="true"
    )
    template(v-else)
      .smart-ab-test-reporting-summary(v-if="hasEnoughConversions || campaign.status === 'active'")
        .d-flex.align-items-center(v-if="hasEnoughConversions")
          UilChartDown(slot="icon" size="2.25rem" :color="`#239E77`")
          .winner-uplift.mx-2 {{ variantUplift }}
          .winner-uplift-label.mx-2 {{ $t('smartAbTest.page.uplift') }}
          .d-flex.flex-grow-1.justify-content-sm-between
            .description.ml-3
              om-body-text(bt400md) {{ $t('smartAbTest.page.upliftDescription', { upliftPercentage: variantUplift }) }}
        om-body-text(bt400md v-else-if="campaign.status === 'active'") {{ $t('smartAbTest.page.testRunningText') }}
        .last-updated(
          v-if="(campaign.status === 'active' && !hasEnoughConversions) || hasEnoughConversions"
        )
          om-body-text(bt400sm v-if="smartABTest.updatedAt")
            span.mr-2 {{ $t('smartAbTest.page.lastUpdated') }}:
            span {{ lastUpdated }}
          om-body-text(bt400sm v-else)
            span {{ $t('smartAbTest.page.firstUpdate') }}
  table.smart-ab-test-table.table
    thead
      tr
        td {{ $t('smartAbTest.page.columns.tests') }}
        td.text-right.w-7 {{ $t('smartAbTest.page.columns.visitors') }}
        td.text-right.w-7
          om-tooltip
            span {{ $t('smartAbTest.page.columns.conversionTooltip') }}
            template(slot="trigger")
              u {{ $t('smartAbTest.page.columns.conversion') }}
        td.text-right.w-7 {{ $t('smartAbTest.page.columns.conversionRate') }}
        td.text-right.w-7
          om-tooltip
            span {{ $t('smartAbTest.page.columns.upliftTooltip') }}
            template(slot="trigger")
              u {{ $t('smartAbTest.page.columns.uplift') }}
        td.text-right.w-7
          om-tooltip
            span {{ $t('smartAbTest.page.columns.chanceToWinTooltip') }}
            template(slot="trigger")
              u {{ $t('smartAbTest.page.columns.chanceToWin') }}
    skeleton-loader.d-contents(
      type="SAB-reporting"
      :rows="skeletonRowNumber"
      v-if="loadingSABTestReport"
    )
    tbody(v-else)
      template(v-for="(smartTest, index) in smartTests")
        tr.current-smart-test
          td.px-0.pb-0(colspan="6")
            table.table
              tbody
                tr
                  td.border-top.pt-5
                    .d-flex
                      .smart-ab-test-element {{ $t('smartAbTest.page.accordion.element', { number: smartTest.index + 1 }) }}
                      om-link.ml-2.smart-ab-test-accordion-toggle(primary small)(
                        @click="toggleAccordion(smartTest.id)"
                        :disabled="!smartTest.history.length"
                      )
                        template(v-if="smartTest.id == activeItem") {{ $t('smartAbTest.page.accordion.showOnly') }}
                        template(v-else) {{ $t('smartAbTest.page.accordion.showAll') }}
                  td.text-right.border-top.pt-5(colspan="5")
                tr(v-if="smartTest.current")
                  td.py-0
                    span.form-text(v-if="campaign.status !== 'active' && !hasImpression") {{ $t('smartAbTest.page.accordion.testRound', { runningTests: countOfHistory(smartTest) + 1, allTests: smartTest.testsCount - 1 }) }}
                    span.form-text(v-else) {{ $t('smartAbTest.page.accordion.testsAreRunning', { runningTests: countOfHistory(smartTest) + 1, allTests: smartTest.testsCount - 1, date: sinceDate(smartTest.current.startedAt), days: getRunningDays(smartTest.current.evaluationCount) }) }}
                  td.py-0.text-right(colspan="5")
                template(v-if="smartTest.current")
                  tr.current-comparer
                    td.pb-3(rowspan="2")
                      .smart-ab-test-item
                        ComparerSigns
                        .comparers
                          .comparer-control
                            Comparer(
                              :comparerName="smartTest.current.control.name"
                              :comparerText="smartTest.current.control.text"
                            )
                          .comparer-challenger
                            Comparer(
                              :comparerName="smartTest.current.challenger.name"
                              :comparerText="smartTest.current.challenger.text"
                            )
                    td.w-7.text-right.align-middle {{ smartTest.current.control.visitorsCount | thousandSep }}
                    td.w-7.text-right.align-middle {{ smartTest.current.control.conversionCount | thousandSep }}
                    td.w-7.text-right.align-middle {{ ((smartTest.current.control.conversionCount / smartTest.current.control.visitorsCount || 0) * 100) | dec2 }}
                    td.w-7.text-right
                    td.w-7.text-right
                  tr(
                    :class="`winner-current-${isWillBeWinner(smartTest.current.challenger.chanceToWin)}`"
                  )
                    td.w-7.text-right.align-middle.pb-3 {{ smartTest.current.challenger.visitorsCount | thousandSep }}
                    td.w-7.text-right.align-middle.pb-3 {{ smartTest.current.challenger.conversionCount | thousandSep }}
                    td.w-7.text-right.align-middle.pb-3
                      strong {{ ((smartTest.current.challenger.conversionCount / smartTest.current.challenger.visitorsCount || 0) * 100) | dec2 }}
                    td.w-7.text-right.align-middle.pb-3
                      strong.smart-kpi {{ (smartTest.current.challenger.uplift * 100) | dec2 }}
                    td.w-7.text-right.align-middle.pb-3(
                      v-if="smartTest.current.challenger.chanceToWin"
                    )
                      strong.smart-kpi {{ (smartTest.current.challenger.chanceToWin * 100) | dec2 }}
                template(v-else)
                  tr.winner-comparer
                    td(rowspan="2")
                      .smart-ab-test-item
                        .winner-sign
                          UilTrophy(slot="icon" size="1.5rem" :color="`#fff`")
                        Comparer(
                          :customLabel="true"
                          :comparerName="$t('smartAbTest.page.accordion.testsFinished', { date: formatDate(smartTest.history[0].finishedAt) })"
                          :comparerText="`${getWinnerDataFromHistory(smartTest.history[0]).text}`"
                          :textSuffix="getWinnerDataFromHistory(smartTest.history[0]).name"
                        )
                  tr(:class="`winner-${getWinnerDataFromHistory(smartTest.history[0]).winner}`")
                    td.w-7.text-right.align-middle {{ getWinnerDataFromHistory(smartTest.history[0]).visitorsCount | thousandSep }}
                    td.w-7.text-right.align-middle {{ getWinnerDataFromHistory(smartTest.history[0]).conversionCount | thousandSep }}
                    td.w-7.text-right.align-middle
                      strong {{ ((getWinnerDataFromHistory(smartTest.history[0]).conversionCount / getWinnerDataFromHistory(smartTest.history[0]).visitorsCount) * 100) | dec2 }}
                    td.w-7.text-right.align-middle
                      strong.smart-kpi {{ (getWinnerDataFromHistory(smartTest.history[0]).uplift * 100) | dec2 }}
                    td.w-7.text-right.align-middle
                      strong.smart-kpi {{ (getWinnerDataFromHistory(smartTest.history[0]).chanceToWin * 100) | dec2 }}
        tr.smart-tests-history(:class="{ active: smartTest.id == activeItem }")
          td.p-0(colspan="6" :class="{ 'pt-3': smartTest.id == activeItem }")
            .smart-tests-history-wrapper(:class="{ 'mb-4': smartTest.id == activeItem }")
              table.table
                tbody
                  template(v-for="(history, index) in smartTest.history")
                    tr
                      td.border-0.pt-0.pl-4.pr-0(colspan="6")
                        table.table.smart-tests-history-item.pb-2.pl-3(
                          :class="{ 'border-top': index }"
                        )
                          tbody
                            tr
                              td.border-top-0.pb-0.pl-0
                                span.form-text {{ $t('smartAbTest.page.accordion.historyRuntime', { allTests: smartTest.testsCount - 1, actualTest: smartTest.history.length - index, intervalDate: sinceBetweenDate(history.startedAt, history.finishedAt), days: getRunningDays(history.evaluationCount) }) }}
                              td.border-top-0.pb-0.pr-0(colspan="5")
                            tr(v-if="history.finishReason === 'TIMEOUT'")
                              td.border-top-0.p-0(colspan="6")
                                .smart-test-timeout.mt-1 {{ $t('smartAbTest.page.accordion.notFindClearWinner') }}
                            tr
                              td.border-top-0.pl-0(rowspan="2")
                                .smart-ab-test-item
                                  ComparerSigns(:winner="getWinner(history)")
                                  .comparers
                                    .comparer-control
                                      Comparer(
                                        :comparerName="history.control.name"
                                        :comparerText="history.control.text"
                                      )
                                    .comparer-challenger
                                      Comparer(
                                        :comparerName="history.challenger.name"
                                        :comparerText="history.challenger.text"
                                      )
                              td.border-top-0.w-7.text-right.align-middle {{ history.control.visitorsCount | thousandSep }}
                              td.border-top-0.w-7.text-right.align-middle {{ history.control.conversionCount | thousandSep }}
                              td.border-top-0.w-7.text-right.align-middle {{ ((history.control.conversionCount / history.control.visitorsCount) * 100) | dec2 }}
                              td.border-top-0.w-7
                              td.border-top-0.w-7
                            tr(:class="`winner-${history.challenger.winner}`")
                              td.border-top-0.w-7.text-right.align-middle {{ history.challenger.visitorsCount | thousandSep }}
                              td.border-top-0.w-7.text-right.align-middle {{ history.challenger.conversionCount | thousandSep }}
                              td.border-top-0.w-7.text-right.align-middle
                                strong {{ ((history.challenger.conversionCount / history.challenger.visitorsCount) * 100) | dec2 }}
                              td.border-top-0.w-7.text-right.align-middle
                                strong.smart-kpi(
                                  :class="{ 'positive-result': history.challenger.uplift * 100 > 0 }"
                                ) {{ (history.challenger.uplift * 100) | dec2 }}
                              td.border-top-0.w-7.text-right.align-middle
                                strong.smart-kpi(
                                  :class="{ 'positive-result': history.challenger.chanceToWin * 100 > 0 }"
                                ) {{ (history.challenger.chanceToWin * 100) | dec2 }}
</template>
<script>
  import Comparer from '@/components/SmartABTest/Comparer.vue';
  import ComparerSigns from '@/components/SmartABTest/ComparerSigns.vue';
  import { UilTrophy, UilChartDown } from '@iconscout/vue-unicons';
  import dateFormat from '@/mixins/dateFormat';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import goalsMixin from '@/mixins/goals';
  import { createDCUrl } from '@/utils/pncURLBuilder';
  import { mapGetters } from 'vuex';
  import moment from 'moment';

  export default {
    components: { Comparer, ComparerSigns, UilTrophy, UilChartDown, SkeletonLoader },
    mixins: [dateFormat, goalsMixin],
    props: {
      smartABTest: { type: Object },
      variants: { type: Array },
      domain: { validator: (prop) => typeof prop === 'string' || prop === null, required: true },
      loadingSABTestReport: { type: Boolean, default: true },
      campaign: { type: Object },
      goals: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        activeItem: null,
      };
    },
    computed: {
      ...mapGetters(['isSuperAdmin']),
      primaryGoalOptions() {
        return this.allGoals.filter((goal) => goal.name !== this.defaultGoal.name);
      },
      activePrimaryGoal: {
        get() {
          if (this.selectedGoal) {
            return this.selectedGoal;
          }

          const primaryGoal = this.goals.find(({ isPrimary }) => isPrimary);

          if (primaryGoal) {
            return primaryGoal;
          }

          return this.goals?.[0];
        },
        set(goal) {
          this.selectedGoal = goal;
        },
      },
      uplift() {
        return this.smartABTest?.uplift;
      },
      smartTests() {
        return this.smartABTest?.changes || [];
      },
      variantUplift() {
        return `${(Number.parseFloat(this.uplift) * 100).toFixed(2)}%`;
      },
      lastUpdated() {
        return moment(this.smartABTest.updatedAt).format(this.dateFormat);
      },
      skeletonRowNumber() {
        return this.smartTests > 30 ? 30 : this.smartTests ? this.smartTests.length : 3;
      },
      metrics() {
        let conversions = 0;
        let impressions = 0;
        const allData = [...this.smartTests, ...this.smartTests.flatMap((test) => test.history)];

        allData.forEach((test) => {
          if (test.current) {
            const currentConversionCount = test.current.control.conversionCount;
            const challengerConversionCount = test.current.challenger.conversionCount;
            const currentImpressionCount = test.current.control.visitorsCount;
            const challengerImpressionCount = test.current.challenger.visitorsCount;
            conversions += currentConversionCount + challengerConversionCount;
            impressions += currentImpressionCount + challengerImpressionCount;
          }
          if (test.history?.length) {
            test.history.forEach((historyTest) => {
              const currentConversionCount = historyTest.control.conversionCount;
              const challengerConversionCount = historyTest.challenger.conversionCount;
              const currentImpressionCount = historyTest.control.visitorsCount;
              const challengerImpressionCount = historyTest.challenger.visitorsCount;
              conversions += currentConversionCount + challengerConversionCount;
              impressions += currentImpressionCount + challengerImpressionCount;
            });
          }
        });

        return { impressions, conversions };
      },
      checkUpliftValue() {
        return this.smartABTest?.uplift > 0;
      },
      hasEnoughConversions() {
        return this.metrics.conversions >= 40;
      },
      hasImpression() {
        return this.metrics.impressions > 0;
      },
      hasConversion() {
        return this.metrics.conversions > 0;
      },
    },
    watch: {
      async activePrimaryGoal(value) {
        if (value) {
          await this.upsertPrimaryGoal(value._id);
        }
      },
    },
    methods: {
      textIsTruncated(text) {
        return text.length > 55;
      },
      isWillBeWinner(data) {
        return data > 0.5;
      },
      getWinner(testCase) {
        if (testCase.control.winner) {
          return 'a';
        }
        if (testCase.challenger.winner) {
          return 'b';
        }
      },
      getWinnerDataFromHistory(testCase) {
        let result = null;

        if (testCase.control?.winner) {
          result = testCase.control;
        }

        if (testCase.challenger?.winner) {
          result = testCase.challenger;
        }
        if (result === null) {
          result = testCase.control;
        }
        return result;
      },
      countOfHistory(test) {
        return test.history.length;
      },
      toggleAccordion(id) {
        this.activeItem = id === this.activeItem ? null : id;
      },
      goToEditor() {
        const { id } = this.$route.params;
        const userId = this.$route.params.userId;
        const returnUrl = `/${userId}/campaign/${id}`;
        const backUrl = `/${userId}/campaign/${id}`;
        let lastEditUrl = null;
        const variantWithLastEditUrl = this.variants.find((variant) => !!variant.lastEditUrl);
        if (variantWithLastEditUrl) {
          lastEditUrl = variantWithLastEditUrl.lastEditUrl;
        }

        window.location.href = createDCUrl({
          domain: this.domain,
          campaignId: id,
          variantId: variantWithLastEditUrl._id,
          locale: this.$i18n.locale,
          returnUrl,
          backUrl,
          lastEditUrl,
          isSuperAdmin: this.isSuperAdmin,
          featureMode: 'smart-ab-test',
        });
      },
      getRunningDays(evaluationCount) {
        if (!evaluationCount) return 0;
        return Math.ceil(evaluationCount / 24);
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  .d-contents
    display: contents
  .smart-kpi
    font-size: 1.25rem
  .winner-false .smart-kpi
    color: $om-alert-red-500
    &.positive-result
      color: #212529
  .winner-true .smart-kpi
    color: $om-alert-green-600
  .winner-current-false .smart-kpi
    color: $om-gray-800
  .winner-current-true .smart-kpi
    color: $om-alert-green-600
  .winner-sign
    height: 3rem
    width: 3rem
    background: $om-alert-green-600
    border-radius: 8px
    display: flex
    align-items: center
    justify-content: center
  .comparers
    display: flex
    flex-direction: column

  .smart-ab-test-reporting-summary
    display: flex
    align-items: center
    background: $om-gray-100
    border-radius: 8px
    padding: 0.5rem 1.25rem
    margin-top: 1.5rem
    justify-content: space-between
    svg
      transform: scaleY(-1)

    .description
      padding-right: 20px
      flex: 1
    .last-updated
      display: flex
      align-items: center
      min-width: 13.5rem
      justify-content: flex-end
      & > div
        color: $om-gray-600

    .winner-uplift
      font-size: 3.75rem
      color: $om-alert-green-600
      font-weight: 700
      text-wrap: nowrap
      &-label
        font-size: 1.25rem
        color: $om-alert-green-600
        font-weight: 700

  .smart-test-timeout
    color: $om-gray-800
    font-size: .75rem
    background: $om-peach-200
    border-radius: 4px
    padding: 4px 8px
  .smart-ab-test-item
    display: flex
  .smart-ab-test-element
    font-size: 1.125rem
    font-weight: 500
  .smart-ab-test-accordion-toggle
    &.brand-link-element
      font-weight: 400
  .smart-ab-test-table
    font-size: 0.85rem
    &.table th,
    &.table td
      padding: 0.5rem
      border-top: 1px solid white
    thead td
      font-size: 0.875rem
      font-weight: 500
  .smart-tests-history
    &-wrapper
      background: $om-gray-100
      max-height: 0
      overflow: hidden
    &.active &-wrapper
      max-height: 2000px
      transition: max-height 0.5s linear
</style>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .primary-goal-select
    .select-content
      min-width: 10rem

  .winner-comparer
    .smart-ab-test-item
      .comparer-text
        color: $om-alert-green-600
  .smart-kpi
    font-size: 1rem!important
</style>
