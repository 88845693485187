<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00499 8.00897L16.595 8.00897L15.295 6.71897C15.1067 6.53067 15.0009 6.27528 15.0009 6.00897C15.0009 5.74267 15.1067 5.48728 15.295 5.29897C15.4833 5.11067 15.7387 5.00488 16.005 5.00488C16.2713 5.00488 16.5267 5.11067 16.715 5.29897L19.715 8.29897C19.806 8.39408 19.8774 8.50622 19.925 8.62897C20.025 8.87244 20.025 9.14551 19.925 9.38897C19.8774 9.51173 19.806 9.62387 19.715 9.71897L16.715 12.719C16.622 12.8127 16.5114 12.8871 16.3896 12.9379C16.2677 12.9886 16.137 13.0148 16.005 13.0148C15.873 13.0148 15.7423 12.9886 15.6204 12.9379C15.4985 12.8871 15.3879 12.8127 15.295 12.719C15.2013 12.626 15.1269 12.5154 15.0761 12.3936C15.0253 12.2717 14.9992 12.141 14.9992 12.009C14.9992 11.877 15.0253 11.7463 15.0761 11.6244C15.1269 11.5025 15.2013 11.3919 15.295 11.299L16.595 10.009L7.00499 10.009C6.73977 10.009 6.48542 10.1143 6.29788 10.3019C6.11034 10.4894 6.00499 10.7438 6.00499 11.009L6.00499 15.009C6.00499 15.2742 5.89963 15.5285 5.71209 15.7161C5.52456 15.9036 5.2702 16.009 5.00499 16.009C4.73977 16.009 4.48541 15.9036 4.29788 15.7161C4.11034 15.5285 4.00499 15.2742 4.00499 15.009L4.00499 11.009C4.00499 10.2133 4.32106 9.45026 4.88367 8.88765C5.44627 8.32504 6.20934 8.00897 7.00499 8.00897Z"
      :fill="disabled ? '#B9BEC6' : '#505763'"
    />
    <path
      d="M6 15.9999L6 11.0049L4 11.0049L4 15.9999C4 16.7955 4.31607 17.5586 4.87868 18.1212C5.44129 18.6838 6.20435 18.9999 7 18.9999L17 18.9999C17.2652 18.9999 17.5196 18.8945 17.7071 18.707C17.8946 18.5195 18 18.2651 18 17.9999C18 17.7347 17.8946 17.4803 17.7071 17.2928C17.5196 17.1053 17.2652 16.9999 17 16.9999L7 16.9999C6.73478 16.9999 6.48043 16.8945 6.29289 16.707C6.10536 16.5195 6 16.2651 6 15.9999Z"
      :fill="disabled ? '#B9BEC6' : '#505763'"
    />
  </svg>
</template>

<script>
  export default {
    props: ['disabled'],
  };
</script>
