<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', xmlns:xlink='http://www.w3.org/1999/xlink', width='30', height='31', viewBox='0 0 30 31')
  defs
    path#a(d='M0 0h29.977v30.537H0z')
  g(fill='none', fill-rule='evenodd')
    mask#b(:fill='color')
      use(xlink:href='#a')
    path(:fill='color', d='M15.36 15.36h3.84v-3.84h-3.84v3.84zM1.92 22.849V9.599h21.12v1.921h1.92V5.951c0-1.166-.93-2.111-2.08-2.111h-.8v.96c0 1.058-.86 1.92-1.92 1.92-1.059 0-1.92-.862-1.92-1.92v-.96H6.72v.96c0 1.058-.86 1.92-1.92 1.92S2.88 5.858 2.88 4.8v-.96h-.8C.931 3.84 0 4.785 0 5.95V22.85c0 1.162.931 2.111 2.08 2.111h11.36v-1.92H2.08c-.087 0-.16-.09-.16-.191zM9.6 21.12h3.84v-3.84H9.6v3.84zm0-5.76h3.84v-3.84H9.6v3.84zm-5.76 5.76h3.84v-3.84H3.84v3.84zm0-5.76h3.84v-3.84H3.84v3.84zm16.32-9.6c.53 0 .96-.43.96-.96V.96a.96.96 0 1 0-1.92 0V4.8c0 .53.43.96.96.96zM3.84 4.8V.96a.96.96 0 1 1 1.92 0V4.8a.96.96 0 1 1-1.92 0z', mask='url(#b)')
    path(:fill='color', d='M22.486 29.037a5.998 5.998 0 0 1-5.992-5.991 5.998 5.998 0 0 1 5.992-5.992 5.998 5.998 0 0 1 5.99 5.992 5.998 5.998 0 0 1-5.99 5.99m1.155-13.393v-1.38h.63a.75.75 0 0 0 0-1.5H20.7a.75.75 0 0 0 0 1.5h.63v1.38c-3.585.558-6.337 3.665-6.337 7.403 0 4.13 3.36 7.49 7.492 7.49 4.13 0 7.49-3.36 7.49-7.49 0-3.738-2.751-6.845-6.335-7.403', mask='url(#b)')
    path(:fill='color', d='M22.948 22.734v-3.533a.75.75 0 0 0-1.5 0v3.966c0 .122.048.239.135.325l2.098 2.089a.75.75 0 1 0 1.058-1.063l-1.791-1.784z', mask='url(#b)')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
