<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="72" height="12" viewBox="0 0 72 12" fill="none">
    <circle cx="6" cy="6" r="6" :fill="color(1)" />
    <circle cx="26" cy="6" r="6" :fill="color(2)" />
    <circle cx="46" cy="6" r="6" :fill="color(3)" />
    <circle cx="66" cy="6" r="6" :fill="color(4)" />
  </svg>
</template>

<script>
  export default {
    props: ['activeIndex'],
    methods: {
      color(index) {
        return this.activeIndex === index ? '#ED5A29' : '#D5D8DD';
      },
    },
  };
</script>
