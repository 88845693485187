<template lang="pug">
.brand-rule-box(
  :class="small ? 'brand-rule-box-small' : ''"
  @mouseenter="activeSvgAnimation = event.type"
  @mouseleave="activeSvgAnimation = ''"
)
  .row.w-100
    .col-9.d-flex.align-items-center
      trigger-svg.svg-preview.flex-shrink-0(
        :type="event.type"
        :activeAnimation="activeSvgAnimation"
      )
      .brand-rule-box-content
        .d-flex.align-items-center
          .brand-rule-box-title {{ $t(`events.${event.type}.title`) }}
          .brand-badge.brand-badge-secondary.ml-3(v-if="isRecommendedEvent(event.type) && !small") {{ $t('recommended') }}

        event-lead.brand-rule-box-lead(v-if="event.options" :rule="event")
        .brand-rule-box-lead(v-else) {{ $t(`events.${event.type}.lead`, { brand: brandName }) }}
    .col-3.d-flex.align-items-center(:class="!small ? 'justify-content-end' : ''")
      om-device(
        v-if="event.device !== undefined"
        :type="event.device"
        :activeColor="small ? 'black' : undefined"
        :small="small"
      )
      slot(name="action")
</template>

<script>
  import TriggerSvg from '@/components/Svg/Trigger/TriggerSvg.vue';
  import EventLead from '@/components/EventLead.vue';
  import { isRecommendedEvent } from '@/config/recommendedSettings';
  import OmDevice from '@/components/OmDevice.vue';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      TriggerSvg,
      EventLead,
      OmDevice,
    },
    props: {
      event: {
        type: Object,
      },
      small: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        activeSvgAnimation: '',
      };
    },

    computed: {
      ...mapGetters(['brandName', 'isPageViewBasedPackage', 'isFreemiumMaster', 'isFreemium']),
    },

    methods: {
      isRecommendedEvent,
    },
  };
</script>

<style lang="sass">
  .brand-rule-box
    display: flex
    align-items: center
    width: 100%

    &-title
      font-weight: bold
      font-size: 1.25rem

    &-lead
      font-size: 0.875rem
      color: #969BA0
      padding-right: 1rem

    &-content
      overflow: hidden
      flex-grow: 1
      line-height: 1.4

    &-small
      margin-left: 1.25rem
      padding-bottom: .75rem
      border-bottom: 1px solid #EBECEE

      &:last-child
        border-bottom: none
        padding-bottom: 0

      .svg-preview,
      .frontend-rules-color-box
        height: 2.5rem
        margin-right: 1rem

      .frontend-rules-color-box
        width: 2.5rem

        img
          height: 1.25rem

      .brand-rule-box-title
        font-size: 0.9375rem !important
        line-height: 1
      .brand-rule-box-lead
        font-size: 0.8125rem
</style>
