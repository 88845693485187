const integrationColors = {
  activeCampaign: '#356AE6',
  actOn: 'white',
  acerCCDB: 'white',
  bizzy: 'white',
  campaignMonitor: '#7856ff',
  cleverReach: 'white',
  shopifyCustomer: 'transparent',
  getResponse: '#00AFEC',
  convertKit: '#212F4F',
  hubSpot: 'white',
  klaviyo: 'white',
  mailChimp: '#FFE01B',
  maileon: 'white',
  mailerLite: 'white',
  mailigen: '#36344B',
  moosend: 'white',
  soundest: 'white',
  dotmailer: 'white',
  emarsys: 'white',
  infusionSoft: 'white',
  interspire: 'white',
  mailjet: '#FEAD0D',
  mailUp: 'black',
  mailWizz: 'white',
  marketo: 'white',
  ontraport: '#14141f',
  robly: 'white',
  silverpop: 'white',
  salesAutopilot: '#165d9d',
  aWeber: '#0c7ac0',
  automizy: 'white',
  hirlevelmanager: 'white',
  IContact: 'white',
  ladyBird: 'white',
  listamester: 'white',
  mailEngine: '#272d33',
  miniCrm: 'white',
  salesforce: 'white',
  shopRenter: '#fff',
  silihost: 'white',
  slack: 'white',
  verticalResponse: '#4e1042',
  webGalamb3: 'white',
  webGalamb4Plus: 'white',
  webhook: 'black',
  copernica: 'white',
  conversio: 'white',
  smsBump: 'white',
  attentive: 'white',
  attentiveV2: 'white',
  postscriptLegacy: 'white',
  keap: 'white',
  sendGrid: 'white',
};

module.exports = integrationColors;
