<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='31', height='26', viewBox='0 0 31 26')
  g(:fill='color', fill-rule='evenodd')
    path(d='M22.867 17.438a2.694 2.694 0 0 1-2.69-2.69 2.694 2.694 0 0 1 2.69-2.69 2.694 2.694 0 0 1 2.691 2.69 2.694 2.694 0 0 1-2.69 2.69m5.538 2.294a7.781 7.781 0 0 0-2.279-1.588 4.71 4.71 0 0 0 1.45-3.396 4.715 4.715 0 0 0-4.71-4.71 4.715 4.715 0 0 0-4.71 4.71 4.71 4.71 0 0 0 1.45 3.396 7.781 7.781 0 0 0-2.278 1.588 7.781 7.781 0 0 0-2.294 5.538v.432h2.02v-.432a5.82 5.82 0 0 1 5.812-5.812 5.82 5.82 0 0 1 5.813 5.812v.432h2.02v-.432a7.781 7.781 0 0 0-2.294-5.538')
    path(d='M23.235 4.737L18.486.034l-1.565 1.55 2.861 2.832H10.75v-.001l-.096.001h-.624l.008.023C4.433 4.802 0 9.417 0 15.059c0 5.878 4.813 10.643 10.75 10.643V23.51c-2.28 0-4.423-.879-6.035-2.475a8.354 8.354 0 0 1-2.5-5.975c0-2.258.887-4.38 2.5-5.976a8.523 8.523 0 0 1 6.035-2.475h9.032l-2.86 2.832 1.564 1.548 4.75-4.702a1.088 1.088 0 0 0 0-1.55')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
