<template>
  <svg width="22" height="18" viewBox="0 0 22 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0195 0.0243628C13.5583 0.145596 13.8968 0.68067 13.7756 1.21948L10.1756 17.2195C10.0544 17.7583 9.5193 18.0968 8.98049 17.9756C8.44167 17.8543 8.10316 17.3193 8.22439 16.7805L11.8244 0.78046C11.9456 0.241646 12.4807 -0.0968705 13.0195 0.0243628ZM5.70711 4.29287C6.09763 4.68339 6.09763 5.31656 5.70711 5.70708L2.41421 8.99997L5.70711 12.2929C6.09763 12.6834 6.09763 13.3166 5.70711 13.7071C5.31658 14.0976 4.68342 14.0976 4.29289 13.7071L0.292893 9.70708C-0.0976311 9.31656 -0.0976311 8.68339 0.292893 8.29287L4.29289 4.29287C4.68342 3.90234 5.31658 3.90234 5.70711 4.29287ZM16.2929 4.29287C16.6834 3.90234 17.3166 3.90234 17.7071 4.29287L21.7071 8.29287C22.0976 8.68339 22.0976 9.31656 21.7071 9.70708L17.7071 13.7071C17.3166 14.0976 16.6834 14.0976 16.2929 13.7071C15.9024 13.3166 15.9024 12.6834 16.2929 12.2929L19.5858 8.99997L16.2929 5.70708C15.9024 5.31656 15.9024 4.68339 16.2929 4.29287Z"
      :stroke="color"
      :fill="color"
    />
  </svg>
</template>
<script>
  export default {
    props: ['color'],
  };
</script>
