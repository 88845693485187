<template lang="pug">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9 1.38462C4.76697 1.38462 1.38462 4.76697 1.38462 9C1.38462 13.233 4.76697 16.6154 9 16.6154C13.233 16.6154 16.6154 13.233 16.6154 9C16.6154 4.76697 13.233 1.38462 9 1.38462ZM0 9C0 4.00226 4.00226 0 9 0C13.9977 0 18 4.00226 18 9C18 13.9977 13.9977 18 9 18C4.00226 18 0 13.9977 0 9ZM9 4.61538C9.38235 4.61538 9.69231 4.92534 9.69231 5.30769V8.30769H12.6923C13.0747 8.30769 13.3846 8.61765 13.3846 9C13.3846 9.38235 13.0747 9.69231 12.6923 9.69231H9.69231V12.6923C9.69231 13.0747 9.38235 13.3846 9 13.3846C8.61765 13.3846 8.30769 13.0747 8.30769 12.6923V9.69231H5.30769C4.92534 9.69231 4.61538 9.38235 4.61538 9C4.61538 8.61765 4.92534 8.30769 5.30769 8.30769H8.30769V5.30769C8.30769 4.92534 8.61765 4.61538 9 4.61538Z" :fill="color"/>
</svg>
</template>

<script>
  export default {
    // eslint-disable-next-line
    props: { color: String, default: '#828282' },
  };
</script>
