<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg', width='30', height='30', viewBox='0 0 30 30')
  g(:fill='color', fill-rule='evenodd')
    path(d='M4.888 3.516c-.567 0-1.026.472-1.026 1.054 0 .583.46 1.055 1.026 1.055.567 0 1.027-.472 1.027-1.055 0-.582-.46-1.054-1.027-1.054M7.688 3.516c-.567 0-1.027.472-1.027 1.054 0 .583.46 1.055 1.027 1.055s1.026-.472 1.026-1.055c0-.582-.46-1.054-1.026-1.054M10.442 3.516c-.567 0-1.027.472-1.027 1.054 0 .583.46 1.055 1.027 1.055.566 0 1.026-.472 1.026-1.055 0-.582-.46-1.054-1.026-1.054M2.05 8.086h25.9V6.328H2.05zM2.05 10.952h25.9V9.194H2.05zM14.517 16.38v-2.05H4.675v9.116h6.793v-2.05H6.67V16.38h2.745')
    path(d='M12.999 27.95H3.88c-1.009 0-1.83-.821-1.83-1.83V3.88c0-1.009.821-1.83 1.83-1.83h22.24c1.009 0 1.83.821 1.83 1.83v12.5H30V3.88A3.88 3.88 0 0 0 26.12 0H3.88A3.88 3.88 0 0 0 0 3.88v22.24A3.88 3.88 0 0 0 3.88 30h9.119v-2.05z')
    path(d='M21.024 28.535a5.857 5.857 0 0 1-5.85-5.85 5.857 5.857 0 0 1 5.85-5.851 5.857 5.857 0 0 1 5.851 5.85 5.857 5.857 0 0 1-5.85 5.851m1.127-13.08v-1.347h.615a.732.732 0 0 0 0-1.465h-3.486a.732.732 0 0 0 0 1.465h.615v1.348c-3.5.544-6.187 3.578-6.187 7.228 0 4.034 3.281 7.316 7.315 7.316 4.034 0 7.316-3.282 7.316-7.316 0-3.65-2.688-6.684-6.188-7.228')
    path(d='M21.757 22.38v-3.45a.732.732 0 0 0-1.465 0v3.873c0 .119.047.233.132.317l2.049 2.04a.732.732 0 1 0 1.033-1.038l-1.75-1.742z')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
