const integrationConfigs = require('./integrations.json');
const integrationsFixedFields = require('./integrationsFixedFields');
const integrationColors = require('./integrationColors');
const recommendedIntegrationStates = require('./recommendedIntegrationStates');
const ERROR_CODES = require('./errorCodes');

const phoneInput = 'phoneNumber';

const getIntegrationColors = (integrationName) => {
  return integrationColors[integrationName] || 'white';
};

const getIntegrationConfigs = () => {
  return integrationConfigs;
};

const getConfigForIntegration = (integrationName) => {
  return integrationConfigs[integrationName] || {};
};

const getPhoneNumberIntegrations = () => {
  return Object.keys(integrationConfigs).filter(
    (key) =>
      integrationConfigs[key].requiredVariantFieldTypes &&
      integrationConfigs[key].requiredVariantFieldTypes.includes('phoneNumber'),
  );
};

const getPhoneNumberOnlyIntegrations = () => {
  return Object.keys(integrationConfigs).filter(
    (key) =>
      integrationConfigs[key].requiredVariantFieldTypes &&
      integrationConfigs[key].requiredVariantFieldTypes.every((name) => name === 'phoneNumber'),
  );
};

const getIntegrationAuthFields = (integrationName) => {
  return getConfigForIntegration(integrationName).authFields || [];
};

const getIntegrationsFixedFields = (integrationName) => {
  return integrationsFixedFields[integrationName];
};

const getIntegrationRequiredFields = (integrationName) => {
  return getConfigForIntegration(integrationName).requiredFieldValidation;
};

const getVariantRequiredFields = (integrationName) => {
  return getConfigForIntegration(integrationName).requiredVariantFieldTypes;
};

const createBinding = (integrationName, variantsInputs) => {
  const integrationFixFields = getIntegrationsFixedFields(integrationName);
  const omFixFields = ['email', 'lastname', 'firstname'];

  const fieldIdGuesser = {
    phone: (inputs) => {
      return inputs.find((input) => input.type === phoneInput)?.customId;
    },
  };

  const mapping = [];

  Object.keys(integrationFixFields).forEach((internalId) => {
    const isOmFixField = omFixFields.includes(internalId);
    const hasCustomMapper = fieldIdGuesser[internalId];

    if (isOmFixField && variantsInputs.find((input) => input.customId === internalId)) {
      mapping.push({
        externalId: integrationFixFields[internalId],
        fieldId: internalId,
      });
    } else if (hasCustomMapper && hasCustomMapper(variantsInputs)) {
      mapping.push({
        externalId: integrationFixFields[internalId],
        fieldId: hasCustomMapper(variantsInputs),
      });
    }
  });

  return mapping;
};

const hasRequiredField = (variantFields, mandatoryFields) => {
  if (!mandatoryFields || !variantFields) return false;
  return variantFields.some(({ type }) => mandatoryFields.includes(type));
};

module.exports = {
  RECOMMENDATION_STATES: recommendedIntegrationStates,
  ERROR_CODES,
  getIntegrationColors,
  getIntegrationConfigs,
  getConfigForIntegration,
  getPhoneNumberIntegrations,
  getPhoneNumberOnlyIntegrations,
  getIntegrationAuthFields,
  getIntegrationsFixedFields,
  getIntegrationRequiredFields,
  getVariantRequiredFields,
  createBinding,
  hasRequiredField,
};
