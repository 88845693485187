<template lang="pug">
div
  div(v-for="index in rows" :key="index")
    .skeleton-row
      .third-column
        vue-skeleton-loader(type="rect" width="80%" height="24px" :rounded="rounded")
      .two-third-column
        .quarter-column.content-right
          vue-skeleton-loader(type="rect" width="50%" height="24px" :rounded="rounded")
        .quarter-column.content-right
          vue-skeleton-loader(type="rect" width="50%" height="24px" :rounded="rounded")
        .quarter-column.content-right
          vue-skeleton-loader(type="rect" width="50%" height="24px" :rounded="rounded")
        .quarter-column.content-right
          vue-skeleton-loader(type="rect" width="50%" height="24px" :rounded="rounded")

    hr(v-if="bottomLine")
</template>

<script>
  export default {
    props: {
      rows: { type: Number },
      rounded: { type: Boolean },
      bottomLine: { type: Boolean },
    },
  };
</script>

<style lang="sass" scoped>
  hr
    margin: 0px
  .skeleton-row
    display: flex
    align-items: center
    justify-content: space-between
    padding-top: 15px
    padding-bottom: 15px
  .third-column
    width: calc(100% / 3)
    display: flex
    justify-content: flex-start
  .two-third-column
    display: flex
    width: calc(2* 100% / 3)
  .quarter-column
    width: 25%
    &-flex
      width: 25%
      display: flex
      justify-content: space-between
  .content-right
    display: flex
    justify-content: flex-end
</style>
