export default {
  methods: {
    getSourceByType(type) {
      switch (type) {
        case 'direct':
          return this.$t('direct');
        case 'google-adwords':
          return 'Google Ads';
        case 'google-organic':
          return 'Google Organic';
        case 'facebook':
          return 'Facebook';
      }
    },
  },
};
