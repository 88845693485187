<template lang="pug">
.variant-preview-holder._lr-hide(data-private @click="$emit('click')")
  template(v-if="isControlVariant")
    om-progressive-image.template-preview-small.flex-shrink-0.cursor-default(
      :src="require('@/assets/controlVariantPreview.svg')"
      background-color="#F0F1F2"
      placeholder="/brand-skeleton.png"
      @click.native="$emit('click', $event)"
    )
  template(v-else-if="isDynamicContent")
    om-progressive-image.template-preview.template-preview-small.flex-shrink-0.cursor-pointer(
      :src="require('@/assets/dynamicContentPreview.svg')"
      placeholder="/brand-skeleton.png"
      @click.native="$emit('click', $event)"
    )
  template(v-else)
    .template-preview.template-preview-small.flex-shrink-0.cursor-pointer(
      ref="parent"
      v-if="ssr"
      :class="{ ssr, loading, embedded: isEmbedded }"
    )
      .template-thumbnail-overlay.brand-themes-template-box-overlay
      .template-thumbnail-iframe(
        :class="{ 'h-100': isEmbedded }"
        :style="frameStyle"
        ref="lazyFrame"
        :id="`template-preview-iframe-${shownVariantId}`"
      )
    variant-image(
      v-else-if="!ssr && (campaign || variant)"
      :variant="campaign ? getPreviewVariant(campaign) : variant"
      :classes="getTemplateClass(shownVariant)"
      :templateName="templateName ? templateName : campaign && campaign.templateName"
      :isNew="true"
    )
</template>

<script>
  import VariantImage from '@/components/VariantImage.vue';
  import { getPreviewVariant, getAccountIdFromCookie } from '@/util';
  import runtimeConfig from '@/config/runtime';
  import previewFrameMixin from '@/mixins/previewFrame';
  import ssrParamsMixin from '@/mixins/ssrParams';

  export default {
    components: { VariantImage },
    mixins: [previewFrameMixin, ssrParamsMixin],
    props: {
      campaign: {
        type: Object,
        default: () => null,
      },
      variant: {
        type: Object,
        default: () => null,
      },
      multiplier: {
        type: Number,
        default: () => 12,
      },
      templateName: {
        type: String,
        default: '',
      },
      templateType: {
        type: String,
        default: '',
      },
      isControlVariant: {
        type: Boolean,
        default: false,
      },
      updatedAt: {
        type: String,
        default: '2000-12-12T00:00:00.000Z',
      },
    },
    data: () => ({
      transformOrigin: '0 0',
    }),
    computed: {
      isDynamicContent() {
        return this.templateType === 'dynamic_content';
      },
      isEmbedded() {
        return this.templateType === 'embedded';
      },
      shownVariant() {
        return this.campaign ? this.findActiveVariant(this.campaign.variants) : this.variant;
      },
      shownVariantId() {
        return this.shownVariant._id;
      },
      ssrUrl() {
        const { VUE_APP_SSR_CDN_URL: ssrUrl } = runtimeConfig;
        if (this.isControlVariant) return null;

        if (ssrUrl) {
          return `${ssrUrl}/preview/${getAccountIdFromCookie()}/${this.shownVariantId}`;
        }
        return null;
      },
      templateUpdatedAt() {
        if (this.campaign) return new Date(this.campaign.updatedAt);
        return new Date(this.updatedAt);
      },
    },

    created() {
      if (this.isDynamicContent) {
        this.ssr = false;
      }
    },
    methods: {
      getPreviewVariant,
      getId() {
        return this.shownVariantId;
      },
      findActiveVariant(variants) {
        const firstActive = variants.find(
          ({ status, isControlVariant }) => status === 'active' && isControlVariant !== true,
        );

        /*
          in case of no active variants the variants[0] may be the control and it can cause ssr error,
          so we have to find the first not active & not control variant
        */
        const firstNotControlAndNotActive = variants.find(
          ({ isControlVariant }) => isControlVariant !== true,
        );
        return firstActive || firstNotControlAndNotActive;
      },
      getTemplateClass(variant) {
        if (!this.templateType && !this.campaign) return;
        const templateType = this.templateType || this.campaign.templateType;
        let result = { [templateType]: true };
        if (templateType === 'popup' && variant.isPrettyBackground === true) {
          result = { interstitial: true };
        }
        return result;
      },
    },
  };
</script>

<style lang="sass">
  .template-preview-small.ssr
    background: url('~@/assets/admin/svg/template-background.svg') no-repeat center center / contain #E3E8ED
    overflow: hidden !important
    padding: 0
    margin: 0
    border: 0
    &.embedded
      border: 1px solid #f7f7f8
  .variant-preview-holder
    position: relative
    display: flex
    align-items: center
    justify-content: flex-start
    .layout-4-col
      .om-holder-fixed
        position: unset
    .om-holder-fixed
      &.embedded
        position: relative
    .om-overlay-center
      font-size: 16px !important
    .om-embedded
      height: auto !important
      width: 100%
      .om-outer-canvas
        display: flex
        align-items: center
        .om-canvas
          height: max-content !important
          width: 100%
    .loading:after
      left: 0
      margin-left: 0
      right: 0
</style>
<style lang="sass" src="@/editor/sass/mock_site.sass" />
