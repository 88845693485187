<template lang="pug">
.d-flex.align-items-center
  .col-6
    om-select#loggedInRule(
      :options="loggedInOptions"
      size="small"
      labelPosition="fill"
      :label="$t('thoseVisitorsWho')"
      :value="loggedIn"
      @input="setLoggedInType($event)"
    )
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';

  export default {
    name: 'LoggedIn',
    mixins: [settingsValidation],
    data() {
      return {
        loggedInOptions: [
          { key: 'yes', value: this.$t('loggedIn.yes') },
          { key: 'no', value: this.$t('loggedIn.no') },
        ],
      };
    },
    computed: {
      loggedIn() {
        return {
          key: this.currentRule.options.value,
          value: this.$t(`loggedIn.${this.currentRule.options.value}`),
        };
      },
    },
    methods: {
      setLoggedInType(event) {
        this.currentRule.options.value = event.key;
      },
    },
  };
</script>
