const ERROR_CODES = {
  INTEGRATION: {
    API_KEY: 'IntegrationErrorAPIKey',
    LIST: 'IntegrationErrorList',
    FIELD: 'IntegrationErrorField',
    ACCOUNT_ISSUE: 'IntegrationErrorAccountIssue',
  },
  SUBSCRIBER: {
    EXISTING: 'SubscriberErrorExisting',
    INVALID_EMAIL: 'SubscriberErrorInvalidEmail',
    INVALID_PHONE: 'SubscriberErrorInvalidPhone',
    UNSUBSCRIBED: 'SubscriberErrorUnsubscribed',
    SERVER_ERROR: 'SubscriberServerError',
  },
};

module.exports = ERROR_CODES;
