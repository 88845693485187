<template lang="pug">
.comparer-signs
  .comparer-a(:class="{ winner: winner === 'a' }") A
  .comparer-b(:class="{ winner: winner === 'b' }") B
</template>
<script>
  export default {
    props: {
      winner: {
        type: String,
        default: '',
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .comparer
    &-a,
    &-b
      width: 3rem
      height: 3rem
      border: 1px solid $om-gray-500
      border-radius: 8px
      font-size: 2rem
      font-weight: 700
      display: flex
      align-items: center
      justify-content: center
      &.winner
        background: $om-alert-green-600
        color: white
        border-color: $om-alert-green-600
    &-a
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
      background: white
      color: $om-gray-500
    &-b
      border-top-left-radius: 0
      border-top-right-radius: 0
      background: $om-gray-500
      color: white
</style>
