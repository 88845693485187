<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 31.5' width="23" height="20")
  g#loggedIn(data-name='loggedIn')
    path(:fill="color" d='M6.75,27A2.25,2.25,0,0,0,9,29.25H31.5A2.25,2.25,0,0,0,33.75,27V4.5A2.25,2.25,0,0,0,31.5,2.25H9A2.25,2.25,0,0,0,6.75,4.5V7.88H4.5V4.5A4.49,4.49,0,0,1,9,0H31.5A4.49,4.49,0,0,1,36,4.5V27a4.49,4.49,0,0,1-4.5,4.5H9A4.49,4.49,0,0,1,4.5,27V23.63H6.75ZM1.13,14.63H25.41l-5.9-5.9A1.12,1.12,0,1,1,21.1,7.14l7.76,7.76a1.06,1.06,0,0,1,.32.85,1.06,1.06,0,0,1-.32.85L21.1,24.36a1.12,1.12,0,0,1-1.59-1.59l5.9-5.89H1.13a1.13,1.13,0,1,1,0-2.25Z')
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
