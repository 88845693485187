import { cloneDeep } from 'lodash-es';
import { FILTERS_BY_FEATURE, FILTERS_BY_TYPE } from './filters';
import { PRESET_DEFAULT, PRESET_SHOPIFY, RULES_CATEGORIES } from './rules';

const filterRulesByType = (rules, type) => FILTERS_BY_TYPE[type](rules);

export const filterRulesByFeatures = (rules, features) => {
  return features.reduce(
    (rules, feature) => FILTERS_BY_FEATURE?.[feature]?.(rules) ?? rules,
    rules,
  );
};

export const getDefaultFrontendRules = (type = 'default', features = []) => {
  const rules = filterRulesByType(cloneDeep(PRESET_DEFAULT), type);
  return filterRulesByFeatures(rules, features);
};

export const isShopifyTimeAndContextRule = (rule) =>
  PRESET_SHOPIFY[RULES_CATEGORIES.TIME_AND_CONTEXT].includes(rule);

export const isShopifyVisitorsRule = (rule) =>
  PRESET_SHOPIFY[RULES_CATEGORIES.VISITORS].includes(rule);

export const getShopifyFrontendRules = (type = 'default', features = []) => {
  const rules = filterRulesByType(cloneDeep(PRESET_SHOPIFY), type);
  return filterRulesByFeatures(rules, features);
};
