<template lang="pug">
.variant-emphasized-table
  emphasized-om-table(
    :emphasizedItems="[]"
    :emphasizedColumns="[]"
    :emphasizedColumnClasses="emphasizedColumnClasses"
    :highlightedEmphasizedRows="[]"
    :highlightedEmphasizedClass="'inactive'"
    :items="variants"
    :columns="columns"
    :highlightedRows="highlightedRows"
    :highlightClass="'inactive'"
    :selectable="false"
    :allItemsCount="variantCount"
    :columnClasses="columnClasses"
    :loadingContent="loadingVariantsData"
    :pagination="pagination"
    :customPaginationControls="customPaginationControls"
    @selectedIdsChange="$emit('selectedIdsChange', $event)"
    @sortingChange="$emit('sortingChange', $event)"
    @paginationChange="$emit('paginationChange', $event)"
    @tableRowLeave="closeKebabMenu($event)"
  )
    template(slot="emphasized-preview" slot-scope="{ rowData }")
      a.brand-variant-name(
        :href="getEditorLink(rowData.row)"
        :class="{ 'cursor-default': !!rowData.row.isControlVariant }"
        :title="rowData.row.name"
      )
        variant-preview(
          :key="rowData.row.id"
          @observable="addObservable($event.$el)"
          :allowSsr="!!templateType"
          @inited="updateDimensions"
          :dimensions="boxDimensions"
          :updatedAt="updatedAt"
          :variant="rowData.row"
          :templateName="templateName"
          @click="navToEditor(rowData.row)"
          :transparentOverlay="false"
          :templateType="templateType"
          :isControlVariant="rowData.row.isControlVariant"
          :ready="!!updatedAt"
        )

    template(slot="emphasized-name" slot-scope="{ rowData }")
      .d-flex.align-items-end
        a.brand-variant-name(
          :href="getEditorLink(rowData.row)"
          :title="rowData.row.name"
          @click="navToEditor(rowData.row)"
        ) {{ rowData.row.name }}
    template(slot="emphasized-status" slot-scope="{ rowData }")
      toggle-button.mb-0(
        :class="{ inactive: rowData.row.status === 'inactive' }"
        :value="rowData.row.status === 'active'"
        :sync="true"
        :labels="{ checked: $t('active'), unchecked: $t('inactive') }"
        :width="76"
        :height="24"
        :margin="1"
        :ref="'toggle_' + rowData.row._id"
        @change.self="toggleVariantStatus(rowData.row._id, $event.value ? 'active' : 'inactive', $event.srcEvent)"
      )
    template(slot="emphasized-impressions" slot-scope="{ rowData }")
      div {{ rowData.row.impressions | thousandSep }}
    template(slot="emphasized-conversions" slot-scope="{ rowData }")
      div {{ rowData.row.conversions | thousandSep }}
    template(slot="emphasized-conversionRate" slot-scope="{ rowData }")
      div {{ `${getConversion(rowData.row.conversions, rowData.row.impressions)} %` }}
    template(slot="emphasized-settings" slot-scope="{ rowData }")
      om-kebab-menu(
        v-if="isNew"
        :ref="`kebab_${rowData.row._id}`"
        :rowData="rowData"
        @edit="toEditor(rowData.row)"
        @duplicate="copyVariant(rowData.row._id, rowData.index)"
        @preview="previewCampaign(rowData.row)"
        @delete="deleteVariant(rowData.row._id)"
        @rename="showRenameModal(rowData.row._id, rowData.row.name, rowData.index)"
        :class="{ 'hide-delete': !showDeleteVariantMenu, 'delete-border-top': showDeleteVariantMenu }"
      )
        template(slot="duplicate")
          span {{ $t('duplicate') }}
        template(slot="edit")
          span {{ $t('edit') }}
        template(slot="preview")
          span {{ $t('preview') }}
        template(slot="rename")
          span {{ $t('rename') }}
        template(slot="delete")
          span {{ $t('delete') }}

    template(slot="preview" slot-scope="{ rowData }")
      a.brand-variant-name(
        :href="getEditorLink(rowData.row)"
        :class="{ 'cursor-default': !!rowData.row.isControlVariant }"
        :title="rowData.row.name"
        @click="navToEditor(rowData.row)"
      )
        variant-preview(
          :key="rowData.row.id"
          @observable="addObservable($event.$el)"
          :allowSsr="true"
          @inited="updateDimensions"
          :dimensions="boxDimensions"
          :variant="rowData.row"
          :updatedAt="updatedAt"
          :templateName="templateName"
          @click="navToEditor(rowData.row)"
          :transparentOverlay="false"
          :templateType="templateType"
          :isControlVariant="rowData.row.isControlVariant"
          :ready="!!updatedAt"
        )
    template(slot="name" slot-scope="{ rowData }")
      .d-flex.align-items-end.brand-variant-name-holder
        a.brand-variant-name(
          v-if="!isControlVariant(rowData.row)"
          :href="getEditorLink(rowData.row)"
          :title="rowData.row.name"
          @click="navToEditor(rowData.row)"
        ) {{ rowData.row.name }}
        .d-flex.flex-column.w-100(v-else)
          .control-title.pb-2.font-size-0--9375 {{ $t('variantTable.controlTitle') }}
          .control-desc-wrapper
            .control-desc.text-truncate.font-size-0--75(v-if="!isDynamicContent") {{ $t('variantTable.controlDesc') }}
            .control-desc.text-truncate.font-size-0--75(v-else) {{ $t('variantTable.dynamicContentDesc') }}
    template(slot="status" slot-scope="{ rowData }")
      toggle-button.mb-0(
        :class="{ inactive: rowData.row.status === 'inactive' }"
        :value="rowData.row.status === 'active'"
        :sync="true"
        :labels="{ checked: $t('active'), unchecked: $t('inactive') }"
        :width="76"
        :height="24"
        :margin="1"
        :ref="'toggle_' + rowData.row._id"
        @change.self="toggleVariantStatus(rowData.row._id, $event.value ? 'active' : 'inactive', $event.srcEvent)"
      )
    template(slot="visitors" slot-scope="{ rowData }")
      vue-skeleton-loader.skeleton-goals(
        v-if="loadingGoals"
        type="rect"
        width="50%"
        height="24px"
        :rounded="true"
      )
      div(v-else data-testid="visitorCell") {{ rowData.row.visitors | thousandSep }}
    template(slot="impressions" slot-scope="{ rowData }")
      div(data-testid="impressionCell") {{ isControlVariant(rowData.row) && isDefaultGoal ? '-' : rowData.row.impressions | thousandSep }}
    template(slot="conversions" slot-scope="{ rowData }")
      vue-skeleton-loader.skeleton-goals(
        v-if="loadingGoals"
        type="rect"
        width="50%"
        height="24px"
        :rounded="true"
      )
      div(v-else data-testid="conversionCell") {{ isControlVariant(rowData.row) && isDefaultGoal ? '-' : rowData.row.conversions | thousandSep }}
    template(slot="conversionRate" slot-scope="{ rowData }")
      vue-skeleton-loader.skeleton-goals(
        v-if="loadingGoals"
        type="rect"
        width="50%"
        height="24px"
        :rounded="true"
      )
      .result.d-flex.align-items-center.justify-content-end(
        v-else
        data-testid="conversionRateCell"
      ) {{ getConversionForRow(rowData.row.isControlVariant, rowData.row.conversionRate) }}
    template(slot="uplift" slot-scope="{ rowData }")
      vue-skeleton-loader.skeleton-goals(
        v-if="loadingGoals"
        type="rect"
        width="50%"
        height="24px"
        :rounded="true"
      )
      div(v-else data-testid="upliftCell" :class="getUpliftClass(rowData.row)") {{ isControlVariant(rowData.row) && isDefaultGoal ? '-' : getFormattedUplift(rowData.row.uplift) }}
    template(slot="chanceToWin" slot-scope="{ rowData }")
      vue-skeleton-loader.skeleton-goals(
        v-if="loadingChanceToWin || loadingGoals"
        type="rect"
        width="50%"
        height="24px"
        :rounded="true"
      )
      div(v-else data-testid="chanceToWinCell") {{ isControlVariant(rowData.row) && isDefaultGoal ? '-' : getFormattedChanceToWin(rowData.row.chanceToWin) }}
    template(slot="settings" slot-scope="{ rowData }")
      .control-variant-delete(v-if="isControlVariant(rowData.row)")
        om-button(ghost iconOnly small icon="trash-alt" @click="deleteControlVariant(rowData.row)")
      template(v-else-if="isNew")
        om-kebab-menu(
          :ref="`kebab_${rowData.row._id}`"
          :rowData="rowData"
          @edit="toEditor(rowData.row)"
          @duplicate="copyVariant(rowData.row._id, rowData.index)"
          @delete="deleteVariant(rowData.row._id)"
          @rename="showRenameModal(rowData.row._id, rowData.row.name, rowData.index)"
          @preview="previewCampaign(rowData.row)"
          :class="{ 'hide-delete': !showDeleteVariantMenu, 'delete-border-top': showDeleteVariantMenu }"
        )
          template(slot="duplicate")
            span {{ $t('duplicate') }}
          template(slot="edit")
            span {{ $t('edit') }}
          template(slot="rename")
            span {{ $t('rename') }}
          template(slot="delete")
            span {{ $t('delete') }}
          template(v-if="!isEmbedded" slot="preview")
            span {{ $t('preview') }}
    template(slot="loadingItems")
      skeleton-loader(type="variant" :rows="skeletonRowNumber")
    template(slot="loadingEmphasizedItems")
      skeleton-loader(type="variant" :rows="1")
    template(slot="smartABTest")
      slot(name="smartABTest")
    template(slot="addVariant")
      slot(name="addVariant")

  variant-rename-modal(@renameSuccess="renameVariant($event)")
</template>

<script>
  import COPY_VARIANT from '@/graphql/CopyVariant.gql';
  import DELETE_VARIANT from '@/graphql/DeleteVariant.gql';
  import DELETE_CONTROL_VARIANT from '@/graphql/DeleteControlVariant.gql';
  import CHANGE_VARIANT_STATUS from '@/graphql/ChangeVariantStatus.gql';
  import VariantRenameModal from '@/components/Modals/VariantRename.vue';
  import VariantPreview from '@/components/Template/VariantPreview.vue';
  import { mapMutations, mapGetters } from 'vuex';
  import upgradePlanModal from '@/mixins/upgradePlanModal';
  import ssrMixin from '@/mixins/ssr';
  import { track } from '@/services/xray';
  import SkeletonLoader from '@/components/SkeletonLoader/SkeletonLoader.vue';
  import { getBrandedClassString } from '@/components/Elements/Button';
  import Kebab from '@/components/Svg/Icons/Kebab.vue';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import closeKebabMenu from '@/components/Elements/KebabMenu/closeKebabMenu';
  import { createDCUrl } from '@/utils/pncURLBuilder';
  import EmphasizedOmTable from './Tables/EmphasizedOmTable.vue';

  export default {
    components: {
      EmphasizedOmTable,
      'variant-rename-modal': VariantRenameModal,
      SkeletonLoader,
      Kebab,
      VariantPreview,
    },
    mixins: [upgradePlanModal, ssrMixin, observableCollectionMixin, closeKebabMenu],
    props: {
      isNew: { type: Boolean, default: true },
      templateName: { type: String, default: null },
      templateType: { type: String, default: null },
      variants: { type: Array },
      isDefaultGoal: { type: Boolean, default: true },
      variantCount: { type: Number, required: true },
      loadingVariantsData: { type: Boolean, default: false },
      pagination: { type: Object, required: true },
      domain: { validator: (prop) => typeof prop === 'string' || prop === null, required: true },
      updatedAt: { type: String, default: null },
      experienceId: { type: String, default: null },
      allVariantsCount: { type: Number, default: 0 },
      customPaginationControls: { type: Boolean, default: false },
      loadingGoals: { type: Boolean, default: false },
      loadingChanceToWin: { type: Boolean, default: false },
      isEmbedded: { type: Boolean, default: false },
      showDeleteVariantMenu: { type: Boolean, default: true },
    },
    data() {
      return {
        columnClasses: {
          preview: 'brand-variant-preview-col',
          name: 'brand-variant-name-col text-left',
          status: 'brand-variant-status-col',
          impressions: 'brand-variant-results-col',
          visitors: 'brand-variant-results-col',
          conversions: 'brand-variant-results-col',
          conversionRate: 'brand-variant-results-col brand-variant-conversionrate-col',
          uplift: 'brand-variant-results-col brand-variant-uplift-col',
          chanceToWin: 'brand-variant-results-col brand-variant-chanceToWin-col',
          settings: 'brand-variant-settings-col',
        },
        emphasizedColumnClasses: {
          'emphasized-preview': 'brand-variant-preview-col',
          'emphasized-name': 'brand-variant-name-col flex-2 text-left',
          'emphasized-status': 'brand-variant-status-col',
          'emphasized-impressions': 'brand-variant-results-col',
          'emphasized-conversions': 'brand-variant-results-col',
          'emphasized-conversionRate': 'brand-variant-results-col brand-variant-conversionrate-col',
          'emphasized-settings': 'brand-variant-settings-col',
        },
        ssrBoxSelector: '.template-preview',
      };
    },
    computed: {
      ...mapGetters(['domainRequestStatus', 'isSuperAdmin']),
      isDynamicContent() {
        return this.templateType === 'dynamic_content';
      },
      highlightedRows() {
        if (!this.variants?.length) return [];
        const inactiveIndexes = [];
        for (const [index, variant] of Object.entries(this.variants)) {
          if (variant.status !== 'active') {
            inactiveIndexes.push(index);
          }
        }

        return inactiveIndexes;
      },
      tableUsedInExperience() {
        return this.experienceId !== null;
      },
      hasControlVariant() {
        return this.variants.some((variant) => this.isControlVariant(variant));
      },
      domainAccessible() {
        return this.domainRequestStatus(this.domain) === 'accessible';
      },
      columns() {
        const columns = [
          { header: this.$t('variants'), key: 'preview', sortKey: 'name' },
          { header: '', key: 'name' },
          { header: this.$t('impressions'), key: 'impressions' },
          { header: this.$t('conversions'), key: 'conversions' },
          { header: this.$t('conversionRate'), key: 'conversionRate' },
          { header: '', key: 'settings' },
        ];

        if (this.variantCount !== 1) {
          columns.splice(2, 0, { header: this.$t('status'), key: 'status' });
          columns.splice(6, 0, { header: this.$t('analyticsPage.uplift'), key: 'uplift' });
          columns.splice(7, 0, {
            header: this.$t('analyticsPage.chanceToWin'),
            key: 'chanceToWin',
          });
        }

        if (!this.isDefaultGoal) {
          const impressionIndex = this.variantCount !== 1 ? 3 : 2;
          columns.splice(impressionIndex, 1, {
            header: this.$t('analyticsPage.visitors'),
            key: 'visitors',
          });
        }

        return columns;
      },

      hasMultipleVariants() {
        return this.variants.length > 1;
      },
      skeletonRowNumber() {
        return this.variants.length > 30 ? 30 : this.variants.length;
      },
    },

    watch: {
      updatedAt(n) {
        if (n) this.updateDimensions();
      },
    },

    methods: {
      ...mapMutations(['showAdminLoader']),
      async deleteControlVariant(variant) {
        const variantId = variant._id;

        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: async () => {
                try {
                  const { data } = await this.$apollo.mutate({
                    mutation: DELETE_CONTROL_VARIANT,
                    variables: { variantId },
                  });
                  const { success } = data.deleteControlVariant;
                  if (!success) throw new Error('Failed to delete control variant');

                  this.$modal.hide('dialog');

                  if (this.tableUsedInExperience) {
                    this.$emit('deleteVariantFromExperience', {
                      experienceId: this.experienceId,
                      variantId,
                    });
                  } else {
                    this.$emit('refetchVariants');
                  }
                  this.$notify({
                    type: 'success',
                    text: this.$t('notifications.deleteSuccess'),
                  });
                } catch (e) {
                  this.$modal.hide('dialog');
                  this.$notify({
                    type: 'error',
                    text: this.$t('notifications.deleteError'),
                  });
                }
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },
      isControlVariant(variant) {
        return variant.isControlVariant;
      },
      renameVariant(event) {
        this.$emit('changeVariantName', event);
      },
      toEditor(variant) {
        window.location.href = this.getEditorLink(variant);
      },
      getEditorLink(variant) {
        const { id } = this.$route.params;
        const userId = this.$route.params.userId;

        if (variant.isControlVariant) return '#';

        if (this.templateType === 'dynamic_content') {
          const returnUrl = `/${userId}/campaign/${id}`;
          const backUrl = `/${userId}/campaign/${id}`;
          let lastEditUrl = null;
          const variantWithLastEditUrl = this.variants.find((variant) => !!variant.lastEditUrl);
          if (variantWithLastEditUrl) {
            lastEditUrl = variantWithLastEditUrl.lastEditUrl;
          }

          return createDCUrl({
            domain: this.domain,
            campaignId: id,
            variantId: variant._id,
            locale: this.$i18n.locale,
            returnUrl,
            backUrl,
            lastEditUrl,
            isSuperAdmin: this.isSuperAdmin,
            featureMode: variant.featureMode,
          });
        }

        if (this.isNew) {
          return `/${userId}/variant/${id}/${variant._id}/edit/`;
        }

        return '#';
      },
      navToEditor(variant) {
        const { id } = this.$route.params;

        if (this.isNew) return;

        this.$modal.show('old-editor-termination', { campaignId: id, variantId: variant.id });
      },
      getConversion(sub, imp) {
        return sub && imp ? ((sub / imp) * 100).toFixed(2) : 0;
      },
      showRenameModal(variantId, name, index) {
        this.$modal.show('variant-rename', { variantId, name, index });
      },
      previewCampaign(row) {
        const url = new URL(`https://${this.domain}`);
        const searchParams = new URLSearchParams();
        const previewSearchParam = this.isDynamicContent ? 'om-preview-v3-id' : 'om-preview-id';
        searchParams.append(previewSearchParam, row._id);
        url.search = searchParams;

        window.open(url.href, '_blank');
      },
      copyVariant(variantId) {
        this.$apollo
          .mutate({
            mutation: COPY_VARIANT,
            variables: {
              variantId,
              templateType: this.templateType,
              experience: this.experienceId,
            },
          })
          .then(() => {
            this.$emit('refetchVariants', { skeletonAllowed: true });
          });
      },
      deleteVariant(variantId) {
        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this.$apollo
                  .mutate({
                    mutation: DELETE_VARIANT,
                    variables: { variantId },
                  })
                  .then(() => {
                    this.$modal.hide('dialog');

                    if (this.tableUsedInExperience) {
                      this.$emit('deleteVariantFromExperience', {
                        experienceId: this.experienceId,
                        variantId,
                      });
                    } else {
                      this.$emit('refetchVariants');
                    }
                  });
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },
      async toggleVariantStatus(variantId, status) {
        const {
          data: {
            changeVariantStatus: { success },
          },
        } = await this.$apollo.mutate({
          mutation: CHANGE_VARIANT_STATUS,
          variables: {
            variantId,
            status,
          },
        });

        this.$emit('refetchVariants', { skeletonAllowed: false });
        if (success && this.experienceId) {
          this.$emit('updateStatus', { variantId, status });
        }

        if (success && status === 'active') {
          const activeVariantCount = this.variants.filter((v) => v.status === 'active').length + 1;
          if (activeVariantCount > 1) {
            track('abTestActivated', { activeVariantCount });
          }
        }

        const foundVariant = this.variants.find((v) => v._id === variantId);
        if (foundVariant) {
          const { id: campaignId } = this.$route.params;
          const foundVariant = this.variants.find((v) => v._id === variantId);
          track('variantStatusChange', {
            campaignId,
            variantId,
            variantName: foundVariant.name,
            status,
          });
        }
      },
      confidenceProductTour() {
        if (this.$i18n.locale === 'hu') {
          window.Intercom('startTour', 63317);
        } else {
          window.Intercom('startTour', 63311);
        }
      },
      getConversionForRow(isControlVariant, conversionRate) {
        if (isControlVariant && this.isDefaultGoal) return '-';
        return !conversionRate && conversionRate !== 0
          ? 'N/A'
          : `${Math.round(conversionRate * 10000) / 100} %`;
      },
      getFormattedUplift(uplift) {
        if (uplift === 'baseline') {
          return this.$t('analyticsPage.baseline');
        }
        return !uplift && uplift !== 0 ? 'N/A' : `${Math.round(uplift * 10000) / 100} %`;
      },
      getFormattedChanceToWin(chanceToWin) {
        return !chanceToWin && chanceToWin !== 0
          ? 'N/A'
          : `${Math.round(chanceToWin * 10000) / 100} %`;
      },
      getUpliftClass(row) {
        const { uplift, isControlVariant } = row;
        if (this.isDefaultGoal && isControlVariant) return '';
        if (uplift < 0) {
          return 'uplift-negative';
        }
        if (uplift > 0) {
          return 'uplift-positive';
        }
        return '';
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .variant-emphasized-table
    .kebab-menu.hide-delete
      .kebab-option-delete
        display: none
    .kebab-menu.delete-border-top
      .kebab-option-delete
        border-top: 1px solid rgba(0,0,0,.1)

    .table-responsive
      overflow: visible !important
    .control-title
      color: $om-gray-700
    .control-desc
      color: $om-gray-600

    .kebab-menu
      opacity: 0

    .brand-table-tr:hover .kebab-menu
      opacity: 1

  .skeleton-goals
    margin-left: auto
  .uplift-positive
    color: $om-alert-green-600
  .uplift-negative
    color: $om-alert-red-500
</style>
