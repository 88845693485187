<template lang="pug">
om-modal.new-campaign-flow-more-details.new-campaign-flow-modal(
  :name="modalName"
  scrollable
  :clickToClose="false"
  width="90%"
  @beforeOpen="showVideo = false"
  @closed="$emit('close')"
)
  template(#header-action-icon)
    .brand-modal-action-icon
      close-icon.cursor-pointer(
        @click.native="onCloseClick"
        :width="14"
        :height="14"
        color="#C2C2C2"
      )
  template(#modal-body)
    transition-group(name="fade")
      .new-campaign-flow-modal-block.row.mb-80(key="first-block" v-if="about")
        .col-5.d-flex.flex-column.justify-content-center
          om-heading.new-campaign-flow-modal-block-preheading.text-uppercase.mb-32(h4) {{ $t(getModalTranslationPathFor('preHeading')) }}
          om-heading.new-campaign-flow-modal-block-title.mb-32(h2) {{ $t(getModalTranslationPathFor('title')) }}
          om-body-text.new-campaign-flow-modal-block-description.mb-32(bt400md) {{ $t(getModalTranslationPathFor('description')) }}
          om-button.new-campaign-flow-modal-block-cta(
            v-if="!isRequested"
            primary
            large
            @click="showNextStep"
          ) {{ $t(isGetStartedTitle ? 'newCampaignFlow.getStarted' : 'requestFreeTrial') }}
          .new-campaign-flow-modal-block-requested(v-else) {{ $t('freeTrialRequested') }}
        .col-7
          img.ai-block-img.mx-auto(
            v-if="aiTypes.includes(about)"
            :src="require(`@/assets/admin/img/new-campaign-flow/${about}/${$t(getModalTranslationPathFor('images.0'))}`)"
          )
      .new-campaign-flow-modal-block.row.mb-100(
        key="second-block"
        v-if="about && $te(getModalTranslationPathFor('video.title'))"
      )
        .col-12
          om-all-caps-text.new-campaign-flow-modal-block-preheading.text-uppercase.text-center.mb-3.fancy-gradient(
            bold
          ) {{ $t(getCommonTranslationPathFor('video.preHeading')) }}
          om-heading.new-campaign-flow-modal-block-title.mb-32.text-center(
            h2
            v-html="$t(getModalTranslationPathFor('video.title'))"
          )
        .col-12.d-flex.justify-content-center.new-campaign-flow-modal-block-video-wrapper
          transition(name="fade" mode="out-in")
            iframe(
              v-if="showVideo"
              :src="videoURL"
              :title="videoTitle"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            )
            img.new-campaign-flow-modal-block-video-thumbnail.cursor-pointer(
              v-else
              :src="require(`@/assets/admin/img/new-campaign-flow/${about}/video-thumbnail.jpg`)"
              @click="playVideo"
            )
      .new-campaign-flow-modal-block.row.mb-100(key="third-block" v-if="about")
        .col-12
          om-all-caps-text.new-campaign-flow-modal-block-preheading.text-uppercase.text-center.mb-3.fancy-gradient(
            bold
          ) {{ $t(getCommonTranslationPathFor('why.preHeading')) }}
          om-heading.new-campaign-flow-modal-block-title.mb-32.text-center.mb-60(
            h2
            v-html="$te(getModalTranslationPathFor('whyTitle')) ? $t(getModalTranslationPathFor('whyTitle')) : $t(getCommonTranslationPathFor('why.title'))"
          )
        .col-4.d-flex.flex-column.justify-content-center.align-items-center.text-center.new-campaign-flow-modal-block-option-col(
          v-for="(_, index) in $t(getModalTranslationPathFor('why'))"
        )
          om-unique-icon.mb-20(:name="$t(getModalTranslationPathFor(`why.${index}.icon`))")
          om-heading.mb-3(h3) {{ $t(getModalTranslationPathFor(`why.${index}.title`)) }}
          om-body-text.mb-32(bt400lg) {{ $t(getModalTranslationPathFor(`why.${index}.description`)) }}
      .new-campaign-flow-modal-block.row.mb-60(key="fourth-block" v-if="about")
        .col-12
          om-all-caps-text.new-campaign-flow-modal-block-preheading.text-uppercase.text-center.mb-3.fancy-gradient(
            bold
          ) {{ $t(getCommonTranslationPathFor('how.preHeading')) }}
          om-heading.new-campaign-flow-modal-block-title.mb-32.text-center.mb-60(
            h2
            v-html="$te(getModalTranslationPathFor('how.title')) ? $t(getModalTranslationPathFor('how.title')) : $t(getCommonTranslationPathFor('how.title'), { minutes: installMinutes })"
          )
        .col-4
          om-accordion
            om-accordion-item(
              v-for="(_, index) in $t(getModalTranslationPathFor('how.options'))"
              :step="index + 1"
              :key="index"
            )
              template(#name)
                om-all-caps-text.new-campaign-flow-modal-block-accordion-preheading.text-uppercase(
                  bold
                )
                  span {{ $t('step', { step: index + 1 }) }}
                om-heading(h3) {{ $t(getModalTranslationPathFor(`how.options.${index}.title`)) }}
              om-body-text(bt400lg) {{ $t(getModalTranslationPathFor(`how.options.${index}.description`)) }}
        .col-8.d-flex.justify-content-center(
          v-if="aiTypes.includes(about) && videoTutorials.includes(about)"
        )
          video.how-it-works-video(
            :src="require(`@/assets/admin/img/new-campaign-flow/${about}/${$t(getModalTranslationPathFor('how.video'))}`)"
            height="370"
            controlslist="nodownload"
            muted="muted"
            playsinline
            autoplay
            loop
          )
        .col-8(v-else-if="aiTypes.includes(about)")
          img(
            :src="require(`@/assets/admin/img/new-campaign-flow/${about}/${$t(getModalTranslationPathFor('how.image'))}`)"
          )
      .new-campaign-flow-modal-block.d-flex.justify-content-center(key="fifth-block")
        om-button.new-campaign-flow-modal-block-cta(
          v-if="!isRequested"
          primary
          large
          @click="showNextStep"
        ) {{ $t(isGetStartedTitle ? 'newCampaignFlow.getStarted' : 'requestFreeTrial') }}
        .new-campaign-flow-modal-block-requested(v-else) {{ $t('freeTrialRequested') }}
</template>

<script>
  import { mapState } from 'vuex';
  import modalsMixin from '@/mixins/newCampaignFlow/modals';
  import campaignCreateMixin from '@/mixins/newCampaignFlow/campaignCreate';

  const VIDEOS = {
    sab: {
      url: 'https://www.youtube.com/embed/9xtVbdfM5q0',
      title: 'Introducing Smart A/B Testing: Revolutionize Website Conversions with AI!',
    },
    sppo: {
      url: 'https://www.youtube.com/embed/JGssKy0Zt8w',
      title: 'Say hi to Smart Product Page Optimizer',
    },
    smartPersonalizer: {
      url: 'https://www.youtube.com/embed/BHoUoTzR4pQ',
      title: 'Meet Smart Personalizer',
    },
    smartPopup: {
      url: 'https://www.youtube.com/embed/gmY0X8GXO08',
      title: 'Introducing Smart Popups',
    },
  };

  const VIDEO_TUTORIALS = ['smartPopup'];

  export default {
    mixins: [modalsMixin, campaignCreateMixin],
    props: {
      aiTypes: { type: Array, required: true },
    },
    data: () => ({
      modalName: 'new-campaign-flow-more-details',
      showVideo: false,
      videoTutorials: VIDEO_TUTORIALS,
    }),
    computed: {
      ...mapState(['account']),
      installMinutes() {
        return this.about === 'sab' ? 2 : 15;
      },
      isRequested() {
        if (!this.account.profile) return false;

        const keys = Object.keys(this.account.profile);
        const hasProfileKey = keys.some((key) => key.includes(`${this.about}_newCampaignFlow`));
        if (this.isSAB) return !this.hasSmartAbTestFeature ? hasProfileKey : false;
        return hasProfileKey;
      },
      isSAB() {
        return this.about === 'sab';
      },
      videoURL() {
        return `${VIDEOS[this.about].url}?autoplay=1`;
      },
      videoTitle() {
        return VIDEOS[this.about].title;
      },
      isGetStartedTitle() {
        return this.isSAB && this.hasSmartAbTestFeature;
      },
    },
    methods: {
      showNextStep() {
        if (this.isSAB && this.hasSmartAbTestFeature) {
          this.$modal.hide(this.modalName);
          this.$router.push({ name: 'new_campaign_flow_sab', query: { ...this.$route.query } });
          return;
        }
        this.showBooking();
      },
      showBooking() {
        this.$modal.hide(this.modalName);
        this.$modal.show('new-campaign-flow-book');
      },
      playVideo() {
        this.showVideo = true;
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .new-campaign-flow-more-details
    .v--modal
      max-width: 81rem
    &.v--modal-overlay:not(.centered-modal)
      .v--modal
        top: 4.375rem !important
        border-radius: 1.25rem
        .brand-modal
          border-radius: 1.25rem
          &-header
            border-radius: 1.25rem
      .v--modal-background-click
        margin-bottom: 5.375rem
    .mb-20
      margin-bottom: 1.5rem
    .mb-32
      margin-bottom: 2rem
    .mb-60
      margin-bottom: 3.75rem
    .mb-80
      margin-bottom: 5rem
    .mb-100
      margin-bottom: 6.25rem
    .fancy-gradient
      background: -webkit-linear-gradient(0deg, #8444E1 0%, #ED5A29 100%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent
    .accordion-v2-item
      border: 0
      border-radius: 0
      padding-bottom: 0
      margin-bottom: 1.5rem
      & + .accordion-v2-item
        margin-top: 0
        padding-top: 1.5rem
        border-top: 1px solid #D4CFDB
      &-step-col
        display: none
      &-head
        align-items: flex-start !important
      &-arrow
        color: $om-orange-500
      &-body
        padding-left: 0
      &.active
        .new-campaign-flow-modal-block-accordion-preheading
          span
            transition: background
            background: rgb(124,16,238)
            background: linear-gradient(90deg, rgba(124,16,238,1) 0%, rgba(237,90,41,1) 50%, rgba(237,90,41,1) 100%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
        .accordion-v2-item-body
          margin-top: .5rem
      &:not(.done)
        @media (hover: hover) and (pointer: fine)
          &:not(.active):hover
            box-shadow: unset
  .new-campaign-flow-modal-block
    &-accordion-preheading
      color: $om-gray-600
    &-preheading
      color: $om-orange-500 !important
    &-cta
      max-width: max-content
    &-requested
      max-width: max-content
      background: $om-alert-green-200
      color: $om-alert-green-600
      padding: .75rem 1.25rem
      font-weight: bold
      border-radius: .25rem
    &-video
      &-wrapper
        iframe
          max-width: 80vw
          width: 56.25rem
          aspect-ratio: 16 / 9
          border-radius: 1rem
      &-thumbnail
        max-width: 53.25rem
        aspect-ratio: 16 / 9
        height: auto
    &-option-col
      padding: 0 3.5rem
    .how-it-works-video
      border-radius: 1rem
      box-shadow: 13px 10px 0px -2px #e6e6e6
</style>
