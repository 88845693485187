<template lang="pug">
div
  template(v-for="(expressionGroup, expressionGroupIndex) in currentRule.options.expressions")
    template(v-for="(target, targetIndex) in expressionGroup")
      .d-flex.align-items-center.mb-3
        .settings-label.mr-3(v-if="$i18n.locale === 'hu'") {{ $t('the') }}
        multiselect.mr-3.w-20(
          :value="getCampaignId(expressionGroupIndex, targetIndex)"
          @input="setCampaignId($event, expressionGroupIndex, targetIndex)"
          :options="campaignList"
          label="name"
          track-by="id"
          :show-labels="false"
          :closeOnSelect="true"
          :allow-empty="true"
          :multiple="false"
          :placeholder="$t('all')"
        )
        .settings-label {{ $tc('campaignWord', currentRule.options.expressions[expressionGroupIndex][targetIndex].campaignId === '' ? 2 : 1) }}
        select#campaignProgressState.form-control.w-15.mx-3(
          v-model="currentRule.options.expressions[expressionGroupIndex][targetIndex].state"
        )
          template(
            v-for="t in ['showed', 'notShowed', 'closed', 'notClosed', 'filled', 'notFilled']"
          )
            option(:value="t") {{ $t(`frontendRules.campaignProgressState.states.${t}`) }}
        .d-flex.flex-grow-1.justify-content-end
          .settings-label {{ $t('byVisitors') }}
          .d-flex.aligns-item-center.font-size-0--75.font-weight-bold.mx-2.flex-grow-1.line-height-1--8 {{ $t('andOperation') }}
          .brand-link.settings-label.mr-2.flex-grow-0.text-center(
            v-if="targetIndex === expressionGroup.length - 1"
            @click="addExpressionToGroup('expressions', expressionGroupIndex, { campaignId: '', state: 'showed' })"
          )
            fa-icon(variant="fa-plus-circle" size="1.5")
          .brand-link.settings-label(
            v-if="currentRule.options.expressions.length === 1 && expressionGroup.length === 1 && targetIndex === 0"
          )
          .brand-link.settings-label.flex-grow-0.text-right(
            v-else
            @click="removeExpressionFromGroup('expressions', expressionGroupIndex, targetIndex)"
          )
            fa-icon(variant="fa-trash" size="1.5")
    .d-flex.align-items-center.mb-3(
      v-if="expressionGroupIndex !== currentRule.options.expressions.length - 1"
    )
      .font-size-0--75.font-weight-bold.mr-2 {{ $t('orOperation') }}
      .brand-separator
  span.brand-link.settings-label(
    @click="addExpressionGroup('expressions', { campaignId: '', state: 'showed' })"
  )
    fa-icon(variant="fa-plus" :html="`&nbsp;${$t('addNew')}`")
</template>

<script>
  import settingsValidation from '@/mixins/settingsValidation';
  import expressionOperations from '@/mixins/expressionOperations';
  import CAMPAIGN_LIST from '@/graphql/CampaignList.gql';

  export default {
    mixins: [settingsValidation, expressionOperations],
    data() {
      return {
        campaignList: [],
        selectedCampaign: null,
      };
    },
    computed: {
      expressionLength() {
        return this.currentRule.options.expression.length;
      },
    },
    methods: {
      getCampaignId(expressionGroupIndex, targetIndex) {
        return this.currentRule.options.expressions[expressionGroupIndex][targetIndex].campaignId
          ? {
              id: this.currentRule.options.expressions[expressionGroupIndex][targetIndex]
                .campaignId,
              name: this.currentRule.options.expressions[expressionGroupIndex][targetIndex]
                .campaignName,
            }
          : null;
      },
      setCampaignId(event, expressionGroupIndex, targetIndex) {
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].campaignId =
          event.id;
        this.currentRule.options.expressions[expressionGroupIndex][targetIndex].campaignName =
          event.name;
      },
    },
    apollo: {
      campaignList: {
        query: CAMPAIGN_LIST,
        result({ data: { campaignList } }) {
          this.campaignList = campaignList.filter(
            (c) => c.id && c.id !== parseInt(this.$route.params.id, 10),
          );
        },
      },
    },
  };
</script>
