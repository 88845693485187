<template lang="pug">
svg(width='60', height='46', viewBox='0 0 60 46', fill='none', xmlns='http://www.w3.org/2000/svg')
  path(fill-rule='evenodd', clip-rule='evenodd', d='M22.4742 42.5638H5.62544C2.60902 42.5638 0.163818 40.1013 0.163818 37.0636V5.53671C0.163818 2.49905 2.60902 0.0365303 5.62544 0.0362549H36.9319C39.9486 0.0365303 42.3935 2.49905 42.3935 5.53671V17.6937H39.8796V15.7308H3.1797V37.422C3.1797 38.8615 4.34271 40.0324 5.77202 40.0324H22.4742V42.5638ZM22.4742 28.3759V29.9146H29.7541L24.1076 37.6525H18.8424C18.7775 37.6525 18.7249 37.5996 18.7249 37.5346V28.4941C18.7249 28.4291 18.7775 28.3762 18.8424 28.3759H22.4742ZM16.5931 37.6525H5.81531C5.75065 37.6525 5.69777 37.5996 5.69777 37.5346V28.4941C5.69777 28.4291 5.75038 28.3762 5.81531 28.3759H16.5931C16.658 28.3762 16.7106 28.4291 16.7106 28.4941V37.5346C16.7106 37.5996 16.658 37.6525 16.5931 37.6525ZM22.4742 18.1559V26.8568H5.80818C5.74736 26.8568 5.69777 26.8072 5.69777 26.7461V18.2669C5.69777 18.2058 5.74709 18.1559 5.80818 18.1559H22.4742ZM39.8796 11.6809H3.1797V13.1995H39.8796V11.6809ZM37.2875 3.07419H5.77202C4.34244 3.07419 3.1797 4.24513 3.1797 5.68436V9.14952H39.8796V5.68436C39.8796 4.24513 38.7168 3.07419 37.2875 3.07419ZM14.7421 5.09929C13.909 5.09929 13.2339 5.77939 13.2339 6.61814C13.2339 7.45688 13.909 8.13696 14.7421 8.13696C15.5753 8.13696 16.2503 7.45688 16.2503 6.61814C16.2503 5.77939 15.5753 5.09929 14.7421 5.09929ZM10.7216 5.09929C9.88872 5.09929 9.21338 5.77939 9.21338 6.61814C9.21338 7.45688 9.88872 8.13696 10.7216 8.13696C11.5547 8.13696 12.2298 7.45688 12.2298 6.61814C12.2298 5.77939 11.5547 5.09929 10.7216 5.09929ZM6.69914 5.09929C5.86627 5.09929 5.19093 5.77939 5.19093 6.61814C5.19093 7.45688 5.86627 8.13696 6.69914 8.13696C7.53229 8.13696 8.20735 7.45688 8.20735 6.61814C8.20735 5.77939 7.53229 5.09929 6.69914 5.09929Z', :fill="color")
  path(d='M33.1216 40.7188H44.3837V45.7779H26.0358V41.063L36.7844 26.333H26.0358V21.2739H44.0071V25.92L33.1216 40.7188Z', :fill="color")
  path(d='M51.2961 41.1038H59.8295V45.7772H43.9767V41.3479L51.9552 32.6987H44.0114V28.0602H59.4827V32.2105L51.2961 41.1038Z', :fill="color")
</template>

<script>
  import eventSvg from '@/mixins/eventSvg';

  export default {
    mixins: [eventSvg],
  };
</script>
