<template lang="pug">
.d-flex.align-items-center
  om-select#aBlock.w-10.mr-3(
    :options="aBlockTypes"
    size="small"
    :placeholder="$t('select')"
    :label="$t('thoseVisitorsWho')"
    labelPosition="fill"
    @input="setAdBlockType($event)"
    :value="{ key: currentRule.options.value, value: $t(`aBlock.${currentRule.options.value}`) }"
  )
  label.settings-label(for="aBlock") {{ $t('aBlock.labelSuffix') }}
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import settingsValidation from '@/mixins/settingsValidation';

  export default {
    name: 'ABlock',
    mixins: [settingsValidation],
    data() {
      return {
        aBlockTypes: [
          {
            key: 'enabled',
            value: this.$t('aBlock.enabled'),
          },
          {
            key: 'disabled',
            value: this.$t('aBlock.disabled'),
          },
        ],
      };
    },
    methods: {
      setAdBlockType(event) {
        this.currentRule.options.value = event.key;
      },
    },
    validations: {
      currentRule: {
        options: {
          value: {
            required,
          },
        },
      },
    },
  };
</script>
