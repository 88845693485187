<template lang="pug">
svg(width="70" height="70" viewbox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg")
  rect(width="70" height="70" rx="4" fill="#CB807D")
  g(:fill="color" clip-path="url(#clip0_6453_206181)")
    path(
      d="M23.0222 29.1851L33.5937 22.2276C34.0106 21.9625 34.6609 21.9625 35.0777 22.2276L45.6492 29.1851C46.2828 29.5993 46.2828 30.2785 45.6492 30.6926L44.2319 31.6202C42.1476 28.3568 38.4793 26.2033 34.3274 26.2033C30.1588 26.2033 26.5072 28.3734 24.4229 31.6202L23.0056 30.6926C22.3886 30.2785 22.3886 29.5827 23.0222 29.1851ZM34.3274 29.1023C31.1759 29.1023 28.4247 30.7588 26.8573 33.2105L29.2918 34.8174C30.3256 33.1277 32.1764 32.0012 34.3108 32.0012C36.4284 32.0012 38.2959 33.1277 39.3297 34.8174L41.7642 33.2105C40.2468 30.7588 37.4788 29.1023 34.3274 29.1023ZM34.3274 34.9168C33.2269 34.9168 32.2598 35.5297 31.7429 36.4243L33.3769 37.4844C33.6437 37.6998 33.9605 37.8323 34.3274 37.8323C34.6942 37.8323 35.0277 37.6998 35.2778 37.4844L36.9119 36.4243C36.4283 35.5463 35.4445 34.9168 34.3274 34.9168Z"
      fill="white"
    )
    path(
      d="M19.3427 43.9117L21.631 47.1772H20.0239L18.2985 44.6514C18.2985 45.4813 18.2985 46.3473 18.2985 47.1772H17V40.7273H18.2985C18.2985 41.5842 18.2985 42.4413 18.2985 43.2982L19.9329 40.7273H21.4494L19.3427 43.9117ZM23.898 40.7273H22.5995V47.1772H25.9591V45.8872H23.898V40.7273H23.898ZM29.8099 40.4927L32.5428 47.1772H31.081L30.8177 46.4826H28.6656L28.4114 47.1772H26.9405L29.6736 40.4927H29.8099ZM30.3364 45.2737L29.7464 43.596L29.1379 45.2737H30.3364ZM34.3713 44.3085L33.0366 40.7273H31.5748L34.3077 47.4118H34.444L37.1771 40.7273H35.7062L34.3713 44.3085ZM38.1523 47.1772H39.4507V40.7273H38.1523V47.1772ZM44.654 40.7273L43.3282 42.9464L42.0026 40.7273H40.4225L42.6835 44.2725V47.1772H43.9728V44.2725L46.2429 40.7273H44.654ZM52.9711 43.8349C52.9711 45.6807 51.4649 47.177 49.607 47.177C47.749 47.177 46.2429 45.6807 46.2429 43.8349C46.2429 41.989 47.749 40.4927 49.607 40.4927C51.4649 40.4927 52.9711 41.989 52.9711 43.8349ZM51.6995 43.8349C51.6995 42.6867 50.7627 41.7559 49.607 41.7559C48.4513 41.7559 47.5144 42.6867 47.5144 43.8349C47.5144 44.983 48.4513 45.9138 49.607 45.9138C50.7627 45.9138 51.6995 44.983 51.6995 43.8349Z"
      fill="white"
    )
  defs
    clippath#clip0_6453_206181
      rect(width="36" height="25.4118" fill="white" transform="translate(17 22)")
</template>

<script>
  import conditionSvg from '@/mixins/conditionSvg';

  export default {
    mixins: [conditionSvg],
  };
</script>
