const slugify = (str) => {
  return str
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading and trailing whitespace
    .replace(/(^\w+:|^)\/\//, '') // Remove protocol
    .replace(/[^a-z0-9 -]/g, '-') // Remove non-alphanumeric characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen
};

module.exports = { slugify };
