<template lang="pug">
svg(width='8', height='8', viewBox='9.500000953674316 0.5 6.999985694885254 7.000004768371582', fill='none', xmlns='http://www.w3.org/2000/svg')
  path(d='M16.1967 1.18267L16.1966 1.18253L15.8175 0.803435C15.4128 0.398776 14.757 0.398982 14.3523 0.803292L14.3522 0.803435L13 2.15562L11.6478 0.803435C11.2432 0.398776 10.5873 0.398982 10.1827 0.803291L10.1825 0.803435L9.80344 1.18253C9.39878 1.58719 9.39898 2.24303 9.80329 2.64767L9.80344 2.64781L11.1556 4L9.80344 5.35219C9.39878 5.75685 9.39898 6.41269 9.80329 6.81733L9.80344 6.81747L10.1825 7.19657C10.5871 7.60115 11.2432 7.60115 11.6478 7.19657L13 5.84438L14.3522 7.19657C14.7568 7.60115 15.4129 7.60115 15.8175 7.19657L16.1966 6.81747C16.6012 6.41281 16.601 5.75697 16.1967 5.35233L16.1966 5.35219L14.8444 4L16.1966 2.64781C16.6012 2.24315 16.601 1.58731 16.1967 1.18267Z', :fill='color', :stroke='stroke || color')
</template>

<script>
  export default {
    props: ['color', 'stroke'],
  };
</script>
