<template lang="pug">
.campaign-alert(v-if="hasDeprecatedIntegrationInCampaign")
  template(v-for="integrationType in savedDeprecatedIntegrations" v-if="hasText(integrationType)")
    transition(name="fade")
      om-toast.mb-5.mt-2(:closable="false" color="warning" type="side")
        .alert-block-header.mb-3.align-items-center {{ $t(`integrations.${integrationType}.reIntegration.alert.campaignAlertTitle`) }}
        .alert-block.mb-2
          .alert-block-wrapper.d-flex.flex-column
            .alert-block-title.d-flex.align-items-center(v-html="getAlertTitle(integrationType)")
            .alert-block-description.d-flex
              .description(
                v-html="$t(`integrations.${integrationType}.reIntegration.alert.altTitle`)"
              )
              om-link.d-flex.align-items-center.alert-block-link.ml-1(
                primary
                @click="$router.push(navigateToReIntegration(integrationType))"
              ) {{ $t(`integrations.${integrationType}.reIntegration.alert.link`) }}
</template>
<script>
  import reIntegrationMixin from '../reIntegration';

  export default {
    mixins: [reIntegrationMixin],
    props: {
      campaignIntegrations: {
        type: Array,
        required: true,
      },
    },
    computed: {
      hasDeprecatedIntegrationInCampaign() {
        const name = this.campaignIntegrations.map(({ global: { type } }) => type);

        return this.savedDeprecatedIntegrations.some((integrationName) =>
          name.includes(integrationName),
        );
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/components/ReIntegration/reIntegration.sass'
</style>
