export default {
  methods: {
    canBeDeleted(expressionGroupIndex) {
      return (
        this.currentRule.options.expressions.length > 1 ||
        this.currentRule.options.expressions[expressionGroupIndex].length > 1
      );
    },
    isLastRuleItem(expressionGroupIndex, targetIndex) {
      return (
        expressionGroupIndex === this.currentRule.options.expressions.length - 1 &&
        targetIndex === this.currentRule.options.expressions[expressionGroupIndex].length - 1
      );
    },
  },
};
